import React, { useEffect, useRef } from 'react';
import { DownloadOutlined, EditOutlined, FileAddOutlined } from '@ant-design/icons';
import { $generateHtmlFromNodes } from '@lexical/html';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import ArrowRight from '../../../../../../../../components/Icons/ArrowRight';
import api from '../../../../service';
import { downloadFile } from '../../../../utils/downloadFile';

export default function FileOptionDropDownList({
    actions,
    setTitle,
    documentTitle,
    editor,
    toolbarRef,
    onCloseDropdown,
    setDocToken,
    inputRef
}) {
    const dropDownRef = useRef(null);

    useEffect(() => {
        const toolbar = toolbarRef.current;

        const dropDown = dropDownRef.current;

        if (toolbar !== null && dropDown !== null) {
            const { top, left } = toolbar.getBoundingClientRect();

            dropDown.style.top = `${top + 85}px`;
            dropDown.style.left = `${left + 20}px`;
        }
    }, [dropDownRef, toolbarRef]);

    useEffect(() => {
        const dropDown = dropDownRef.current;

        const toolbar = toolbarRef.current;

        if (dropDown !== null && toolbar !== null) {
            const handle = (event) => {
                const target = event.target;

                if (!dropDown.contains(target) && !toolbar.contains(target)) {
                    onCloseDropdown();
                }
            };

            document.addEventListener('click', handle);

            return () => {
                document.removeEventListener('click', handle);
            };
        }
    }, [dropDownRef, toolbarRef]);

    const onDownloadFile = async (fileType) => {
        editor.update(async () => {
            const htmlString = $generateHtmlFromNodes(editor, null);

            const response = await api.document.download({ htmlString, fileType });

            await actions.update({ htmlString, title: documentTitle, id: localStorage.getItem('DocToken') });

            downloadFile(response, documentTitle, fileType);
        });

        onCloseDropdown();
    };

    const onCreateNewDoc = async () => {
        await actions.create({ setLocalStorage: setDocToken });
        editor.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);

        setTitle(() => '');
        onCloseDropdown();
    };

    const onRenameFile = async () => {
        inputRef.current.focus({
            cursor: 'end'
        });

        onCloseDropdown();
    };

    return (
        <div className='dropdown' ref={dropDownRef}>
            <button className='item' onClick={onCreateNewDoc}>
                <span className='text'>
                    <FileAddOutlined />
                    New
                </span>
            </button>
            <button className='item download'>
                <span className='text'>
                    <DownloadOutlined />
                    Download
                    <ArrowRight />
                </span>
                <div className='upload-dropdown-wrapper'>
                    <div className='upload-dropdown'>
                        <button onClick={() => onDownloadFile('docx')}>Microsoft Word (.docx)</button>
                        <button onClick={() => onDownloadFile('pdf')}>PDF Document (.pdf)</button>
                    </div>
                </div>
            </button>
            <button className='item' onClick={onRenameFile}>
                <span className='text'>
                    <EditOutlined />
                    Rename
                </span>
            </button>
        </div>
    );
}
