import { axios } from '../../../../../../services/http/axios';
import { post } from '../../../../../../services/http/fetch';
import { chatStream } from '../../../../../../services/stream';

export const messageService = {
    list: async (conversationId) => {
        const response = await axios.get(`/chat/conversations/${conversationId}/messages`);

        return response.data;
    },
    get: async (conversationId, messageId) => {
        const response = await axios.get(`/chat/conversations/${conversationId}/messages/${messageId}`);

        return response.data;
    },
    readAloud: async (conversationId, messageId, voice) => {
        const response = await axios.post(`/chat/conversations/${conversationId}/messages/${messageId}/read`, {
            voice
        });

        return response.data;
    },
    create: async (conversationId, body, controller, onChunk, onAbort, done, onError) => {
        const headers = {
            Accept: 'text/event-stream',
            'Content-Type': 'multipart/form-data',
            'x-access-token': localStorage.getItem('BrainstormAuthToken')
        };

        try {
            const response = await post(`/chat/conversations/${conversationId}/messages`, body, {
                headers,
                signal: controller.signal
            });

            await chatStream(response, onChunk, done);
        } catch (e) {
            if (e.name === 'AbortError') {
                onAbort();
            } else {
                onError();
            }
        }
    },
    update: async (conversationId, messageId, body) => {
        const response = await axios.patch(`/chat/conversations/${conversationId}/messages/${messageId}`, body);

        return response.data;
    },
    retry: async (conversationId, messageId) => {
        const response = await axios.post(`/chat/conversations/${conversationId}/messages/${messageId}/retry`);

        return response.data;
    },
    clear: async (conversationId) => {
        const response = await axios.delete(`/chat/conversations/${conversationId}/messages`);

        return response.data;
    }
};
