export const initState = {
    languages: [],
    voicesByLanguage: [],
    voicesById: [],
    presenters: [],
    singleVideo: {},
    history: {
        data: [],
        pagination: {}
    },
    modals: {
        share: {
            status: false,
            id: ''
        },
        loadVideo: {
            status: false,
            isLoadingVideo: true,
            text: ''
        }
    },
    loader: {
        voice: {
            list: false
        },
        presenter: {
            list: false,
            upload: false,
            remove: false
        },
        talk: {
            single: false,
            list: false,
            create: false,
            remove: false
        }
    }
};

export function imageToVideoReducer(draft, action) {
    switch (action.type) {
        case 'SET_DURATIONS': {
            draft.history.durations = [...draft.history.durations, action.payload];
            break;
        }
        case 'VIDEO_LOADING_MODAL': {
            draft.modals.loadVideo.status = action.payload;
            break;
        }
        case 'HISTORY_MODAL': {
            draft.modals.share.status = action.payload.status;
            draft.modals.share.id = action.payload.id;
            break;
        }
        case 'GET_VOICES': {
            draft.loader.voice.list = true;
            break;
        }
        case 'GET_VOICES_SUCCESS': {
            draft.loader.voice.list = false;
            draft.languages = action.payload.languages;
            draft.voicesByLanguage = action.payload.voicesByLanguage;
            draft.voicesById = action.payload.voicesById;
            break;
        }
        case 'GET_VOICES_ERROR': {
            draft.loader.voice.list = false;
            break;
        }
        case 'GET_PRESENTERS': {
            draft.loader.presenter.list = true;
            break;
        }
        case 'GET_PRESENTERS_SUCCESS': {
            draft.loader.presenter.list = false;
            draft.presenters = action.payload;
            break;
        }
        case 'GET_PRESENTERS_ERROR': {
            draft.loader.presenter.list = false;
            break;
        }
        case 'UPLOAD_PRESENTER': {
            draft.loader.presenter.upload = true;
            break;
        }
        case 'UPLOAD_PRESENTER_SUCCESS': {
            draft.loader.presenter.upload = false;
            draft.presenters = [...draft.presenters, action.payload];
            break;
        }
        case 'UPLOAD_PRESENTER_ERROR': {
            draft.loader.presenter.upload = false;
            break;
        }
        case 'REMOVE_PRESENTER': {
            draft.loader.presenter.remove = true;
            break;
        }
        case 'REMOVE_PRESENTER_SUCCESS': {
            draft.loader.presenter.remove = false;
            draft.presenters = draft.presenters.filter((presenter) => presenter._id !== action.payload);
            break;
        }
        case 'REMOVE_PRESENTER_ERROR': {
            draft.loader.presenter.remove = false;
            break;
        }
        case 'CREATE_TALK': {
            draft.modals.loadVideo.text = '';
            draft.modals.loadVideo.isLoadingVideo = true;
            draft.loader.talk.create = true;
            draft.modals.loadVideo.status = true;
            break;
        }
        case 'CREATE_TALK_SUCCESS': {
            draft.loader.talk.create = false;
            draft.modals.loadVideo.isLoadingVideo = false;
            draft.modals.loadVideo.text = 'Your video is ready. Please visit history page.';
            break;
        }
        case 'CREATE_TALK_ERROR': {
            draft.loader.talk.create = false;
            draft.modals.loadVideo.isLoadingVideo = false;
            draft.modals.loadVideo.status = false;
            draft.modals.loadVideo.text = '';
            break;
        }
        case 'GET_TALKS': {
            draft.loader.talk.list = true;
            break;
        }
        case 'GET_TALKS_SUCCESS': {
            draft.loader.talk.list = false;
            draft.history.data = action.payload.data;
            draft.history.pagination = action.payload.pagination;
            break;
        }
        case 'GET_TALKS_ERROR': {
            draft.loader.talk.list = false;
            break;
        }
        case 'SHARE_VIDEO': {
            draft.loader.talk.single = true;
            break;
        }
        case 'SHARE_VIDEO_SUCCESS': {
            draft.loader.talk.single = false;
            draft.singleVideo = action.payload;
            break;
        }
        case 'SHARE_VIDEO_ERROR': {
            draft.loader.talk.single = false;
            break;
        }
        case 'REMOVE_VIDEO': {
            draft.loader.talk.remove = true;
            break;
        }
        case 'REMOVE_VIDEO_SUCCESS': {
            draft.loader.talk.remove = false;
            draft.history.data = draft.history.data.filter((row) => {
                return row._id !== action.payload.id;
            });
            break;
        }
        case 'REMOVE_VIDEO_ERROR': {
            draft.loader.talk.remove = false;
            break;
        }
        default: {
            return draft;
        }
    }
}
