import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Empty, Pagination, Spin, Tabs, Typography } from 'antd';
import { useSearchParams } from '../../../../hooks/searchParams';
import ImagesList from './components/ImagesList';
import { useArtHistory } from './hooks';
import './ArtHistoryPage.scss';

const DEFAULT_PAGE = 1;

const DEFAULT_PAGE_SIZE = 14;

const DEFAULT_PAGE_SIZE_FAV = 12;

export function ArtHistoryPage() {
    const historyUrl = useHistory();

    const { state, actions } = useArtHistory();

    const [tab, setTab] = useState('all');

    const searchParams = useSearchParams();

    useEffect(() => {
        actions.list(state.currentPage, DEFAULT_PAGE_SIZE);
        searchParams.set('page', state.currentPage);
        searchParams.set('limit', DEFAULT_PAGE_SIZE);
        historyUrl.push({ pathname: '/art/history', search: searchParams.toString() });
    }, []);

    const onChangePage = (page, pageSize) => {
        searchParams.set('page', page);
        historyUrl.push({ pathname: '/art/history', search: searchParams.toString() });

        if (tab === 'favorite') {
            searchParams.set('page', page);
            searchParams.set('favorite', true);
            historyUrl.push({ pathname: '/art/history', search: searchParams.toString() });

            return actions.list(page, pageSize, true);
        }

        actions.list(page, pageSize);
    };

    const updateFavoriteArt = async (item) => {
        await actions.update(item._id, {
            isFavorite: !item.isFavorite
        });

        if (tab === 'favorite') {
            const changeCurPage =
                state.images.length === 1 && state.currentPage !== '1' ? state.currentPage - 1 : state.currentPage;

            actions.list(changeCurPage, DEFAULT_PAGE_SIZE_FAV, true);
        }
    };

    const onChangeTab = async (tabKey) => {
        setTab(tabKey);
        searchParams.delete('favorite', true);
        historyUrl.push({ pathname: '/art/history', search: searchParams.toString() });

        if (tabKey === 'favorite') {
            searchParams.set('page', DEFAULT_PAGE);
            searchParams.set('limit', DEFAULT_PAGE_SIZE_FAV);
            searchParams.set('favorite', true);
            historyUrl.push({ pathname: '/art/history', search: searchParams.toString() });

            return await actions.list(DEFAULT_PAGE, DEFAULT_PAGE_SIZE_FAV, tabKey === 'favorite');
        }

        actions.list(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
    };

    const children = (
        <React.Fragment>
            {state.images.length === 0 && state.loader === false ? (
                <React.Fragment>
                    {tab === 'favorite' ? (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_DEFAULT}
                            description={
                                <React.Fragment>
                                    <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                        Your favorited images will appear here.
                                    </p>
                                    <p>You can favorite images as you&apos;re creating.</p>
                                </React.Fragment>
                            }
                        />
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_DEFAULT}
                            description={
                                <React.Fragment>
                                    <p style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                        Your history images will appear here.
                                    </p>
                                    <p>You can generate or upscale images.</p>
                                </React.Fragment>
                            }
                        />
                    )}
                </React.Fragment>
            ) : (
                <div className='art-history__spin'>
                    <Spin spinning={state.loader} size='large' delay={500}>
                        <div
                            className={tab === 'favorite' ? 'art-history__favorite' : 'art-history__images'}
                            loading={state.loader}
                        >
                            <ImagesList array={state.images} changeFavorite={updateFavoriteArt} tabCurrent={tab} />
                        </div>
                        {state.totalPages > 1 && (
                            <Pagination
                                style={{ width: '100%', textAlign: 'end', marginTop: '13px' }}
                                current={+state.currentPage}
                                defaultCurrent={1}
                                pageSize={tab === 'favorite' ? +DEFAULT_PAGE_SIZE_FAV : +DEFAULT_PAGE_SIZE}
                                onChange={onChangePage}
                                total={state.total}
                                showSizeChanger={false}
                            />
                        )}
                    </Spin>
                </div>
            )}
        </React.Fragment>
    );

    return (
        <div className='art-history'>
            <Typography.Title>History</Typography.Title>
            <Tabs
                defaultActiveKey={tab}
                onChange={onChangeTab}
                items={[
                    {
                        label: 'All generation',
                        key: 'all',
                        children
                    },
                    {
                        label: 'Favorite',
                        key: 'favorite',
                        children
                    }
                ]}
            />
        </div>
    );
}
