export const downloadMp3 = async (voice, url) => {
    const res = await fetch(url, { mode: 'cors' });

    const blob = await res.blob();

    let a = document.createElement('a');

    a.href = URL.createObjectURL(blob);
    a.download = `${voice}_${Date.now()}.mp3`;
    a.click();
    a = null;
};

export const downloadBlob = (data, filename) => {
    const url = window.URL.createObjectURL(new Blob([data], { type: 'application/zip' }));

    let a = document.createElement('a');

    a.href = url;
    a.setAttribute('download', filename);
    document.body.appendChild(a);
    a.click();
    a.parentNode.removeChild(a);
    a = null;
};
