import React, { useContext } from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { App, Col, Row, Tooltip, Typography } from 'antd';
import PropTypes from 'prop-types';
import { $colorWhite } from '../../../../../../styles/global';
import { useAuthContext } from '../../../../../AuthPage/context';
import { DashboardContext } from '../../../../context';

export default function ToolCard({ collapsed, tool, history, actions = null }) {
    const auth = useAuthContext();

    const context = useContext(DashboardContext);

    const { notification } = App.useApp();

    const isFavoriteTool = auth.profile?.favoriteTools.includes(tool._id);

    const onFavoriteClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            await auth.tools.favorite.toggle(tool._id);
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };

    return (
        <Col
            key={`tool-alias-${tool.alias}-id-${tool._id}`}
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}
            xxl={{ span: 6 }}
        >
            <Row
                data-tool-alias={tool.alias}
                onClick={(e) => {
                    if (actions) actions.promptsOpen(false);

                    if (typeof collapsed === 'function') collapsed(false);

                    const { toolAlias } = e.currentTarget.dataset;

                    history.push(`/tools/${toolAlias}`);
                }}
                className='tools-page__single-card'
            >
                {tool.categories.includes('My tools') ? null : (
                    <Tooltip destroyTooltipOnHide overlayStyle={{ position: 'fixed' }} title='Save to Favorites'>
                        {isFavoriteTool ? (
                            <StarFilled style={{ color: $colorWhite }} onClick={onFavoriteClick} />
                        ) : (
                            <StarOutlined
                                style={{ color: $colorWhite }}
                                className='tool-card-favorite-icon'
                                onClick={onFavoriteClick}
                            />
                        )}
                    </Tooltip>
                )}
                <Col
                    style={{
                        background: context.state.tool.tabs.find((item) => tool.categories[0] === item.tab)?.bgGradient
                    }}
                >
                    <Typography.Title style={{ color: $colorWhite }} level={4}>
                        {tool.title}
                    </Typography.Title>
                </Col>
                <Col>
                    <Typography.Paragraph ellipsis={{ rows: 5 }}>{tool.about}</Typography.Paragraph>
                </Col>
            </Row>
        </Col>
    );
}

ToolCard.propTypes = {
    tool: PropTypes.shape({
        alias: PropTypes.string.isRequired,
        icon: PropTypes.element,
        title: PropTypes.string.isRequired
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};
