import { voices } from './constants';

export const initState = {
    audio: {
        file: null,
        popup: false,
        loading: false,
        error: null
    },
    samples: {
        id: null,
        status: false
    },
    textCount: 0,
    voice: null,
    gender: voices.male,
    activeTab: 'male',
    loader: {
        speech: {
            remove: false
        },
        voice: {
            get: false,
            add: false,
            remove: false
        }
    },
    loadingList: false,
    history: {
        list: [],
        pagination: {
            total: 0,
            limit: 10,
            totalPages: 1,
            currentPage: 1,
            nextPage: null,
            hasPrevPage: false,
            hasNextPage: false
        }
    }
};

export function speechReducer(draft, action) {
    switch (action.type) {
        case 'SPEECH_LIST_ITEM_PLAY': {
            draft.audio.file = action.payload.url;
            draft.voice = action.payload.voiceName;
            draft.audio.popup = true;
            break;
        }
        case 'SPEECH_LIST_ITEM_REMOVE': {
            draft.loader.speech.remove = true;
            break;
        }
        case 'SPEECH_LIST_ITEM_REMOVE_FAILED': {
            draft.loader.speech.remove = false;
            break;
        }
        case 'SPEECH_LIST': {
            draft.loadingList = true;
            if (!action.payload) {
                draft.history.list = draft.history.list.concat(
                    [...new Array(5)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {}
                    }))
                );
            }
            break;
        }
        case 'SPEECH_LIST_SUCCESS': {
            if (action.payload.replace) {
                draft.history.list = action.payload.list;
            } else {
                draft.history.list = draft.history.list.filter((item) => !item.loading).concat(action.payload.list);
            }
            draft.history.pagination = action.payload.pagination;
            draft.loadingList = false;
            draft.loader.speech.remove = false;
            break;
        }
        case 'SPEECH_LIST_FAILED': {
            draft.loadingList = false;
            break;
        }
        case 'SAMPLE_PLAY': {
            draft.samples.status = true;
            draft.samples.id = action.payload.id;
            break;
        }
        case 'SAMPLE_STOP': {
            draft.samples.status = false;
            draft.samples.id = action.payload.id;
            break;
        }
        case 'LOAD_AUDIO': {
            draft.voice = action.payload.name;
            draft.audio.loading = true;
            draft.audio.error = null;
            break;
        }
        case 'LOAD_SUCCESS': {
            draft.audio.file = action.payload.path;
            draft.audio.popup = true;
            draft.audio.loading = false;
            break;
        }
        case 'LOAD_FAILED': {
            draft.audio.popup = false;
            draft.audio.loading = false;
            draft.audio.error = 'Something went wrong, please try again';
            break;
        }
        case 'CLOSE_POPUP': {
            draft.audio.popup = false;
            break;
        }
        case 'CHANGE_GENDER': {
            draft.gender = action.payload === 'female' ? voices.female : voices.male;
            draft.activeTab = action.payload;
            break;
        }
        case 'TEXT_COUNT': {
            draft.textCount = action.payload;
            break;
        }
        case 'CUSTOM_GET': {
            draft.loader.voice.get = true;
            break;
        }
        case 'CUSTOM_SUCCESS': {
            draft.loader.voice.get = false;
            draft.activeTab = 'custom';
            draft.gender = action.payload;
            break;
        }
        case 'CUSTOM_FAILED': {
            draft.loader.voice.get = false;
            break;
        }
        case 'CUSTOM_ADD': {
            draft.loader.voice.add = true;
            break;
        }
        case 'CUSTOM_ADD_SUCCESS': {
            draft.loader.voice.add = false;
            draft.activeTab = 'custom';
            draft.gender.push(action.payload);
            break;
        }
        case 'CUSTOM_ADD_FAILED': {
            draft.loader.voice.add = false;
            break;
        }
        case 'VOICE_DELETE': {
            draft.loader.voice.remove = true;
            break;
        }
        case 'VOICE_DELETE_SUCCESS': {
            draft.loader.voice.remove = false;
            draft.gender = draft.gender.filter((it) => it.voiceId !== action.payload);

            if (draft.activeTab === 'custom' && draft.gender.length === 0) {
                draft.activeTab = 'male';
                draft.gender = voices.male;
            }

            break;
        }
        case 'VOICE_DELETE_FAILED': {
            draft.loader.voice.remove = false;
            break;
        }
        default: {
            return draft;
        }
    }
}
