import React from 'react';
import { Image } from 'antd';
import cn from 'classnames';

export const ChatImages = ({ images }) => {
    return (
        <div className={cn({ 'chat-images': true }, { single: images.length < 2 })}>
            {images?.map((file, key) => (
                <Image
                    getContainer={() => document.querySelector('#app-layout')}
                    key={key}
                    alt='preview'
                    src={file.path ? file.path : URL.createObjectURL(file.originFileObj)}
                />
            ))}
        </div>
    );
};
