import React from 'react';

export default function PlusCircleBorder() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='75' height='75' fill='none' viewBox='0 0 75 75'>
            <g fill='#1F1F1F' fillOpacity='0.5' clipPath='url(#clip0_4500_2325)'>
                <path d='M52.902 34.822H40.179V22.1a.672.672 0 00-.67-.67H35.49a.672.672 0 00-.67.67v12.723H22.098a.672.672 0 00-.67.67v4.018c0 .368.302.67.67.67h12.723v12.723c0 .368.302.67.67.67h4.018c.368 0 .67-.302.67-.67V40.18h12.723c.368 0 .67-.302.67-.67v-4.018a.672.672 0 00-.67-.67z' />
                <path d='M37.5 0C16.791 0 0 16.791 0 37.5S16.791 75 37.5 75 75 58.209 75 37.5 58.209 0 37.5 0zm0 68.638c-17.193 0-31.138-13.945-31.138-31.138S20.307 6.362 37.5 6.362 68.638 20.307 68.638 37.5 54.693 68.638 37.5 68.638z' />
            </g>
            <defs>
                <clipPath id='clip0_4500_2325'>
                    <path fill='#fff' d='M0 0H75V75H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
