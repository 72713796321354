/* eslint-disable */

const config = {
    _id: 112,
    title: 'Personal Development Planner',
    alias: 'personal-development-planner',
    about: 'Helps students set and track personal development goals. It can provide tips and resources to achieve these goals and monitor progress over time.',
    categories: ['Education'],
    tips: [
        'Encourage students to set realistic and measurable goals.',
        'Use the AI tool regularly to keep track of progress and adjust the plan if necessary.',
        'Celebrate milestones and progress to keep students motivated.'
    ],
    prompts: [
        'Help [student name] set personal development goals for this school year.',
        'Track [student name]\'s progress on their personal development goals.',
        'Suggest resources to help [student name] achieve their goal of [specific goal].',
        'How can [student name] improve their [specific skill]?',
        'What milestones should we celebrate in [student name]\'s personal development plan?'
    ]
};

export default config;
