import React, { useEffect, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import ScrollToBottom from 'react-scroll-to-bottom';
import { CloudUploadOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Flex, notification, Radio, Row, Typography, Upload } from 'antd';
import cn from 'classnames';
import ChatGPT3 from '../../../../components/Icons/ChatGPT3/ChatGPT3';
import ChatGPT4 from '../../../../components/Icons/ChatGPT4/ChatGPT4';
import ClaudeIcon from '../../../../components/Icons/ClaudeIcon';
import { useBreakpoint } from '../../../../hooks/media';
import { uuid } from '../../../../utils/crypto';
import { useAuthContext } from '../../../AuthPage/context';
import { uploadImage } from '../ImageUpscalePage/hooks';
import { useBilling } from '../SettingsPage/pages/BillingPage/hooks';
import { ChatConversation } from './components/Conversation';
import { useConversationContext } from './components/Conversation/context';
import { ChatInput } from './components/Input';
import { beforeUpload } from './components/Input/Components/utils/upload';
import { KnowledgeBaseDrawer } from './components/KnowledgeBase';
import { ChatMessages } from './components/Messages';
import PromptLibrary from './components/PromptLibrary';
import ShareModal from './components/ShareModal';
import WelcomeModal from './components/WelcomeModal';
import './ChatPage.scss';

const { Dragger } = Upload;

const leftColSettings = {
    lg: { span: 18, order: 1 },
    md: { span: 24, order: 1 },
    sm: { span: 24, order: 1 },
    xs: { span: 24, order: 1 }
};

const rightColSettings = {
    lg: { span: 6, order: 2 },
    md: { span: 24, order: 2 },
    sm: { span: 24, order: 1 }
};

const engineChoice = [
    {
        engine: 'GPT-3.5',
        icon: <ChatGPT3 />,
        title: 'Fastest Model',
        description: 'Great for everyday tasks'
    },
    {
        engine: 'GPT-4.0',
        icon: <ChatGPT4 />,
        title: 'Best Model',
        description: 'Ideal for tasks that demand \n' + 'creativity and advanced reasoning.'
    },
    {
        engine: 'Claude 3',
        icon: <ClaudeIcon />,
        title: 'Deep Model',
        description:
            'Designed for extensive context—ideal \n' + 'for long detailed narratives and \n' + 'complex discussions.'
    }
];

export const Popover = ({ btn, billing }) => {
    return (
        <div className='chat-btn-engine__wrap__popover'>
            <div>
                <Typography.Title level={3}>{btn.title}</Typography.Title>
                <p>{btn.description}</p>
                <span>
                    {btn.engine === 'GPT-4.0' || btn.engine === 'Claude 3'
                        ? 'Available exclusively for members'
                        : 'Available for free and paid members'}
                    {billing?.state?.plan?.planType === 'free' && <NavLink to='/settings/billing'> upgrade</NavLink>}
                </span>
            </div>
        </div>
    );
};

export default function ChatPage({ setDocToken, collapsed, ctrlRef }) {
    const [dragZoneVisibility, setDragZoneVisibility] = useState(false);
    const dragBoxRef = useRef(null);
    const wrapperRef = useRef(null);
    const dragBox = dragBoxRef.current;
    const wrapper = wrapperRef.current;

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    const [showShareModal, setShowShareModal] = useState(false);

    const auth = useAuthContext();

    const { isMobile } = useBreakpoint();

    const [engine, setEngine] = useState(auth.profile.chatEngine || engineChoice[0].engine);

    const { conversation, message } = useConversationContext();

    const billing = useBilling();

    const org = auth.org.getCurrent();

    const isStartChat = message.state.data.docs.length < 1;

    useEffect(() => {
        billing.actions.fetch(org);

        setShowWelcomeModal(() => auth?.profile?.modalActiveStatuses?.welcome);

        return () => {
            ctrlRef.current?.abort();
        };
    }, []);

    const onDragEnter = (e) => {
        setDragZoneVisibility(true);
        e.stopPropagation();
        e.preventDefault();
    };

    const onMouseLeave = (e) => {
        setDragZoneVisibility(false);
        e.stopPropagation();
        e.preventDefault();
    };

    const onDragLeave = (e) => {
        setDragZoneVisibility(false);
        e.stopPropagation();
        e.preventDefault();
    };

    const onDrop = (e) => {
        e.preventDefault();
        setDragZoneVisibility(false);
    };

    useEffect(() => {
        if (dragBox) {
            dragBox.addEventListener('mouseleave', onMouseLeave);
            dragBox.addEventListener('dragleave', onDragLeave);
            dragBox.addEventListener('drop', onDrop);
        }

        if (wrapper) {
            wrapper.addEventListener('dragenter', onDragEnter);
        }

        return () => {
            if (dragBox) {
                dragBox.removeEventListener('mouseleave', onMouseLeave);
                dragBox.removeEventListener('dragleave', onDragLeave);
                dragBox.removeEventListener('drop', onDrop);
            }
            if (wrapper) {
                wrapper.removeEventListener('dragenter', onDragEnter);
            }
        };
    }, [dragBoxRef.current]);

    if (!conversation?.state?.current) return null;

    const lastMsg = message.state.data.docs.at(-1);

    const onNewMessage = (formData, content, regenerate = false) => {
        formData.append('engine', engine);

        // Prevent from pushing new message if last message is not responded
        if (!lastMsg || lastMsg.respond) {
            ctrlRef.current = new AbortController();
            message.actions.create(conversation.state.current._id, formData, ctrlRef.current, content);
        }
    };

    const stopGenerating = () => {
        ctrlRef.current.abort();
    };

    const chooseEngine = async (e) => {
        setEngine(e.target.value);

        try {
            await auth.updateProfile(
                {
                    chatEngine: e.target.value
                },
                false
            );
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };

    const isChatInputDisabled = message.state.data.docs.length && !lastMsg?.respond;

    const isFreePlan = billing?.state?.plan?.planType === 'free';

    const isUploadDisabled = billing?.state?.plan?.status !== 'active' || isFreePlan;

    const onChangeFile = async ({ file, fileList }) => {
        const beforeUploadCheck = await beforeUpload(file);

        if (beforeUploadCheck) {
            message.actions.setUploadFiles({ content: fileList });
        }
    };

    return (
        <Row className='chat-wrap'>
            <Col {...leftColSettings} ref={wrapperRef}>
                <Dragger
                    disabled={isUploadDisabled}
                    accept='.pdf, .csv, .txt, .jpeg, .png, .gif, .webp, .jpg'
                    maxCount={3}
                    onChange={onChangeFile}
                    name='file'
                    multiple
                    customRequest={uploadImage}
                    showUploadList
                    className={cn({ 'chat-wrap__drag': true }, { active: dragZoneVisibility })}
                    fileList={message.state.uploadFileList}
                    openFileDialogOnClick={false}
                >
                    <div ref={dragBoxRef} className='chat-wrap__drag__wrapper'>
                        <div className='chat-wrap__drag__title'>
                            <CloudUploadOutlined />
                            {isUploadDisabled ? (
                                <React.Fragment>
                                    <Typography.Title>Available to paid members only!</Typography.Title>
                                    <Typography.Text>Please upgrade your plan</Typography.Text>
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Typography.Title>Add Anything</Typography.Title>
                                    <Typography.Text>Drop any file here to add it to the conversation</Typography.Text>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                </Dragger>
                <div className='chat-share'>
                    <Flex>
                        {engineChoice.map((item) => {
                            if (item.engine !== engine) return;
                            return (
                                <Flex gap={4} align='center' key={uuid()}>
                                    {item.icon}
                                    <Typography.Text>{item.engine}</Typography.Text>
                                </Flex>
                            );
                        })}
                    </Flex>
                    <Button
                        disabled={!message.state.data?.docs?.length}
                        onClick={() => setShowShareModal(true)}
                        size='small'
                        type='default'
                        icon={<UploadOutlined />}
                    />
                </div>
                <div className='chat'>
                    <div className='scroll-to-bottom__wrapper'>
                        {isStartChat && (
                            <Radio.Group
                                onChange={(value) => chooseEngine(value)}
                                value={engine}
                                className='chat-btn-engine__wrap'
                            >
                                {engineChoice.map((btn) => (
                                    <Radio.Button
                                        disabled={(btn.engine === 'GPT-4.0' || btn.engine === 'Claude 3') && isFreePlan}
                                        key={uuid()}
                                        value={btn.engine}
                                        className={engine === btn.engine ? 'isActive' : null}
                                    >
                                        {btn.icon}
                                        {btn.engine}
                                        <Popover btn={btn} billing={billing} />
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        )}
                        <ScrollToBottom className='scroll-to-bottom' followButtonClassName='scroll-button'>
                            <ChatMessages
                                conversation={conversation}
                                onNewMessage={onNewMessage}
                                setDocToken={setDocToken}
                                auth={auth}
                                avatar={auth.profile.avatar}
                                message={message}
                            />
                        </ScrollToBottom>
                        <div className='chat-input-container'>
                            {message.state.loader.streaming && (
                                <Button className='chat-button-stop' onClick={stopGenerating}>
                                    Stop Generating
                                </Button>
                            )}
                            <ChatInput
                                setEngine={() => setEngine(engineChoice[2].engine)}
                                isUploadDisabled={isUploadDisabled}
                                conversation={conversation}
                                create={onNewMessage}
                                disabled={isChatInputDisabled}
                                message={message}
                                ctrlRef={ctrlRef}
                            />
                            <PromptLibrary collapsed={collapsed} conversation={conversation} message={message} />
                            <KnowledgeBaseDrawer collapsed={collapsed} conversation={conversation} message={message} />
                        </div>
                    </div>
                </div>
            </Col>
            {!isMobile ? (
                <Col {...rightColSettings}>
                    <ChatConversation ctrlRef={ctrlRef} conversation={conversation} message={message} />
                </Col>
            ) : null}
            {showWelcomeModal ? (
                <WelcomeModal open={showWelcomeModal} onClose={() => setShowWelcomeModal(false)} />
            ) : null}
            {showShareModal ? (
                <ShareModal
                    conversation={conversation}
                    auth={auth}
                    message={message}
                    open={showShareModal}
                    onClose={() => setShowShareModal(false)}
                />
            ) : null}
        </Row>
    );
}
