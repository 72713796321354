import React from 'react';
import { Button } from 'antd';
import cn from 'classnames';
import styles from './index.module.scss';

export default function EmailStatusBadge(props) {
    return (
        <Button type='primary' className={cn(props.badge, styles[props.status || 'success'], styles.badge)}>
            {props.children}
        </Button>
    );
}
