import React from 'react';
import { Typography } from 'antd';
import styles from './BookTitle.module.scss';

export default function BookTitle({ title }) {
    return (
        <Typography.Title style={{ marginBottom: '0px' }} className={styles.book__title}>
            {title}
        </Typography.Title>
    );
}
