import React from 'react';
import { Form, Input, Typography } from 'antd';

const ToolCreationForm = ({ form, onCreateNewTool }) => {
    return (
        <Form form={form} onFinish={onCreateNewTool}>
            <Typography.Text>Need your own tool? Create your favorite tool and custom prompt here.</Typography.Text>
            <Form.Item>
                <Typography.Title level={5}>Tool Title</Typography.Title>
                <Form.Item name='title' rules={[{ required: true }]}>
                    <Input maxLength={80} showCount placeholder='Ex: Blog Article Generator' />
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Typography.Title level={5}>About Tool</Typography.Title>
                <Form.Item name='about'>
                    <Input.TextArea
                        autoSize={{ minRows: 3, maxRows: 3 }}
                        showCount
                        maxLength={250}
                        placeholder='Ex: This Tool helps me generate blog articles using just a blog title'
                    />
                </Form.Item>
            </Form.Item>
            <Form.Item>
                <Typography.Title level={5}>Prompt</Typography.Title>
                <Form.Item name='prompts'>
                    <Input.TextArea
                        autoSize={{ minRows: 6, maxRows: 6 }}
                        showCount
                        maxLength={3000}
                        placeholder='Example: Given my video blog title: [blog title] and target audience:
                        [target audience], generate a long blog article that is SEO optimized.'
                    />
                </Form.Item>
            </Form.Item>
        </Form>
    );
};

export default ToolCreationForm;
