import React, { Fragment, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { DownloadOutlined, LinkOutlined, MoreOutlined, ReloadOutlined } from '@ant-design/icons';
import { App, Button, Card, Col, Dropdown, Image, Input, Popover, Radio, Row, Space, Spin, Typography } from 'antd';
import ChatGPT3Icon from '../../../../components/Icons/ChatGPT3/ChatGPT3';
import ChatGPT4Icon from '../../../../components/Icons/ChatGPT4/ChatGPT4';
import { CDN_URL } from '../../../../config';
import { useBreakpoint } from '../../../../hooks/media';
import { axios } from '../../../../services/http/axios';
import { uuid } from '../../../../utils/crypto';
import { download } from '../../../../utils/image';
import { useAuthContext } from '../../../AuthPage/context';
import { ArtTemplate } from '../ImageTemplates/components/ArtTemplate';
import { configurations } from '../ImageTemplates/configurations';
import ArtIntro from './components/ArtIntro/ArtIntro';
import { EnhanceModal } from './components/EnhanceModal';
import { PopoverPrompt } from './components/PopoverPrompt';
import { ReportModal } from './components/ReportModal';
import { surpriseStrings } from './constants';
import { useArt } from './hooks';
import './ArtPage.scss';

const { TextArea } = Input;

const imageAspectRatio = ['1:1', '7:4', '4:7'];

export default function ArtPage({ history }) {
    const location = useLocation();

    const { isMobile, isTable } = useBreakpoint();

    const [aspectRatio, setAspectRatio] = useState(0);

    const { actions, state } = useArt();

    const [value, setValue] = React.useState(location.state?.text || '');

    const [buttonVisibility, setButtonVisibility] = React.useState(false);

    const [loading, setLoading] = React.useState(false);

    const [images, setImages] = React.useState([]);

    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const [singleImageReload, setSingleImageReload] = React.useState([]);

    const [, setReportedImage] = React.useState(null); // TODO: report mechanism

    const auth = useAuthContext();

    const { notification } = App.useApp();

    const org = auth.org.getCurrent();

    useEffect(() => {
        if (location.state?.text) {
            (async () => {
                await actions.promptPopover(true);
            })();
        }
    }, [location.state?.text]);

    const isDisabledAdvancedBtn = () => {
        return !(
            org.billingPlan.planType !== 'free' && +org.billingPlan.images.limit - +org.billingPlan.images.value >= 1
        );
    };

    const engineChoice = [
        {
            engine: 'Basic',
            icon: <ChatGPT3Icon />,
            isDisabled: false,
            titlePopover: 'Basic Model',
            contextPopover: 'Ideal for casual users, providing essential tools to convert text into images with ease.'
        },
        {
            engine: 'Advanced',
            icon: <ChatGPT4Icon />,
            isDisabled: isDisabledAdvancedBtn(),
            titlePopover: 'Advanced Model',
            contextPopover: 'Capable of producing remarkably detailed and lifelike visuals.'
        }
    ];

    const [engine, setEngine] = useState(engineChoice[0].engine);

    const isDarkMode = auth?.profile?.uiSettings?.darkMode;

    const chooseEngine = (e) => {
        setEngine(e.target.value);
    };

    const onHandleChange = (e) => {
        setValue(e.target.value);

        if (e.target.value.length > 15) {
            setButtonVisibility(true);
        } else {
            setButtonVisibility(false);
        }
    };

    const onRandomPrompt = () => {
        const randomIdx = parseInt(Math.random() * surpriseStrings.length);

        setValue(surpriseStrings[randomIdx]);
    };

    const onFinish = async (e) => {
        if (e.key === 'Enter') {
            return;
        }

        if (!value) {
            return notification.warning({
                message: 'Please type in the field.',
                description: 'You cannot to generate empty request.',
                placement: 'top'
            });
        }

        setLoading(() => true);

        try {
            const { data } = await axios.post('/stability/text-to-image/generate', {
                content: value,
                engine,
                aspectRatio
            });

            setImages(() => data);
        } catch (e) {
            if (e.response.status !== 402) {
                notification.error({
                    message: 'Error',
                    description: 'Something went wrong. Please try again later.',
                    placement: 'top'
                });
            }
        }

        setLoading(() => false);
    };

    const openSingleImage = (base64URL) => {
        const win = window.open();

        const colorBg = isDarkMode ? '#141414' : '#fff';

        win.document.write(
            `<body style="margin:0;padding:0"><div style="width:100%; height:100%; background-color:${colorBg}; display:flex; justify-content:center; align-items:center"><img src=${base64URL}></img></div></body>`
        );
        win.document.close();
    };

    const onEnhancePrompt = async () => {
        await actions.modals.helper(true);
        await actions.helper({ value, engine });
    };

    return (
        <Spin spinning={state.loader.main}>
            <div className='art-page'>
                <ArtIntro />
                <div className='art-btn-engine__wrap'>
                    {engineChoice.map((btn) => (
                        <Radio.Button
                            key={uuid()}
                            value={btn.engine}
                            className={engine === btn.engine ? 'isActive' : null}
                            disabled={btn.isDisabled}
                            onClick={chooseEngine}
                        >
                            {btn.icon}
                            {btn.engine}
                            <div className='art-btn-engine__wrap__popover'>
                                <div>
                                    <Typography.Title level={3}>{btn.titlePopover}</Typography.Title>
                                    <p>{btn.contextPopover}</p>
                                    {btn.engine === 'Basic' ? (
                                        <span>Available for free and paid members</span>
                                    ) : (
                                        <React.Fragment>
                                            <span>Available exclusively for members </span>
                                            {org?.billingPlan?.planType === 'free' && (
                                                <span>
                                                    <NavLink to='/settings/billing'>upgrade</NavLink>
                                                </span>
                                            )}
                                        </React.Fragment>
                                    )}
                                </div>
                            </div>
                        </Radio.Button>
                    ))}
                </div>
                <div className='art-page__explanation'>
                    <div>
                        <Typography.Text type='secondary'>Not sure how this works?</Typography.Text>
                        <Typography.Text type='secondary'>Get an example by clicking on the button:</Typography.Text>
                    </div>
                    <Button htmlType='submit' onClick={() => onRandomPrompt()}>
                        Click Here
                    </Button>
                    {buttonVisibility ? (
                        <Button className='prompt-helper' onClick={onEnhancePrompt}>
                            Enhance Prompt
                        </Button>
                    ) : null}
                </div>
                <div className='art-page__search'>
                    <Popover
                        overlayClassName='art-page__search__popover'
                        getPopupContainer={() => document.querySelector('.art-page')}
                        placement='bottomLeft'
                        title='Input Prompt Details'
                        open={state.promptPopover}
                        content={() => (
                            <PopoverPrompt actions={actions} setValue={setValue} text={location.state?.text} />
                        )}
                    >
                        <TextArea
                            showCount
                            maxLength={4000}
                            rows={4}
                            style={{
                                color: '1F1F1F'
                            }}
                            value={value}
                            placeholder='Generate futuristic AI images'
                            onChange={onHandleChange}
                            onPressEnter={onFinish}
                            onPaste={onHandleChange}
                        />
                    </Popover>
                    <Row className='art-page__search__btn'>
                        <Col span={24}>
                            <Button loading={loading} htmlType='submit' type='primary' onClick={(e) => onFinish(e)}>
                                Generate
                            </Button>
                        </Col>
                        {isMobile || isTable ? null : (
                            <Col span={24} className='art-page__search__btn__sizes'>
                                {imageAspectRatio.map((item, key) => {
                                    return (
                                        <Button
                                            onClick={() => setAspectRatio(key)}
                                            key={key}
                                            className={aspectRatio === key ? 'active' : null}
                                        >
                                            {item}
                                        </Button>
                                    );
                                })}
                            </Col>
                        )}
                    </Row>
                </div>
                {!images.length ? (
                    <Spin className='spinner' spinning={loading} delay={500}>
                        {isMobile || isTable ? (
                            <div className='art-page__empty'>
                                <Image
                                    height='100%'
                                    width='100%'
                                    preview={false}
                                    src={`${CDN_URL}/other/just-think-lightbulb.png`}
                                />
                                <Typography.Title>Create an Image</Typography.Title>
                            </div>
                        ) : (
                            <Fragment>
                                <Typography.Title className='art-page__templates__title'>
                                    Image Templates
                                </Typography.Title>
                                <Typography.Text className='art-page__templates__descr'>
                                    Need Help? Our Pre-made Art Templates Have You Covered!
                                </Typography.Text>
                                <Row
                                    gutter={[
                                        { xs: 16, sm: 16, md: 30 },
                                        { xs: 16, sm: 16, md: 30 }
                                    ]}
                                >
                                    {configurations.map((art) => {
                                        return (
                                            <ArtTemplate
                                                isFavoriteShow={false}
                                                state={state}
                                                key={`art-card-${art._id}`}
                                                art={art}
                                                history={history}
                                            />
                                        );
                                    })}
                                </Row>
                            </Fragment>
                        )}
                    </Spin>
                ) : (
                    <Row
                        gutter={[
                            { xs: 3, sm: 3, md: 3, lg: 4, xl: 4 },
                            { xs: 3, sm: 3, md: 3, lg: 4, xl: 4 }
                        ]}
                    >
                        {images.map((image, index) => (
                            <Col xs={12} sm={12} md={12} lg={12} xl={12} key={'img-' + index}>
                                <Spin
                                    style={{ maxHeight: '100%' }}
                                    spinning={loading || singleImageReload.includes(index)}
                                    delay={500}
                                >
                                    <Card
                                        className='art-card'
                                        style={{
                                            borderRadius: '0px'
                                        }}
                                        bodyStyle={{ padding: '0' }}
                                        hoverable
                                        cover={
                                            <Image
                                                style={{
                                                    borderRadius: '0px'
                                                }}
                                                placeholder={
                                                    <Image
                                                        crossOrigin='anonymous'
                                                        placeholder
                                                        preview={false}
                                                        src={image.url}
                                                    />
                                                }
                                                alt=''
                                                src={image.url}
                                                onClick={null}
                                                crossOrigin='anonymous'
                                            />
                                        }
                                    >
                                        <Dropdown
                                            className='img-dropdown'
                                            menu={{
                                                items: [
                                                    {
                                                        label: (
                                                            <div
                                                                style={{ display: 'flex', justifyContent: 'start' }}
                                                                onClick={() => openSingleImage(image.url)}
                                                            >
                                                                <LinkOutlined />
                                                                <p style={{ marginLeft: '8px' }}>New Tab</p>
                                                            </div>
                                                        ),
                                                        key: '1'
                                                    },
                                                    {
                                                        type: 'divider'
                                                    },
                                                    {
                                                        label: (
                                                            <div
                                                                style={{ display: 'flex', justifyContent: 'start' }}
                                                                onClick={async () => {
                                                                    const currentImgIndex = images.findIndex(
                                                                        (img) => img.url === image.url
                                                                    );

                                                                    try {
                                                                        setSingleImageReload((imgIds) => [
                                                                            ...imgIds,
                                                                            currentImgIndex
                                                                        ]);
                                                                        setImages((prev) =>
                                                                            prev.map((row, index) => ({
                                                                                ...row,
                                                                                ...(index === currentImgIndex
                                                                                    ? { loading: true }
                                                                                    : {})
                                                                            }))
                                                                        );

                                                                        const idArtImg = images[currentImgIndex]._id;

                                                                        const { data } = await axios.post(
                                                                            '/stability/text-to-image/variation',
                                                                            { id: idArtImg, engine }
                                                                        );

                                                                        const newImage = data[0];

                                                                        setImages((prev) =>
                                                                            prev.map((row, index) => ({
                                                                                ...(index === currentImgIndex
                                                                                    ? newImage
                                                                                    : row)
                                                                            }))
                                                                        );

                                                                        setSingleImageReload((imgIds) =>
                                                                            imgIds.filter(
                                                                                (item) => item !== currentImgIndex
                                                                            )
                                                                        );
                                                                    } catch (error) {
                                                                        setSingleImageReload((imgIds) =>
                                                                            imgIds.filter(
                                                                                (item) => item !== currentImgIndex
                                                                            )
                                                                        );

                                                                        if (error.response?.status !== 402) {
                                                                            notification.error({
                                                                                placement: 'top',
                                                                                message: 'Error',
                                                                                description:
                                                                                    'Something went wrong. Please try again later.'
                                                                            });
                                                                        }
                                                                    }

                                                                    setImages((prev) =>
                                                                        prev.map((row, index) => ({
                                                                            ...row,
                                                                            ...(index === currentImgIndex
                                                                                ? { loading: false }
                                                                                : {})
                                                                        }))
                                                                    );
                                                                }}
                                                            >
                                                                <ReloadOutlined />
                                                                <p style={{ marginLeft: '8px' }}>Variations</p>
                                                            </div>
                                                        ),
                                                        key: '2'
                                                    },
                                                    {
                                                        type: 'divider'
                                                    },
                                                    {
                                                        label: (
                                                            <div
                                                                style={{ display: 'flex', justifyContent: 'center' }}
                                                                onClick={() => download(image.url, image.prompt)}
                                                            >
                                                                <DownloadOutlined />
                                                                <p style={{ marginLeft: '8px' }}>Download</p>
                                                            </div>
                                                        ),
                                                        key: '3'
                                                    }
                                                ]
                                            }}
                                            overlayStyle={{ cursor: 'pointer' }}
                                        >
                                            <MoreOutlined className='img-dropdown-icon' />
                                        </Dropdown>
                                        <Space wrap className='art-generated-btns'>
                                            <Button
                                                size='small'
                                                type='default'
                                                icon={<LinkOutlined />}
                                                onClick={() => openSingleImage(image.url)}
                                            >
                                                New Tab
                                            </Button>
                                            <Button
                                                size='small'
                                                type='default'
                                                icon={<ReloadOutlined />}
                                                onClick={async () => {
                                                    const currentImgIndex = images.findIndex(
                                                        (img) => img.url === image.url
                                                    );

                                                    try {
                                                        setSingleImageReload((imgIds) => [...imgIds, currentImgIndex]);
                                                        setImages((prev) =>
                                                            prev.map((row, index) => ({
                                                                ...row,
                                                                ...(index === currentImgIndex ? { loading: true } : {})
                                                            }))
                                                        );

                                                        const idArtImg = images[currentImgIndex]._id;

                                                        const { data } = await axios.post(
                                                            '/stability/text-to-image/variation',
                                                            { id: idArtImg, engine }
                                                        );

                                                        const newImage = data[0];

                                                        setImages((prev) =>
                                                            prev.map((row, index) => ({
                                                                ...(index === currentImgIndex ? newImage : row)
                                                            }))
                                                        );

                                                        setSingleImageReload((imgIds) =>
                                                            imgIds.filter((item) => item !== currentImgIndex)
                                                        );
                                                    } catch (error) {
                                                        setSingleImageReload((imgIds) =>
                                                            imgIds.filter((item) => item !== currentImgIndex)
                                                        );

                                                        if (error.response?.status !== 402) {
                                                            notification.error({
                                                                placement: 'top',
                                                                message: 'Error',
                                                                description:
                                                                    'Something went wrong. Please try again later.'
                                                            });
                                                        }
                                                    }

                                                    setImages((prev) =>
                                                        prev.map((row, index) => ({
                                                            ...row,
                                                            ...(index === currentImgIndex ? { loading: false } : {})
                                                        }))
                                                    );
                                                }}
                                            >
                                                Variations
                                            </Button>
                                            <Button
                                                size='small'
                                                type='default'
                                                icon={<DownloadOutlined />}
                                                onClick={() => download(image.url, image.prompt)}
                                            >
                                                Download
                                            </Button>
                                        </Space>
                                    </Card>
                                </Spin>
                            </Col>
                        ))}
                    </Row>
                )}
                <ReportModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    setReportedImage={setReportedImage}
                />
                <EnhanceModal engine={engine} state={state} actions={actions} setValue={setValue} />
            </div>
        </Spin>
    );
}
