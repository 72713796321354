import React from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'antd';

const useModal = (Component, props) => {
    const [open, setOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const onOk = props.onOk || (() => {});
    const handleOk = () => {
        (async () => {
            setLoading(true);
            return onOk();
        }).then(() => {
            setOpen(false);
        });
    };

    const onCancel = props.onCancel || (() => {});
    const handleCancel = () => {
        setOpen(false);
        onCancel();
    };

    const handleAfterClose = () => {
        setLoading(false);
    };

    return {
        setModalLoading: setLoading,
        setModalOpen: setOpen,
        modal: ReactDOM.createPortal(
            <Modal
                title={props.title}
                open={open}
                confirmLoading={loading}
                onOk={handleOk}
                onCancel={handleCancel}
                afterClose={handleAfterClose}
                {...props.modalProps}
            >
                <Component {...props.componentProps} />
            </Modal>,
            document.body
        )
    };
};

export default useModal;
