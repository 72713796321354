import React from 'react';
import { App, Button, Card, Radio, Space, Typography } from 'antd';
import { uuid } from '../../../../../../utils/crypto';
import styles from './BookCover.module.scss';

export default function BookCover({ item, index }) {
    const coverWrap = React.useRef();

    const { notification } = App.useApp();

    const bookCoverLeftHalfBind = React.useMemo(() => {
        return {
            onMouseEnter: () => {
                coverWrap.current.classList.add(`${styles.book_rotate__left}`);
            },
            onMouseLeave: () => {
                coverWrap.current.classList.remove(`${styles.book_rotate__left}`);
            }
        };
    }, []);

    const bookCoverRightHalfBind = React.useMemo(() => {
        return {
            onMouseEnter: () => {
                coverWrap.current.classList.add(`${styles.book_rotate__right}`);
            },
            onMouseLeave: () => {
                coverWrap.current.classList.remove(`${styles.book_rotate__right}`);
            }
        };
    }, []);

    const chooseBookType = (e, item) => {
        e.preventDefault();

        if (!item.isAvailable) {
            const key = 'book-select-warning';

            return notification.warning({
                key,
                message: 'Sorry, this book is not available.',
                description: item.title + ' is coming soon.',
                placement: 'top',
                maxCount: 1
            });
        }
    };

    return (
        <Radio.Button
            key={uuid()}
            name='bookType'
            value={item.title}
            data-key={index}
            className={styles.book_cover}
            onClick={(e) => chooseBookType(e, item)}
        >
            <Card.Grid
                className={item.isAvailable ? `${styles.book_card__available}` : `${styles.book_card__not_available}`}
            >
                <Space
                    direction='vertical'
                    size='middle'
                    style={{
                        display: 'flex'
                    }}
                >
                    <Button disabled>{item.isAvailable ? 'Available' : 'Coming Soon'}</Button>
                    <div ref={coverWrap} className={styles.book_cover__wrap}>
                        <div className={styles[`front_cover_${index}`]}>
                            <div {...bookCoverLeftHalfBind} className={styles[`book_cover__first_half_${index}`]} />
                            <div {...bookCoverRightHalfBind} className={styles[`book_cover__second_half_${index}`]} />
                        </div>
                    </div>
                    <Typography.Paragraph
                        style={{ textAlign: 'center' }}
                        className={item.isAvailable ? `${styles.book__title}` : `${styles.book__title_disabled}`}
                    >
                        {item.title}
                    </Typography.Paragraph>
                </Space>
            </Card.Grid>
        </Radio.Button>
    );
}
