import { useImmerReducer } from 'use-immer';
import { billingReducer, initState } from './reducer';
import billingService from './service';

export const useBilling = () => {
    const [state, dispatch] = useImmerReducer(billingReducer, initState);

    const addUserRadioButton = (value) => {
        dispatch({
            type: 'ADD_USER_BUTTON',
            payload: value
        });
    };

    const setLoader = (value) => {
        dispatch({
            type: 'BILLING_MODAL_LOADER',
            payload: value
        });
    };

    const fetch = async (org) => {
        dispatch({
            type: 'GET_BILLING'
        });

        try {
            dispatch({
                type: 'GET_BILLING_SUCCESS',
                payload: await billingService.fetch(org)
            });
        } catch (error) {
            dispatch({
                type: 'GET_BILLING_ERROR'
            });
        }
    };

    const intent = {
        confirm: async (org, searchParams, history) => {
            try {
                dispatch({
                    type: 'CONFIRM_INTENT'
                });

                const data = await billingService.confirmIntent(org, searchParams);

                dispatch({
                    type: 'CONFIRM_INTENT_SUCCESS',
                    payload: data.stripe.cards
                });

                history.push('/settings/billing');
            } catch (error) {
                dispatch({
                    type: 'CONFIRM_INTENT_ERROR'
                });
            }
        },
        confirmNoRedirect: async (org, setupIntent, form) => {
            try {
                dispatch({
                    type: 'CONFIRM_INTENT'
                });

                const data = await billingService.confirmNoRedirectIntent(org, setupIntent);

                if (!data.stripe.cards.length) {
                    return false;
                }

                form.setFieldValue('card', data.stripe.intent.paymentMethod);

                dispatch({
                    type: 'CONFIRM_INTENT_SUCCESS',
                    payload: data.stripe.cards
                });

                // history.push('/settings/billing');
            } catch (error) {
                dispatch({
                    type: 'CONFIRM_INTENT_ERROR'
                });
            }
        },
        setup: async (org) => {
            try {
                dispatch({
                    type: 'SETUP_INTENT'
                });

                const data = await billingService.setupIntent(org);

                dispatch({
                    type: 'SETUP_INTENT_SUCCESS',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'SETUP_INTENT_ERROR'
                });
            }
        }
    };

    const modal = {
        open: (name) => {
            dispatch({
                type: 'OPEN_MODAL',
                payload: name
            });
        },
        close: (name) => {
            dispatch({
                type: 'CLOSE_MODAL',
                payload: name
            });
        }
    };

    const plan = {
        calculate: async (org, values) => {
            try {
                dispatch({
                    type: 'CALCULATE_START'
                });

                const data = await billingService.calculate(org, values);

                dispatch({
                    type: 'CALCULATE_PRICE',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'CALCULATE_END'
                });
            }
        },
        cancel: async (org, values) => {
            try {
                dispatch({
                    type: 'CANCEL_PLAN'
                });

                await billingService.cancelPlan(org, values);

                dispatch({
                    type: 'CANCEL_PLAN_SUCCESS'
                });
            } catch (error) {
                dispatch({
                    type: 'CANCEL_PLAN_ERROR'
                });
            }
        },
        update: async (org, values) => {
            try {
                dispatch({
                    type: 'UPDATE_PLAN'
                });

                const data = await billingService.completeIntent(org, values);

                dispatch({
                    type: 'UPDATE_PLAN_SUCCESS'
                });

                return data;
            } catch (error) {
                dispatch({
                    type: 'UPDATE_PLAN_ERROR'
                });

                throw error;
            }
        }
    };

    const card = {
        detach: async (org, paymentMethod, form) => {
            try {
                dispatch({
                    type: 'DETACH_CARD'
                });

                const {
                    billing: { stripe }
                } = await billingService.detachCard(org, paymentMethod);

                form.setFieldValue('card', stripe.intent.paymentMethod);

                dispatch({
                    type: 'DETACH_CARD_SUCCESS',
                    payload: stripe
                });
            } catch (error) {
                dispatch({
                    type: 'DETACH_CARD_ERROR'
                });
            }
        },
        change: async (org, paymentMethod) => {
            try {
                dispatch({
                    type: 'CHANGE_CARD'
                });

                const billing = await billingService.changeCard(org, paymentMethod);

                dispatch({
                    type: 'CHANGE_CARD_SUCCESS',
                    payload: billing
                });
            } catch (error) {
                dispatch({
                    type: 'CHANGE_CARD_ERROR'
                });
            }
        }
    };

    const discount = {
        add: async (org, values) => {
            dispatch({
                type: 'ADD_DISCOUNT',
                payload: values.discountCode
            });

            try {
                const data = await billingService.addDiscount(org, values);

                dispatch({
                    type: 'ADD_DISCOUNT_SUCCESS',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'ADD_DISCOUNT_ERROR',
                    payload: error.response.data?.message || 'Something went wrong'
                });
            }
        },
        remove: async (org, discountId) => {
            dispatch({
                type: 'REMOVE_DISCOUNT'
            });

            try {
                await billingService.removeDiscount(org, discountId);

                dispatch({
                    type: 'REMOVE_DISCOUNT_SUCCESS'
                });
            } catch (error) {
                dispatch({
                    type: 'REMOVE_DISCOUNT_ERROR',
                    payload: error.response.data?.message || 'Something went wrong'
                });
            }
        },

        clearError: () => {
            dispatch({
                type: 'CLEAR_DISCOUNT_ERROR'
            });
        }
    };

    return {
        state,
        actions: {
            setLoader,
            addUserRadioButton,
            fetch,
            intent,
            modal,
            plan,
            card,
            discount
        }
    };
};
