import React from 'react';
import { Form } from 'antd';
import InputForm from '../../components/InputForm/InputForm';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import SelectForm from '../../components/SelectForm/SelectForm';
import styles from './BookDetails.module.scss';

export default function BookDetailsPage() {
    const form = Form.useFormInstance();

    const onAgeChange = (value) => {
        form.setFieldsValue({
            ageGroup: value
        });
    };

    const arrayBtns = [
        {
            name: 'Shot',
            value: 'shot',
            nameGroup: 'storyLength',
            isDefaultActive: true
        },
        {
            name: 'Long',
            value: 'long',
            nameGroup: 'storyLength',
            isDefaultActive: false
        }
    ];

    return (
        <React.Fragment>
            <Form.Item
                className={styles.details_form__label}
                label='Book Title'
                name='bookTitle'
                labelCol={{ span: 24 }}
                onChange={(e) => {
                    form.setFieldsValue({
                        bookTitle: e.target.value
                    });
                }}
                rules={[
                    {
                        required: true,
                        message: 'Please input book title!'
                    }
                ]}
            >
                <InputForm />
            </Form.Item>
            <Form.Item
                className={styles.details_form__label}
                name='nameMainCharacter'
                label='Main Character Name'
                labelCol={{ span: 24 }}
                onChange={(e) => {
                    form.setFieldsValue({
                        nameMainCharacter: e.target.value
                    });
                }}
                rules={[
                    {
                        required: true,
                        message: 'Please input main character Name!'
                    }
                ]}
            >
                <InputForm />
            </Form.Item>
            <Form.Item
                className={styles.details_form__label}
                name='ageGroup'
                label='Age Group'
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: 'Please select age group!'
                    }
                ]}
            >
                <SelectForm setAgeGroup={onAgeChange} />
            </Form.Item>
            <Form.Item
                className={styles.details_form__label}
                label='Story Length'
                name='storyLength'
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: 'Please select story length!'
                    }
                ]}
            >
                <div className={styles.book_details__radio_btn}>
                    <RadioGroup arrayBtns={arrayBtns} initialBtnActive='shot' />
                </div>
            </Form.Item>
        </React.Fragment>
    );
}
