import React from 'react';
import { Form } from 'antd';
import TextAreaForm from '../../components/TextAreaForm/TextAreaForm';
import styles from './YourStoryPage.module.scss';

export default function YourStoryPage() {
    const form = Form.useFormInstance();

    return (
        <Form.Item
            className={styles.story__form_label}
            label=''
            name='storyText'
            onChange={(e) => {
                form.setFieldsValue({
                    storyText: e.target.value
                });
            }}
            rules={[
                {
                    required: true,
                    message: 'Please type your story!'
                }
            ]}
        >
            <TextAreaForm />
        </Form.Item>
    );
}
