import { axios } from '../../../../../../services/http/axios';

export const conversationService = {
    list: async (searchParams) => {
        const response = await axios.get(`/chat/conversations?${searchParams.toString()}`);

        return response.data;
    },
    create: async () => {
        const response = await axios.post('/chat/conversations');

        return response.data;
    },
    update: async (id, body) => {
        const response = await axios.patch(`/chat/conversations/${id}`, body);

        return response.data;
    },
    remove: async (id) => {
        const response = await axios.delete(`/chat/conversations/${id}`);

        return response.data;
    },
    knowledgeBase: {
        list: async () => {
            const response = await axios.get('/embeddings/base/');

            return response.data;
        },
        chat: async (chatId, knowledgeId, action, type) => {
            const response = await axios.post(`/embeddings/chat/${chatId}/${knowledgeId}`, { action, type });

            return response.data;
        }
    }
};
