import { useImmerReducer } from 'use-immer';
import { dashboardReducer, initState } from './reducer';
import service from './service';

export const useDashboard = () => {
    const [state, dispatch] = useImmerReducer(dashboardReducer, initState);

    const toolsList = async (payload) => {
        try {
            dispatch({
                type: 'TOOLS_LIST_START'
            });

            const response = await service.tools.list();

            dispatch({
                type: 'TOOLS_LIST_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'TOOLS_LIST_END'
            });
        }
    };

    const billing = {
        modal: (status) => {
            dispatch({
                type: 'BILLING_MODAL',
                payload: status
            });
        },
        setStep: (step) => {
            dispatch({
                type: 'BILLING_SET_STEP',
                payload: step
            });
        },
        loader: (status) => {
            dispatch({
                type: 'BILLING_LOADING',
                payload: status
            });
        }
    };
    const billingPage = {
        modal: (status) => {
            dispatch({
                type: 'BILLING_UPGRADE_MODAL',
                payload: status
            });
        }
    };
    const instruction = {
        createInstruction: async (payload) => {
            dispatch({
                type: 'INSTRUCTION_MODAL_ADD',
                payload
            });
        }
    };

    const actions = {
        toolsList,
        billing,
        billingPage,
        instruction
    };

    return {
        state,
        actions
    };
};
