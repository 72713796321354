import React from 'react';

export default function UndoIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='15' height='20' fill='none' viewBox='0 0 15 20'>
            <path
                fill='#1F1F1F'
                fillOpacity='0.7'
                d='M15 11.25c0-4.825-3.925-8.75-8.75-8.75H5V0L0 5l5 5V7.5h1.25c3.45 0 6.25 2.8 6.25 6.25S9.7 20 6.25 20c4.825 0 8.75-3.925 8.75-8.75z'
            />
        </svg>
    );
}
