/* eslint-disable */
const config = {
    _id: 103,
    title: 'SEL (Social Emotional Learning) Support',
    alias: 'social-emotional-learning-support',
    about: 'Provide strategies, activities, and resources to support social emotional learning in students.',
    categories: ['Education'],
    tips: [
        'Provide details about your students\' age, social dynamics, and specific needs for personalized recommendations.',
        'Regularly incorporate SEL activities into your teaching to foster students\' emotional intelligence.'
    ],
    prompts: [
        'What are some daily classroom activities to support SEL for [grade level]?',
        'Provide strategies to foster emotional intelligence in [grade level] students.',
        'Suggest a lesson plan focusing on empathy for [grade level].',
        'How can I integrate SEL into my [subject] teaching?',
        'Suggest a list of books that promote SEL for [grade level] students.'
    ]
};

export default config;
