import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, Image, notification, Tooltip, Typography } from 'antd';
import cn from 'classnames';
import JustThinkLogoSmall from '../../../../../../../../assets/images/bulb-icon-mini.png';
import LockIcon from '../../../../../../../../components/Icons/LockIcon';
import { CDN_URL } from '../../../../../../../../config';
import { containsWordInBrackets } from '../../../../../../../../utils/containsWordInBrackets';
import { DashboardContext } from '../../../../../../context';
import styles from './index.module.scss';

export const SinglePrompt = ({ art, prompt, isFreePlan, index, actions }) => {
    const history = useHistory();
    let image;

    if (art.categories[0] === 'my templates') {
        image = prompt.image;
    } else {
        image = `${CDN_URL}/art-template/${art.categories[0]}/${art.alias}/prompts/${prompt.image}.jpg`;
    }

    const context = useContext(DashboardContext);
    const onUpdateBilling = async () => {
        await context.actions.billing.modal(true);
    };
    const onTryPrompt = () => {
        if (!containsWordInBrackets(prompt.text)) {
            return notification.error({
                placement: 'top',
                message: 'Text should contain at least 1 prompt in square brackets. For example: [prompt]'
            });
        }

        history.push('/art/image-generator', { text: prompt.text, replace: true });
    };

    const onRemove = async () => {
        await actions.template.remove({ id: art._id, promptId: prompt._id });
        await actions.template.list();
    };

    return (
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
            <div
                className={cn(styles['single-art__prompt'], {
                    'free-plan': isFreePlan
                })}
            >
                <Button onClick={onRemove} className={styles['single-art__prompt__remove']} icon={<DeleteOutlined />} />
                <Image preview={false} alt={prompt.text} src={image || JustThinkLogoSmall} />
                <Typography.Paragraph className={styles['single-art__prompt__descr']}>
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '"' +
                                prompt.text?.replace(/\[(.*?)\]/g, function (text) {
                                    return '<span>' + text + '</span>';
                                }) +
                                '"'
                        }}
                    />
                    {isFreePlan && index ? (
                        <Tooltip
                            className={styles['single-art__prompt__tooltip']}
                            title='Upgrade to unlock this art template'
                        >
                            <LockIcon />{' '}
                        </Tooltip>
                    ) : null}
                </Typography.Paragraph>
                {isFreePlan && index ? (
                    <Button className='menu-side__bottom__update' onClick={onUpdateBilling}>
                        Create
                    </Button>
                ) : (
                    <Button onClick={onTryPrompt}>Create</Button>
                )}
            </div>
        </Col>
    );
};
