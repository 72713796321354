import React, { useEffect } from 'react';
import { Button, Divider, Modal } from 'antd';
import { NODE_ENV } from '../../../../../../config';
import { useAuthContext } from '../../../../../AuthPage/context';
import welcomeImage from './welcome.png';
import './index.scss';

/* eslint-disable */
const MODAL_CONTENT_MAP = {
    lavegaisd: {
        title: () => 'Welcome LaVega ISD!',
        text: () =>
            'Just Think’s AI tools are designed to assist you in creating dynamic lesson plans and engaging quizzes. In addition, you can get answers instantly, transform text into speech, and even create art from text. Even if you\'re new to AI, it\'s simple to use. We\'re here to guide you. Excited to see the wonderful things you\'ll create with Just Think!'
    },
    default: {
        title: (fname) => `Welcome ${fname}!`,
        text: () =>
            'Just Think’s AI tools are designed with you in mind. Whether you need instant answers from our AI chat, want to transform text into speech, or create unique art from text, we\'ve got you covered . No prior experience with AI? No problem. Our platform is easy to navigate and we\'re always here to guide you. We\'re excited to see the wonderful things you\'ll create with Just Think!'
    }
};

export default function WelcomeModal({ open, onClose }) {
    const auth = useAuthContext();

    const handleOk = () => {
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    const isProduction = NODE_ENV === 'production';

    let title = MODAL_CONTENT_MAP.default.title(auth.profile.fname);

    let text = MODAL_CONTENT_MAP.default.text();

    if (
        (isProduction && auth.profile.email.endsWith('@lavegaisd.org')) ||
        (!isProduction && auth.profile.email.includes('lavegaisd'))
    ) {
        title = MODAL_CONTENT_MAP.lavegaisd.title();
        text = MODAL_CONTENT_MAP.lavegaisd.text();
    }

    useEffect(() => {
        auth.updateProfile({
            modalActiveStatuses: { welcome: false }
        });
    }, []);

    return (
        <Modal
            title={
                <div className='welcome-modal__cover'>
                    <img src={welcomeImage} alt='welcome' />
                </div>
            }
            width={586}
            className='welcome-modal'
            centered
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            closeIcon={null}
            footer={null}
            okText='Start Creating'
        >
            <div>
                <div>
                    <div className='welcome-modal__title'>{title}</div>
                    <div className='welcome-modal__text'>{text}</div>
                </div>
            </div>
            <Divider style={{ margin: '14.5px 0 16px 0' }} />
            <div className='welcome-modal-footer'>
                <Button type='primary' size='large' onClick={handleOk}>
                    Start Creating
                </Button>
            </div>
        </Modal>
    );
}
