import React, { useState } from 'react';
import { App, Button, Form, Radio, Typography } from 'antd';
import ProfessionSurveyCard from '../../components/ProfessionSurveyCard';
import { surveyConfig } from '../../constants/surveyConfig';
import { useAuthContext } from '../AuthPage/context';
import styles from './index.module.scss';

export default function UserSurveyPage() {
    const auth = useAuthContext();

    const [, setOtherOption] = useState(false);

    const [selectedOption, setSelectedOption] = useState(surveyConfig.choices[0].option);

    const [form] = Form.useForm();

    const { notification } = App.useApp();

    const onChangeSelect = (value) => {
        setSelectedOption(value);
        setOtherOption(() => false);
    };

    const onFinish = async (formValues) => {
        try {
            await auth.updateProfile({
                userProf: formValues.option
            });
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            <div className={styles.container}>
                <div className={styles.container__header}>
                    <Typography.Title className={`${styles['container__header--title']}`} level={2}>
                        Why are you using Just Think AI?
                    </Typography.Title>
                    <span className={`${styles['container__header--description']}`}>
                        Choose an option below to see how we can help you achieve your goals faster and more
                        efficiently.
                    </span>
                </div>

                <div className={styles.container__content}>
                    <Form
                        autoComplete='off'
                        form={form}
                        initialValues={{
                            option: surveyConfig.choices[0].option
                        }}
                        onFinish={onFinish}
                    >
                        <Form.Item name='option' className={styles.form_item}>
                            <Radio.Group className={styles.cards_group} buttonStyle='solid'>
                                {surveyConfig.choices.map((choice) => (
                                    <Radio.Button
                                        key={choice.option}
                                        value={choice.option}
                                        className={styles.cards_group__radio_button}
                                        onChange={(e) => onChangeSelect(e.target.value)}
                                    >
                                        <ProfessionSurveyCard
                                            title={choice.option}
                                            isSelected={choice.option === selectedOption}
                                        />
                                    </Radio.Button>
                                ))}
                            </Radio.Group>
                        </Form.Item>

                        <div className={`${styles['container__content--button']}`}>
                            <Form.Item>
                                <Button block type='primary' htmlType='submit' className={styles.button__submit}>
                                    Next
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
}
