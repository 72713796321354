const config = {
    _id: 80,
    title: 'Translate Copy',
    alias: 'translate-copy',
    about: 'Expand your reach with ad copy in multiple languages. This advanced language model can assist you in translating your ad copy with ease and accuracy. Whether you need to translate to one language or multiple, Just Think can help you communicate your message effectively.',
    categories: ['Marketing'],
    tips: ['Provide any specific guidelines or tone that you would like the translation or rewriting to adhere to.'],
    prompts: [
        'Translate the following copy into [language]: [ad ].',
        'Rewrite the following copy to make it more persuasive: [ad ].',
        'What are some alternative ways to phrase the following copy in [language]: [ad ].',
        'Write a compelling call-to-action for our [product/service] in [language]: [product/service description].',
        'Create a catchy tagline for our new [product/service] in [language]: [product/service description].'
    ]
};

export default config;
