/* eslint-disable */

export const animals = {
    _id: 4,
    title: 'Animals',
    alias: 'animals',
    about: 'Generate a variety of animals',
    image: 'animals',
    categories: ['animals'],
    tips: [],
    prompts: [
        {
            text: 'close up photography of a [animal], [location], animal photography, nature, animal, natural lighting, global illumination, sun lighting, ray traced lighting, rich colors, instagram contest, award-winning photography, highly detailed, highly textured, ultra resolution, photorealistic 8k, HDR, UHD, 64k, professional, F/2.8 TC',
            image: 'close-photography'
        },
        {
            text: 'A photograph of a [animal] in the [location], showcasing expert animal photography with natural, ray-traced lighting, rich colors, and highly detailed textures. The image is ultra realistic.',
            image: 'expert-photography'
        },
        {
            text: 'This is an incredibly lifelike picture that features a [animal] inhabiting the [location]. The photograph serves as a testament to the finesse of skilled animal photography. Its most captivating elements include naturally traced lighting, effectively capturing the interplay between light and shadow. Furthermore, it showcases an array of rich, vibrant colors that bring every aspect of the image to life, making it more appealing to the viewer\'s eye. Another noteworthy aspect is the superior level of detail in textures which makes every feature stand out distinctly, thereby contributing to its ultra-realistic quality. This intricate attention to detail provides viewers with a sense of being present in the scene itself.',
            image: 'lifelike-picture'
        }
    ]
};
