import React from 'react';
import { Button, Form, Input, Modal, notification, Spin, Typography } from 'antd';
import styles from '../index.module.scss';

const TipCreate = ({ id, state, actions }) => {
    const [form] = Form.useForm();
    const onCreateTip = async (values) => {
        try {
            const data = new FormData();
            data.append('values', JSON.stringify(values));
            await actions.template.update({ id, data });
            form.resetFields();
        } catch (err) {
            notification.error({
                placement: 'top',
                message: 'Something went wrong! Please try again.'
            });
        }

        await actions.template.list();
        actions.modals.close('tip');
    };
    return (
        <Modal
            title='New Tip'
            width={585}
            className='modal-component'
            centered
            open={state.modal.tip}
            onCancel={() => actions.modals.close('tip')}
            footer={null}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Spin spinning={state.loader.modal}>
                <Form className={styles['template-modals']} form={form} onFinish={onCreateTip}>
                    <Form.Item>
                        <Typography.Text>Tip</Typography.Text>
                        <Form.Item rules={[{ required: true }]} name='tips'>
                            <Input.TextArea
                                maxLength={300}
                                autoSize={{ minRows: 3, maxRows: 3 }}
                                placeholder='Write a text for a tip'
                            />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item className={styles['template-modals__footer']}>
                        <Button>Cancel</Button>
                        <Button htmlType='submit'>Add Tip</Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default TipCreate;
