import React, { createContext, useContext, useEffect } from 'react';
import { useMessages } from '../Messages/hooks';
import { messageService } from '../Messages/service';
import { useConversation } from './hooks';

const ConversationContext = createContext({});

const useConversationContext = () => useContext(ConversationContext);

const ConversationProvider = ({ children }) => {
    const conversation = useConversation();

    const message = useMessages();

    // When first loaded, fetch all conversations
    useEffect(() => {
        conversation.actions.list();
    }, []);

    // When conversation has been changed, fetch all messages of new conversation
    useEffect(() => {
        if (conversation.state.current?._id) {
            message.actions.list(conversation.state.current._id);
        }
    }, [conversation.state.current]);

    // When first message and conversation title is empty, update conversation title
    useEffect(() => {
        if (message.state.data.docs.length === 1 && !conversation.state.current?.title) {
            (async () => {
                const data = await messageService.list(conversation.state.current._id);

                if (!data?.docs[0]?.content) return;

                await conversation.actions.update(conversation.state.current._id, {
                    title: data?.docs[0]?.content
                });
            })();
        }
    }, [message.state.data.docs]);

    return <ConversationContext.Provider value={{ conversation, message }}>{children}</ConversationContext.Provider>;
};

export { ConversationProvider, useConversationContext };
