import { useImmerReducer } from 'use-immer';
import { conversationReducer, initState } from './reducer';
import { conversationService } from './service';

export const useConversation = () => {
    const [state, dispatch] = useImmerReducer(conversationReducer, initState);

    const knowledgeBase = {
        drawer: (status) => {
            dispatch({
                type: 'KNOWLEDGE_BASE_DRAWER',
                payload: status
            });
        },
        chat: async (chatId, knowledgeId, action, type) => {
            dispatch({
                type: 'KNOWLEDGE_BASE_CHAT'
            });

            try {
                const data = await conversationService.knowledgeBase.chat(chatId, knowledgeId, action, type);

                dispatch({
                    type: 'KNOWLEDGE_BASE_CHAT_SUCCESS',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'KNOWLEDGE_BASE_CHAT_ERROR'
                });
            }
        },
        list: async () => {
            dispatch({
                type: 'KNOWLEDGE_BASE_LIST'
            });

            try {
                const data = await conversationService.knowledgeBase.list();

                dispatch({
                    type: 'KNOWLEDGE_BASE_LIST_SUCCESS',
                    payload: data
                });
            } catch (error) {
                dispatch({
                    type: 'KNOWLEDGE_BASE_LIST_ERROR'
                });
            }
        }
    };

    const edit = (id) => {
        dispatch({
            type: 'EDIT_MODE',
            payload: id
        });
    };

    const promptsOpen = (payload) => {
        dispatch({
            type: 'PROMPTS_OPEN',
            payload
        });
    };

    const updateOptions = (payload) => {
        dispatch({
            type: 'UPDATE_OPTIONS',
            payload
        });
    };

    const select = (id) => {
        dispatch({
            type: 'SELECT',
            payload: id
        });
    };

    const list = async () => {
        dispatch({
            type: 'LIST'
        });

        try {
            const data = await conversationService.list(
                new URLSearchParams({
                    page: state.data.page,
                    limit: state.data.limit
                })
            );

            dispatch({
                type: 'LIST_SUCCESS',
                payload: data
            });

            if (!state.current) {
                const selected = data.list.find((item) => item.selected);

                select(selected?._id || data.list[0]?._id);
            }
        } catch (error) {
            dispatch({
                type: 'LIST_ERROR'
            });
        }
    };

    const create = async (payload) => {
        dispatch({
            type: 'CREATE'
        });

        try {
            const data = await conversationService.create(payload);

            dispatch({
                type: 'CREATE_SUCCESS',
                payload: data
            });
            select(data._id);
        } catch (error) {
            dispatch({
                type: 'CREATE_ERROR'
            });
        }
    };

    const update = async (id, payload) => {
        dispatch({
            type: 'UPDATE'
        });

        try {
            await conversationService.update(id, payload);

            dispatch({
                type: 'UPDATE_SUCCESS',
                payload: {
                    _id: id,
                    ...payload
                }
            });
        } catch (error) {
            dispatch({
                type: 'UPDATE_ERROR'
            });
        }
    };

    const remove = async (id) => {
        dispatch({
            type: 'REMOVE'
        });

        try {
            const response = await conversationService.remove(id);

            dispatch({
                type: 'REMOVE_SUCCESS',
                payload: id
            });

            select(response.conversation);
        } catch (error) {
            dispatch({
                type: 'REMOVE_ERROR'
            });
        }
    };

    const clear = async (id) => {
        dispatch({
            type: 'CLEAR'
        });

        try {
            await conversationService.clear(id);
            dispatch({
                type: 'CLEAR_SUCCESS'
            });
        } catch (error) {
            dispatch({
                type: 'CLEAR_ERROR'
            });
        }
    };

    return {
        state,
        actions: {
            knowledgeBase,
            promptsOpen,
            updateOptions,
            list,
            create,
            update,
            remove,
            clear,
            edit,
            select
        }
    };
};
