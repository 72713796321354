import { useImmerReducer } from 'use-immer';
import { artReducer, initState } from './reducer';
import service from './service';

export const useArt = () => {
    const [state, dispatch] = useImmerReducer(artReducer, initState);

    const helper = async (payload) => {
        try {
            dispatch({
                type: 'PROMPT_HELPER_START'
            });

            const response = await service.artService.helper(payload);

            dispatch({
                type: 'PROMPT_HELPER_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'PROMPT_HELPER_END'
            });
        }
    };

    const modals = {
        helper: (payload) => {
            dispatch({
                type: 'PROMPT_HELPER_MODAL',
                payload
            });
        }
    };

    const promptPopover = (payload) => {
        dispatch({
            type: 'PROMPT_POPOVER_STATUS',
            payload
        });
    };

    const actions = {
        modals,
        helper,
        promptPopover
    };

    return {
        state,
        actions
    };
};
