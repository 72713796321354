import React from 'react';
import Icon from '@ant-design/icons';

export default function Delete(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='18' viewBox='0 0 16 18' fill='none'>
                    <path
                        d='M15.4517 3.15348C14.176 3.02671 12.9003 2.93163 11.6168 2.86032V2.85239L11.4425 1.82236C11.3236 1.09342 11.1493 0 9.29523 0H7.21932C5.37317 0 5.19885 1.04588 5.07208 1.81444L4.90569 2.82863C4.16882 2.87617 3.43195 2.92371 2.69509 2.99501L1.07873 3.15348C0.745949 3.18517 0.508249 3.47834 0.539942 3.80319C0.571636 4.12805 0.856875 4.36575 1.18965 4.33406L2.80601 4.17559C6.95785 3.76358 11.1414 3.92204 15.3407 4.34198H15.4041C15.7052 4.34198 15.9667 4.1122 15.9984 3.80319C16.0221 3.47834 15.7844 3.18517 15.4517 3.15348Z'
                        fill='#292D32'
                    />
                    <path
                        d='M13.9942 5.4594C13.804 5.26132 13.5426 5.15039 13.2732 5.15039H3.25812C2.98873 5.15039 2.71934 5.26132 2.5371 5.4594C2.35487 5.65748 2.25186 5.92688 2.26771 6.20419L2.75896 14.3335C2.84611 15.5379 2.95704 17.0433 5.72228 17.0433H10.809C13.5742 17.0433 13.6853 15.5458 13.7723 14.3335L14.2636 6.21212C14.2794 5.92688 14.1764 5.65748 13.9942 5.4594ZM9.58089 13.0737H6.94243C6.61761 13.0737 6.34822 12.8043 6.34822 12.4795C6.34822 12.1546 6.61761 11.8852 6.94243 11.8852H9.58089C9.90575 11.8852 10.1751 12.1546 10.1751 12.4795C10.1751 12.8043 9.90575 13.0737 9.58089 13.0737ZM10.2465 9.90438H6.28483C5.95998 9.90438 5.69058 9.63499 5.69058 9.31013C5.69058 8.98528 5.95998 8.71588 6.28483 8.71588H10.2465C10.5713 8.71588 10.8407 8.98528 10.8407 9.31013C10.8407 9.63499 10.5713 9.90438 10.2465 9.90438Z'
                        fill='#292D32'
                    />
                </svg>
            )}
        />
    );
}
