const config = {
    _id: 105,
    title: 'Language Translator',
    alias: 'language-translator',
    about: 'Easily translate text from one language to another. Whether you need a quick translation for a paragraph, sentence, or even a full document.',
    categories: ['Education'],
    tips: [
        'Be specific about the language you want the text translated into to ensure accurate results.',
        'Keep the text you want to translate concise and clear to avoid confusion in the translation.',
        'Review the translated text to ensure it captures the intended meaning and context accurately.'
    ],
    prompts: [
        'Translate the following paragraph from English to French: [provide a paragraph in English].',
        'Can you translate the following sentence from Spanish to English: [provide a sentence in Spanish].',
        'Please translate the following dialogue from Mandarin Chinese to German: [provide a short dialogue in Mandarin Chinese].',
        'Translate the given poem from Italian to English: [provide a short poem in Italian].',
        'Provide a translation of the following document from Japanese to Russian: [provide a document in Japanese].'
    ]
};

export default config;
