/* eslint-disable */

const config = {
    _id: 68,
    title: 'Write Sales Copy for Cold Calling',
    alias: 'write-sales-copy-for-cold-calling',
    about: 'Craft warm, witty, and persuasive sales copy that leaves a lasting impression, making potential customers eager to pick up the phone and chat.',
    categories: ['Marketing'],
    tips: [
        "Highlight the benefits: Focus on the benefits of your product or service, rather than just its features. Explain how it will make the recipient's life easier, healthier, or more enjoyable."
    ],
    prompts: [
        'Can you provide me with a complete sales copy about [product/service] for a cold call to a potential client, including an opening, presentation, overcoming objections, and close?',
        'Can you draft a copy for a sales cold call that effectively showcases the [product/service] to [Prospect Name] and leads to a successful close?',
        'Can you formulate a sales copy for [product/service] for a cold call to [Specific audience] that covers the aspects of introduction, demonstration, objection handling, and closing?',
        'Can you provide me an example of a successful pitch for [product/service] to a [specific audience] potential client?',
        'Write me a sales cold calling copy by presenting the value of [product/service] to [prospective customer name] in the most effective way.'
    ]
};

export default config;
