import React, { createRef, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { List, Modal } from 'antd';
import UserSurvey from '../Options';
import { SlowText } from '../SlowText/SlowText';
import { RenderItem } from './components/RenderItem';

export function ChatMessages({ onNewMessage, setDocToken, auth, avatar, message, conversation }) {
    const loading = message.state.loader.list;

    const player = createRef();

    const [isReadLoud, setIsReadLoud] = useState(false);

    const [url, setUrl] = useState('');

    const [modal, contextHolder] = Modal.useModal();

    const messages = message.state.data?.docs || [];

    const chunks = message.state.data?.chunks || [];

    if (!messages.length && !loading) {
        return (
            <div className='chat-messages'>
                <UserSurvey message={message} />
            </div>
        );
    }

    return (
        <div className='chat-messages'>
            <AudioPlayer
                onPause={() => setIsReadLoud(false)}
                className='chat-messages__read_aloud'
                src={url}
                ref={player}
                preload='auto'
                showJumpControls={false}
            />
            <List
                itemLayout='vertical'
                size='large'
                loading={loading}
                dataSource={messages}
                split={false}
                renderItem={(item, key) => (
                    <RenderItem
                        index={key}
                        messages={messages}
                        isReadLoud={isReadLoud}
                        onNewMessage={onNewMessage}
                        setUrl={setUrl}
                        setIsReadLoud={setIsReadLoud}
                        modal={modal}
                        player={player}
                        setDocToken={setDocToken}
                        conversation={conversation}
                        message={message}
                        item={item}
                        auth={auth}
                    />
                )}
            />
            {message.state.loader.streaming ? (
                <List.Item style={{ listStyle: 'none' }}>
                    <div className='chat-item-wrapper'>
                        <div className='chat-message'>
                            <div className='chat-message-logo'>
                                <div className='chat-message-loader' data-title='dot-pulse'>
                                    <div className='stage'>
                                        <div className='dot-pulse' />
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div className='chat-message-content'>
                                    <div className='chat-message-wrapper'>
                                        <h3>JustThink</h3>
                                        <SlowText
                                            chunks={chunks}
                                            end={() => {
                                                message.actions.textStreamEnd();
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </List.Item>
            ) : null}
            {contextHolder}
        </div>
    );
}
