const config = {
    _id: 100,
    title: 'Peer Review Pro',
    alias: 'peer-review-pro',
    about: 'Provide guidelines and structure for students to give constructive peer reviews.',
    categories: ['Education'],
    tips: [
        'Tailor prompts to specific assignments or projects.',
        'Emphasize the importance of respectful and constructive feedback to students.'
    ],
    prompts: [
        'Provide guidelines for a peer review of [specific project].',
        'Suggest a structure for students to give feedback on [specific assignment].',
        'Generate a rubric for peer reviewing a [specific type of work].',
        'Generate a list of constructive feedback prompts for [specific assignment].',
        'Formulate guidelines for giving respectful peer feedback on [specific assignment].'
    ]
};

export default config;
