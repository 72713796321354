import React from 'react';
import { Col, Row, Table, Tooltip, Typography } from 'antd';
import { uuid } from '../../../../../../../../../../utils/crypto';
import { dateFormats, dateWithLocalTimezone } from '../../../../../../../../../../utils/date';
import { stripeErrorsMap } from '../../../../constants';
import './PaymentHistory.scss';

export default function PaymentHistory({ billing }) {
    return (
        <div className='billing__payment__history'>
            <Row className='billing__payment__history__title'>
                <Col>
                    <Typography.Title level={4}>Payment History</Typography.Title>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Table
                        className='ant-table--centered'
                        rowKey={() => uuid()}
                        scroll={{ x: 500 }}
                        columns={[
                            {
                                title: 'Date',
                                dataIndex: 'date',
                                key: 'date',
                                width: 150,
                                render: (date) => dateWithLocalTimezone(date, dateFormats.LL)
                            },
                            {
                                title: 'Amount',
                                dataIndex: 'amount',
                                key: 'amount',
                                width: 100
                            },
                            {
                                title: 'Status',
                                dataIndex: 'status',
                                key: 'status',
                                width: 150,
                                render: (status) => {
                                    return (
                                        <Tooltip title={stripeErrorsMap[status]?.description || ''}>
                                            {stripeErrorsMap[status]?.message || 'Unknown'}
                                        </Tooltip>
                                    );
                                }
                            }
                        ]}
                        pagination={{ pageSize: 5 }}
                        dataSource={billing.state.payments}
                    />
                </Col>
            </Row>
        </div>
    );
}
