import React from 'react';
import { Form, Typography } from 'antd';
import RadioGroup from '../../components/RadioGroup/RadioGroup';
import VoicesGroup from '../../components/VoicesGroup/VoicesGroup';
import styles from './ChooseVoicePage.module.scss';

const arrayBtns = [
    {
        name: 'Male',
        value: 'male',
        nameGroup: 'gender',
        isDefaultActive: false
    },
    {
        name: 'Female',
        value: 'female',
        nameGroup: 'gender',
        isDefaultActive: true
    }
];

export default function ChooseVoicePage() {
    const [gender, setGender] = React.useState('female');

    const form = Form.useFormInstance();

    const chooseVoice = (value) => {
        form.setFieldsValue({
            voice: value
        });
    };

    return (
        <React.Fragment>
            <Form.Item
                className={styles.book_form__label}
                label='Gender'
                name='gender'
                onChange={(e) => setGender(e.target.value)}
                labelCol={{ span: 24 }}
                rules={[
                    {
                        required: true,
                        message: 'Please select gender voice!'
                    }
                ]}
            >
                <div>
                    <Typography.Paragraph className={styles.book_form__sub_label}>
                        Voice will be changed based on your selection
                    </Typography.Paragraph>
                    <RadioGroup arrayBtns={arrayBtns} initialBtnActive='female' />
                </div>
            </Form.Item>
            <div className={styles.voice_form__label_wrap}>
                <Typography.Paragraph className={styles.book_form__label_voice}>
                    Select your favorite AI voice artist
                </Typography.Paragraph>
                <Typography.Paragraph className={styles.book_form__sub_label_voice}>
                    Play each of this AI generated voice to become a content creator!
                </Typography.Paragraph>
            </div>
            <Form.Item
                name='voice'
                className={styles.voice_form__label_voice}
                rules={[{ required: true, message: 'Please select a voice' }]}
            >
                <VoicesGroup className={styles.voice__radio_group} setVoice={chooseVoice} activeGender={gender} />
            </Form.Item>
        </React.Fragment>
    );
}
