import React, { useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { App, Avatar, Button, Card, Col, Form, Input, Row, Select, Tooltip, Typography } from 'antd';
import moment from 'moment';
import EmailIcon from '../../../../../../components/Icons/EmailIcon/EmailIcon';
import PhoneIcon from '../../../../../../components/Icons/PhoneIcon/PhoneIcon';
import { surveyConfig } from '../../../../../../constants/surveyConfig';
import { systemVoices } from '../../../../../../constants/systemVoices';
import { useBreakpoint } from '../../../../../../hooks/media';
import {
    FIRST_NAME_VALIDATION_RULES,
    LAST_NAME_VALIDATION_RULES,
    PHONE_VALIDATION_RULES
} from '../../../../../../utils/validation';
import { useAuthContext } from '../../../../../AuthPage/context';
import AvatarUpload from './components/AvatarUpload';
import EmailStatusBadge from './components/EmailStatusBadge';
import ProfileInfo from './components/ProfileInfo';
import ResendVerificationButton from './components/ResendVerificationButton';
import './index.scss';
import styles from './index.module.scss';

const { Meta } = Card;

const { Title } = Typography;

const EMAIL_BADGE_MAP = {
    text: {
        true: 'Verified',
        false: 'Un-Verified'
    },
    status: {
        true: 'success',
        false: 'warning'
    }
};

export default function ProfilePage() {
    const auth = useAuthContext();

    const [form] = Form.useForm();

    const { isMobile } = useBreakpoint();

    const [loading, setLoading] = useState(false);

    const { notification } = App.useApp();

    const referralLink = window.location.origin + '/auth/signup?ref=' + auth.profile.referralId;

    useEffect(() => {
        auth.whoami();
    }, [auth.whoami]);

    const onFinish = async (values) => {
        if (!form.isFieldsTouched()) {
            return;
        }

        setLoading(() => true);

        try {
            await auth.updateProfile({
                fname: values.fname,
                lname: values.lname,
                userProf: values.option,
                systemVoice: values.system_voice,
                phone: values.phone
            });
            setLoading(() => false);
            notification.success({
                placement: 'top',
                message: 'Profile updated successfully'
            });
        } catch (error) {
            setLoading(() => false);
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };

    const [isCopied, setIsCopied] = useState(false);

    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(referralLink);

        setIsCopied(true);
    };

    const { isEmailVerified, emailVerificationSentAt, verifyEmailAttempts } = auth.profile;

    const calcResendEmailDiff = () => {
        const diff = moment.utc().diff(moment.utc(emailVerificationSentAt), 'seconds');

        return {
            disabled: diff < 30,
            diffValue: diff,
            leftValue: 30 - diff
        };
    };

    const [resendBtnDisabled, setResetBtnDisabled] = useState(false);

    const [resendPossibleIn, setResendBtnInterval] = useState(null);

    const resetTimeout = useRef(null);

    useEffect(() => {
        if (!verifyEmailAttempts) return;

        const { disabled, leftValue } = calcResendEmailDiff();

        setResetBtnDisabled(disabled);

        if (leftValue > 0) {
            setResendBtnInterval(leftValue);
        }

        if (disabled) {
            resetTimeout.current = setTimeout(() => {
                setResetBtnDisabled(false);
            }, leftValue * 1000);
        }

        return () => {
            if (resetTimeout.current) {
                clearTimeout(resetTimeout.current);
            }
        };
    }, []);

    return (
        <div className={styles.profile}>
            <Row type='flex' justify='center'>
                <Col
                    xs={{
                        span: 24
                    }}
                    lg={{
                        span: 18
                    }}
                >
                    <Card bordered={false} className={styles.profile__card}>
                        <Title level={3} className={styles.profile__title}>
                            User Profile
                        </Title>
                        <Row gutter={0} className={styles.profile__meta__wrapper}>
                            <Col span={24}>
                                <Meta
                                    className={styles.profile__meta}
                                    avatar={
                                        <Avatar
                                            className={styles.profile__meta__avatar}
                                            src={
                                                <div className={styles.profile__meta__avatar__wrapper}>
                                                    <Col
                                                        className={styles.profile__meta__avatar__inner}
                                                        style={{ padding: 0 }}
                                                        gutters={0}
                                                    >
                                                        <AvatarUpload
                                                            avatar={auth.profile.avatar}
                                                            onRemoveCallback={async () => {
                                                                try {
                                                                    await auth.updateProfile({
                                                                        avatar: null
                                                                    });

                                                                    auth.setProfile((prevState) => ({
                                                                        ...prevState,
                                                                        avatar: null
                                                                    }));

                                                                    notification.success({
                                                                        placement: 'top',
                                                                        message: 'Avatar removed successfully'
                                                                    });
                                                                } catch (error) {
                                                                    notification.error({
                                                                        placement: 'top',
                                                                        message:
                                                                            error.response?.data?.message ||
                                                                            'Something went wrong'
                                                                    });
                                                                }
                                                            }}
                                                            onUpdateCallback={(avatar) => {
                                                                auth.setProfile((prevState) => ({
                                                                    ...prevState,
                                                                    avatar
                                                                }));
                                                            }}
                                                        />
                                                        <ProfileInfo form={form} />
                                                    </Col>
                                                    <EmailStatusBadge status={EMAIL_BADGE_MAP.status[isEmailVerified]}>
                                                        {EMAIL_BADGE_MAP.text[isEmailVerified]}
                                                    </EmailStatusBadge>
                                                </div>
                                            }
                                        />
                                    }
                                />
                            </Col>
                        </Row>
                        <Form
                            form={form}
                            className={styles.profile__form}
                            layout='vertical'
                            requiredMark={false}
                            initialValues={{
                                fname: auth.profile.fname,
                                lname: auth.profile.lname,
                                email: auth.profile.email,
                                phone: auth.profile.phone,
                                system_voice: auth.profile.systemVoice ?? 'alloy',
                                option: auth?.profile?.userProf ?? 'Just playing around'
                            }}
                            onFinish={onFinish}
                        >
                            <Row
                                gutter={{
                                    sm: 20,
                                    md: 24,
                                    lg: 32,
                                    xxl: 46
                                }}
                                justify='space-between'
                            >
                                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                    <Form.Item
                                        name='fname'
                                        label='First Name'
                                        rules={FIRST_NAME_VALIDATION_RULES}
                                        className={styles.profile__form__label}
                                    >
                                        <Input placeholder='eg. Alaa' className={styles.profile__form__input} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                    <Form.Item
                                        name='lname'
                                        label='Last Name'
                                        rules={LAST_NAME_VALIDATION_RULES}
                                        className={styles.profile__form__label}
                                    >
                                        <Input placeholder='eg. Mohamed' className={styles.profile__form__input} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row
                                gutter={{
                                    sm: 20,
                                    md: 24,
                                    lg: 32,
                                    xxl: 46
                                }}
                                justify='space-between'
                                className={styles.profile__form__row}
                            >
                                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                    <Form.Item
                                        label='Email Address'
                                        name='email'
                                        className={styles.profile__form__label}
                                    >
                                        <Input
                                            disabled
                                            prefix={<EmailIcon />}
                                            className={styles.profile__form__input}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                    <Form.Item
                                        label='Phone Number'
                                        name='phone'
                                        rules={PHONE_VALIDATION_RULES}
                                        className={`${styles.profile__form__label} ${styles.profile__form__phone}`}
                                        tooltip={{
                                            title: 'Add your phone number for 2,000 bonus words',
                                            overlayClassName: styles.profile__form__tooltip__text,
                                            placement: 'topLeft'
                                        }}
                                    >
                                        <Input prefix={<PhoneIcon />} className={styles.profile__form__input} />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row
                                gutter={{
                                    sm: 20,
                                    md: 24,
                                    lg: 32,
                                    xxl: 46
                                }}
                            >
                                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                    <Form.Item
                                        name='system_voice'
                                        label='System Voice'
                                        className={styles.profile__form__label}
                                    >
                                        <Select
                                            placeholder='This is Dropdown'
                                            options={systemVoices.map((option) => {
                                                const key = Object.keys(option)?.[0];
                                                const name = Object.values(option)?.[0];
                                                return {
                                                    ...option,
                                                    value: key,
                                                    label: name
                                                };
                                            })}
                                            className={styles.profile__form__select}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={12} lg={24} xl={12} xxl={12}>
                                    <Form.Item
                                        name='option'
                                        label='Why are you using Just Think'
                                        className={styles.profile__form__label}
                                    >
                                        <Select
                                            placeholder='This is Dropdown'
                                            options={surveyConfig.choices.map((option) => ({
                                                ...option,
                                                value: option.option,
                                                label: option.option
                                            }))}
                                            className={styles.profile__form__select}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            {isEmailVerified ? (
                                <Form.Item
                                    label='Your referral link:'
                                    name='referral'
                                    className={`${styles.profile__referral__link} ${styles.profile__form__label}`}
                                >
                                    <p>
                                        Share this link and unlock exciting rewards! By sharing, you`ll receive an
                                        additional 2,000 words and 25 pictures. If you`re already subscribed to our paid
                                        plan, we have an exclusive treat for you as well. Three extra days will be added
                                        to your billing period! Start sharing now and enjoy the benefits!
                                    </p>
                                    <Col className={styles.profile__referral__link__inner}>
                                        <CopyToClipboard text={referralLink}>
                                            <Typography>
                                                <pre>{referralLink}</pre>
                                            </Typography>
                                        </CopyToClipboard>
                                        <CopyToClipboard text={referralLink}>
                                            <Tooltip title={isCopied ? 'Copied!' : 'Copy link'}>
                                                <span onClick={handleCopyClick} style={{ cursor: 'pointer' }}>
                                                    {isCopied ? <CheckOutlined /> : <CopyOutlined />}
                                                </span>
                                            </Tooltip>
                                        </CopyToClipboard>
                                    </Col>
                                </Form.Item>
                            ) : (
                                <Form.Item noStyle>
                                    <Row gutter={[10, 10]} justify='start' align='middle'>
                                        <Col>
                                            <div>Please verify your email to use all features</div>
                                        </Col>
                                        <Col style={{ textAlign: 'right' }}>
                                            <ResendVerificationButton
                                                delay={resendPossibleIn}
                                                disabled={resendBtnDisabled}
                                                onClick={async () => {
                                                    try {
                                                        await auth.resendVerification();

                                                        setResetBtnDisabled(true);
                                                        setResendBtnInterval(30);
                                                        resetTimeout.current = setTimeout(() => {
                                                            setResetBtnDisabled(false);
                                                        }, 30 * 1000);

                                                        notification.success({
                                                            placement: 'top',
                                                            message: 'Verification email sent successfully'
                                                        });
                                                    } catch (error) {
                                                        notification.error({
                                                            placement: 'top',
                                                            message:
                                                                error.response?.data?.message || 'Something went wrong'
                                                        });
                                                    }
                                                }}
                                                type='dashed'
                                            >
                                                Resend Verification
                                            </ResendVerificationButton>
                                        </Col>
                                    </Row>
                                </Form.Item>
                            )}
                            <br />
                            <Form.Item>
                                <Button
                                    block={isMobile}
                                    loading={loading}
                                    disabled={loading}
                                    type='primary'
                                    htmlType='submit'
                                    className={styles.profile__form__submit}
                                >
                                    Save Changes
                                </Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}
