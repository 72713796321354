import { useImmerReducer } from 'use-immer';
import { getResultUrl } from './pages/utils';
import { imageToVideoReducer, initState } from './reducer';
import service from './service';

export const useImageToVideo = () => {
    const [state, dispatch] = useImmerReducer(imageToVideoReducer, initState);

    const voice = {
        list: async () => {
            try {
                dispatch({
                    type: 'GET_VOICES'
                });

                const response = await service.voice.list();

                const data = response.reduce(
                    (acc, next) => {
                        acc.languages.add(next.language);

                        if (!acc.voicesByLanguage[next.language]) {
                            acc.voicesByLanguage[next.language] = [];
                        }

                        acc.voicesByLanguage[next.language].push(next);
                        acc.voicesById[next.id] = next;

                        return acc;
                    },
                    {
                        languages: new Set(),
                        voicesByLanguage: {},
                        voicesById: {}
                    }
                );

                dispatch({
                    type: 'GET_VOICES_SUCCESS',
                    payload: {
                        languages: Array.from(data.languages),
                        voicesByLanguage: data.voicesByLanguage,
                        voicesById: data.voicesById
                    }
                });
            } catch (error) {
                dispatch({
                    type: 'GET_VOICES_ERROR'
                });
            }
        }
    };

    const presenter = {
        list: async () => {
            try {
                dispatch({
                    type: 'GET_PRESENTERS'
                });

                const response = await service.presenter.list();

                dispatch({
                    type: 'GET_PRESENTERS_SUCCESS',
                    payload: response
                });
            } catch (error) {
                dispatch({
                    type: 'GET_PRESENTERS_ERROR'
                });
            }
        },
        upload: async (body) => {
            try {
                dispatch({
                    type: 'UPLOAD_PRESENTER'
                });

                const response = await service.presenter.upload(body);

                dispatch({
                    type: 'UPLOAD_PRESENTER_SUCCESS',
                    payload: response
                });

                return response;
            } catch (error) {
                dispatch({
                    type: 'UPLOAD_PRESENTER_ERROR'
                });

                throw error;
            }
        },
        remove: async (id) => {
            try {
                dispatch({
                    type: 'REMOVE_PRESENTER'
                });

                await service.presenter.remove(id);

                dispatch({
                    type: 'REMOVE_PRESENTER_SUCCESS',
                    payload: id
                });
            } catch (error) {
                dispatch({
                    type: 'REMOVE_PRESENTER_ERROR'
                });

                throw error;
            }
        }
    };

    const modals = {
        history: (body) => {
            dispatch({
                type: 'HISTORY_MODAL',
                payload: body
            });
        },
        loadVideo: (body) => {
            dispatch({
                type: 'VIDEO_LOADING_MODAL',
                payload: body
            });
        }
    };

    const share = {
        get: async (id) => {
            try {
                dispatch({
                    type: 'SHARE_VIDEO'
                });

                const response = await service.share.get(id);

                dispatch({
                    type: 'SHARE_VIDEO_SUCCESS',
                    payload: response
                });
            } catch (error) {
                dispatch({
                    type: 'SHARE_VIDEO_ERROR'
                });
            }
        }
    };

    const talk = {
        create: async (body) => {
            try {
                dispatch({
                    type: 'CREATE_TALK'
                });

                const newTalk = await service.talk.create(body);

                await getResultUrl(service, newTalk);

                dispatch({
                    type: 'CREATE_TALK_SUCCESS'
                });
            } catch (error) {
                dispatch({
                    type: 'CREATE_TALK_ERROR'
                });

                throw error;
            }
        },

        get: async (id) => {
            try {
                dispatch({
                    type: 'GET_VIDEO'
                });

                const response = await service.talk.get(id);

                dispatch({
                    type: 'GET_VIDEO_SUCCESS',
                    payload: response
                });
            } catch (error) {
                dispatch({
                    type: 'GET_VIDEO_ERROR'
                });
            }
        },

        list: async ({ page, limit, search, loader }) => {
            try {
                dispatch({
                    type: 'GET_TALKS',
                    payload: loader
                });

                const response = await service.talk.list(page, limit, search);

                dispatch({
                    type: 'GET_TALKS_SUCCESS',
                    payload: response
                });
            } catch (error) {
                dispatch({
                    type: 'GET_TALKS_ERROR'
                });
            }
        },

        remove: async (id) => {
            try {
                dispatch({
                    type: 'REMOVE_VIDEO'
                });

                await service.talk.remove(id);

                dispatch({
                    type: 'REMOVE_VIDEO_SUCCESS',
                    payload: { id }
                });
            } catch (error) {
                dispatch({
                    type: 'REMOVE_VIDEO_ERROR'
                });
            }
        }
    };

    const actions = {
        share,
        modals,
        voice,
        presenter,
        talk
    };

    return {
        state,
        actions
    };
};
