/* eslint-disable */

const config = {
    _id: 90,
    title: 'Write a Pitch For Influencer',
    alias: 'write-a-pitch-for-influencer',
    about: 'Crafting compelling pitches for influencers. With this tool, you can efficiently create persuasive and tailored messages that grab the attention of influencers, increasing your chances of successful collaborations.',
    categories: ['Social Media & Ads'],
    tips: [
        'Try to personalize the message by mentioning something specific about the influencer, like a recent post or video they did that you liked.',
        'Be specific about the details of the campaign or partnership, such as the target audience and the goals of the collaboration.'
    ],
    prompts: [
        'Can you please write a pitch for an influencer that highlights the unique selling point of our [product/service] and how it can benefit their followers?',
        "Our [product/service] is perfect for [influencer's niche], can you write a pitch that showcases why they should collaborate with us and how it can bring value to their followers?",
        'We are looking for an influencer who can help us reach a [target audience], can you write a pitch that explains why our [product/service] is a great fit for their followers and what they can expect from this collaboration?',
        'Our brand is all about [brand values/ mission], can you write a pitch that emphasizes the synergy between our brand and the influencer, and how collaboration can help both of us achieve our goals?',
        'We are thrilled to offer an exclusive deal to [influencer\'s name], can you write a personalized pitch that details the benefits of this partnership and how it can help their followers discover our [product/service]?'
    ]
};

export default config;
