import React from 'react';
import Icon from '@ant-design/icons';

export default function ZipIcon(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg xmlns='http://www.w3.org/2000/svg' width='14' height='17' fill='none' viewBox='0 0 14 17'>
                    <path
                        fill='#fff'
                        d='M13.837 5.386H9.348l4.037-5.12A.164.164 0 0013.257 0H5.442a.162.162 0 00-.14.082L.022 9.235a.163.163 0 00.141.246h3.554l-1.822 7.315c-.039.16.153.272.271.157L13.949 5.666a.163.163 0 00-.112-.28z'
                    />
                </svg>
            )}
        />
    );
}
