export const dObjects = {
    _id: 8,
    title: '3D Objects',
    alias: '3d-objects',
    about: 'Generate 3D objects',
    image: '3d-objects',
    categories: ['illustration'],
    tips: [],
    prompts: [
        {
            text: '3D-like realistic design of a [color] gummy gelli letter [letter], isolated on a clean white background. The image showcases a three-dimensional, lifelike letter with a glossy surface, subtle shadows, and reflections. The color is vibrant and the texture resembles real gummy candy.',
            image: 'realistic-design'
        },
        {
            text: 'A photorealistic [type of food], isolated on a white background, exudes an appetizing allure with its delicious and charming appearance, featuring a shiny finish, high-detail, high quality, 8K resolution',
            image: 'photorealistic'
        },
        {
            text: 'Very tiny [type of emoji 🚀] that looks like the iOS emoji and has the same colors, 3D clay render, 4k UHD, white background, isometric top down left view, diffuse lighting, zoomed out very far',
            image: 'ios-emoji'
        }
    ]
};
