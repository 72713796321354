import React from 'react';
import { Form, Radio, Tabs as TabAnt } from 'antd';
import { uuid } from '../../../../../../utils/crypto';
import { imagesCharacters } from '../../constants';
import CheckList from '../CheckList/CheckList';
import ImageCharacter from '../ImageCharacter/ImageCharacter';
import styles from './Tabs.module.scss';

export default function Tabs() {
    const form = Form.useFormInstance();

    const ImagesComponent = () => {
        const onChangeRadioBtnValue = (e) => {
            form.setFieldsValue({
                characterName: e.target.value
            });
        };

        return (
            <Form.Item name='characterName'>
                <Radio.Group
                    optionType='button'
                    onChange={onChangeRadioBtnValue}
                    className={styles.radio_group}
                    gutter={[16, 16]}
                >
                    {imagesCharacters.map((item) => (
                        <Radio.Button
                            className={styles.radio_btn}
                            name='characterName'
                            value={item.nameCharacter}
                            key={uuid()}
                        >
                            <ImageCharacter src={item.src} />
                        </Radio.Button>
                    ))}
                </Radio.Group>
            </Form.Item>
        );
    };

    const items = [
        {
            key: '1',
            label: 'Choose character',
            children: <ImagesComponent />
        },
        {
            key: '2',
            label: 'Upload character',
            children: <CheckList />,
            disabled: true
        }
    ];

    return <TabAnt className={styles.tabs_wrap} defaultActiveKey='1' items={items} />;
}
