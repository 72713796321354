import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { useAuthContext } from '../../page/AuthPage/context';

export default function PrivateRoute({ children, ...rest }) {
    const { profile } = useAuthContext();

    if (profile?.userProf === null) {
        return <Redirect to='/signup-survey' />;
    }

    return (
        <React.Fragment>
            <Route
                {...rest}
                render={(props) =>
                    profile ? (
                        React.Children.map(children, (child) => {
                            if (React.isValidElement(child)) {
                                return React.cloneElement(child, { ...props });
                            }

                            return child;
                        })
                    ) : (
                        <Redirect to='/auth/login' />
                    )
                }
            />
        </React.Fragment>
    );
}
