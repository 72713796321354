export const initState = {
    images: [],
    total: 0,
    currentPage: 1,
    totalPages: 0,
    loader: false
};

export function artHistoryReducer(draft, action) {
    switch (action.type) {
        case 'ART_GET_ALL': {
            draft.loader = true;
            break;
        }
        case 'ART_GET_ALL_SUCCESS': {
            draft.loader = false;
            // action.payload.data.reverse()
            draft.images = action.payload.data;
            draft.currentPage = action.payload.pagination.currentPage;
            draft.total = action.payload.pagination.total;
            draft.totalPages = action.payload.pagination.totalPages;
            break;
        }
        case 'ART_GET_ALL_ERROR': {
            draft.loader = false;
            break;
        }
        case 'ART_UPDATE': {
            draft.loader = true;
            break;
        }
        case 'ART_UPDATE_SUCCESS': {
            draft.loader = false;
            draft.images = draft.images.map((img) => (img._id === action.payload._id ? action.payload : img));
            break;
        }
        case 'ART_UPDATE_ERROR': {
            draft.loader = false;
            break;
        }
        default: {
            return draft;
        }
    }
}
