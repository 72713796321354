export const initState = {
    docData: {
        html: null,
        title: '',
        id: null
    },
    renameField: '',
    list: {
        data: [],
        pagination: {}
    },
    modal: {
        create: false
    },
    loader: {
        rename: false,
        list: false,
        get: false,
        update: false,
        create: false
    }
};

export function documentReducer(draft, action) {
    switch (action.type) {
        case 'DOCUMENT_UPDATE_START': {
            draft.loader.update = true;
            break;
        }
        case 'DOCUMENT_UPDATE_END': {
            draft.loader.update = false;
            break;
        }
        case 'DOCUMENT_CREATE_START': {
            draft.loader.create = true;
            break;
        }
        case 'DOCUMENT_CREATE_END': {
            draft.loader.create = false;
            break;
        }
        case 'DOCUMENT_GET_START': {
            draft.loader.get = true;
            break;
        }
        case 'DOCUMENT_GET_END': {
            draft.docData.html = action.payload?.data?.html || null;
            draft.docData.title = action.payload?.data?.title || '';
            draft.loader.get = false;
            break;
        }
        case 'SET_DOCUMENT_TITLE': {
            draft.docData.title = action.payload;
            break;
        }
        case 'DOCUMENT_LIST_START': {
            draft.loader.list = true;
            break;
        }
        case 'DOCUMENT_LIST_END_SUCCESS': {
            draft.list.data = action.payload.data.data;
            draft.list.pagination = action.payload.data.pagination;
            draft.loader.list = false;
            break;
        }
        case 'DOCUMENT_LIST_END_FAILED': {
            draft.loader.list = false;
            break;
        }
        case 'DOCUMENT_REMOVE': {
            draft.loader.list = true;
            break;
        }
        case 'DOCUMENT_REMOVE_SUCCESS': {
            draft.list.data = action.payload.data.data;
            draft.list.pagination = action.payload.data.pagination;
            draft.loader.list = false;
            break;
        }
        case 'DOCUMENT_REMOVE_FAILED': {
            draft.loader.list = false;
            break;
        }
        case 'OPEN_MODAL': {
            draft.modal.create = true;
            break;
        }
        case 'CLOSE_MODAL': {
            draft.modal.create = false;
            break;
        }
        case 'HANDLE_DOCUMENT_INPUT': {
            draft.renameField = action.payload;
            break;
        }
        case 'DOCUMENT_RENAME': {
            draft.loader.rename = true;
            break;
        }
        case 'DOCUMENT_RENAME_SUCCESS': {
            draft.loader.rename = false;
            break;
        }
        case 'DOCUMENT_RENAME_FAILED': {
            draft.loader.rename = false;
            break;
        }
        default: {
            return draft;
        }
    }
}
