import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Button, Col, Divider, Image, Layout, Row, Typography } from 'antd';
import logo from '../../assets/images/logo-redesign.png';
import GoogleIcon from '../../components/Icons/GoogleIcon';
import { useBreakpoint } from '../../hooks/media';
import Forgot from './components/Forgot/Forgot';
import Join from './components/Join/Join';
import Reset from './components/Reset/Reset';
import Signin from './components/Signin/Signin';
import Signup from './components/Signup';
import Verify from './components/Verify/Verify';
import { useAuthContext } from './context';
import styles from './index.module.scss';

const { Content } = Layout;

const { Title } = Typography;

export default function AuthPage({ match, location, history }) {
    const auth = useAuthContext();

    const { isMobile } = useBreakpoint();

    const onOauthProviderClick = (provider) => async (e) => {
        e.preventDefault();

        const ok = await auth.oAuthSignup(provider, {
            isMobile
        });

        if (ok && auth?.profile?.userProf?.option === null) history.push('/options');

        if (ok) history.push('/signup-survey');
    };

    const onGoogleSignupClick = onOauthProviderClick('google');

    const loginPath = `${match.path}/login`;

    const signupPath = `${match.path}/signup`;

    const verifyPath = `${match.path}/verify`;

    const joinPath = `${match.path}/join`;

    const forgotPath = `${match.path}/password/forgot`;

    const resetPath = `${match.path}/password/reset`;

    return (
        <Layout>
            <Content>
                <Row className={styles.auth}>
                    <Col className={styles.auth__left}>
                        <Image src={logo} alt='logo' preview={false} />
                        <div className={styles.auth__left__form}>
                            <Title>
                                {location.pathname === loginPath && 'Welcome Back'}
                                {location.pathname === signupPath && 'Create an account'}
                                {location.pathname === verifyPath && 'Verify your account'}
                                {location.pathname === forgotPath && 'Forgot password'}
                                {location.pathname === resetPath && 'Reset password'}
                            </Title>
                            <React.Fragment>
                                <div className={styles.auth__socials}>
                                    <Button onClick={onGoogleSignupClick} icon={<GoogleIcon />}>
                                        Continue with Google
                                    </Button>
                                </div>
                                <Divider orientation='center'>Or</Divider>
                            </React.Fragment>
                            <Switch>
                                <Route exact path={loginPath} component={Signin} />
                                <Route exact path={signupPath} component={Signup} />
                                <Route exact path={forgotPath} component={Forgot} />
                                <Route exact path={resetPath} component={Reset} />
                                <Route exact path={verifyPath} component={Verify} />
                                <Route exact path={joinPath} component={Join} />
                                <Redirect from='*' to={loginPath} />
                            </Switch>
                        </div>
                    </Col>
                </Row>
            </Content>
        </Layout>
    );
}
