import { notification } from 'antd';

const validMimeTypes = [
    'application/pdf',
    'text/plain',
    'text/csv',
    'text/x-csv',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/webp'
];

const imageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

export const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
        if (!validMimeTypes.includes(file.type)) {
            notification.error({
                placement: 'top',
                message: 'Currently only PDF, CSV, TXT, PNG, JPEG, GIF, WEBP is available to upload'
            });

            return false;
        }

        const isLt3M = file.size / 1024 / 1024 <= 3;
        if (!isLt3M) {
            notification.error({
                placement: 'top',
                message: 'Max file size 3MB'
            });
            return false;
        }

        if (imageTypes.includes(file.type)) {
            var reader = new FileReader();
            reader.readAsDataURL(file?.originFileObj);
            reader.onload = (e) => {
                var image = new Image();

                image.src = e.target.result;

                image.onload = function () {
                    const height = this.height;
                    const width = this.width;
                    if (width > 200 && height > 200) {
                        resolve(true);
                    } else {
                        notification.error({
                            placement: 'top',
                            message: 'Min file dimensions 200x200'
                        });

                        return false;
                    }
                };
            };
        } else {
            resolve(true);
        }
    });
};
