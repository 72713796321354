/* eslint-disable */

const config = {
    _id: 66,
    title: 'Potential Client Proposal',
    alias: 'potential-client-proposal',
    about: 'Craft captivating proposals that make clients say "I do!" Harness the power of a business-focused Shakespeare.',
    categories: ['Marketing'],
    tips: [
        'Provide specific information about the client and project when asking Just Think AI for help with the proposal.',
        "Use industry-specific language and jargon to show understanding of the client's business.",
        'Highlight unique selling points that align with the client\'s needs.'
    ],
    prompts: [
        'Can you please write a B2B proposal for [Company] that highlights the benefits of using our [Product/Service] and how it can help them achieve their [specific business goal]?',
        'Can you draft a B2B proposal for [Company Name] that explains how our [Product/Service] can improve their [specific business process] and increase their [specific metric]?',
        'I am looking to write a proposal for a potential client in the [industry] industry. Can you help me create a compelling introduction and outline the key points and benefits of my [product/service]?',
        'Can you compose a B2B proposal for [Company Name] that showcases the unique features of our [Product/Service] and how it compares to similar solutions in the market?',
        'I am trying to win over a new client for my [product/service]. Can you help me write a persuasive proposal that highlights the benefits and value of [offering]?'
    ]
};

export default config;
