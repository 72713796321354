const wordsCount = 16;

export const initState = {
    aboutWordsCount: wordsCount,
    editMode: false,
    promptsList: [],
    tipsList: [],
    aboutMore: 'Learn more',
    promptsView: 'View All',
    tipsView: 'View All',
    loader: {
        prompts: false,
        tips: false
    },
    modal: {
        btnCreateTools: false,
        btnRemove: false,
        createTools: false,
        removeTools: false,
        btnCreatePrompt: false,
        btnCreateTip: false,
        btnRemovePrompt: false,
        btnRemoveTip: false,
        createPrompt: false,
        createTip: false,
        removePrompt: false,
        removeTip: false
    }
};

export function toolsReducer(draft, action) {
    switch (action.type) {
        case 'TOOLS_MODAL_ADD': {
            draft.modal.createTools = action.payload;
            break;
        }
        case 'TOOLS_MODAL_REMOVE': {
            draft.modal.removeTools = action.payload;
            break;
        }
        case 'TOOLS_CREATE_START': {
            draft.modal.btnCreateTools = true;
            break;
        }
        case 'TOOLS_CREATE_END': {
            draft.modal.btnCreateTools = false;
            break;
        }
        case 'TOOLS_DELETE_START': {
            draft.modal.btnRemove = true;
            break;
        }
        case 'TOOLS_DELETE_END': {
            draft.modal.removeTools = false;
            draft.modal.btnRemove = false;
            break;
        }
        case 'ABOUT_MORE': {
            draft.aboutMore = draft.aboutMore === 'Hide' ? 'Learn more' : 'Hide';
            draft.aboutWordsCount = draft.aboutWordsCount === 0 ? wordsCount : 0;
            break;
        }
        case 'PROMPTS_VIEW': {
            draft.promptsView = draft.promptsView === 'Hide' ? 'View All' : 'Hide';
            break;
        }
        case 'PROMPTS_MODAL_REMOVE': {
            draft.modal.removePrompt = action.payload;
            break;
        }
        case 'TIPS_MODAL_REMOVE': {
            draft.modal.removeTip = action.payload;
            break;
        }
        case 'PROMPTS_MODAL_STATUS': {
            draft.modal.createPrompt = action.payload;
            break;
        }
        case 'TIPS_MODAL_STATUS': {
            draft.modal.createTip = action.payload;
            break;
        }
        case 'PROMPT_CREATE_START': {
            draft.modal.btnCreatePrompt = true;
            break;
        }
        case 'PROMPT_CREATE_END': {
            draft.promptsList = action.payload?.data?.prompts;
            draft.modal.btnCreatePrompt = false;
            break;
        }
        case 'TIP_CREATE_START': {
            draft.modal.btnCreateTip = true;
            break;
        }
        case 'TIP_CREATE_END': {
            draft.tipsList = action.payload?.data?.tips;
            draft.modal.btnCreateTip = false;
            break;
        }
        case 'PROMPT_DELETE_START': {
            draft.modal.btnRemovePrompt = true;
            break;
        }
        case 'PROMPT_DELETE_END': {
            draft.promptsList = action.payload?.data?.prompts;
            draft.modal.removePrompt = false;
            draft.modal.btnRemovePrompt = false;
            break;
        }
        case 'TIP_DELETE_START': {
            draft.modal.btnRemoveTip = true;
            break;
        }
        case 'TIP_DELETE_END': {
            draft.tipsList = action.payload?.data?.tips;
            draft.modal.removeTip = false;
            draft.modal.btnRemoveTip = false;
            break;
        }
        case 'SET_PROMPTS_LIST': {
            draft.promptsList = action.payload;
            break;
        }
        case 'PROMPTS_LIST_START': {
            draft.loader.prompts = true;
            draft.loader.tips = true;
            break;
        }
        case 'PROMPTS_LIST_END': {
            draft.promptsList = action.payload?.data?.prompts;
            draft.tipsList = action.payload?.data?.tips;
            draft.loader.prompts = false;
            draft.loader.tips = false;
            break;
        }
        case 'EDIT_MODE': {
            draft.editMode = true;
            break;
        }
        case 'UPDATE_TITLE': {
            draft.editMode = false;
            break;
        }
        default: {
            return draft;
        }
    }
}
