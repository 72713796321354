import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DownloadOutlined, PlayCircleOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Button, Popover, Space } from 'antd';
import CircleWithDots from '../../../../../../../../components/Icons/CircleWithDots';
import { downloadMp3 } from '../../../../../../../../utils/mp3';
import styles from './index.module.scss';

export const Actions = ({ onPlay, record }) => {
    const history = useHistory();

    const [open, setOpen] = useState(false);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const onPlayBtn = () => {
        hide();
        onPlay(record);
    };

    const onCreateVideo = () => {
        history.push('/video/image-to-video', { audio: record.url });
    };

    const onDownloadAudio = async () => {
        await downloadMp3(record.voice, record.url);
    };

    const content = (
        <div>
            <Button style={{ fontWeight: 500, border: '0' }} onClick={onPlayBtn}>
                <Space align='center'>
                    <PlayCircleOutlined style={{ fontSize: '20px' }} />
                    Play
                </Space>
            </Button>
            <br />
            <Button style={{ fontWeight: 500, border: '0' }} onClick={onDownloadAudio}>
                <Space align='center'>
                    <DownloadOutlined style={{ fontSize: '20px' }} />
                    Download
                </Space>
            </Button>
            <br />
            <Button style={{ fontWeight: 500, border: '0' }} onClick={onCreateVideo}>
                <Space align='center'>
                    <VideoCameraOutlined style={{ fontSize: '20px' }} />
                    Create Video
                </Space>
            </Button>
        </div>
    );

    return (
        <Popover
            onOpenChange={handleOpenChange}
            className={styles.actions__popover}
            trigger='click'
            content={content}
            open={open}
        >
            <Button>
                <CircleWithDots />
            </Button>
        </Popover>
    );
};
