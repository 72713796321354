/* eslint-disable */

const config = {
    _id: 122,
    title: 'Prospective Client Email',
    alias: 'prospective-client-email',
    about: 'This tool can draft personalized emails to prospective clients, incorporating the details and tone that are most likely to elicit a positive response.',
    categories: ['Real Estate'],
    tips: [
        'Personalize your emails as much as possible. Include details about the client\'s property preferences, past interactions, and any common points of interest.'
    ],
    prompts: [
        "Compose an email to [prospective client's name] about [property type] in [location].",
        "Draft an email following up with [prospective client's name] after their property viewing.",
        'Write an email introducing myself and my services to [prospective client\'s name].',
        'Compose an email to [prospective client\'s name] summarizing the benefits of my real estate services.',
        'Draft an email to [prospective client\'s name] detailing the potential value of their property.',
        'Write an email introducing a new property listing to [prospective client\'s name].',
        "Compose a follow-up email after an initial meeting with [prospective client's name].",
        'Write a seasonal greeting email to [prospective client\'s name] mentioning the current real estate market.'
    ]
};

export default config;
