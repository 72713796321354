export const uploadImage = ({ onSuccess }) => {
    setTimeout(() => {
        onSuccess('ok');
    }, 0);
};

export const onDownloadImage = (upscaleImage) => {
    let a = document.createElement('a');

    a.href = upscaleImage;
    a.download = 'Upscale image.png';
    a.click();
    a = null;
};
