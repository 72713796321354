import React from 'react';

export default function CenterAlignIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='22' height='20' fill='none' viewBox='0 0 22 20'>
            <path
                fill='#1F1F1F'
                fillOpacity='0.7'
                d='M0 0v2.75h22V0H0zm2.75 5.5v2.75h16.5V5.5H2.75zM0 11v2.75h22V11H0zm2.75 5.5v2.75h16.5V16.5H2.75z'
            />
        </svg>
    );
}
