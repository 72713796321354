const config = {
    _id: 101,
    title: 'Project Idea Generator',
    alias: 'project-idea-generator',
    about: 'Generate project ideas for various subjects and grade levels.',
    categories: ['Education'],
    tips: [
        'Specify the subject and grade level for best results.',
        'Include any particular learning objectives or outcomes in your prompt.'
    ],
    prompts: [
        'Generate a project idea for [grade level] students in [subject].',
        'Suggest a group project that would help [grade level] students learn about [specific topic].',
        'Provide an idea for a cross-curricular project involving [subjects] for [grade level].',
        'Provide an idea for a service-learning project for [grade level].',
        'Suggest a [subject]-related project that can be presented at the school science fair.'
    ]
};

export default config;
