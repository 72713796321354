import React from 'react';

export default function ArrowTopIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='13' fill='none' viewBox='0 0 14 13'>
            <path
                stroke='#1F1F1F'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M6.803 12V1.324m-5.507 4.67l5.507-4.67 5.507 4.67'
            />
        </svg>
    );
}
