import React from 'react';
import Icon from '@ant-design/icons';

export default function PauseCircleOutlined(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg xmlns='http://www.w3.org/2000/svg' width={18} height={18} viewBox='0 0 24 25' fill='none'>
                    <g clipPath='url(#clip0_559_3329)'>
                        <path
                            d='M12 0.263672C5.37321 0.263672 0 5.63689 0 12.2637C0 18.8905 5.37321 24.2637 12 24.2637C18.6268 24.2637 24 18.8905 24 12.2637C24 5.63689 18.6268 0.263672 12 0.263672ZM12 22.228C6.49821 22.228 2.03571 17.7655 2.03571 12.2637C2.03571 6.76189 6.49821 2.29939 12 2.29939C17.5018 2.29939 21.9643 6.76189 21.9643 12.2637C21.9643 17.7655 17.5018 22.228 12 22.228ZM9.64286 7.97796H8.35714C8.23929 7.97796 8.14286 8.07439 8.14286 8.19224V16.3351C8.14286 16.453 8.23929 16.5494 8.35714 16.5494H9.64286C9.76071 16.5494 9.85714 16.453 9.85714 16.3351V8.19224C9.85714 8.07439 9.76071 7.97796 9.64286 7.97796ZM15.6429 7.97796H14.3571C14.2393 7.97796 14.1429 8.07439 14.1429 8.19224V16.3351C14.1429 16.453 14.2393 16.5494 14.3571 16.5494H15.6429C15.7607 16.5494 15.8571 16.453 15.8571 16.3351V8.19224C15.8571 8.07439 15.7607 7.97796 15.6429 7.97796Z'
                            fill='url(#paint0_diamond_559_3329)'
                        />
                    </g>
                    <defs>
                        <radialGradient
                            id='paint0_diamond_559_3329'
                            cx='0'
                            cy='0'
                            r='1'
                            gradientUnits='userSpaceOnUse'
                            gradientTransform='translate(0 0.263672) rotate(53.6702) scale(51.3393 59.8433)'
                        >
                            <stop offset='0.385417' stopColor='#FF916D' />
                            <stop offset='0.723958' stopColor='#ED5E93' />
                            <stop offset='0.994792' stopColor='#ED5E93' />
                        </radialGradient>
                        <clipPath id='clip0_559_3329'>
                            <rect width='24' height='24' fill='white' transform='translate(0 0.263672)' />
                        </clipPath>
                    </defs>
                </svg>
            )}
        />
    );
}
