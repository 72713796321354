import React from 'react';

export default function CircleWithDots({ width = 26, height = 26 }) {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} fill='none' viewBox='0 0 26 26'>
            <path
                fill='#000'
                stroke='#000'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M7 13.598a.6.6 0 100-1.2.6.6 0 000 1.2zM13 13.598a.6.6 0 100-1.2.6.6 0 000 1.2zM19 13.598a.6.6 0 100-1.2.6.6 0 000 1.2z'
            />
            <path
                stroke='#000'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M13 25c6.627 0 12-5.373 12-12S19.627 1 13 1 1 6.373 1 13s5.373 12 12 12z'
            />
        </svg>
    );
}
