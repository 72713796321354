/* eslint-disable */

const promptsConfig = {
    prompts: [
        {
            title: 'Productivity and Work',
            list: [
                'write me an email to apply for a job',
                'draft a polite reminder for a late invoice payment',
                'write a farewell message for a coworker leaving the company',
                'compose a friendly email to follow up on a networking event',
                'draft a cover letter for an internship application',
                'write an elevator pitch for a new business idea',
                'write a letter of recommendation for a student',
                'list 10 tips for staying productive while working from home',
                'list 10 tips for improving communication skills'
            ]
        },
        {
            title: 'Creative Writing and Content',
            list: [
                'write an essay about abolishing homework',
                'generate a catchy slogan for my eco-friendly product',
                'compose a thank you note for a thoughtful gift',
                'write a poem about the beauty of nature',
                'write a brief bio for a professional social media profile',
                'compose a haiku about a peaceful morning',
                'create a social media caption to promote an upcoming sale'
            ]
        },
        {
            title: 'Education and Learning',
            list: [
                'explain AI in three sentences',
                'explain the basics of cryptocurrency in a paragraph',
                'explain the concept of mindfulness in a few sentences',
                'explain the health benefits of yoga in a few lines',
                'explain the importance of recycling in a few sentences',
                'generate a list of interesting facts about a historical figure',
                'generate a list of trivia questions for a game night',
                'generate a list of interesting debate topics'
            ]
        },
        {
            title: 'Hobbies and Lifestyle',
            list: [
                'create a list of healthy meal ideas for a week',
                'outline a fitness plan for beginners',
                'brainstorm blog post ideas for a travel website',
                'create a workout routine for improving core strength',
                'generate a playlist of R&B songs for a Sunday afternoon',
                'create a travel itinerary for a 3-day trip to New York City',
                'suggest five TED Talks to watch for personal growth',
                'suggest five podcasts to listen to for personal finance advice',
                'list the top 10 tourist attractions in Paris',
                'create a beginner\'s guide to meditation'
            ]
        },
        {
            title: 'Health and Wellness',
            list: [
                'list 10 benefits of regular exercise',
                'suggest five ways to improve mental health',
                'compose a daily self-care routine to practice'
            ]
        },
        {
            title: 'Social and Events',
            list: [
                'draft a birthday party invitation for a 10-year-old',
                'compose a friendly reminder to RSVP for an event',
                'write a motivational quote for a Monday morning',
                'suggest five icebreaker activities for a team-building event',
                'write an inspirational message for someone starting a new job'
            ]
        },
        {
            title: 'Food and Recipes',
            list: [
                'compose a recipe for a delicious vegan dessert',
                'list healthy foods that reduce stress and anxiety',
                'create a brief guide on how to grow your own herbs'
            ]
        },
        {
            title: 'Language and Culture',
            list: [
                'explain the significance of a cultural festival or holiday',
                'teach five basic phrases in Spanish',
                'list the most spoken languages in the world'
            ]
        },
        {
            title: 'Technology and Innovation',
            list: [
                'explain how solar energy works in a simple way',
                'compare the pros and cons of electric cars',
                'provide an overview of the evolution of smartphones'
            ]
        },
        {
            title: 'Entertainment and Media',
            list: [
                'suggest five movies to watch for a movie night with friends',
                'create a list of binge-worthy TV series across different genres',
                'write a brief review of a recently released album'
            ]
        },
        {
            title: 'Personal Development and Motivation',
            list: [
                'write a personal mission statement',
                'suggest five personal development books to read',
                'create a list of achievable short-term and long-term goals'
            ]
        },
        {
            title: 'Travel and Adventure',
            list: [
                'plan a road trip from Los Angeles to San Francisco with scenic stops',
                'create a packing checklist for a 10-day European vacation',
                'suggest five must-visit national parks in the United States'
            ]
        },
        {
            title: 'Arts and Crafts',
            list: [
                'suggest three easy science experiments for kids',
                'create a step-by-step guide on how to make a paper airplane',
                'draft a short tutorial on how to tie a tie',
                'suggest three fun crafts to do with kids on a rainy day'
            ]
        },
        {
            title: 'Miscellaneous',
            list: [
                'write a product review for a wireless headphone',
                'compare and contrast the features of two smartphones',
                'write a press release for a new app launch'
            ]
        }
    ]
};

module.exports = promptsConfig;
