import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Empty, Row, Spin, Typography } from 'antd';
import { Grid } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/grid';
import { useBreakpoint } from '../../../../../../hooks/media';
import { WhiteCover } from '../../../../../../shared/components/WhiteCover';
import { uuid } from '../../../../../../utils/crypto';
import { artHistory } from '../../service';
import 'swiper/css';
import styles from './index.module.scss';

export default function Image() {
    const history = useHistory();

    const [images, setImages] = useState([]);

    const [loader, setLoader] = useState(true);

    const { isMobile, isTable } = useBreakpoint();

    useEffect(() => {
        (async () => {
            const res = await artHistory.list(
                new URLSearchParams({
                    page: 1,
                    limit: 13,
                    favorite: null
                })
            );

            setLoader(() => false);
            setImages(() => res.data);
        })();
    }, []);

    if (!images.length && !loader)
        return (
            <WhiteCover padding='47px 93px'>
                <Empty />
            </WhiteCover>
        );

    return (
        <Spin spinning={loader}>
            <WhiteCover padding='47px 80px'>
                <Row align='middle' justify='space-between' className={styles.tabs__images__head}>
                    {!isMobile && (
                        <Col>
                            <Typography.Title className={styles.tabs__title} level={2}>
                                Image Gallery
                            </Typography.Title>
                        </Col>
                    )}
                    <Col>
                        <Typography.Title
                            level={2}
                            className={styles.tabs__explore}
                            onClick={() => history.push('/art/history')}
                        >
                            Explore
                        </Typography.Title>
                    </Col>
                </Row>
                <Row className={styles.tabs__images__wrap}>
                    {!isMobile && !isTable && (
                        <Col
                            className={`${styles.tabs__images} ${images.length > 11 ? styles.tabs__images__large : null}`}
                        >
                            {images.map((item) => {
                                return <img key={uuid()} src={item.url} alt={item.prompt} />;
                            })}
                        </Col>
                    )}
                    {(isMobile || isTable) && (
                        <Swiper
                            modules={[Grid]}
                            className={styles.tabs__images__slider}
                            spaceBetween={4}
                            slidesPerView='auto'
                            grid={{
                                rows: 2,
                                fill: 'row'
                            }}
                        >
                            {images.map((item) => {
                                return (
                                    <SwiperSlide key={uuid()}>
                                        <img key={uuid()} src={item.url} alt={item.prompt} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    )}
                </Row>
            </WhiteCover>
        </Spin>
    );
}
