import moment from 'moment';

export function formatDate(date) {
    const today = moment().startOf('day');

    const yesterday = moment(today).subtract(1, 'day');

    const startOfLastWeek = moment(today).subtract(7, 'days').startOf('isoWeek');

    const endOfLastWeek = moment(today).subtract(7, 'days').endOf('isoWeek');

    const startOfLastMonth = moment(today).subtract(30, 'days').startOf('month');

    const endOfLastMonth = moment(today).subtract(30, 'days').endOf('month');

    const endOfLastYear = moment(today).subtract(1, 'year').endOf('year');

    if (date.isSame(today, 'day')) {
        return 'Today';
    }

    if (date.isSame(yesterday, 'day')) {
        return 'Yesterday';
    }

    if (date.isBetween(endOfLastWeek, yesterday)) {
        return `Last ${today.diff(date, 'day')} days`;
    }

    if (date.isBetween(startOfLastWeek, endOfLastWeek)) {
        return 'Last week';
    }

    if (date.isBetween(startOfLastMonth, endOfLastMonth)) {
        return 'Last month';
    }

    if (date.isBetween(endOfLastYear, today)) {
        return date.format(dateFormats.MMMM);
    }

    if (date.isBefore(endOfLastYear)) {
        return date.format(dateFormats.YYYY);
    }

    return 'Archive';
}

export function dateWithLocalTimezone(timestamp, format) {
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

    const localDate = new Date(timestamp).toLocaleString('en-US', { timeZone });

    return moment(new Date(localDate)).format(format ?? dateFormats.LLLL);
}

export const dateFormats = {
    MMMM: 'MMMM',
    YYYY: 'YYYY',
    LL: 'LL',
    LLL: 'LLL',
    LLLL: 'LLLL'
};
