const dollarFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
});

const dollarFormatterRound = new Intl.NumberFormat('en-US', {
    style: 'currency',
    maximumSignificantDigits: 3,
    currency: 'USD'
});

export const dollarFormatterFunc = (value, round = false) => {
    if (round) {
        return dollarFormatterRound.format(value);
    }
    return dollarFormatter.format(value);
};
