import React, { useState } from 'react';
import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Form, Input, Popover, Typography } from 'antd';
import { useBreakpoint } from '../../../hooks/media';
import { useAuthContext } from '../../../page/AuthPage/context';

const ModalForm = ({ form, onCreateNewInstruction }) => {
    const auth = useAuthContext();

    const usePopoverState = () => {
        const [visible, setVisible] = useState(false);
        const toggleVisible = () => setVisible(!visible);
        return { visible, toggleVisible };
    };
    const PopoverTitle = ({ text, onClose }) => (
        <React.Fragment>
            <Typography.Text>{text}</Typography.Text>
            <a onClick={onClose}>
                <CloseOutlined />
            </a>
        </React.Fragment>
    );
    const PopoverContent = ({ items }) => (
        <ul>
            {items.map((item, index) => (
                <li key={index}>{item}</li>
            ))}
        </ul>
    );
    const firstPopover = usePopoverState();
    const secondPopover = usePopoverState();
    const tipItems = [
        'Where are you based?',
        'What do you do for work?',
        'What are your hobbies and interests?',
        'What subjects can you talk about for hours?',
        'What are some goals you have?'
    ];

    const extraTipItems = [
        'How formal or casual should Just Think be?',
        'How long or short should responses generally be?',
        'How do you want to be addressed?',
        'Should Just Think have opinions on topics or remain neutral?'
    ];
    const { isDesktopLarge } = useBreakpoint();
    return (
        <React.Fragment>
            <Form
                form={form}
                onFinish={onCreateNewInstruction}
                initialValues={{
                    introduce: auth.profile?.systemMessages?.first || '',
                    respond: auth.profile?.systemMessages?.second || ''
                }}
            >
                <Typography.Text>
                    What would you like JustThink to know about you to give you better responses?
                </Typography.Text>
                <Form.Item className='form-input-wrap'>
                    <Form.Item name='introduce'>
                        <Input.TextArea
                            autoSize={{ minRows: 8, maxRows: 8 }}
                            showCount
                            maxLength={1500}
                            placeholder='Please introduce yourself by sharing your profession, hobbies and interests, favorite subjects for discussion, and some of your goals.'
                        />
                    </Form.Item>
                    <Popover
                        getPopupContainer={() => document.querySelector('#app-layout')}
                        content={<PopoverContent items={tipItems} />}
                        title={<PopoverTitle text='Thought starters' onClose={firstPopover.toggleVisible} />}
                        className='modal-form-tip'
                        trigger='click'
                        placement={isDesktopLarge ? 'right' : 'top'}
                        open={firstPopover.visible}
                        onOpenChange={firstPopover.toggleVisible}
                        arrow={false}
                        overlayClassName='modal-form-popover'
                    >
                        <Typography.Text>
                            Show Tips <EyeOutlined />
                        </Typography.Text>
                    </Popover>
                </Form.Item>
                <Typography.Text>How would you like Just Think to respond?</Typography.Text>
                <Form.Item className='form-input-wrap'>
                    <Form.Item name='respond'>
                        <Input.TextArea
                            autoSize={{ minRows: 8, maxRows: 8 }}
                            showCount
                            maxLength={1500}
                            placeholder='Please share your preferences for Just Think regarding formality, response length, how you prefer to be address, and opinions.'
                        />
                    </Form.Item>
                    <Popover
                        getPopupContainer={() => document.querySelector('#app-layout')}
                        content={<PopoverContent items={extraTipItems} />}
                        title={<PopoverTitle text='Thought starters' onClose={secondPopover.toggleVisible} />}
                        className='modal-form-tip'
                        trigger='click'
                        placement={isDesktopLarge ? 'right' : 'top'}
                        open={secondPopover.visible}
                        onOpenChange={secondPopover.toggleVisible}
                        arrow={false}
                        overlayClassName='modal-form-popover'
                    >
                        <Typography.Text>
                            Show Tips <EyeOutlined />
                        </Typography.Text>
                    </Popover>
                </Form.Item>
            </Form>
        </React.Fragment>
    );
};
export default ModalForm;
