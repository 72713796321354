import React, { useEffect } from 'react';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TRANSFORMERS } from '@lexical/markdown';
import { AutoFocusPlugin } from '@lexical/react/LexicalAutoFocusPlugin';
import { ClearEditorPlugin } from '@lexical/react/LexicalClearEditorPlugin';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { MarkdownShortcutPlugin } from '@lexical/react/LexicalMarkdownShortcutPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import PageLoader from '../../../../../../components/PageLoader/PageLoader';
import { useDocument } from '../../hooks';
import { PageBreakNode } from './nodes/PageBreakNode';
import AutoLinkPlugin from './plugins/AutoLinkPlugin';
import CodeHighlightPlugin from './plugins/CodeHighlightPlugin';
import { ExtendedTextNode } from './plugins/ExtendedTextNode';
import ListMaxIndentLevelPlugin from './plugins/ListMaxIndentLevelPlugin';
import PageBreakPlugin from './plugins/PageBreakPlugin';
import ToolbarPlugin from './plugins/ToolbarPlugin';
import docTheme from './themes/DocTheme';
import './index.scss';
import styles from '../../index.module.scss';

function Placeholder() {
    return <div className='editor-placeholder'>Enter some rich text...</div>;
}

const editorConfig = {
    editable: true,
    theme: docTheme,
    onError(error) {
        throw error;
    },
    nodes: [
        PageBreakNode,
        ExtendedTextNode,
        HeadingNode,
        ListNode,
        ListItemNode,
        QuoteNode,
        CodeNode,
        CodeHighlightNode,
        TableNode,
        TableCellNode,
        TableRowNode,
        AutoLinkNode,
        LinkNode
    ]
};

export default function DocumentEditor({ docToken, setDocToken }) {
    const { actions, state } = useDocument();

    useEffect(() => {
        if (docToken) {
            (async () => {
                await actions.get(docToken);
            })();

            return;
        }

        (async () => {
            await actions.create({ setLocalStorage: setDocToken });
        })();
    }, []);

    if (state.loader.create || state.loader.get) {
        return <PageLoader />;
    }

    return (
        <div className={styles.doc}>
            <LexicalComposer initialConfig={editorConfig}>
                <div className='editor-container'>
                    <ToolbarPlugin actions={actions} state={state} setDocToken={setDocToken} />
                    <div className='editor-inner'>
                        <div className='editor-content'>
                            <RichTextPlugin
                                contentEditable={<ContentEditable className='editor-input' />}
                                placeholder={<Placeholder />}
                                ErrorBoundary={LexicalErrorBoundary}
                            />
                        </div>
                        <HistoryPlugin />
                        <ClearEditorPlugin />
                        <AutoFocusPlugin />
                        <CodeHighlightPlugin />
                        <ListPlugin />
                        <LinkPlugin />
                        <AutoLinkPlugin />
                        <PageBreakPlugin />
                        <ListMaxIndentLevelPlugin maxDepth={7} />
                        <MarkdownShortcutPlugin transformers={TRANSFORMERS} />
                    </div>
                </div>
            </LexicalComposer>
        </div>
    );
}
