import React from 'react';
import { Form, Input, Modal } from 'antd';

export default function CancelPlanModal({ billing, org }) {
    const [form] = Form.useForm();

    return (
        <Modal
            title='Are you sure you want to cancel your plan?'
            open={billing.state.activeModals.cancelPlan}
            afterClose={() => {
                form.resetFields();
            }}
            onCancel={() => {
                billing.actions.modal.close('cancelPlan');
            }}
            onOk={async () => {
                const values = await form.validateFields();

                if (!values.reason) {
                    return true;
                }

                await billing.actions.plan.cancel(org, values);

                billing.actions.modal.close('cancelPlan');

                await billing.actions.fetch(org);
            }}
            destroyOnClose
            forceRender
            centered
            okButtonProps={{
                loading: billing.state.loader.cancelingPlan,
                disabled: billing.state.loader.cancelingPlan,
                type: 'default'
            }}
            cancelButtonProps={{
                type: 'primary'
            }}
            okText='Yes, I do'
            cancelText="No, I don't"
        >
            <React.Fragment>
                <div>Your plan will expire on {new Date(billing.state.plan.expiredAt).toDateString()}</div>
                <br />
                <Form form={form} layout='vertical' requiredMark={false}>
                    <Form.Item
                        name='reason'
                        label='Reason'
                        rules={[{ required: true, message: 'This is important, please provide us a reason' }]}
                    >
                        <Input.TextArea rows={4} />
                    </Form.Item>
                </Form>
            </React.Fragment>
        </Modal>
    );
}
