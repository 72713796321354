import React from 'react';
import Icon from '@ant-design/icons';

export default function Attention(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg xmlns='http://www.w3.org/2000/svg' width='21' height='20' viewBox='0 0 21 20' fill='none'>
                    <path
                        d='M10.5 0C4.99 0 0.5 4.49 0.5 10C0.5 15.51 4.99 20 10.5 20C16.01 20 20.5 15.51 20.5 10C20.5 4.49 16.01 0 10.5 0ZM9.75 6C9.75 5.59 10.09 5.25 10.5 5.25C10.91 5.25 11.25 5.59 11.25 6V11C11.25 11.41 10.91 11.75 10.5 11.75C10.09 11.75 9.75 11.41 9.75 11V6ZM11.42 14.38C11.37 14.51 11.3 14.61 11.21 14.71C11.11 14.8 11 14.87 10.88 14.92C10.76 14.97 10.63 15 10.5 15C10.37 15 10.24 14.97 10.12 14.92C10 14.87 9.89 14.8 9.79 14.71C9.7 14.61 9.63 14.51 9.58 14.38C9.53 14.26 9.5 14.13 9.5 14C9.5 13.87 9.53 13.74 9.58 13.62C9.63 13.5 9.7 13.39 9.79 13.29C9.89 13.2 10 13.13 10.12 13.08C10.36 12.98 10.64 12.98 10.88 13.08C11 13.13 11.11 13.2 11.21 13.29C11.3 13.39 11.37 13.5 11.42 13.62C11.47 13.74 11.5 13.87 11.5 14C11.5 14.13 11.47 14.26 11.42 14.38Z'
                        fill='#1F1F1F'
                        fillOpacity='0.6'
                    />
                </svg>
            )}
        />
    );
}
