import React from 'react';

export default function UnderlineIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <path
                fill='#000'
                fillOpacity='0.85'
                d='M20.358 19.823H3.643a.21.21 0 00-.214.204v1.628a.21.21 0 00.214.204h16.715a.21.21 0 00.214-.204v-1.628a.21.21 0 00-.214-.204zM12 17.787a6.924 6.924 0 004.923-2.04 6.909 6.909 0 002.04-4.924V2.466a.322.322 0 00-.32-.321h-1.608a.322.322 0 00-.321.321v8.357A4.721 4.721 0 0112 15.537a4.721 4.721 0 01-4.715-4.714V2.466a.322.322 0 00-.321-.321H5.358a.322.322 0 00-.322.321v8.357c0 1.859.726 3.605 2.041 4.923a6.908 6.908 0 004.924 2.041z'
            />
        </svg>
    );
}
