import { App } from 'antd';
import { useImmerReducer } from 'use-immer';
import { initState, speechReducer } from './reducer';
import { speechService, voiceService } from './service';

export const useSpeech = () => {
    const [state, dispatch] = useImmerReducer(speechReducer, initState);

    const { notification } = App.useApp();

    const samplePlay = (payload) => {
        dispatch({
            type: 'SAMPLE_PLAY',
            payload
        });
    };

    const sampleStop = (payload) => {
        dispatch({
            type: 'SAMPLE_STOP',
            payload
        });
    };

    const load = async (payload) => {
        const presenterObj = state.gender?.find((element) => element.voiceId === payload.voice);

        dispatch({
            type: 'LOAD_AUDIO',
            payload: presenterObj
        });

        try {
            const data = await speechService.convert({ values: payload, presenter: presenterObj });

            dispatch({
                type: 'LOAD_SUCCESS',
                payload: { path: data.path, values: payload }
            });
        } catch (err) {
            dispatch({
                type: 'LOAD_FAILED',
                payload: err
            });
            throw err;
        }
    };

    const close = () => {
        dispatch({
            type: 'CLOSE_POPUP'
        });
    };

    const gender = async (payload) => {
        if (payload !== 'custom') {
            return dispatch({
                type: 'CHANGE_GENDER',
                payload
            });
        }

        try {
            dispatch({
                type: 'CUSTOM_GET'
            });

            const data = await voiceService.list();

            dispatch({
                type: 'CUSTOM_SUCCESS',
                payload: data
            });
        } catch (err) {
            dispatch({
                type: 'CUSTOM_FAILED'
            });
            notification.error({
                placement: 'top',
                message: 'Error',
                description: 'Something went wrong, please try again.'
            });
        }
    };

    const count = (payload) => {
        dispatch({
            type: 'TEXT_COUNT',
            payload
        });
    };

    const addVoice = async (payload) => {
        try {
            dispatch({
                type: 'CUSTOM_ADD'
            });

            const response = await voiceService.create(payload);

            if (response) {
                notification.success({
                    placement: 'top',
                    message: 'Your own voice is ready!'
                });
                dispatch({
                    type: 'CUSTOM_ADD_SUCCESS',
                    payload: response
                });
            }
        } catch (error) {
            dispatch({
                type: 'CUSTOM_ADD_FAILED'
            });

            if (error.response.status !== 402) {
                notification.error({
                    placement: 'top',
                    message: error.response.data.message || 'Something went wrong. Try again later.'
                });
            }
        }
    };

    const deleteVoice = async (payload) => {
        try {
            dispatch({
                type: 'VOICE_DELETE'
            });

            await voiceService.delete(payload);

            dispatch({
                type: 'VOICE_DELETE_SUCCESS',
                payload
            });
            notification.success({
                placement: 'top',
                message: 'Voice is deleted'
            });
        } catch (err) {
            dispatch({
                type: 'VOICE_DELETE_FAILED'
            });
            notification.error({
                placement: 'top',
                message: 'Error',
                description: 'Something went wrong, please try again later.'
            });
        }
    };

    const speechList = {
        get: async (pageNum = 1, replace = false) => {
            try {
                dispatch({
                    type: 'SPEECH_LIST',
                    payload: replace
                });

                const { data: list, pagination } = await speechService.list(pageNum);

                dispatch({
                    type: 'SPEECH_LIST_SUCCESS',
                    payload: { list, pagination, replace }
                });
            } catch (err) {
                dispatch({
                    type: 'SPEECH_LIST_FAILED'
                });
            }
        },
        play: async (item) => {
            dispatch({
                type: 'SPEECH_LIST_ITEM_PLAY',
                payload: item
            });
        },
        remove: async (payload) => {
            dispatch({
                type: 'SPEECH_LIST_ITEM_REMOVE',
                payload
            });

            try {
                await speechService.remove([payload._id]);

                dispatch({
                    type: 'SPEECH_LIST_ITEM_REMOVE_SUCCESS'
                });
            } catch (err) {
                dispatch({
                    type: 'SPEECH_LIST_ITEM_REMOVE_FAILED',
                    payload: err
                });
            }
        }
    };

    return {
        state,
        actions: {
            speechList,
            count,
            gender,
            close,
            load,
            samplePlay,
            sampleStop,
            addVoice,
            deleteVoice
        }
    };
};
