import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Icon, { CheckCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { Col, Form, Image, Modal, Row, Spin, Typography } from 'antd';
import { CDN_URL } from '../../../../config';
import { useBreakpoint } from '../../../../hooks/media';
import { planConfig } from '../../../../hooks/planConfig';
import StripeElements from '../../../../shared/components/StripeElements';
import { dollarFormatterFunc } from '../../../../utils/dollarFormater';
import { useAuthContext } from '../../../AuthPage/context';
import { useBilling } from '../../pages/SettingsPage/pages/BillingPage/hooks';
import billingService from '../../pages/SettingsPage/pages/BillingPage/hooks/service';
import { PlanSelect } from './components/PlanSelect';
import styles from './index.module.scss';

const billingPlan = [{ annual: 'yearly' }, { month: 'monthly' }];

export const PaymentValue = ({ value }) => {
    return (
        <Row justify='space-between' className={styles.billing__payment__value}>
            <Col>Due today</Col>
            <Col>{value}</Col>
        </Row>
    );
};

export const BillingUpdate = ({ context }) => {
    const history = useHistory();

    const auth = useAuthContext();

    const getPlanConfig = planConfig(auth?.profile?.userProf);

    const prices = getPlanConfig.initialValues.standard.price;

    const { isMobile } = useBreakpoint();

    const [form] = Form.useForm();

    const org = auth.org.getCurrent();

    const billing = useBilling();

    useEffect(() => {
        (async () => {
            await billingService.getPlan(org, getPlanConfig.initialValues.standard);

            await billing.actions.fetch(org);
            await billing.actions.intent.setup(org);
        })();
    }, []);

    const onFinish = async () => {
        await context.actions.billing.setStep(2);
    };

    const onBack = async () => {
        await context.actions.billing.setStep(1);
    };

    const onCancel = async () => {
        await context.actions.billing.modal(false);
        await context.actions.billing.setStep(1);
    };

    const onSetupSuccess = async (setupIntent) => {
        await billing.actions.intent.confirmNoRedirect(org, setupIntent);

        await billing.actions.plan.update(
            org,
            {
                ...getPlanConfig.initialValues.standard,
                period: form.getFieldValue('type')
            },
            false
        );

        await auth.whoami();
        await billing.actions.fetch(org);
        await context.actions.billing.modal(false);
        history.push('/settings/billing', { state: { confirmPayment: true } });
    };

    const period = form.getFieldValue('type');
    const paymentValue = period === 'annual' ? prices[period] * 12 : prices[period];

    return (
        <Modal
            open
            onCancel={onCancel}
            width={1133}
            centered
            className={styles.billing__upgrade}
            footer={false}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Row>
                {!isMobile ? (
                    <Col span={12}>
                        <Image
                            style={{ objectFit: 'cover' }}
                            height='100%'
                            preview={false}
                            src={`${CDN_URL}/billing/modal/billing_update.jpeg`}
                        />
                    </Col>
                ) : null}
                <Col className={styles.billing__upgrade__right} span={isMobile ? 24 : 12}>
                    <Spin size='large' spinning={context.state.loaders.billing}>
                        <Row justify='flex-start' gutter={15} style={{ marginBottom: '20px' }}>
                            {context.state.billing.step === 2 ? (
                                <Col align='center' style={{ display: 'flex' }}>
                                    <Icon onClick={onBack} component={LeftOutlined} />
                                </Col>
                            ) : null}
                            <Col>
                                <Typography.Title level={2}>Upgrade to Standard</Typography.Title>
                            </Col>
                        </Row>
                        <Typography.Text className={styles.billing__upgrade__right__descr}>
                            Create without limits by upgrading to an standard plan.
                        </Typography.Text>
                        <Typography>
                            {context.state.billing.step === 2 ? (
                                <ul className={styles.billing__upgrade__right__list}>
                                    <li>
                                        <CheckCircleOutlined /> 30 day money back gaurantee, cancel any time
                                    </li>
                                    <li>
                                        <CheckCircleOutlined /> Around-the-clock assistance 24/7 support
                                    </li>
                                </ul>
                            ) : (
                                <ul>
                                    <li>
                                        <CheckCircleOutlined /> Unlimited AI Chat
                                    </li>
                                    <li>
                                        <CheckCircleOutlined /> Generate Realistic Text to Speech voices
                                    </li>
                                    <li>
                                        <CheckCircleOutlined /> Create Advanced AI Images
                                    </li>
                                    <li>
                                        <CheckCircleOutlined /> Create groundbreaking AI Video Generation
                                    </li>
                                </ul>
                            )}
                        </Typography>
                        {context.state.billing.step === 1 ? (
                            <PlanSelect billingPlan={billingPlan} prices={prices} form={form} onFinish={onFinish} />
                        ) : (
                            <StripeElements
                                onSetupSuccess={onSetupSuccess}
                                setLoader={context.actions.billing.loader}
                                billing={billing}
                                paymentValue={<PaymentValue value={dollarFormatterFunc(paymentValue)} />}
                            />
                        )}
                    </Spin>
                </Col>
            </Row>
        </Modal>
    );
};
