import React, { Fragment, useMemo, useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Slider from 'react-slick';
import { CloseOutlined, PlayCircleOutlined, UpOutlined } from '@ant-design/icons';
import { App, Button, Form, Input, Radio, Spin, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import PauseCircleOutlined from '../../../../../../components/Icons/PauseCircleOutlined/PauseCircleOutlined';
import { CDN_URL, NODE_ENV } from '../../../../../../config';
import { useBreakpoint } from '../../../../../../hooks/media';
import { useAuthContext } from '../../../../../AuthPage/context';
import { AudioPlayerDrawer, AudioPlayerSpin } from '../components/AudioPlayer';
import { Accordion } from './components/Accordion';
import AddVoiceModal from './components/AddVoiceModal';
import { SpeechList } from './components/SpeechList';
import { useSpeech } from './hooks';
import { voiceService } from './service';
import 'react-h5-audio-player/lib/styles.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.scss';

const { TextArea } = Input;

export default function JustSpeakPage() {
    const { isMobile } = useBreakpoint();

    const [visible, setVisible] = useState(false);

    const [speechListVisible, setSpeechListVisible] = useState(false);

    const [isClonedVoices, setIsClonedVoices] = useState(false);

    const { state, actions } = useSpeech();

    const auth = useAuthContext();

    const prevId = useRef(null);

    const [form] = useForm();

    const audio = useMemo(() => new Audio(), []);

    const currentOrg = auth.org.getCurrent();

    const { notification } = App.useApp();

    const maxTextLength = 5000;

    const sliderSettings = {
        row: 1,
        arrows: false,
        dots: false,
        slidesToShow: isMobile ? 1 : 3,
        slidesToScroll: 3,
        initialSlide: 0,
        infinite: false,
        variableWidth: true,
        focusOnSelect: true
    };

    React.useEffect(() => {
        const getCustomVoices = async () => {
            const data = await voiceService.list();

            data.length > 0 ? setIsClonedVoices(true) : setIsClonedVoices(false);
        };

        getCustomVoices();
    }, [state.activeTab]);

    const onSampleAudioStart = async (e, option) => {
        e.stopPropagation();

        if (prevId.current !== option.id) audio.src = `${CDN_URL}/text-to-speech/samples/${option.voiceId}.mp3`;

        prevId.current = option.id;
        await audio.play();
        actions.samplePlay(option);

        audio.addEventListener('ended', () => {
            actions.sampleStop(option);
        });
    };

    const onSampleAudioStop = async (e, option) => {
        e.stopPropagation();
        audio.pause();
        actions.sampleStop(option);
    };

    const onGenderChange = (e) => {
        actions.gender(e.target.value);
        form.setFieldValue('voice', null);
    };

    const onFinish = async (values) => {
        if (state.textCount > 5000) {
            notification.error({
                placement: 'top',
                message: 'Maximum text length 5000'
            });

            return;
        }

        try {
            await actions.load(values);
            await actions.speechList.get(1, true);
        } catch (error) {
            if (error.response?.status !== 402) {
                notification.error({
                    placement: 'top',
                    message: 'Error',
                    description: error.response.data.message
                });
            }
        }

        // Update billing org info
        await auth.whoami();
    };

    const audioQuota = currentOrg.billingPlan.audio.limit - currentOrg.billingPlan.audio.value;

    const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        useGrouping: false
    });

    const maxRequestQuota = formatter.format(audioQuota > 5000 ? 5000 : audioQuota);

    const toggleModal = () => {
        setVisible(!visible);
    };

    const onCreate = async (values) => {
        const formData = new FormData();

        formData.append('name', values.name);
        values.audio.forEach((file, index) => {
            formData.append('files', file.originFileObj, `audio${index}.mp3`);
        });
        formData.append('description', values.description);

        await actions.addVoice(formData);

        await actions.gender('custom');

        setVisible(false);
    };

    const spinVoiceGet = state.loader.voice.get;

    const spinVoiceRemove = state.loader.voice.remove;

    const isPlanTypeFree = currentOrg?.billingPlan?.planType === 'free';

    return (
        <React.Fragment>
            <div className='audio-page'>
                <div className='audio-page__heading'>
                    <Typography.Title level={2}>Text to Speech</Typography.Title>
                    <Typography.Text>
                        Create realistic, engaging speech in multiple languages with Just Think AI
                    </Typography.Text>
                </div>
                <div className='audio-page__main'>
                    <div className='audio-page-settings'>
                        <Form
                            className='form'
                            onFinish={onFinish}
                            form={form}
                            initialValues={{
                                boost: false,
                                style: [0.75],
                                clarity: [0.75],
                                stability: [0.75],
                                gender: 'male'
                            }}
                        >
                            <div className='gender-section section'>
                                <Typography.Title className='section-title' level={2}>
                                    Select Gender
                                </Typography.Title>
                                <Form.Item name='gender' className='section-control gender'>
                                    <Radio.Group
                                        className='gender-radio-group'
                                        buttonStyle='solid'
                                        onChange={(e) => onGenderChange(e)}
                                        value={state.activeTab}
                                    >
                                        <Radio.Button className='gender-btn' value='male'>
                                            Male
                                        </Radio.Button>
                                        <Radio.Button className='gender-btn' value='female'>
                                            Female
                                        </Radio.Button>
                                        {NODE_ENV === 'production' ? null : isClonedVoices && !isPlanTypeFree ? (
                                            <Radio.Button className='gender-btn' value='custom'>
                                                Your Voices
                                            </Radio.Button>
                                        ) : null}
                                    </Radio.Group>
                                    {NODE_ENV === 'production' ? null : (
                                        <Button
                                            className='add-voice-btn'
                                            value='add'
                                            onClick={toggleModal}
                                            disabled={isPlanTypeFree}
                                        >
                                            {isPlanTypeFree ? 'Voice Clone' : 'Add Voice'}
                                            {isPlanTypeFree && (
                                                <div className='clone_voice__wrap__popover'>
                                                    <div />
                                                    <div>
                                                        <Typography.Title level={3}>Clone Your Voice</Typography.Title>
                                                        <p>
                                                            Empowering you to create <br /> astonishingly accurate and
                                                            lifelike <br /> vocal clones.
                                                        </p>
                                                        <span>
                                                            Available exclusively for members&nbsp;
                                                            {isPlanTypeFree && (
                                                                <NavLink to='/settings/billing'>upgrade</NavLink>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                        </Button>
                                    )}
                                </Form.Item>
                            </div>
                            <div className='voice-section section'>
                                <Typography.Title className='section-title' level={3}>
                                    Select a Voice
                                </Typography.Title>
                                <Form.Item
                                    name='voice'
                                    className='section-control voice'
                                    rules={[{ required: true, message: 'Please select a voice' }]}
                                >
                                    <Spin className='spinner' spinning={spinVoiceRemove || spinVoiceGet}>
                                        <Radio.Group className='voice-radio-group' buttonStyle='outline'>
                                            <Slider className='voice-slider' {...sliderSettings}>
                                                {state.gender.map((option, key) => (
                                                    <Radio.Button
                                                        className={
                                                            state.activeTab === 'custom' && state.gender.length > 0
                                                                ? 'voice-card card custom'
                                                                : 'voice-card card'
                                                        }
                                                        key={key}
                                                        value={option.voiceId}
                                                    >
                                                        <div className='card-content content'>
                                                            <div className='content-info'>
                                                                {state.activeTab === 'custom' &&
                                                                state.gender.length > 0 ? (
                                                                    <div className='content-info-inner clone-wrap'>
                                                                        <p>{option.voiceName}</p>
                                                                        <p>{option.description}</p>
                                                                        <CloseOutlined
                                                                            style={{ color: '#D3D3D3' }}
                                                                            onClick={() =>
                                                                                actions.deleteVoice(option.voiceId)
                                                                            }
                                                                        />
                                                                    </div>
                                                                ) : (
                                                                    <div className='content-info-inner'>
                                                                        <p>{option.name}</p>
                                                                        {option.id === state.samples.id &&
                                                                        state.samples.status ? (
                                                                            <PauseCircleOutlined
                                                                                className='content-info-icon'
                                                                                onClick={(e) =>
                                                                                    onSampleAudioStop(e, option)
                                                                                }
                                                                            />
                                                                        ) : (
                                                                            <PlayCircleOutlined
                                                                                className='content-info-icon'
                                                                                onClick={(e) =>
                                                                                    onSampleAudioStart(e, option)
                                                                                }
                                                                            />
                                                                        )}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </Radio.Button>
                                                ))}
                                            </Slider>
                                        </Radio.Group>
                                    </Spin>
                                </Form.Item>
                            </div>
                            <Accordion form={form} />
                            <div className='textarea-section section'>
                                <Typography.Title level={3}>Text</Typography.Title>
                                <Form.Item>
                                    <Form.Item
                                        name='textarea'
                                        style={{ position: 'relative', marginBottom: '0px' }}
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please fill in the text field!'
                                            },
                                            {
                                                max: maxTextLength,
                                                message: `The text field must be less than ${maxTextLength} characters!`
                                            }
                                        ]}
                                    >
                                        <div className='section-container container'>
                                            <TextArea
                                                className='container-text'
                                                maxLength={maxRequestQuota}
                                                onChange={(e) => actions.count(e.target.value.length)}
                                                placeholder='Start typing or paste your text here'
                                                autoSize={{ minRows: isMobile ? 7 : 17, maxRows: isMobile ? 7 : 17 }}
                                            />
                                            <div className='container-count'>{state.textCount}/5000</div>
                                        </div>
                                    </Form.Item>
                                </Form.Item>
                            </div>

                            <div className='button-section section'>
                                <Form.Item>
                                    <Button
                                        block
                                        type='primary'
                                        htmlType='submit'
                                        className='button-submit'
                                        loading={state.audio.loading}
                                    >
                                        Generate & Save
                                    </Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                    {isMobile ? null : <SpeechList actions={actions} state={state} />}
                </div>
                {isMobile ? (
                    <Fragment>
                        <div className='audio-page-result active'>
                            <AudioPlayerSpin state={state} actions={actions} />
                        </div>
                        <Button onClick={() => setSpeechListVisible(true)} className='audio-page-results'>
                            <UpOutlined /> Show Generations
                        </Button>
                    </Fragment>
                ) : null}
            </div>
            {!isMobile && <AudioPlayerDrawer state={state} actions={actions} />}
            {isMobile ? (
                <SpeechList
                    isVisible={speechListVisible}
                    setVisible={setSpeechListVisible}
                    actions={actions}
                    state={state}
                />
            ) : null}
            {visible && (
                <AddVoiceModal
                    visible={visible}
                    setVisible={setVisible}
                    onCreate={onCreate}
                    loading={state.loader.voice.add}
                />
            )}
        </React.Fragment>
    );
}
