/* eslint-disable */

const config = {
    _id: 98,
    title: 'Homework Helper',
    alias: 'homework-helper',
    about: "Create engaging and appropriate homework assignments based on the day's lessons.",
    categories: ['Education'],
    tips: [
        'Use specific lesson details for best homework assignment results.',
        'Include desired learning outcomes in your prompt.'
    ],
    prompts: [
        'Create a homework assignment to reinforce today\'s lesson on [specific topic].',
        'Generate a set of [subject] problems that will help students practice [specific topic].',
        'Devise a [type of assignment] assignment to supplement our discussion on [specific topic in literature].',
        'Develop a [subject] homework assignment that promotes critical thinking on [specific topic].',
        "Create a homework task for [subject] to reinforce today's lesson on [specific topic], incorporating a mix of problem-solving and short answer questions."
    ]
};

export default config;
