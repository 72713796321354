import * as React from 'react';
import ColorPicker from './ColorPicker';
import DropDown from './Dropdown';

export default function DropdownColorPicker({
    disabled = false,
    stopCloseOnClickSelf = true,
    color,
    onChange,
    ...rest
}) {
    return (
        <DropDown {...rest} colorBtn disabled={disabled} stopCloseOnClickSelf={stopCloseOnClickSelf}>
            <ColorPicker color={color} onChange={onChange} />
        </DropDown>
    );
}
