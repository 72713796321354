import { useImmerReducer } from 'use-immer';
import { downloadBlob, downloadMp3 } from '../../../../../../utils/mp3';
import { initState, speechHistoryReducer } from './reducer';
import { speechHistoryService } from './service';

export const useHistorySpeech = () => {
    const [state, dispatch] = useImmerReducer(speechHistoryReducer, initState);

    const play = (payload) => {
        dispatch({
            type: 'PLAY_AUDIO',
            payload
        });
    };

    const homepageAudioStart = (payload) => {
        dispatch({
            type: 'HOMEPAGE_AUDIO_START',
            payload
        });
    };

    const homepageAudioPause = (payload) => {
        dispatch({
            type: 'HOMEPAGE_AUDIO_PAUSE',
            payload
        });
    };

    const close = () => {
        dispatch({
            type: 'CLOSE_POPUP'
        });
    };

    const voice = (payload) => {
        dispatch({
            type: 'VOICE',
            payload
        });
    };

    const fetch = async (pageNum = 1) => {
        dispatch({
            type: 'FETCH_DATA'
        });

        const { data: list, pagination } = await speechHistoryService.list(pageNum);

        try {
            dispatch({
                type: 'FETCH_DATA_SUCCESS',
                payload: { list, pagination }
            });
        } catch (error) {
            dispatch({
                type: 'FETCH_FAILED',
                payload: error
            });
        }
    };

    const remove = async (payload) => {
        dispatch({
            type: 'REMOVE_AUDIO',
            payload
        });

        try {
            await speechHistoryService.remove(payload);

            dispatch({
                type: 'REMOVE_SUCCESS'
            });
        } catch (err) {
            dispatch({
                type: 'REMOVE_FAILED',
                payload: err
            });
        }
    };

    const download = async (payload) => {
        dispatch({
            type: 'DOWNLOAD_FILES'
        });

        try {
            if (payload.length === 1) {
                const el = state.history.list.find((item) => item._id === payload[0]);

                await downloadMp3(el.voiceName, el.url);
            }

            if (payload.length > 1) {
                const data = await speechHistoryService.download(payload);

                await downloadBlob(data, 'audio.zip');
            }

            dispatch({
                type: 'DOWNLOAD_SUCCESS'
            });
        } catch (err) {
            dispatch({
                type: 'DOWNLOAD_FAILED'
            });
        }
    };

    return {
        state,
        actions: {
            homepageAudioStart,
            homepageAudioPause,
            fetch,
            download,
            remove,
            play,
            voice,
            close
        }
    };
};
