import { axios } from '../../../../../../../services/http/axios';

const billingService = {
    fetch: async (org) => {
        const response = await axios.get(`/billing/${org.billingPlan._id}/settings`);

        return response.data;
    },
    cancelPlan: async (org, values) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/plan/cancel`, values);

        return response.data;
    },
    calculate: async (org, values) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/plan/calc`, values);

        return response.data;
    },
    getPlan: async (org, values) => {
        const params = new URLSearchParams(values).toString();

        const response = await axios.get(`/billing/${org.billingPlan._id}/plan/calc/?${params}`, values);

        return response.data;
    },
    setupIntent: async (org) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/intent/setup`);

        return response.data;
    },
    confirmIntent: async (org, searchParams) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/intent/confirm`, searchParams);

        return response.data;
    },
    confirmNoRedirectIntent: async (org, setupIntent, amount) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/intent/confirm/no-redirect`, {
            setupIntent,
            amount
        });

        return response.data;
    },
    completeIntent: async (org, values) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/intent/charge`, values);

        return response.data;
    },
    detachCard: async (org, paymentMethod) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/card/detach`, { paymentMethod });

        return response.data;
    },
    changeCard: async (org, paymentMethod) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/card/change`, { paymentMethod });

        return response.data;
    },
    addDiscount: async (org, values) => {
        const response = await axios.post(`/billing/${org.billingPlan._id}/discount`, values);

        return response.data;
    },
    removeDiscount: async (org, discountId) => {
        const response = await axios.delete(`/billing/${org.billingPlan._id}/discount/${discountId}`);

        return response.data;
    }
};

export default billingService;
