import React from 'react';
import { Card } from 'antd';
import styles from './index.module.scss';

export const WhiteCover = ({ padding = '24px', children, ...props }) => {
    const cardStyle = {
        '--padding': padding
    };

    return (
        <Card style={cardStyle} className={styles.card} {...props}>
            {children}
        </Card>
    );
};
