import React from 'react';

export default function CardChip(props) {
    return (
        <svg {...props} xmlns='http://www.w3.org/2000/svg' width='67' height='37' fill='none' viewBox='0 0 67 37'>
            <path
                stroke='#0288D1'
                d='M0 7.65h27.085M.713 32.15h65.574M0 16.75h67M42.053 18.5c0 9.665-3.83 17.5-8.553 17.5-4.724 0-8.553-7.835-8.553-17.5S28.777 1 33.5 1c4.724 0 8.553 7.835 8.553 17.5z'
            />
        </svg>
    );
}
