const config = {
    _id: 119,
    title: 'Headline Optimizer',
    alias: 'headline-optimizer',
    about: 'This AI tool can suggest more compelling headlines for your blog posts, which can lead to better click-through rates.',
    categories: ['Blog Content'],
    tips: [
        'Make sure your headline accurately reflects your content.',
        'Keep your headlines concise and engaging, making sure they pique reader interest.'
    ],
    prompts: [
        'Create a list of headlines on [topic].',
        'How can I make this headline more SEO-friendly? [insert headline].',
        'Generate a catchy headline for a blog post about [topic].',
        'Which headline would be more effective for this blog post? [insert blog post].'
    ]
};

export default config;
