import { axios } from '../../../../services/http/axios';

const document = {
    download: (body) =>
        axios.post('/document/download', body, {
            responseType: 'blob',
            headers: { 'Content-Type': 'application/json' }
        }),
    create: () => axios.post('/document/create'),
    remove: (id) => axios.delete(`/document/remove/${id}`),
    get: (id) => axios.get(`/document/get/${id}`),
    update: (body) => axios.post('/document/update', body),
    list: (page = 1, limit = 10, search = '') => axios.get('/document/list', { params: { page, limit, search } })
};

const api = {
    document
};

export default api;
