import React from 'react';
import CreditCards from './components/CreditCards/CreditCards';
import PaymentHistory from './components/PaymentHistory/PaymentHistory';
import styles from './index.module.scss';

export default function Payment({ form, billing, org }) {
    return (
        <div className={styles.billing__payment}>
            <div className={styles.billing__payment__container}>
                <CreditCards form={form} billing={billing} org={org} />
                <PaymentHistory billing={billing} org={org} />
            </div>
        </div>
    );
}
