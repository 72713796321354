import React, { useEffect, useRef } from 'react';
import { App, Form, Input, Modal, Typography } from 'antd';
import styles from './index.module.scss';

export const ModalPromptCreate = ({ actions, state, id }) => {
    const [form] = Form.useForm();

    const { notification } = App.useApp();

    const onCreateNewPrompt = async () => {
        const prompt = form.getFieldValue('prompts');

        if (!prompt) {
            notification.error({
                placement: 'top',
                message: 'Prompt field cannot be empty.'
            });

            return;
        }

        try {
            await actions.createPrompt(prompt, id);
            await actions.modals.createPrompt(false);
        } catch (err) {
            notification.error({
                placement: 'top',
                message: 'Something went wrong! Please try again.'
            });
        } finally {
            form.submit();
        }
    };

    const useResetFormOnCloseModal = ({ form, open }) => {
        const prevOpenRef = useRef();

        useEffect(() => {
            prevOpenRef.current = open;
        }, [open]);

        const prevOpen = prevOpenRef.current;

        useEffect(() => {
            if (!open && prevOpen) {
                form.resetFields();
            }
        }, [form, prevOpen, open]);
    };

    useResetFormOnCloseModal({
        form,
        open
    });

    return (
        <Modal
            title='Create a prompt'
            className='modal-component single__tool__modal'
            centered
            open
            width={585}
            closable
            okText='Create Prompt'
            confirmLoading={state.modal.btnCreatePrompt}
            getContainer={() => document.querySelector('#app-layout')}
            onOk={onCreateNewPrompt}
            onCancel={() => actions.modals.createPrompt(false)}
        >
            <Form form={form} name='promptForm' className={styles.formNewTool}>
                <Form.Item>
                    <Typography.Title level={5}>Prompt</Typography.Title>
                    <Form.Item name='prompts'>
                        <Input.TextArea
                            autoSize={{ minRows: 6, maxRows: 6 }}
                            showCount
                            maxLength={3000}
                            placeholder='Example: Given my video blog title: [blog title] and target audience:
                        [target audience], generate a long blog article that is SEO optimized.'
                        />
                    </Form.Item>
                </Form.Item>
            </Form>
        </Modal>
    );
};
