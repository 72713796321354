export const initState = {
    data: {
        list: [],
        page: 1,
        total: 0
    },
    knowledgeBase: {
        list: [],
        loading: false,
        drawer: false
    },
    current: null,
    loader: {
        list: false,
        create: false,
        update: false,
        remove: false,
        updateOptions: false,
        promptsOpen: false
    }
};

export function conversationReducer(draft, action) {
    switch (action.type) {
        case 'LIST': {
            draft.loader.list = true;
            break;
        }
        case 'PROMPTS_OPEN': {
            draft.loader.promptsOpen = action.payload;
            break;
        }
        case 'UPDATE_OPTIONS': {
            draft.loader.updateOptions = action.payload;
            break;
        }
        case 'LIST_SUCCESS': {
            draft.loader.list = false;
            draft.data = {
                list: [...draft.data.list, ...action.payload.list],
                page: action.payload.page,
                total: action.payload.total
            };
            break;
        }
        case 'LIST_ERROR': {
            draft.loader.list = false;
            break;
        }
        case 'CREATE': {
            draft.loader.create = true;
            break;
        }
        case 'CREATE_SUCCESS': {
            draft.loader.updateOptions = action.payload;
            draft.loader.create = false;
            draft.data.list = [
                action.payload,
                ...draft.data.list.map((doc) => ({
                    ...doc,
                    selected: false
                }))
            ];
            break;
        }
        case 'CREATE_ERROR': {
            draft.loader.create = false;
            break;
        }
        case 'UPDATE': {
            draft.loader.update = true;
            break;
        }
        case 'UPDATE_SUCCESS': {
            draft.loader.update = false;
            draft.current.title = action.payload.title;
            draft.current._id = action.payload._id;
            draft.data.list = draft.data.list.map((doc) => {
                return doc._id === action.payload._id
                    ? {
                          ...doc,
                          ...action.payload,
                          editMode: false
                      }
                    : doc;
            });
            break;
        }
        case 'UPDATE_ERROR': {
            draft.loader.update = false;
            break;
        }
        case 'REMOVE': {
            draft.loader.remove = true;
            break;
        }
        case 'REMOVE_SUCCESS': {
            draft.loader.remove = false;
            draft.data.list = draft.data.list.filter((doc) => {
                return doc._id !== action.payload;
            });
            break;
        }
        case 'REMOVE_ERROR': {
            draft.loader.remove = false;
            break;
        }
        case 'SELECT': {
            draft.current = draft.data.list.find((doc) => doc._id === action.payload);
            break;
        }
        case 'EDIT_MODE': {
            draft.data.list.map((doc) => {
                doc.editMode = doc._id === action.payload;

                return doc;
            });
            break;
        }
        case 'KNOWLEDGE_BASE_LIST_SUCCESS': {
            draft.knowledgeBase.list = action.payload;
            draft.knowledgeBase.loading = false;
            break;
        }
        case 'KNOWLEDGE_BASE_LIST': {
            draft.knowledgeBase.loading = true;
            break;
        }
        case 'KNOWLEDGE_BASE_LIST_ERROR': {
            draft.knowledgeBase.loading = false;
            break;
        }
        case 'KNOWLEDGE_BASE_DRAWER': {
            draft.knowledgeBase.drawer = action.payload;
            break;
        }
        default: {
            return draft;
        }
    }
}
