import React from 'react';
import Icon from '@ant-design/icons';

export default function CloseIcon(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg xmlns='http://www.w3.org/2000/svg' width='7' height='7' fill='none' viewBox='0 0 7 7'>
                    <path
                        fill='#000'
                        d='M1.257 0L0 1.257l.642.642 1.587 1.614L.642 5.101 0 5.716 1.257 7l.642-.642 1.614-1.614 1.588 1.614.615.642L7 5.716 6.358 5.1 4.744 3.513 6.358 1.9 7 1.257 5.716 0 5.1.642 3.513 2.229 1.9.642 1.257 0z'
                    />
                </svg>
            )}
        />
    );
}
