import React from 'react';
import Icon from '@ant-design/icons';

export default function AmericanExpress(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg width='58' height='18' viewBox='0 0 58 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M2.9258 0.00109863L0 6.54615H1.90485L2.4447 5.23553H5.58308L6.12013 6.54615H8.06694L5.14393 0.00109863H2.9258ZM4.00829 1.52433L4.96492 3.81456H3.04888L4.00829 1.52433Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M8.26831 6.54506V0L10.9752 0.00967267L12.5496 4.22951L14.0864 0H16.7716V6.54506H15.0709V1.72238L13.2682 6.54506H11.7767L9.96897 1.72238V6.54506H8.26831Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M17.9353 6.54506V0H23.4848V1.46403H19.6539V2.58357H23.3953V3.96148H19.6539V5.12409H23.4848V6.54506H17.9353Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M24.4692 0.00109863V6.54615H26.1699V4.22093H26.886L28.9251 6.54615H31.0033L28.7656 4.13482C29.684 4.06026 30.6313 3.30188 30.6313 2.12448C30.6313 0.747167 29.5078 0.00109863 28.2538 0.00109863H24.4692ZM26.1699 1.46512H28.1139C28.5802 1.46512 28.9195 1.81609 28.9195 2.15407C28.9195 2.5889 28.4799 2.84303 28.1391 2.84303H26.1699V1.46512Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M33.0621 6.54506H31.3257V0H33.0621V6.54506Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M37.1796 6.54506H36.8048C34.9912 6.54506 33.8901 5.17039 33.8901 3.29944C33.8901 1.38228 34.9789 0 37.2691 0H39.1488V1.55014H37.2004C36.2707 1.55014 35.6132 2.2482 35.6132 3.31559C35.6132 4.58312 36.365 5.11548 37.4481 5.11548H37.8956L37.1796 6.54506Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M40.8801 0.00109863L37.9543 6.54615H39.8592L40.399 5.23553H43.5374L44.0745 6.54615H46.0213L43.0983 0.00109863H40.8801ZM41.9626 1.52433L42.9193 3.81456H41.0032L41.9626 1.52433Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M46.22 6.54506V0H48.3822L51.1429 4.11219V0H52.8436V6.54506H50.7513L47.9206 2.32522V6.54506H46.22Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M12.3857 17.9989V11.4539H17.9353V12.9179H14.1043V14.0374H17.8457V15.4153H14.1043V16.5779H17.9353V17.9989H12.3857Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M39.5784 17.9989V11.4539H45.1279V12.9179H41.2969V14.0374H45.0205V15.4153H41.2969V16.5779H45.1279V17.9989H39.5784Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M18.1505 17.9989L20.8525 14.7668L18.0862 11.4539H20.2288L21.8763 13.5019L23.5294 11.4539H25.5881L22.8581 14.7264L25.5651 17.9989H23.4228L21.8232 15.9832L20.2624 17.9989H18.1505Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M25.7671 11.455V18H27.5125V15.9331H29.3027C30.8174 15.9331 31.9655 15.16 31.9655 13.6564C31.9655 12.4108 31.0651 11.455 29.5236 11.455H25.7671ZM27.5125 12.9351H29.3978C29.8871 12.9351 30.2369 13.2237 30.2369 13.6887C30.2369 14.1255 29.8889 14.4422 29.3922 14.4422H27.5125V12.9351Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M32.7041 11.4539V17.9989H34.4048V15.6737H35.1208L37.1599 17.9989H39.2382L37.0005 15.5876C37.9189 15.513 38.8662 14.7546 38.8662 13.5772C38.8662 12.1999 37.7426 11.4539 36.4886 11.4539H32.7041ZM34.4048 12.9179H36.3488C36.8151 12.9179 37.1543 13.2689 37.1543 13.6068C37.1543 14.0417 36.7148 14.2958 36.3739 14.2958H34.4048V12.9179Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M45.9156 17.9989V16.5779H49.3192C49.8228 16.5779 50.0409 16.3161 50.0409 16.0289C50.0409 15.7538 49.8235 15.4756 49.3192 15.4756H47.7812C46.4443 15.4756 45.6997 14.6919 45.6997 13.5153C45.6997 12.4659 46.3815 11.4539 48.3682 11.4539H51.68L50.9639 12.9265H48.0996C47.5521 12.9265 47.3836 13.2029 47.3836 13.4669C47.3836 13.7382 47.5919 14.0374 48.0101 14.0374H49.6213C51.1116 14.0374 51.7583 14.8508 51.7583 15.9159C51.7583 17.061 51.0377 17.9989 49.5402 17.9989H45.9156Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M52.1573 17.9989V16.5779H55.5609C56.0645 16.5779 56.2826 16.3161 56.2826 16.0289C56.2826 15.7538 56.0652 15.4756 55.5609 15.4756H54.0229C52.686 15.4756 51.9414 14.6919 51.9414 13.5153C51.9414 12.4659 52.6232 11.4539 54.6099 11.4539H57.9217L57.2056 12.9265H54.3413C53.7938 12.9265 53.6253 13.2029 53.6253 13.4669C53.6253 13.7382 53.8336 14.0374 54.2518 14.0374H55.863C57.3533 14.0374 58 14.8508 58 15.9159C58 17.061 57.2794 17.9989 55.7819 17.9989H52.1573Z'
                        fill='#828D9E'
                    />
                </svg>
            )}
        />
    );
}
