const config = {
    _id: 79,
    title: 'Generate Content Ideas',
    alias: 'generate-content-ideas',
    about: 'Generate unique, creative and engaging ideas that will keep your audience coming back for more.',
    categories: ['Marketing'],
    tips: [
        'If you want to generate a longer text, you can prompt Just Think AI  to continue writing on a topic by using the command "continue writing on the topic of [insert topic here]".',
        'Avoid asking open-ended questions that can lead to a wide range of answers. Instead, try to focus on a specific topic or aspect of a subject.'
    ],
    prompts: [
        'I am looking to create content on [topic]. Can you help me generate a list of potential article ideas and titles?',
        'I am trying to expand my content portfolio by writing about [industry/niche]. Can you help me come up with a list of topics that would be of interest to my [target audience]?',
        'I want to create a series of blog posts that cover the basics of [topic]. Can you help me generate a list of subtopics and titles for these posts?',
        'I am looking to expand the content on my website, but I am struggling to come up with new ideas. Can you help me generate a list of possible topics for my [industry/niche] business?',
        'I am a content creator and looking to come up with new ideas for my [YouTube channel/ Instagram account]. Can you help me generate a list of potential [video/post topics] that would be popular among my audience [audience type]?'
    ]
};

export default config;
