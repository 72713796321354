import { axios } from '../../../../services/http/axios';

const toolsService = {
    create: (payload) => axios.post('/tools/create', payload),
    remove: (id) => axios.delete(`/tools/remove/${id}`),
    update: (id, payload) => axios.patch(`/tools/update/${id}`, payload)
};

const promptsService = {
    update: (payload, id) => axios.patch(`/tools/${id}`, { prompts: payload }),
    remove: (payload, id) => axios.patch(`/tools/prompts/${id}`, { prompts: payload }),
    list: (id) => axios.get(`/tools/prompts/${id}`)
};

const tipsService = {
    update: (payload, id) => axios.patch(`/tools/tips/${id}`, { tips: payload }),
    remove: (payload, id) => axios.patch(`/tools/tips/remove/${id}`, { tips: payload })
};

const api = {
    promptsService,
    tipsService,
    toolsService
};

export default api;
