import React from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Typography } from 'antd';

export const ModalRemove = ({ actions, state, config, context }) => {
    const history = useHistory();

    return (
        <Modal
            title='Notification'
            className='modal-component'
            centered
            open
            width={585}
            closable
            okText='Remove'
            confirmLoading={state.modal.btnRemove}
            getContainer={() => document.querySelector('#app-layout')}
            onCancel={() => actions.modals.removeTools(false)}
            onOk={async () => {
                await actions.removeTools(config._id);

                history.push('/tools');

                await context.actions.toolsList();
            }}
        >
            <Typography.Title level={3}>Are you sure you want to delete this tool?</Typography.Title>
        </Modal>
    );
};
