import React from 'react';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import { Col, Image, notification, Row, Tooltip, Typography } from 'antd';
import JustThinkLogoSmall from '../../../../../../assets/images/bulb-icon-mini.png';
import { CDN_URL } from '../../../../../../config';
import { useBreakpoint } from '../../../../../../hooks/media';
import { $colorWhite } from '../../../../../../styles/global';
import { useAuthContext } from '../../../../../AuthPage/context';
import styles from './index.module.scss';

export const ArtTemplate = ({ isFavoriteShow = true, art, actions, history }) => {
    const auth = useAuthContext();

    let image;

    if (art.categories[0] === 'my templates') {
        image = art.image;
    } else {
        image = `${CDN_URL}/art-template/${art.categories[0]}/${art.alias}/main/${art.alias}.jpg`;
    }

    const { isMobile } = useBreakpoint();

    const isFavoriteArt = auth.profile?.favoriteArt?.includes(art._id);

    const onFavoriteClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        try {
            await auth.art.favorite.toggle(art._id);
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };

    return (
        <Col
            key={`art-alias-${art.alias}-id-${art._id}`}
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}
            xl={{ span: 8 }}
            xxl={{ span: 6 }}
        >
            <Row
                data-art-alias={art.alias}
                onClick={(e) => {
                    if (actions) actions.promptsOpen(false);

                    const { artAlias } = e.currentTarget.dataset;

                    history.push(`/art/image-templates/${artAlias}`);
                }}
                className={styles['art-page__single-card']}
            >
                <Col>
                    {art.categories.includes('my templates') || !isFavoriteShow ? null : (
                        <Tooltip destroyTooltipOnHide overlayStyle={{ position: 'fixed' }} title='Save to Favorites'>
                            {isFavoriteArt ? (
                                <StarFilled style={{ color: $colorWhite }} onClick={onFavoriteClick} />
                            ) : (
                                <StarOutlined
                                    style={{ color: $colorWhite }}
                                    className='art-card-favorite-icon'
                                    onClick={onFavoriteClick}
                                />
                            )}
                        </Tooltip>
                    )}
                    <Image
                        width='100%'
                        height={isMobile ? '140px' : '300px'}
                        preview={false}
                        src={image || JustThinkLogoSmall}
                    />
                </Col>
                <Col className={styles['art-page__single-card__footer']}>
                    <Typography.Title level={2} ellipsis={{ rows: isMobile ? 2 : 1 }}>
                        {art.title}
                    </Typography.Title>
                    <Typography.Paragraph ellipsis={{ rows: isMobile ? 2 : 1 }}>{art.about}</Typography.Paragraph>
                </Col>
            </Row>
        </Col>
    );
};
