import React, { useContext, useState } from 'react';
import { ArrowLeftOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Space, Typography } from 'antd';
import { useBreakpoint } from '../../../../../../hooks/media';
import { uuid } from '../../../../../../utils/crypto';
import { DashboardContext } from '../../../../context';
import { descriptions } from '../../constants';
import { useTools } from '../../hooks';
import { cutStringByWordCount } from './components/hooks';
import { ModalRemove } from './components/ModalRemove';
import { PromptsList } from './components/PromptsList';
import { TipsList } from './components/TipsList';
import './Tool.scss';

export default function Tool({ config, onBack }) {
    const { actions: toolsActions, state: toolsState } = useTools();

    const { isMobile } = useBreakpoint();

    const context = useContext(DashboardContext);

    const [toolTitle, setTitle] = useState(config.title);

    const onViewAllPrompts = () => {
        document.querySelector('.single-tool__prompts').classList.toggle('show');
        toolsActions.promptsView();
    };

    const onShowMore = () => {
        toolsActions.aboutMore();
    };

    const onRemove = async () => {
        await toolsActions.modals.removeTools(true);
    };

    const onViewAllTips = () => {
        document.querySelector('.single-tool__tips').classList.toggle('show');
        toolsActions.tipsView();
    };

    const style = context.state.tool.tabs.find((c) => c.tab === config.categories[0]);

    const onUpdate = async (e) => {
        e.preventDefault();

        const newTitle = e.target.value.trim();

        setTitle(newTitle);
        await toolsActions.updateToolTitle(config._id, { title: newTitle });
        await context.actions.toolsList();
    };

    const onEdit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        toolsActions.editToolTitle(config._id);
    };

    return (
        <Row className='single-tool'>
            <Col className='single-tool__left' xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 15 }}>
                <Space align='start' style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Button onClick={onBack} className='single-tool__back' icon={<ArrowLeftOutlined />}>
                        Back to All Tools
                    </Button>
                    {!config.categories.includes('My tools') ? null : (
                        <Button onClick={onRemove} className='single-tool__remove' icon={<DeleteOutlined />} />
                    )}
                </Space>
                <Space className='single-tool__categories'>
                    {config.categories?.map((category) => {
                        return (
                            <div key={uuid()} style={{ background: style?.bgGradient }} className='category'>
                                {category}
                            </div>
                        );
                    })}
                </Space>
                <div className='single-tool__title-wrap'>
                    <div className='single-tool__title__inner'>
                        {toolsState.editMode ? (
                            <Input
                                size='small'
                                autoFocus
                                defaultValue={toolTitle}
                                onPressEnter={onUpdate}
                                onBlur={onUpdate}
                                onClick={(e) => e.stopPropagation()}
                            />
                        ) : (
                            <Typography.Title className='single-tool__title' level={2}>
                                {toolTitle}
                            </Typography.Title>
                        )}
                    </div>
                    <div className='single-tool__title__edit'>
                        <Button onClick={onEdit} icon={<EditOutlined />} />
                    </div>
                </div>
                <Typography.Title level={4}>About Task</Typography.Title>
                {!isMobile ? (
                    <Typography.Paragraph style={{ marginBottom: '30px' }}>{config.about}</Typography.Paragraph>
                ) : (
                    <Typography.Paragraph style={{ marginBottom: '30px' }} className='single-tool__about'>
                        {cutStringByWordCount(config.about, toolsState.aboutWordsCount)}
                        <a onClick={onShowMore}>
                            {config.about.split(' ').length > toolsState.aboutWordsCount ? toolsState.aboutMore : null}
                        </a>
                    </Typography.Paragraph>
                )}
                <Space align='center' className='single-tool__view'>
                    <Typography.Title level={4}>Prompts</Typography.Title>
                    {isMobile && config.prompts.length > 2 && (
                        <a onClick={onViewAllPrompts}>{toolsState.promptsView}</a>
                    )}
                </Space>
                <Typography.Paragraph style={{ marginBottom: '30px' }}>{descriptions.prompts}</Typography.Paragraph>
                <PromptsList context={context} config={config} actions={toolsActions} state={toolsState} />
            </Col>
            <Col className='single-tool__right' xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 9 }}>
                <Space align='center' className='single-tool__view'>
                    <Typography.Title level={4}>Tips</Typography.Title>
                    {isMobile && config.tips?.length > 2 && <a onClick={onViewAllTips}>{toolsState.tipsView}</a>}
                </Space>
                <Typography.Paragraph>
                    <div dangerouslySetInnerHTML={{ __html: descriptions.tips }} />
                </Typography.Paragraph>
                <TipsList config={config} state={toolsState} actions={toolsActions} />
            </Col>
            {toolsState.modal.removeTools}
            {toolsState.modal.removeTools ? (
                <ModalRemove state={toolsState} actions={toolsActions} config={config} context={context} />
            ) : null}
        </Row>
    );
}
