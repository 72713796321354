export const downloadFile = (response, documentTitle, fileType) => {
    const url = window.URL.createObjectURL(new Blob([response.data]));

    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${documentTitle || 'Untitled document'}.${fileType}`);
    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(url);
};
