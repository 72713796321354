import React from 'react';
import Icon from '@ant-design/icons';

export default function MasterCard(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg width='30' height='18' viewBox='0 0 30 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M11.4592 9C11.4592 6.125 12.8498 3.575 14.9871 1.925C13.4163 0.725 11.4335 0 9.27039 0C4.14592 0 0 4.025 0 9C0 13.975 4.14592 18 9.27039 18C11.4335 18 13.4163 17.275 14.9871 16.075C12.8498 14.45 11.4592 11.875 11.4592 9Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M29.9998 9C29.9998 13.975 25.8539 18 20.7294 18C18.5663 18 16.5835 17.275 15.0127 16.075C17.1758 14.425 18.5406 11.875 18.5406 9C18.5406 6.125 17.15 3.575 15.0127 1.925C16.5835 0.725 18.5663 0 20.7294 0C25.8539 0 29.9998 4.05 29.9998 9Z'
                        fill='#DDDFE3'
                    />
                </svg>
            )}
        />
    );
}
