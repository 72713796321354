/* eslint-disable */

const config = {
    _id: 125,
    title: 'Eviction Notice Generator',
    alias: 'eviction-notice-generator',
    about: 'The Eviction Notice Writer tool generates eviction notices in a legal and professional manner. By providing specific information about the tenant, the reason for eviction, and local regulations, the AI can draft a compliant eviction notice.',
    categories: ['Real Estate'],
    tips: [
        "When drafting eviction notices, always ensure that you're complying with local laws regarding eviction notice periods and justifications."
    ],
    prompts: [
        'Draft an eviction notice for [Tenant\'s Name] living at [Property Address] due to [Specific Reason].',
        "I need to create an eviction notice for a tenant who hasn't paid rent for [Number of Months].",
        'Generate an eviction notice for a tenant who has violated the lease agreement by [Specific Violation].',
        'Write an eviction notice for a property located in [City/State] to ensure it complies with local regulations.',
        'Draft a tactful eviction notice for a long-term tenant due to [Specific Reason].'
    ]
};

export default config;
