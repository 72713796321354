export const FIRST_NAME_VALIDATION_RULES = [
    {
        required: true,
        type: 'string',
        message: 'required'
    },
    {
        min: 2,
        message: 'must be at least 2 characters'
    },
    {
        max: 30,
        message: 'must be at most 30 characters'
    },
    {
        pattern: /^[a-zA-Z ]+$/,
        message: 'must contain only letters'
    }
];

export const LAST_NAME_VALIDATION_RULES = [
    {
        required: true,
        type: 'string',
        message: 'required'
    },
    {
        min: 2,
        message: 'must be at least 2 characters'
    },
    {
        max: 30,
        message: 'must be at most 30 characters'
    },
    {
        pattern: /^[a-zA-Z]+$/,
        message: 'must contain only letters'
    }
];

export const EMAIL_VALIDATION_RULES = [
    {
        required: true,
        type: 'email',
        message: 'address invalid'
    },
    {
        max: 320,
        message: 'must be at most 320 characters'
    }
];

export const PASSWORD_VALIDATION_RULES = [
    {
        required: true,
        message: 'required'
    },
    {
        min: 6,
        message: 'must be at least 6 characters'
    },
    {
        max: 22,
        message: 'must be at most 22 characters'
    }
];

export const PHONE_VALIDATION_RULES = [
    {
        max: 22,
        message: 'must be at most 22 characters'
    },
    {
        pattern: /^(\+\d{1,3}[- ]?)?\d{10}$/,
        message: 'The number should follow an international phone format'
    }
];
