import React from 'react';
import Icon from '@ant-design/icons';

export default function VisaIcon(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg width='56' height='18' viewBox='0 0 56 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M24.2666 17.7403H19.7302L22.5676 0.315796H27.1038L24.2666 17.7403Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M36.6427 0C32.1628 0 29.0082 2.37257 28.9888 5.76463C28.9516 8.26729 31.2475 9.65732 32.9646 10.4918C34.7197 11.3445 35.3162 11.901 35.3162 12.6611C35.2984 13.8284 33.8981 14.3665 32.592 14.3665C30.7809 14.3665 29.8104 14.0891 28.3357 13.4396L27.7383 13.1612L27.1035 17.0727C28.1675 17.5541 30.1277 17.9813 32.1628 18C36.9227 18 40.0216 15.6641 40.0583 12.0494C40.0764 10.0658 38.8641 8.54593 36.2505 7.30397C34.6638 6.50671 33.6922 5.96912 33.6922 5.15338C33.7108 4.4118 34.514 3.65223 36.305 3.65223C37.7798 3.61503 38.8634 3.9671 39.6842 4.31941L40.0945 4.50443L40.7114 0.741829C39.8167 0.389269 38.3975 0 36.6427 0Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M48.5518 6.69217C48.533 6.72937 48.9247 5.67268 49.1487 5.02398L49.4657 6.52538C49.4657 6.52538 50.3248 10.6962 50.5113 11.5674C49.8023 11.5674 47.6367 11.5674 46.7407 11.5674C47.1143 10.5664 48.5518 6.69217 48.5518 6.69217ZM48.8314 0.315796C47.7493 0.315796 46.9273 0.63066 46.4604 1.76151L39.7222 17.74H44.4821C44.4821 17.74 45.2657 15.5894 45.4341 15.1263C45.9563 15.1263 50.5867 15.1263 51.2585 15.1263C51.3887 15.738 51.7998 17.74 51.7998 17.74H56L52.3403 0.315796H48.8314Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M15.9412 0.315796L11.4985 12.1976L11.0131 9.78785C10.1917 7.00728 7.6158 3.98626 4.74121 2.48412L8.81049 17.7218H13.6076L20.738 0.315796H15.9412V0.315796Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M7.37328 0.315796H0.074668L0 0.667863C5.69337 2.11382 9.46398 5.59926 11.0132 9.78859L9.42652 1.78048C9.16531 0.667616 8.36257 0.352505 7.37328 0.315796Z'
                        fill='#DDDFE3'
                    />
                </svg>
            )}
        />
    );
}
