import React, { useEffect, useRef } from 'react';
import { App, Form, Input, Modal, Typography } from 'antd';
import styles from './index.module.scss';

export const ModalTipCreate = ({ actions, state, id }) => {
    const [form] = Form.useForm();

    const { notification } = App.useApp();

    const onCreateNewTip = async () => {
        const tip = form.getFieldValue('tips');

        if (!tip) {
            notification.error({
                placement: 'top',
                message: 'Tip field cannot be empty.'
            });

            return;
        }

        try {
            await actions.createTip(tip, id);

            await actions.modals.createTip(false);
        } catch (err) {
            notification.error({
                placement: 'top',
                message: 'Something went wrong! Please try again.'
            });
        } finally {
            form.submit();
        }
    };

    const useResetFormOnCloseModal = ({ form, open }) => {
        const prevOpenRef = useRef();

        useEffect(() => {
            prevOpenRef.current = open;
        }, [open]);

        const prevOpen = prevOpenRef.current;

        useEffect(() => {
            if (!open && prevOpen) {
                form.resetFields();
            }
        }, [form, prevOpen, open]);
    };

    useResetFormOnCloseModal({
        form,
        open
    });

    return (
        <Modal
            title='Create a tip'
            className='modal-component  single__tool__modal'
            centered
            open
            width={585}
            closable
            okText='Create Tool Tip'
            confirmLoading={state.modal.btnCreateTip}
            getContainer={() => document.querySelector('#app-layout')}
            onOk={onCreateNewTip}
            onCancel={() => actions.modals.createTip(false)}
        >
            <Form form={form} name='tipForm' className={styles.formNewTool}>
                <Form.Item>
                    <Typography.Title level={5}>Tool Tip</Typography.Title>
                    <Form.Item name='tips'>
                        <Input.TextArea
                            autoSize={{ minRows: 6, maxRows: 6 }}
                            showCount
                            maxLength={3000}
                            placeholder='Example: Provide a clear and focused topic for the blog post to make it easier for Just Think AI to generate relevant content.'
                        />
                    </Form.Item>
                </Form.Item>
            </Form>
        </Modal>
    );
};
