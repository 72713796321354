export const initState = {
    promptHelper: '',
    promptPopover: false,
    modal: {
        helper: false
    },
    loader: {
        helper: false,
        main: false
    }
};

export function artReducer(draft, action) {
    switch (action.type) {
        case 'PROMPT_POPOVER_STATUS': {
            draft.promptPopover = action.payload;
            break;
        }
        case 'PROMPT_HELPER_START': {
            draft.promptHelper = '';
            draft.loader.helper = true;
            break;
        }
        case 'PROMPT_HELPER_END': {
            draft.promptHelper = action.payload.data.prompt;
            draft.loader.helper = false;
            break;
        }
        case 'PROMPT_HELPER_MODAL': {
            draft.modal.helper = action.payload;
            break;
        }
        default: {
            return draft;
        }
    }
}
