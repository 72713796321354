/* eslint-disable */

const config = {
    _id: 97,
    title: 'Quiz Master',
    alias: 'quiz-master',
    about: 'Quiz creation tool designed to generate quizzes based on specified topics and difficulty levels.',
    categories: ['Education'],
    tips: [
        'Use specific subjects and topics in your prompts to generate quizzes.',
        'Specify the desired difficulty level to match your students\' abilities.'
    ],
    prompts: [
        'Create a [difficulty level] quiz on [specific topic].',
        'Generate an [difficulty level] [subject] quiz covering the topic of [specific topic].',
        'Formulate a [difficulty level] [subject] quiz focusing on [specific topic].',
        'Construct a [difficulty level] quiz on [specific topic] with multiple choice and true or false questions.',
        'Generate a fun and challenging [subject] quiz that includes fill-in-the-blank and match-the-following questions on [specific topic].'
    ]
};

export default config;
