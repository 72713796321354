export const $colorPrimary = '#0288d1';
export const $colorGrayBg = '#F1F1F1';
export const $colorWhite = '#FFFFFF';
export const $colorText = '#1F1F1F';
export const $colorModalTitle = '#141E30';
export const $bgModalHeader = '#EAF9FE';
export const $darkModeText = 'rgba(255, 255, 255, 0.85)';

export const $fontFamily = 'Poppins, sans-serif';

/// gradients

export const $blueGradient = 'radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%)';
