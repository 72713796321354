import React from 'react';
import { useHistory } from 'react-router-dom';
import { notification, Typography } from 'antd';
import moment from 'moment/moment';
import DocsDownload from '../../../../../../../../components/Icons/DocsDownload';
import api from '../../../../../DocumentPage/service';
import { downloadFile } from '../../../../../DocumentPage/utils/downloadFile';
import styles from './index.module.scss';

export const SingleRow = ({ item, setDocToken }) => {
    const history = useHistory();

    const onDocSelect = () => {
        setDocToken(() => item.key);

        history.push('/document/editor');
    };

    const onDownload = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (item.html) {
            const response = await api.document.download({ fileType: 'docx', id: item.key });

            downloadFile(response, item.title, 'docx');
        } else {
            notification.error({
                placement: 'top',
                message: 'File is empty'
            });
        }
    };

    return (
        <div onClick={onDocSelect} className={styles.docs__single}>
            <div className={styles.docs__single__inner}>
                <Typography.Title level={3}>{item.title || 'Untitled Document'}</Typography.Title>
                <Typography.Text>
                    <b>{moment(item.date).format('MMM DD')}</b>
                    {moment(item.date).format('YYYY')}
                </Typography.Text>
            </div>
            <div className={styles.docs__single__btns}>
                <div onClick={(e) => onDownload(e)} className={styles.docs__single__download}>
                    <DocsDownload />
                </div>
            </div>
        </div>
    );
};
