import hljs from 'highlight.js';
import * as memoize from 'lodash/memoize';

const TAG_REGEX = /<\s+(\w+)/g;

const REGEX_FIND_ALL_HTML_TAGS = /<([a-z0-9]+)([^>]*)>/gi;

const CODE_HTML_REGEX = /<\/?[a-z][^>]*>/gi;

const SQUARE_BRACES_REGEX = /\[(\/?)\s*(\w+)\s*(\w*?)\s*\]|\[link=(.*?)\](.*?)\[\/link\]/g;

const CODE_BLOCK_REGEX = /```([\s\S]*?)\n```/g;

export const download = (data, title) => {
    const dialog = data.reduce((acc, item, index) => {
        if (index === 0) {
            return acc + `User: ${item.content} \nChat GPT: ${item.respond}`;
        }

        return acc + `\r\nUser: ${item.content} \r\nChat GPT: ${item.respond}`;
    }, '');

    const blobFile = new Blob([`${dialog}`], { type: 'text/plain' });

    let a = document.createElement('a');

    a.href = URL.createObjectURL(blobFile);
    a.download = `chat_${title}.txt`;
    a.click();
    a = null;
};

let isResponseContainsCode = false;

export const highlightHTML = (text) => {
    text = replaceBulletPoints(text);

    if (isResponseContainsCode) {
        text = text.replace(CODE_HTML_REGEX, (match) => {
            return match.replace(/<|>/g, (char) => (char === '<' ? '&lt;' : '&gt;'));
        });
    }

    text = text.replace(CODE_BLOCK_REGEX, (match) => {
        isResponseContainsCode = true;

        let lines = match.split('\n');

        const firstLine = lines[0].replace('```', '')?.trim();

        const codeLanguage = (firstLine || lines[1]?.trim() || '').toLowerCase();

        const div = document.createElement('div');

        const pre = document.createElement('pre');

        const code = document.createElement('code');

        if (codeLanguage) {
            const header = document.createElement('header');

            header.style = 'padding:5px 10px;background:#333;color:white;border-radius:5px 5px 0px 0px;font-size:12px;';
            header.innerText = codeLanguage;
            div.appendChild(header);
        }

        lines = lines.slice(1, -1);

        if (codeLanguage === 'html') {
            pre.style = 'padding:1em;color: #abb2bf;background: #282c34;';

            const joined = lines
                .join('\n')
                .replace(/<\s+|\s+>/g, (match) => match.trim())
                .replace(/&lt;/g, '<')
                .replace(/&gt;/g, '>');

            pre.innerHTML = hljs.highlight(joined, { language: 'html' }).value;
        } else {
            pre.appendChild(code);

            const joined = lines.join('\n');

            code.innerHTML = hljs.highlightAuto(joined).value;

            hljs.highlightElement(code);
        }

        div.appendChild(pre);

        return div.outerHTML;
    });

    if (!isResponseContainsCode) {
        text = text
            .split(/\n\s*\n/)
            .filter((block) => block.trim() !== '')
            .map((block) => `<p>${block}</p>`)
            .join('');

        text = text.replace(TAG_REGEX, '<$1');

        text = text.replace(/<!--\s*li\s*-->/g, '</li>');
        text = text.replace(/<!--\s*ul\s*-->/g, '</ul>');

        text = text.replace(SQUARE_BRACES_REGEX, (match, p1, p2, p3, link, linkText) => {
            if (link && linkText) {
                return `<a href="${link}">${linkText}</a>`;
            }

            return `<${p1}${p2}${p3}>`;
        });

        const className = 'styled-html';

        text = text.replace(REGEX_FIND_ALL_HTML_TAGS, (match, tagName, attributes) => {
            if (attributes.includes('class=')) {
                return `<${tagName}${attributes.replace(/class="([^"]*)"/, `class="$1 ${className}"`)}>`;
            } else {
                return `<${tagName} class="${className}"${attributes}>`;
            }
        });
    } else {
        text = text
            .split(/\n\s*\n/)
            .filter((block) => block.trim() !== '')
            .map((block) => `<p>${block}</p>`)
            .join('');
    }

    return text;
};

function replaceBulletPoints(text) {
    let replacedText = text.replace(/\n\n-/g, '</li></ul><ul><li>');

    if (replacedText.includes('<ul><li>')) {
        replacedText += '</li></ul>';
    }

    return replacedText;
}

export const highlightHTMLMemoized = memoize(highlightHTML);
