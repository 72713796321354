import React from 'react';
import { Tabs, Typography } from 'antd';
import { useBreakpoint } from '../../../../hooks/media';
import AudioTab from './components/AudioTab';
import { DocTab } from './components/DocTab';
import Image from './components/Image';
import { VideoTab } from './components/VideoTab';
import styles from './index.module.scss';

export default function LibraryPage({ setDocToken }) {
    const { isMobile } = useBreakpoint();

    return (
        <div className={styles.library}>
            <Typography.Title level={2}>Your Library</Typography.Title>
            <Tabs
                className={styles.library__tabs}
                tabBarGutter={isMobile ? 20 : 100}
                items={[
                    {
                        label: 'Image',
                        key: 'image',
                        children: <Image />
                    },
                    {
                        label: 'Audio',
                        key: 'audio',
                        children: <AudioTab />
                    },
                    {
                        label: 'Video',
                        key: 'video',
                        children: <VideoTab />
                    },
                    {
                        label: 'Docs',
                        key: 'docs',
                        children: <DocTab setDocToken={setDocToken} />
                    }
                ]}
            />
        </div>
    );
}
