import React from 'react';

export default function EmailIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='28' height='28' fill='none' viewBox='0 0 28 28'>
            <path
                fill='#000'
                d='M25.667 10.042v9.499a3.792 3.792 0 01-3.577 3.786l-.215.006H6.125a3.792 3.792 0 01-3.786-3.577l-.006-.215v-9.499l11.26 5.9a.875.875 0 00.813 0l11.26-5.9zM6.125 4.666h15.75a3.792 3.792 0 013.773 3.41L14 14.179 2.352 8.077a3.792 3.792 0 013.556-3.405l.217-.006z'
            />
        </svg>
    );
}
