import React from 'react';
import { Button, Space, Typography } from 'antd';
import styles from './index.module.scss';

export default function BillingHead({ billing }) {
    const {
        state: { plan }
    } = billing;

    return (
        <Space className={styles.billing__selector__head}>
            <Typography.Title level={2}>Billing</Typography.Title>
            <Button loading={billing.state.loader.getPlan} disabled>
                <strong>{plan.planType}</strong>
            </Button>
        </Space>
    );
}
