import React from 'react';
import { Modal, Spin } from 'antd';
import StripeElements from '../../../../../../../../../shared/components/StripeElements';

export default function AddCardModal({ form, org, billing }) {
    const onModalClose = () => {
        billing.actions.modal.close('addCard');
    };

    const onSetupSuccess = async (setupIntent) => {
        await billing.actions.intent.confirmNoRedirect(org, setupIntent, form);
        await billing.actions.modal.close('addCard');
    };

    return (
        <Modal
            className='billing__modal__add-card'
            centered
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            title='Payment method'
            open={billing.state.activeModals.addCard}
            onCancel={onModalClose}
        >
            <Spin spinning={billing.state.loader.addCardModal}>
                {billing.state.loader.setupIntent ? (
                    <Spin style={{ padding: '16px 0' }} size='large' tip='Please wait...'>
                        <div style={{ opacity: '0', pointerEvents: 'none' }}>Spinning</div>
                    </Spin>
                ) : (
                    <StripeElements
                        onSetupSuccess={onSetupSuccess}
                        setLoader={billing.actions.setLoader}
                        onModalClose={onModalClose}
                        billing={billing}
                    />
                )}
            </Spin>
        </Modal>
    );
}
