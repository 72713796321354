import React from 'react';

export default function CopyIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='13' height='14' fill='none' viewBox='0 0 13 14'>
            <path
                fill='#1F1F1F'
                fillOpacity='0.7'
                d='M6.125 0a.867.867 0 00-.875.875v.875H3.937a.433.433 0 00-.437.438V3.5h5.25V2.187a.433.433 0 00-.438-.437H7V.875A.867.867 0 006.125 0zM.437 1.75A.433.433 0 000 2.188v11.374c0 .245.193.438.438.438h11.374a.433.433 0 00.438-.438V2.188a.433.433 0 00-.438-.438H10.5v3.5H1.75v-3.5H.437z'
            />
        </svg>
    );
}
