import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { App, Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { PASSWORD_VALIDATION_RULES } from '../../../../utils/validation';
import { useAuthContext } from '../../context';
import styles from './index.module.scss';

export default function Reset({ history }) {
    const [loading, setLoading] = React.useState(false);

    const [form] = Form.useForm();

    const { verify, reset, resetPwdToken } = useAuthContext();

    const { notification } = App.useApp();

    const onFinish = async (values) => {
        setLoading(() => true);

        try {
            await reset(values);

            setLoading(() => false);

            notification.success({
                placement: 'top',
                message: 'Password reset successfully'
            });

            history.push('/');
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });

            setLoading(() => false);
        }
    };

    useEffect(() => {
        try {
            (async () => {
                await verify();
            })();

            notification.success({
                placement: 'top',
                message: 'Token verified, please reset your password'
            });
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    }, [verify]);

    return (
        <Form className={styles.reset} form={form} onFinish={onFinish} layout='vertical'>
            <Form.Item name='password' label='Password' rules={PASSWORD_VALIDATION_RULES}>
                <Input type='password' placeholder='••••••••' disabled={!resetPwdToken} />
            </Form.Item>
            <Form.Item>
                <Button block type='primary' htmlType='submit' disabled={!resetPwdToken} loading={loading}>
                    Reset password
                </Button>
            </Form.Item>
            <Form.Item className={styles.reset__links}>
                Already have an account? <Link to='/auth/login'>Login here.</Link>
            </Form.Item>
        </Form>
    );
}

Reset.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};
