import React from 'react';
import Icon from '@ant-design/icons';

export default function Jcb(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg width='24' height='18' viewBox='0 0 24 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M23.9999 0V14.9835C23.9999 16.5889 22.7422 17.8877 21.1734 17.9785L20.9979 17.9835H16.5938V11.8846H21.2453C22.334 11.8846 23.1587 11.2912 23.1587 10.3846C23.1587 9.59066 22.4966 9.06135 21.6032 8.94945L21.4432 8.93407V8.9011C22.334 8.78571 22.8123 8.20879 22.8123 7.53297C22.8123 6.67582 22.103 6.18132 21.1463 6.13187C21.0913 6.12088 21.0217 6.11722 20.9521 6.11599L16.6102 6.11538V3C16.6102 1.39462 17.8679 0.0957894 19.4368 0.00506325L19.6123 0H23.9999ZM19.8842 9.34676C19.9367 9.34799 19.9917 9.35165 20.0247 9.36264C20.3546 9.44506 20.635 9.74176 20.635 10.1374C20.635 10.5495 20.3546 10.8462 20.0247 10.9121L19.8102 10.9286H18.0783V9.34615L19.8842 9.34676ZM19.6453 7.00549L19.7027 7.00977L19.8102 7.02198C20.1401 7.07143 20.4206 7.33517 20.4206 7.73077C20.4206 8.12637 20.1401 8.39011 19.8102 8.43956L19.7845 8.44909L19.6453 8.45604H18.0783V7.00549H19.6453Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M3.01856 0C1.36907 0 0.0164948 1.33516 0.0164948 3V10.4011C0.857732 10.8132 1.73196 11.0769 2.60619 11.0769C3.64536 11.0769 4.20619 10.4505 4.20619 9.59341V6.0989H6.77938V9.57692C6.77938 10.9286 5.93814 12.033 3.08454 12.033C1.35258 12.033 0 11.6538 0 11.6538V17.967H4.40412C6.05361 17.967 7.40619 16.6319 7.40619 14.967V0L3.01856 0Z'
                        fill='#828D9E'
                    />
                    <path
                        fillRule='evenodd'
                        clipRule='evenodd'
                        d='M11.3155 0C9.66605 0 8.31348 1.33516 8.31348 3V6.92308C9.07224 6.28022 10.3918 5.86813 12.5197 5.96703C13.6578 6.01648 14.8784 6.32967 14.8784 6.32967V7.5989C14.2681 7.28571 13.5423 7.00549 12.6021 6.93956C10.9856 6.82418 10.0124 7.61538 10.0124 9C10.0124 10.4011 10.9856 11.1923 12.6021 11.0604C13.5423 10.9945 14.2681 10.6978 14.8784 10.4011V11.6703C14.8784 11.6703 13.6743 11.9835 12.5197 12.033C10.3918 12.1319 9.07224 11.7198 8.31348 11.0769V18H12.7176C14.3671 18 15.7197 16.6648 15.7197 15V0L11.3155 0Z'
                        fill='#828D9E'
                    />
                </svg>
            )}
        />
    );
}
