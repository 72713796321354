import { uuid } from '../../../../../../utils/crypto';

export const initialState = {
    data: {
        docs: [],
        chunks: []
    },
    chatInput: '',
    uploadFileList: [],
    error: null,
    readAloudBuffer: null,
    modal: {
        newDoc: false
    },
    loader: {
        readAloud: false,
        list: false,
        create: false,
        update: false,
        clear: false,
        retry: false,
        streaming: false
    }
};

export function messagesReducer(draft, action) {
    switch (action.type) {
        case 'MESSAGE_READ_ALOUD': {
            draft.loader.readAloud = true;
            break;
        }

        case 'MESSAGE_READ_ALOUD_SUCCESS': {
            draft.readAloudBuffer = action.payload.buffer;
            draft.loader.readAloud = false;
            break;
        }

        case 'MESSAGE_READ_ALOUD_ERROR': {
            draft.loader.readAloud = false;
            break;
        }

        case 'SET_FILE_LIST': {
            draft.uploadFileList = action.payload?.content?.map((item) => ({
                ...item,
                thumbUrl: ''
            }));
            break;
        }

        case 'CHAT_INPUT_CHANGE': {
            draft.chatInput = action.payload;
            break;
        }

        case 'CHAT_INPUT_ERROR': {
            draft.chatInput = '';
            break;
        }

        case 'MESSAGE_FETCH': {
            draft.loader.list = true;
            break;
        }

        case 'MESSAGE_FETCH_SUCCESS': {
            draft.loader.list = false;
            draft.data.docs = action.payload.docs;
            break;
        }

        case 'MESSAGE_FETCH_ERROR': {
            draft.loader.list = false;
            break;
        }

        case 'MESSAGE_CREATE_SUBMIT': {
            draft.loader.create = true;

            if (action.payload?.formData?.get('regenerate')) {
                draft.data.docs.map((el, i, row) => {
                    if (i + 1 === row.length) {
                        el.respond = null;
                    }
                });
            } else {
                draft.data.docs.push({
                    _id: uuid(),
                    files: draft.uploadFileList,
                    content: action.payload.content,
                    createdAt: new Date()
                });
            }

            draft.uploadFileList = [];
            break;
        }

        case 'MESSAGE_CREATE_SUCCESS': {
            draft.loader.create = false;
            break;
        }

        case 'MESSAGE_CREATE_ERROR': {
            draft.loader.create = false;
            draft.loader.streaming = false;
            draft.data.chunks = [];
            draft.data.docs.pop();
            draft.error = 'Please try again.';
            break;
        }

        case 'MESSAGE_STREAM_START': {
            draft.loader.streaming = true;
            break;
        }

        case 'MESSAGE_STREAM_ERROR': {
            const lastIndex = draft.data.docs.length - 1;

            if (lastIndex === -1) return;

            draft.loader.streaming = false;
            draft.data.docs[lastIndex].streaming = false;
            draft.data.docs[lastIndex].respond = 'Something went wrong, please try again';
            draft.data.chunks = [];
            break;
        }

        case 'MESSAGE_STREAM_END': {
            const regex = /^\{".+?":[^{}]*\}/;
            const lastIndex = draft.data.docs.length - 1;

            if (lastIndex === -1) return;

            /**
             * On stream end, we will remove the [DONE] string from the chunks
             * And then join the chunks into a string
             */
            const respond = draft.data.chunks.join('').replace('[DONE]', '');
            const match = respond.match(regex);
            const obj = match[0] ? JSON.parse(match[0]) : null;

            draft.loader.streaming = false;
            draft.loader.create = false;
            draft.data.docs[lastIndex].streaming = false;
            draft.data.docs[lastIndex]._id = obj?._id;
            draft.data.docs[lastIndex].respond = respond.replace(regex, '').trim();
            draft.data.chunks = [];
            break;
        }

        case 'MESSAGE_CLEAR': {
            draft.loader.clear = true;
            draft.chatInput = '';
            break;
        }

        case 'MESSAGE_CLEAR_SUCCESS': {
            draft.loader.clear = false;
            draft.data = { docs: [], chunks: [] };
            break;
        }

        case 'MESSAGE_CLEAR_ERROR': {
            draft.loader.clear = false;
            break;
        }

        case 'MESSAGE_CHUNK_SUCCESS': {
            if (!draft.loader.streaming) draft.loader.streaming = true;

            draft.data.chunks.push(action.payload);
            break;
        }

        case 'NEW_DOC_MODAL': {
            draft.modal.newDoc = action.payload;
            break;
        }

        default: {
            return draft;
        }
    }
}
