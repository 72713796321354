import { $generateHtmlFromNodes } from '@lexical/html';
import { $getRoot } from 'lexical';

export const saveContent = async (editor, title, save, actions) => {
    const stringifiedEditorState = JSON.stringify(editor.getEditorState().toJSON());

    const parsedEditorState = editor.parseEditorState(stringifiedEditorState);

    const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent());

    const htmlString = $generateHtmlFromNodes(editor, null);

    if (editorStateTextString || title) {
        save.current = 'Saving';
        await actions.update({ htmlString, title, id: localStorage.getItem('DocToken') });
        save.current = 'Saved';
    }
};
