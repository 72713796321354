import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Empty, Row, Spin, Typography } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/grid';
import { useBreakpoint } from '../../../../../../hooks/media';
import { WhiteCover } from '../../../../../../shared/components/WhiteCover';
import { uuid } from '../../../../../../utils/crypto';
import { useImageToVideo } from '../../../VideoPage/hooks';
import { Share } from '../../../VideoPage/pages/HistoryPage/components/Share';
import { SingleVideo } from '../../../VideoPage/pages/HistoryPage/components/SingleVideo';
import 'swiper/css';
import styles from './index.module.scss';

export const VideoTab = () => {
    const history = useHistory();

    const { state, actions } = useImageToVideo();

    const [loader, setLoader] = useState(true);

    const { isMobile } = useBreakpoint();

    useEffect(() => {
        actions.talk.list({ page: 1 });

        setLoader(() => false);
    }, []);

    if (!state.history.data.length && !state.loader.talk.list)
        return (
            <WhiteCover padding='47px 93px'>
                <Empty />
            </WhiteCover>
        );

    return (
        <Spin spinning={loader}>
            <WhiteCover padding='47px 40px'>
                <Row align='middle' justify='space-between' className={styles.tabs__video}>
                    {!isMobile && (
                        <Col>
                            <Typography.Title className={styles.tabs__title} level={2}>
                                Docs
                            </Typography.Title>
                        </Col>
                    )}
                    <Col>
                        <Typography.Title
                            className={styles.tabs__explore}
                            level={2}
                            onClick={() => history.push('/video/history')}
                        >
                            Explore
                        </Typography.Title>
                    </Col>
                    <Col span={24} className={styles.tabs__video__slider__wrap}>
                        <Swiper className={styles.tabs__video__slider} spaceBetween={32} slidesPerView='auto'>
                            {state.history.data?.slice(0, 6).map((item) => {
                                return (
                                    <SwiperSlide key={uuid()}>
                                        <SingleVideo item={item} actions={actions} />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </Col>
                </Row>
                <Share state={state} actions={actions} />
            </WhiteCover>
        </Spin>
    );
};
