export default class AudioRecorder {
    static isAudioSupported() {
        return !!navigator.mediaDevices && !!navigator.mediaDevices.getUserMedia;
    }

    static isSpeechRecognitionSupported() {
        return !!window.webkitSpeechRecognition;
    }

    constructor(options = {}) {
        this.stream = null;
        this.recognition = null;
        this.mediaRecorder = null;
        this.audioBlobs = [];
        this.transcription = '';
        this.options = options;
        this.results = [];
    }

    async start() {
        try {
            this.reset();

            this.stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            this.mediaRecorder = new MediaRecorder(this.stream);

            this.mediaRecorder.addEventListener('dataavailable', (event) => {
                this.audioBlobs.push(event.data);
            });

            this.mediaRecorder.addEventListener('stop', () => {
                this.options.onStop(this.transcription?.trim() || '');
            });

            this.mediaRecorder.start();
        } catch (error) {
            switch (error.name) {
                case 'AbortError': // error from navigator.mediaDevices.getUserMedia
                    // eslint-disable-next-line no-console
                    console.log('An AbortError has occurred.');
                    break;
                case 'NotAllowedError': // error from navigator.mediaDevices.getUserMedia
                    // eslint-disable-next-line no-console
                    console.log('A NotAllowedError has occurred. User might have denied permission.');
                    break;
                case 'NotFoundError': // error from navigator.mediaDevices.getUserMedia
                    // eslint-disable-next-line no-console
                    console.log('A NotFoundError has occurred.');
                    break;
                case 'NotReadableError': // error from navigator.mediaDevices.getUserMedia
                    // eslint-disable-next-line no-console
                    console.log('A NotReadableError has occurred.');
                    break;
                case 'SecurityError': // error from navigator.mediaDevices.getUserMedia or from the MediaRecorder.start
                    // eslint-disable-next-line no-console
                    console.log('A SecurityError has occurred.');
                    break;
                case 'TypeError': // error from navigator.mediaDevices.getUserMedia
                    // eslint-disable-next-line no-console
                    console.log('A TypeError has occurred.');
                    break;
                case 'InvalidStateError': // error from the MediaRecorder.start
                    // eslint-disable-next-line no-console
                    console.log('An InvalidStateError has occurred.');
                    break;
                case 'UnknownError': // error from the MediaRecorder.start
                    // eslint-disable-next-line no-console
                    console.log('An UnknownError has occurred.');
                    break;
                default:
                    // eslint-disable-next-line no-console
                    console.log('An error occurred with the error name ' + error.name);
            }
        }
    }

    startSpeechRecognition() {
        // eslint-disable-next-line new-cap
        this.recognition = new window.webkitSpeechRecognition();

        this.recognition.continuous = true;
        this.recognition.interimResults = true;

        this.recognition.onresult = (event) => {
            this.results = event.results;

            // Check if the user stopped speaking
            if (event.results[event.results.length - 1].isFinal) {
                this.stop();
            }
        };

        this.recognition.onerror = () => {
            this.stop({ error: true });
        };

        this.recognition.start();
    }

    stop(opt = {}) {
        if (
            !this.results.length ||
            (this.results[this.results.length - 1]?.isFinal && this.mediaRecorder?.state === 'recording')
        ) {
            this.transcription = this.results?.[0]?.[0]?.transcript || '';
            this.recognition?.stop();
            this.mediaRecorder.stop();
            this.stream.getTracks().forEach((track) => track.stop());
        }

        if (opt.error) {
            this.options.onError();
        }
    }

    reset() {
        this.audioBlobs = [];
        this.mediaRecorder = null;
        this.stream = null;
        this.recognition = null;
        this.transcription = '';
        this.results = [];
    }
}
