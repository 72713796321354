/* eslint-disable */

const config = {
    _id: 117,
    title: 'Content Enhancer',
    alias: 'content-enhancer',
    about: 'This AI tool aids in improving your content, suggesting ways to make it more engaging, relevant, and reader-friendly.',
    categories: ['Blog Content'],
    tips: [
        'Be open to constructive criticism. The AI\'s suggestions can lead to better content.',
        'Use the AI to help keep your language simple and accessible.',
        'The AI can help you add variety to your content with different formats like quizzes, infographics, or interactive elements.'
    ],
    prompts: [
        'How can I make this blog post more engaging for my readers? [insert blog]',
        'Suggest ways to make this lesson plan more interactive for my students. [insert lesson plan]',
        'Can you simplify this paragraph for a [specific age group] audience? [insert paragraph]',
        'Create a quiz based on this educational article. [insert article]'
    ]
};

export default config;
