const config = {
    _id: 99,
    title: 'Vocab Master',
    alias: 'vocab-master',
    about: 'Generate vocabulary lists and associated activities based on given text.',
    categories: ['Education'],
    tips: [
        'Specify the grade level for vocabulary appropriateness.',
        'Provide context or source material for vocabulary extraction.'
    ],
    prompts: [
        'Generate a grade [grade level] vocabulary list from this [book/chapter/article].',
        'Design vocabulary activities for the words extracted from this [specific topic].',
        'Create a vocabulary quiz using words from this [book/chapter/article].',
        'Create a crossword puzzle using vocabulary extracted from this [book/chapter/article].',
        'Design flashcards for [grade level] using the vocabulary from this [book/chapter/article].'
    ]
};

export default config;
