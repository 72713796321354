/* eslint-disable */

const config = {
    _id: 87,
    title: 'Generate LinkedIn Ads targeting ideas',
    alias: 'generate-linkedin-ads-targeting-ideas',
    about: 'Generate targeted LinkedIn ad ideas effortlessly.',
    categories: ['Social Media & Ads'],
    tips: [
        'Be specific about your target audience: To get the most accurate and relevant ad targeting ideas, provide Just Think with as much information as possible about your target audience, including their demographics, interests, and job titles.',
        'Experiment with different ad formats: Just Think can provide insights into various ad formats, such as sponsored content, sponsored InMail, and display ads, to help you choose the best format for your business goals and target audience.',
        'Keep track of your ad performance: Use LinkedIn\'s ad analytics to monitor the performance of your ads and make adjustments to your targeting strategies as needed. This can help you refine your ad targeting over time and improve your overall ad performance.'
    ],
    prompts: [
        "What are some effective targeting strategies for [specific industry] companies looking to reach [specific demographic] on LinkedIn? I'm particularly interested in targeting users who are interested in [specific interest] and [specific interest].",
        'I\'m looking to target LinkedIn Ads to [specific job titles] in [specific location] who work in [specific industry] and are interested in [specific interest]. Can you suggest some ad formats and targeting options that might work well for this audience?',
        'What are some ways I can optimize my LinkedIn Ad targeting to reach [specific demographic], especially those who are interested in [specific interest] and work in [specific industry]? I\'m also curious about the effectiveness of targeting by [specific skill] and [specific skill].',
        'Can you provide me with some LinkedIn Ad targeting ideas that would be effective for a B2B business targeting [specific job titles] and [specific job titles] in [specific location]? I\'m interested in both demographic and interest-based targeting options.',
        'I\'m designing a LinkedIn Ad campaign to promote a [specific product/service] to [specific industry] professionals who are interested in [specific interest]. What are some best practices for targeting this audience, and what ad formats and targeting options do you recommend?'
    ]
};

export default config;
