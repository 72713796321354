import { axios } from '../../../../../../services/http/axios';

export const speechHistoryService = {
    list: async (page = 1, limit = 10) => {
        const response = await axios.post('/speech/list', { page, limit });

        return response.data;
    },

    remove: async (ids) => {
        const response = await axios.post('/speech/remove', ids);

        return response.data;
    },

    download: async (ids) => {
        const response = await axios.post('/speech/download', ids, {
            responseType: 'arraybuffer'
        });

        return response.data;
    }
};
