import React from 'react';
import { DownloadOutlined, LinkOutlined, MoreOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { Dropdown, Tooltip } from 'antd';
import { $colorWhite } from '../../../../../styles/global';
import { uuid } from '../../../../../utils/crypto';
import { download } from '../../../../../utils/image';
import { useAuthContext } from '../../../../AuthPage/context';

export default function ImagesList({ array, changeFavorite, tabCurrent }) {
    const auth = useAuthContext();

    const isDarkMode = auth?.profile?.uiSettings?.darkMode;

    const openSingleImage = (base64URL) => {
        const win = window.open();

        const colorBg = isDarkMode ? '#141414' : '#fff';

        win.document.write(
            `<body style="margin:0;padding:0"><div style="width:100%; height:100%; background-color:${colorBg}; display:flex; justify-content:center; align-items:center"><img src=${base64URL}></img></div></body>`
        );
        win.document.close();
    };

    const elements = array.map((item) => (
        <div className={tabCurrent === 'favorite' ? 'art-history__fav-wrap' : 'art-history__img-wrap'} key={uuid()}>
            <Tooltip
                destroyTooltipOnHide
                overlayStyle={{ position: 'fixed' }}
                title={item.isFavorite ? 'Remove from favorite' : 'Save to Favorites'}
            >
                {item.isFavorite ? (
                    <StarFilled
                        style={{ color: $colorWhite }}
                        className='art-history__icon'
                        onClick={() => changeFavorite(item)}
                    />
                ) : (
                    <StarOutlined
                        style={{ color: $colorWhite }}
                        className='art-history__icon'
                        onClick={() => changeFavorite(item)}
                    />
                )}
            </Tooltip>
            <img
                className={tabCurrent === 'favorite' ? 'art-history__img-fav' : null}
                src={item.preview}
                alt='imageGenerate'
            />
            <Dropdown
                className='art-history__dropdown'
                menu={{
                    items: [
                        {
                            label: (
                                <div
                                    style={{ display: 'flex', justifyContent: 'start' }}
                                    onClick={() => openSingleImage(item.url)}
                                >
                                    <LinkOutlined />
                                    <p style={{ marginLeft: '8px' }}>New Tab</p>
                                </div>
                            ),
                            key: '1'
                        },
                        {
                            type: 'divider'
                        },
                        {
                            label: (
                                <div
                                    style={{ display: 'flex', justifyContent: 'center' }}
                                    onClick={() => download(item.url, item.prompt)}
                                >
                                    <DownloadOutlined />
                                    <p style={{ marginLeft: '8px' }}>Download</p>
                                </div>
                            ),
                            key: '3'
                        }
                    ]
                }}
                overlayStyle={{ cursor: 'pointer' }}
            >
                <MoreOutlined className='art-history__dropdown-icon' />
            </Dropdown>
        </div>
    ));

    return <React.Fragment>{elements}</React.Fragment>;
}
