export const stickers = {
    _id: 1,
    title: 'Minimalist Stickers',
    alias: 'minimalist-stickers',
    about: 'Craft personalized stickers',
    image: 'minimalist-stickers',
    categories: ['stickers'],
    tips: [],
    prompts: [
        {
            text: 'Sticker design of [simple object], isolated and plain and complementary [color] background, stickers outline stroke has 15px',
            image: 'pizza'
        },
        {
            text: 'Sticker design of [simple object], isolated and plain and complementary [color] background, stickers outline stroke has 15px',
            image: 'cloud'
        },
        {
            text: 'Sticker design of [simple object], isolated and plain and complementary [color] background, stickers outline stroke has 15px',
            image: 'smile'
        }
    ]
};
