import React, { Fragment, useEffect } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Modal, notification, Row, Spin, Typography } from 'antd';
import { planConfig } from '../../../../../../../../../hooks/planConfig';
import StripeElements from '../../../../../../../../../shared/components/StripeElements';
import { useAuthContext } from '../../../../../../../../AuthPage/context';
import styles from './index.module.scss';

export const PaymentValue = ({ loader, value }) => {
    return (
        <Row justify='space-between' className={styles.billing__payment__value}>
            <Col>Due today</Col>
            <Col>{loader ? 'Calculating...' : value}</Col>
        </Row>
    );
};

export default function BillingUpdate({ org, form, billing }) {
    const auth = useAuthContext();
    const getPlanConfig = planConfig(auth?.profile?.userProf);

    const onModalClose = () => {
        billing.actions.modal.close('billingUpdate');
    };

    const formValues = form.getFieldsValue();

    const onSetupSuccess = async (setupIntent) => {
        await billing.actions.intent.confirmNoRedirect(org, setupIntent);

        await billing.actions.plan.update(org, {
            ...getPlanConfig.initialValues[formValues?.type],
            period: formValues.period
        });

        await billing.actions.fetch(org);
        await auth.whoami();
        await billing.actions.modal.close('billingUpdate');
    };

    const onPay = async () => {
        try {
            await billing.actions.plan.update(org, {
                ...getPlanConfig.initialValues[formValues?.type],
                period: formValues.period
            });
            notification.success({
                placement: 'top',
                message: 'Payment successful',
                description: 'Your payment has been processed successfully'
            });

            await auth.whoami();
            await billing.actions.fetch(org);
            await billing.actions.modal.close('billingUpdate');
        } catch (error) {
            notification.error({
                placement: 'top',
                message: 'Payment failed',
                description:
                    error.message ||
                    error.response?.data?.message ||
                    'Something went wrong while processing your payment'
            });
        }
    };

    useEffect(() => {
        (async () => {
            await billing.actions.plan.calculate(org, {
                ...getPlanConfig.initialValues[formValues?.type],
                period: formValues.period
            });
        })();
    }, []);

    return (
        <Modal
            className={styles.billing__upgrade}
            centered
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            open={billing.state.activeModals.billingUpdate}
            onCancel={onModalClose}
            footer={false}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Row>
                <Spin spinning={billing.state.loader.updatingPlan}>
                    <Col className={styles.billing__upgrade__right}>
                        <Row justify='flex-start' gutter={15} style={{ marginBottom: '20px' }}>
                            <Col>
                                <Typography.Title level={2}>
                                    Upgrade to <span style={{ textTransform: 'capitalize' }}>{formValues?.type}</span>
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Typography.Text className={styles.billing__upgrade__right__descr}>
                            Create without limits by upgrading to an {formValues?.type} plan.
                        </Typography.Text>
                        <Typography>
                            <ul className={styles.billing__upgrade__right__list}>
                                <li>
                                    <CheckCircleOutlined /> 30 day money back gaurantee, cancel any time
                                </li>
                                <li>
                                    <CheckCircleOutlined /> Around-the-clock assistance 24/7 support
                                </li>
                                {billing.state.cards.length ? (
                                    <React.Fragment>
                                        <li>
                                            <CheckCircleOutlined /> Unlimited AI Chat
                                        </li>
                                        <li>
                                            <CheckCircleOutlined /> Generate Realistic Text to Speech voices
                                        </li>
                                        <li>
                                            <CheckCircleOutlined /> Create Advanced AI Images
                                        </li>
                                        <li>
                                            <CheckCircleOutlined /> Create groundbreaking AI Video Generation
                                        </li>
                                    </React.Fragment>
                                ) : null}
                            </ul>
                        </Typography>
                        {!billing.state.cards.length ? (
                            <Fragment>
                                {billing.state.loader.setupIntent ? (
                                    <Spin
                                        style={{
                                            position: 'relative',
                                            transform: 'none',
                                            padding: '15px 0',
                                            height: '50px'
                                        }}
                                        size='large'
                                        tip='Please wait...'
                                    >
                                        <div style={{ opacity: '0', pointerEvents: 'none' }}>Spinning</div>
                                    </Spin>
                                ) : (
                                    <Spin spinning={billing.state.loader.addCardModal}>
                                        <StripeElements
                                            onSetupSuccess={onSetupSuccess}
                                            billing={billing}
                                            paymentValue={<PaymentValue value={billing.state.total.priceDiff} />}
                                        />
                                    </Spin>
                                )}
                            </Fragment>
                        ) : (
                            <React.Fragment>
                                <PaymentValue
                                    loader={billing.state.loader.calculate}
                                    value={billing.state.total.priceDiff}
                                />
                                <button
                                    style={{ width: '100%' }}
                                    className='stripe-submit-btn'
                                    id='submit'
                                    onClick={onPay}
                                >
                                    <span id='button-text'>Upgrade</span>
                                </button>
                                <div className='stripe-agreements'>
                                    By continuing you agree to the{' '}
                                    <a
                                        target='_blank'
                                        href='https://www.justthink.ai/terms-of-service'
                                        rel='noreferrer'
                                    >
                                        Terms of Use
                                    </a>{' '}
                                    applicable to Just Think AI and confirm you have read our{' '}
                                    <a target='_blank' href='www.justthink.ai/privacy-policy'>
                                        Privacy policy
                                    </a>
                                    .
                                </div>
                            </React.Fragment>
                        )}
                    </Col>
                </Spin>
            </Row>
        </Modal>
    );
}
