import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Typography } from 'antd';
import JustThinkLogoFull from '../../../../assets/images/just-think-logo-blue-full-logo-yellow.png';
import styles from '../../index.module.scss';

const NotFound = ({ profile }) => {
    return (
        <Flex align='center' justify='center' vertical className={styles.share_chat__not_found}>
            <Flex vertical align='center' justify='center'>
                <img src={JustThinkLogoFull} alt='logo' />
                <Typography.Title level={1}>Conversation not found</Typography.Title>
                <Typography.Text>Please register to create your own conversation.</Typography.Text>
            </Flex>
            <Flex align='center' justify='center' className={styles.share_chat__signup}>
                <Link to={profile ? '/' : '/auth/signup'}>Get Started With JustThink</Link>
            </Flex>
        </Flex>
    );
};

export default NotFound;
