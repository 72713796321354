import { surveyConfig } from '../constants/surveyConfig';

const billingMapper = {
    A: {
        standard: { month: 49, annual: 36 },
        business: { month: 199, annual: 149 },
        premium: { month: 599, annual: 449 },
        ultimate: { month: 799, annual: 699 }
    },
    B: {
        standard: { month: 29, annual: 21 },
        business: { month: 99, annual: 74 },
        premium: { month: 229, annual: 224 },
        ultimate: { month: 399, annual: 299 }
    },
    C: {
        standard: { month: 19, annual: 14 },
        business: { month: 59, annual: 44 },
        premium: { month: 159, annual: 119 },
        ultimate: { month: 209, annual: 156 }
    }
};

export const planConfig = (config) => {
    const getBillingClass = surveyConfig.choices.find((e) => e.option === config);

    const prices = billingMapper[getBillingClass.pricing];

    return {
        initialValues: {
            standard: {
                type: 'standard',
                description: 'Best for personal use',
                price: {
                    month: prices.standard.month,
                    annual: prices.standard.annual
                },
                users: 3,
                words: -1,
                images: 100,
                video: 300,
                speak: 25000,
                features: [
                    '3 Seats',
                    'Unlimited Words in Chat',
                    '100 AI Generated Images',
                    '5 min AI Video Generation',
                    '25,000 Text to speech characters'
                ]
            },
            business: {
                popular: true,
                type: 'business',
                description: 'Best for small teams',
                price: {
                    month: prices.business.month,
                    annual: prices.business.annual
                },
                users: 10,
                words: -1,
                images: 700,
                video: 1800,
                speak: 100000
            },
            premium: {
                type: 'premium',
                description: 'Best for scaling up',
                price: {
                    month: prices.premium.month,
                    annual: prices.premium.annual
                },
                users: 30,
                words: -1,
                images: 3000,
                video: 2700,
                speak: 500000
            },
            ultimate: {
                type: 'ultimate',
                description: 'Best for large teams',
                price: {
                    month: prices.ultimate.month,
                    annual: prices.ultimate.annual
                },
                users: 50,
                words: -1,
                images: 5000,
                video: 3600,
                speak: 1000000
            }
        },
        plans: [
            { value: 'standard', label: 'Standard', monthly: 49, annually: 36 },
            { value: 'business', label: 'Business', monthly: 199, annually: 149, popular: true },
            { value: 'premium', label: 'Premium', monthly: 599, annually: 449 },
            { value: 'ultimate', label: 'Ultimate', monthly: 799, annually: 699 }
        ],
        words: [
            { value: '10000', label: '10000' },
            { value: 'unlimited', label: 'Unlimited' }
        ],
        speak: [
            { value: '25,000 Text to speech characters', label: '25000' },
            { value: '100000', label: '100000' },
            { value: '350000', label: '350000' },
            { value: '1000000', label: '1000000' },
            { value: '0', label: 'None' }
        ],
        video: [
            { value: '30', label: '30 sec' },
            { value: '300', label: '5 min' },
            { value: '600', label: '10 min' },
            { value: '900', label: '15 min' },
            { value: '1800', label: '30 min' },
            { value: '3600', label: '60 min' },
            { value: '0', label: 'None' }
        ],
        images: [
            { value: '50', label: '50' },
            { value: '100', label: '100' },
            { value: '250', label: '250' },
            { value: '700', label: '700' },
            { value: '1000', label: '1000' },
            { value: '2000', label: '2000' },
            { value: '5000', label: '5000' },
            { value: '0', label: 'None' }
        ],
        users: [
            { value: '1', label: '1 User' },
            { value: '2', label: '2 Users' },
            { value: '3', label: '3 Users' },
            { value: '4', label: '4 Users' },
            { value: '5', label: '5 Users' }
        ],
        usersMob: [
            { value: '1', label: '1 User' },
            { value: '2', label: '2 Users' }
        ],
        period: [
            { value: 'month', label: 'Monthly' },
            { value: 'annual', label: 'Yearly' }
        ]
    };
};
