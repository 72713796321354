export const travelPhotography = {
    _id: 6,
    title: 'Travel Photography',
    alias: 'travel-photography',
    about: 'Create Travel Photography',
    image: 'travel-photography',
    categories: ['travel'],
    tips: [],
    prompts: [
        {
            text: 'Take a hyper realistic street photo in [City/Country], capturing the everyday bustle of [specific neighborhood or market]. Emphasize vibrant colors and the dynamic motion of [locals, vehicles, wildlife]. Aim to tell a story of [local life, cultural traditions] that defines the spirit of the place.',
            image: 'realistic-street'
        },
        {
            text: 'Photograph a serene landscape in [Region/country], highlighting the tranquil beauty of [historical landmark]. Use the soft light of early morning to create a mood that reflects the peacefulness of this historic region. The composition should convey a sense of solitude and connection with the ancient land.',
            image: 'serene-landscape'
        },
        {
            text: 'Capture the architectural essence of [City/Country] through its unique buildings. Focus on the intricate details of [historical landmarks, modern structures, famous buildings], using natural light, shadows to accentuate the textures and forms. This style should showcase the architectural heritage and design of the location.',
            image: 'architectural-essence'
        }
    ]
};
