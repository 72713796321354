/* eslint-disable */

const config = {
    _id: 121,
    title: 'Security Deposit Refund Letter',
    alias: 'security-deposit-refund-letter',
    about: 'This tool generates professional and compliant security deposit refund letters, ensuring all necessary details are included.',
    categories: ['Real Estate'],
    tips: [
        'Be clear about the deductions and their reasons. If a state-specific regulation applies to a deduction, make sure to specify it.'
    ],
    prompts: [
        'Create a security deposit refund letter for tenant [tenant\'s name].',
        'What information needs to be included in a security deposit refund letter?',
        'Generate a security deposit refund letter with deductions for [damages/issues].',
        'Draft a security deposit refund letter for a lease ending on [date].',
        'Create a security deposit refund letter with a full refund for tenant [tenant\'s name].',
        'Generate a refund letter for a security deposit where [specific item/issue] was deducted.',
        'How do I explain the deductions for [specific issue] in a security deposit refund letter?',
        'What language should I use in a security deposit refund letter to [specific type of tenant - e.g., long-term, short-term]?'
    ]
};

export default config;
