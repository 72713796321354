/* eslint-disable */
export const storybookIllustration = {
    _id: 5,
    title: 'Storybook Illustration',
    alias: 'storybook-illustration',
    about: 'Illustrations made easy',
    image: 'storybook-illustration',
    categories: ['illustration'],
    tips: [],
    prompts: [
        {
            text: 'Illustrate a whimsical fantasy scene for a storybook, featuring [fantasy elements] in an enchanted forest. Use vibrant colors like [colors], and imaginative details like [fantastical decorations] to bring the scene to life. The illustration should appeal to children\'s imagination and transport them to a world of fantasy and adventure.',
            image: 'fantasy-storybook'
        },
        {
            text: 'Create a classic fairy tale illustration for [a classic fairy tale]. Depict key scenes like [iconic moments from the story] using a traditional art style, with soft colors and detailed [natural settings] backgrounds. The illustrations should feel timeless and evoke nostalgia, reminiscent of the classic storybooks from [a historical era].',
            image: 'fairy-tale'
        },
        {
            text: 'Design a modern and edgy illustration for a contemporary children\'s storybook titled [Modern Story Title]. Focus on [contemporary themes and characters]. Use bold lines and a bright, contrasting color palette of [colors] to create a lively and engaging scene. The style should be cutting-edge and appeal to a young, modern audience, reflecting [modern trends and settings]',
            image: 'edgy-illustration'
        }
    ]
};
