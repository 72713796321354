import { axios } from '../../../../services/http/axios';

const templateService = {
    create: (payload) => axios.post('/art/template', payload),
    remove: ({ id, query }) => axios.delete(`/art/template/${id}/?${query}`),
    list: () => axios.get('/art/template'),
    update: ({ id, data }) => axios.patch(`/art/template/${id}`, data)
};

const api = {
    templateService
};

export default api;
