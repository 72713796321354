const config = {
    _id: 124,
    title: 'Lease Agreement Generator',
    alias: 'lease-agreement-generator',
    about: 'This tool generates customizable lease agreement templates, ensuring all necessary clauses and stipulations are included.',
    categories: ['Real Estate'],
    tips: [
        'When generating lease agreement templates, be specific about the unique clauses or stipulations that you want to include. Different property types or rental situations might require different terms.'
    ],
    prompts: [
        'Generate a lease agreement template for a [property type].',
        'Include a [specific clause] in my lease agreement template.',
        'How can I tailor this lease agreement template for a property in [location]?',
        'Generate a lease agreement template that includes a [specific pet policy].',
        'Create a lease agreement template for a property that includes utilities in the rent.',
        'Include [specific maintenance responsibilities] in my lease agreement template.'
    ]
};

export default config;
