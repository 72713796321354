import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { App, Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { EMAIL_VALIDATION_RULES, PASSWORD_VALIDATION_RULES } from '../../../../utils/validation';
import { useAuthContext } from '../../context';
import styles from './index.module.scss';

export default function Signin({ history }) {
    const [loading, setLoading] = React.useState(false);

    const [form] = Form.useForm();

    const auth = useAuthContext();

    const { notification } = App.useApp();

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.get('email_verified') === 'true') {
            notification.success({
                placement: 'top',
                message: 'Email verified',
                description: 'Your email has been verified. You can now login.'
            });

            history.push('/auth/login');
        }
    }, []);

    const onFinish = async (values) => {
        setLoading(() => true);

        try {
            await auth.signin(values);

            setLoading(() => false);

            history.push('/signup-survey');
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });

            setLoading(() => false);
        }
    };

    return (
        <Form className={styles.signin} form={form} onFinish={onFinish} layout='vertical'>
            <Form.Item name='email' label='Email' rules={EMAIL_VALIDATION_RULES}>
                <Input placeholder='youremail@gmail.com' />
            </Form.Item>
            <Form.Item
                className={styles.signin__password}
                name='password'
                label='Password'
                rules={PASSWORD_VALIDATION_RULES}
            >
                <Input.Password visibilityToggle={false} placeholder='••••••••' />
            </Form.Item>
            <Form.Item className={styles.signin__forgot}>
                <Link to='/auth/password/forgot'>Forgot Password?</Link>
            </Form.Item>
            <Form.Item>
                <Button block type='primary' htmlType='submit' loading={loading}>
                    Log in
                </Button>
            </Form.Item>
            <Form.Item className={styles.signin__links}>
                No Account? <Link to='/auth/signup'>&nbsp;Signup Here</Link>
            </Form.Item>
        </Form>
    );
}

Signin.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};
