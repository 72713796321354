import React from 'react';
import PlanConfig from './components/PlanConfig';
import PlanSettings from './components/PlanSettings';
import './index.scss';

export default function PlanSelector({ form, billing, org, auth }) {
    return (
        <div className='billing__selector'>
            <div className='billing__selector__container'>
                <PlanConfig form={form} billing={billing} org={org} />
                <PlanSettings billing={billing} org={org} auth={auth} />
            </div>
        </div>
    );
}
