/* eslint-disable */

const config = {
    _id: 81,
    title: 'Develop A Content Calendar',
    alias: 'develop-a-content-calendar',
    about: 'Generate ideas and suggestions for topics, themes, and keywords to include in the content calendar. This can save time and effort in brainstorming and researching content ideas. Additionally, Just Think can provide a fresh perspective and generate new ideas that may not have been considered otherwise.',
    categories: ['Social Media & Ads'],
    tips: [
        'Provide specific details and context when asking for prompts. The more specific the request, the more relevant and useful the generated content ideas will be.',
        'Be open to unconventional ideas and perspectives. Just Think may suggest topics or themes that may not have been considered before, but could be valuable additions to the content calendar.',
        'Use the generated prompts as starting points for further research and brainstorming. Just Think can provide helpful suggestions and ideas, but additional refinement and creativity may be necessary to develop a comprehensive and effective content calendar.'
    ],
    prompts: [
        "Can you suggest [number] topics for my content calendar focused on [industry/niche] that would be relevant to my target audience [demographic] and aligned with my brand's messaging around [topic/cause]? I am looking for topics that can be presented in [content format] and provide [goal] to my readers.",
        "What are some popular themes I should include in my content calendar for the [season/month] that would align with my brand's messaging around [topic/cause], while also taking into account current industry trends around [trend] and my target audience's interests in [interests]? Can you provide [number] topic ideas that can be presented in [content format] and provide value to my readers who are interested in [topic/industry]?",
        'Please provide [number] content ideas related to [specific keyword or phrase] that would be unique and informative to my readers who are interested in [topic/industry]. I am looking for ideas that can be presented in [content format] and provide [goal]. Can you also suggest some relevant [subtopics] that can be included in the content to provide additional value to my readers?',
        'What are some upcoming events or holidays I should consider when creating my content calendar for [month/year], and how can I incorporate them into my content strategy in a way that resonates with my target audience [demographic]? Can you provide [number] topic ideas that are related to [event/holiday], and can be presented in [content format] and provide [goal] to my readers?',
        'Can you recommend some keywords or phrases that would be beneficial to include in my content calendar for [industry/niche] that are related to [subtopic/aspect], and how can I position them to provide value to my readers who are interested in [topic/industry]? Additionally, can you suggest [number] topic ideas that can be presented in [content format] and provide [goal] to my readers?'
    ]
};

export default config;
