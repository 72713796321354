import _ from 'lodash';
import { configurations } from './configurations';
import { categoryTabs } from './constants';

export const initState = {
    tabs: [],
    configs: [],
    promptsView: 'View All',
    tipsView: 'View All',
    templatesList: [],
    promptList: [],
    loader: {
        main: false,
        modal: false
    },
    modal: {
        templateRemove: false,
        template: false,
        prompt: false,
        tip: false
    }
};

export function artTemplatesReducer(draft, action) {
    switch (action.type) {
        case 'ABOUT_MORE': {
            draft.aboutMore = draft.aboutMore === 'Hide' ? 'Learn more' : 'Hide';
            break;
        }
        case 'MODAL_OPEN': {
            draft.modal[action.payload] = true;
            break;
        }
        case 'MODAL_CLOSE': {
            draft.modal[action.payload] = false;
            break;
        }
        case 'TEMPLATE_LOADING': {
            draft.loader.main = action.payload;
            break;
        }
        case 'MODAL_LOADING': {
            draft.loader.modal = action.payload;
            break;
        }
        case 'TEMPLATE_LIST_SUCCESS': {
            const list = action?.payload?.data;
            draft.templatesList = action.payload.data;
            if (list.length) {
                draft.configs = [...configurations, ...list];
                const tabs = _.cloneDeep(categoryTabs);
                tabs.splice(2, 0, {
                    key: 'my-templates',
                    tab: 'My templates',
                    title: 'My templates',
                    bgGradient:
                        'radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%)'
                });
                draft.tabs = tabs;
            } else {
                draft.tabs = categoryTabs;
                draft.configs = configurations;
            }
            draft.loader.main = false;
            draft.loader.modal = false;
            break;
        }
        case 'TEMPLATE_LIST_FAILED': {
            draft.loader.main = false;
            draft.loader.modal = false;
            break;
        }
        case 'PROMPT_СREATE_SUCCESS': {
            draft.loader.main = false;
            draft.loader.modal = false;
            break;
        }
        case 'PROMPT_СREATE_FAILED': {
            draft.loader.main = false;
            draft.loader.modal = false;
            break;
        }
        case 'PROMPT_DELETE_SUCCESS': {
            draft.loader.main = false;
            draft.loader.modal = false;
            break;
        }
        case 'PROMPT_DELETE_FAILED': {
            draft.loader.main = false;
            draft.loader.modal = false;
            break;
        }
        default: {
            return draft;
        }
    }
}
