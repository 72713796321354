/* eslint-disable */

const config = {
    _id: 110,
    title: 'Motivational Coach',
    alias: 'motivational-coach',
    about: 'A personal motivational coach, offering tips and techniques to keep students engaged and motivated. It can generate motivational messages, suggest reward systems, or offer advice on building a growth mindset.',
    categories: ['Education'],
    tips: [
        'Tailor motivational messages to the individual student\'s needs and goals.',
        'Use a variety of motivational techniques to appeal to different learning styles.',
        'Encourage students to share what motivates them and incorporate that into the AI\'s suggestions.'
    ],
    prompts: [
        'Suggest ways to motivate students to engage in [specific activity].',
        'Create a motivational message for students struggling with [specific topic].',
        'Provide tips to students on maintaining motivation during exam period.',
        'Suggest a reward system to encourage participation in class.',
        'How can I build a growth mindset in my students?'
    ]
};

export default config;
