export const architecture = {
    _id: 10,
    title: 'Architecture',
    alias: 'architecture',
    about: 'Create stunning architecture',
    image: 'architecture',
    categories: ['architecture'],
    tips: [],
    prompts: [
        {
            text: 'Create a [style: industrial, country, ultra-modern] kitchen that includes [feature: an island, hanging pot racks, built-in appliances]. Opt for [material: stainless steel, wood, marble] accents and natural lighting.',
            image: 'kitchen'
        },
        {
            text: 'Design a [style: modern, rustic, minimalist] bedroom with [feature: smart lighting, canopy bed, wall murals]. Choose a color palette that evokes [mood: serenity, warmth, vibrancy].',
            image: 'bedroom'
        },
        {
            text: 'Design a [style: contemporary, traditional, eco-friendly] home that emphasizes [feature: energy efficiency, open floor plans] with natural lighting. Incorporate materials that align with [mood: warmth, modernity, sustainability].',
            image: 'home'
        }
    ]
};
