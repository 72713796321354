import React from 'react';

export default function ArrowBottom() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='20' height='18' fill='none' viewBox='0 0 20 18'>
            <path
                fill='#000'
                fillOpacity='0.85'
                fillRule='evenodd'
                d='M6.121 6.53c-.585-.585-1.416-.704-1.856-.265-.44.44-.32 1.27.265 1.856l4.243 4.243c.363.363.82.547 1.22.53.403.022.867-.162 1.234-.53l4.243-4.242c.586-.586.705-1.417.265-1.856-.439-.44-1.27-.32-1.856.265l-3.878 3.879-3.88-3.88z'
                clipRule='evenodd'
            />
        </svg>
    );
}
