import React, { Fragment } from 'react';
import { PlusCircleOutlined } from '@ant-design/icons';
import { Col, Flex } from 'antd';
import PromptCreate from '../../../Modals/PromptCreate';
import styles from './index.module.scss';

export const SinglePromptAdd = ({ id, state, actions }) => {
    return (
        <Fragment>
            <Col
                className={styles['template-single__add']}
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
            >
                <Flex>
                    <button
                        onClick={() => actions.modals.open('prompt')}
                        className={styles['template-single__add__border']}
                    >
                        <PlusCircleOutlined />
                    </button>
                </Flex>
            </Col>
            <PromptCreate id={id} state={state} actions={actions} />
        </Fragment>
    );
};
