import React from 'react';

export default function GoogleIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none' viewBox='0 0 18 18'>
            <path
                fill='#2196F3'
                d='M17.855 7.334c.08 0 .145.065.145.145V9a9.002 9.002 0 01-9.063 9A8.978 8.978 0 010 8.98a9 9 0 0115.258-6.448c.059.056.06.15.003.207l-2.152 2.152a.144.144 0 01-.202.003A5.648 5.648 0 009 3.332 5.651 5.651 0 003.332 8.96a5.668 5.668 0 0011.087 1.705H9.145A.145.145 0 019 10.52V7.479c0-.08.065-.145.145-.145h8.71z'
            />
            <path
                fill='#1E88E5'
                d='M17.855 7.336H16.77c.08 0 .145.065.145.145V9a9.004 9.004 0 01-8.457 8.984A9.002 9.002 0 0018 9.002v-1.52a.145.145 0 00-.146-.145z'
            />
            <path
                fill='#F44336'
                d='M3.852 6.626L1.119 4.651A8.998 8.998 0 019 0c2.432 0 4.639.965 6.258 2.532.059.056.06.15.003.207l-2.152 2.152a.144.144 0 01-.201.003 5.668 5.668 0 00-9.056 1.732z'
            />
            <path
                fill='#E53935'
                d='M3.063 6.056l.789.57A5.671 5.671 0 018.41 3.363l.045-.005a5.651 5.651 0 00-5.393 2.698zM14.172 2.532c.058.056.06.15.002.207l-1.746 1.747c.167.127.327.263.48.408a.144.144 0 00.2-.003l2.153-2.152a.145.145 0 00-.003-.207A8.972 8.972 0 008.457.017a8.971 8.971 0 015.715 2.515z'
            />
            <path
                fill='#4CAF50'
                d='M15.602 15.117A8.975 8.975 0 019 18 8.999 8.999 0 01.95 13.026l2.79-1.913a5.67 5.67 0 009.13 2.03l2.733 1.974z'
            />
            <path
                fill='#43A047'
                d='M3.74 11.113l-.824.564a5.667 5.667 0 005.54 2.966 5.673 5.673 0 01-4.717-3.53zM9 18a8.975 8.975 0 006.602-2.883l-.67-.484a8.984 8.984 0 01-6.474 3.35c.18.011.36.017.542.017z'
            />
            <path
                fill='#FFC107'
                d='M3.332 9c0 .748.145 1.46.407 2.114L.95 13.026A8.959 8.959 0 010 9.001C0 7.424.406 5.94 1.12 4.652l2.733 1.975a5.645 5.645 0 00-.52 2.374z'
            />
            <path
                fill='#FFB300'
                d='M2.916 11.675l.823-.564a5.651 5.651 0 01-.407-2.113c0-.847.186-1.651.52-2.373l-.79-.57a5.66 5.66 0 00-.816 2.904 5.644 5.644 0 00.67 2.716z'
            />
        </svg>
    );
}
