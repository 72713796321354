import { theme } from 'antd';
import {
    $bgModalHeader,
    $colorGrayBg,
    $colorModalTitle,
    $colorPrimary,
    $colorText,
    $colorWhite,
    $darkModeText,
    $fontFamily
} from './styles/global';

export const getTheme = (auth) => ({
    algorithm: auth?.profile?.uiSettings?.darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
        colorPrimary: $colorPrimary,
        fontFamily: $fontFamily,
        colorBgLayout: $colorGrayBg,
        fontSize: 14,
        lineHeight: 1.4,
        colorText: auth?.profile?.uiSettings?.darkMode ? $darkModeText : $colorText,
        fontSizeHeading2: 24,
        fontSizeHeading3: 20,
        fontSizeHeading4: 16,
        fontWeightStrong: 700
    },
    components: {
        Modal: {
            titleColor: $colorModalTitle,
            titleFontSize: 24,
            titleLineHeight: 1.1,
            headerBg: $bgModalHeader
        },
        Menu: {
            colorPrimary: $colorWhite,
            fontSize: 16,
            margin: 0,
            marginXS: 0,
            marginXXS: 0,
            padding: 20,
            paddingXL: 20,
            paddingXS: 20,
            borderRadius: 5,
            borderRadiusLG: 5,
            borderRadiusSM: 5,
            controlHeightLG: 55
        },
        Message: {
            colorPrimary: auth?.profile?.uiSettings?.darkMode ? $darkModeText : $colorText,
            contentBg: auth?.profile?.uiSettings?.darkMode ? '#1f1f1f' : $colorWhite
        }
    }
});
