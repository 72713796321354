import React from 'react';
import { Button, Form, Row, Typography } from 'antd';
import PlusCircleBorder from '../../../../../../../../components/Icons/PlusCircleBorder/PlusCircleBorder';
import { uuid } from '../../../../../../../../utils/crypto';
import { ModalTipCreate } from '../ModalTipCreate';
import SingleTip from '../Tip/Tip';

export const TipsList = ({ config, state, actions }) => {
    const onTipCreate = async () => {
        await actions.modals.createTip(true);
    };

    if (!config.categories.includes('My tools')) {
        return (
            <Row
                className='single-tool__tips'
                gutter={[
                    { xs: 16, sm: 16, md: 16 },
                    { xs: 16, sm: 16, md: 16 }
                ]}
            >
                {config.tips.map((tip) => {
                    return <SingleTip config={config} key={uuid()} text={tip} />;
                })}
            </Row>
        );
    }

    return (
        <Row
            className='single-tool__tips single-tool__tips--new'
            gutter={[
                { xs: 16, sm: 16, md: 16 },
                { xs: 16, sm: 16, md: 16 }
            ]}
        >
            <Typography.Paragraph className='single-tool__tips__title'>Add Tool Tip</Typography.Paragraph>
            <Form.Provider>
                <Form name='tipCreateForm'>
                    <Form.Item name='tips' hidden />
                    <Form.Item noStyle shouldUpdate>
                        {() => {
                            return (
                                <React.Fragment>
                                    {state.tipsList.map((tip, index) => (
                                        <SingleTip
                                            key={`tip-${index}`}
                                            tip={tip}
                                            number={index}
                                            config={config}
                                            actions={actions}
                                            state={state}
                                        />
                                    ))}
                                </React.Fragment>
                            );
                        }}
                    </Form.Item>
                    {config.categories.includes('My tools') && state.tipsList.length < 5 ? (
                        <Row
                            className='single-tool__tips single-tool__tips--new'
                            gutter={[
                                { xs: 16, sm: 16, md: 16 },
                                { xs: 16, sm: 16, md: 16 }
                            ]}
                        >
                            <Form.Item>
                                <div className='single-tool__tip__body'>
                                    <Button onClick={onTipCreate}>
                                        <PlusCircleBorder />
                                    </Button>
                                </div>
                            </Form.Item>
                        </Row>
                    ) : null}
                </Form>
                {state.modal.createTip ? <ModalTipCreate state={state} actions={actions} id={config._id} /> : null}
            </Form.Provider>
        </Row>
    );
};
