import { axios } from '../../../../services/http/axios';

const done = (res) => res.data;

const voice = {
    list: () => axios.get('/video/voices').then(done)
};

const presenter = {
    list: () => axios.get('/video/presenters').then(done),
    upload: (body) => axios.post('/video/presenters', body).then(done),
    remove: (id) => axios.delete(`/video/presenters/${id}`).then(done)
};

const talk = {
    list: (page = 1, limit = 10, search = '') =>
        axios.get('/video/talks', { params: { page, limit, search } }).then(done),
    create: (body) => axios.post('/video/talks', body).then(done),
    get: (id) => axios.get(`/video/talks/${id}`).then(done),
    remove: (id) => axios.delete(`/video/talks/${id}`).then(done)
};

const share = {
    get: (id) => axios.get(`/share/video/${id}`).then(done),
    html: (id) => axios.post(`/share/html/${id}`).then(done)
};

const api = {
    voice,
    presenter,
    talk,
    share
};

export default api;
