import React from 'react';

export default function CardChipBg(props) {
    return (
        <svg {...props} xmlns='http://www.w3.org/2000/svg' width='187' height='139' fill='none' viewBox='0 0 187 139'>
            <path
                fill='#fff'
                d='M101.761-86.725c28.46 15.142 53.436 39.588 57.713 71.532 4.126 30.81-16.754 57.11-37.372 80.38-20.617 23.269-44.22 46.08-75.306 46.787-33.636.765-66.783-15.305-85.688-43.127-18.42-27.11-16.646-61.889-6.941-93.193 9.362-30.201 28.26-57.234 57.392-69.547 29.229-12.353 62.19-7.737 90.202 7.168z'
                opacity='0.1'
            />
            <path
                fill='#fff'
                d='M128.761-59.725c28.46 15.142 53.436 39.588 57.713 71.532 4.126 30.81-16.754 57.11-37.372 80.38-20.617 23.269-44.219 46.08-75.306 46.787-33.636.765-66.783-15.305-85.688-43.127-18.42-27.11-16.646-61.889-6.942-93.193C-9.47-27.547 9.427-54.58 38.56-66.893c29.229-12.353 62.189-7.737 90.202 7.168z'
                opacity='0.1'
            />
        </svg>
    );
}
