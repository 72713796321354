import { useCallback, useEffect, useRef, useState } from 'react';
import copy from 'copy-to-clipboard';
import { useImmerReducer } from 'use-immer';
import { initialState, messagesReducer } from './reducer';
import { messageService } from './service';

export function useMessages() {
    const [state, dispatch] = useImmerReducer(messagesReducer, initialState);

    const timerRef = useRef(null);

    const textStreamStart = () => dispatch({ type: 'MESSAGE_STREAM_START' });

    const textStreamEnd = () => dispatch({ type: 'MESSAGE_STREAM_END' });

    const textStreamError = () => dispatch({ type: 'MESSAGE_STREAM_ERROR' });

    const create = async (conversationId, payload, controller, content) => {
        dispatch({ type: 'MESSAGE_CREATE_SUBMIT', payload: { content, formData: payload } });

        try {
            const onChunk = (payload) => {
                dispatch({ type: 'MESSAGE_CHUNK_SUCCESS', payload });
            };

            await messageService.create(conversationId, payload, controller, onChunk, onAbort, done, onError);
        } catch (error) {
            dispatch({ type: 'MESSAGE_CREATE_ERROR' });
        }
    };

    const updateInput = (value) => {
        try {
            dispatch({ type: 'CHAT_INPUT_CHANGE', payload: value });
        } catch (error) {
            dispatch({ type: 'CHAT_INPUT_ERROR' });
        }
    };

    const list = async (conversationId) => {
        dispatch({ type: 'MESSAGE_FETCH' });

        try {
            const data = await messageService.list(conversationId);

            dispatch({ type: 'MESSAGE_FETCH_SUCCESS', payload: data });
        } catch (error) {
            dispatch({ type: 'MESSAGE_FETCH_ERROR' });
        }
    };

    const clear = async (conversationId) => {
        dispatch({ type: 'MESSAGE_CLEAR' });

        try {
            await messageService.clear(conversationId);
            dispatch({ type: 'MESSAGE_CLEAR_SUCCESS' });
        } catch (error) {
            dispatch({ type: 'MESSAGE_CLEAR_ERROR' });
        }
    };

    const onAbort = () => textStreamEnd();

    const onError = () => textStreamError();

    const done = () => {
        dispatch({ type: 'MESSAGE_CREATE_SUCCESS' });
        textStreamEnd();
    };

    const setUploadFiles = (payload) => {
        dispatch({ type: 'SET_FILE_LIST', payload });
    };

    const newDocModal = (payload) => {
        dispatch({ type: 'NEW_DOC_MODAL', payload });
    };

    const onReadAloud = async (conversationId, messageId) => {
        dispatch({ type: 'MESSAGE_READ_ALOUD' });

        try {
            const data = await messageService.readAloud(conversationId, messageId);

            dispatch({ type: 'MESSAGE_READ_ALOUD_SUCCESS', payload: data });
        } catch (error) {
            dispatch({ type: 'MESSAGE_READ_ALOUD_ERROR' });
        }
    };

    useEffect(() => {
        return () => {
            if (timerRef.current) {
                timerRef.current = null;
                clearTimeout(timerRef.current);
            }
        };
    }, [state.data.docs]);

    return {
        state,
        actions: {
            onReadAloud,
            newDocModal,
            setUploadFiles,
            updateInput,
            list,
            create,
            clear,
            textStreamStart,
            textStreamEnd,
            onAbort,
            onError
        }
    };
}

export function useCopyToClipboard(resetInterval = null) {
    const [isCopied, setCopied] = useState('');

    useEffect(() => {
        let timeout;

        if (isCopied && resetInterval) timeout = setTimeout(() => setCopied(false), resetInterval);

        return () => clearTimeout(timeout);
    }, [isCopied, resetInterval]);

    const handleCopy = useCallback((item) => {
        if (item.respond[0] === ' ') {
            const transformText = item.respond.slice(1);

            copy(transformText.toString());
            setCopied(item._id);
        } else {
            copy(item.respond);
            setCopied(item._id);
        }
    }, []);

    return [isCopied, handleCopy];
}
