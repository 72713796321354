import React, { useState } from 'react';
import Icon, { LoadingOutlined, UploadOutlined, WarningOutlined } from '@ant-design/icons';
import { App, Button, Col, Image, Modal, Row, Space, Spin, Typography, Upload } from 'antd';
import UploadIcon from '../../../../components/Icons/UploadIcon/UploadIcon';
import { axios } from '../../../../services/http/axios';
import { beforeUpload } from '../../../../utils/upload';
import { onDownloadImage, uploadImage } from './hooks';
import './index.scss';

export default function ImageUpscalePage() {
    const [loadingImage, setLoadingImage] = useState(false);

    const [upscaleLoading, setUpscaleLoading] = useState(false);

    const [upscaleImage, setUpscaleImage] = useState('');

    const [fileList, setFileList] = useState([]);

    const { notification } = App.useApp();

    const onUpscale = async () => {
        if (!fileList.length) return;

        setUpscaleLoading(true);

        try {
            const formData = new FormData();

            formData.append('image-upscale', fileList[0]?.originFileObj);

            const response = await axios.post('/stability/upscale', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            setUpscaleLoading(false);
            setUpscaleImage(() => response.data.image);
        } catch (err) {
            setUpscaleLoading(false);
            notification.error({
                placement: 'top',
                message: err?.response?.data?.message || 'Something went wrong'
            });
        }
    };

    const onChange = async (info) => {
        if (!info.fileList.length) return;

        setLoadingImage(true);
        setFileList(() => [...info.fileList]);
        setLoadingImage(false);
    };

    const uploadButton = (
        <div>
            {loadingImage ? (
                <LoadingOutlined />
            ) : (
                <Row gutter={[0, 30]}>
                    <Col span={24}>
                        <Icon className='upscale-icon' component={UploadIcon} />
                    </Col>
                    <Col span={24}>
                        <Button type='primary'>
                            <UploadOutlined /> Upload image
                        </Button>
                    </Col>
                    <Col span={24}>
                        <Typography.Text>Max image size 3MB</Typography.Text>
                    </Col>
                </Row>
            )}
        </div>
    );

    const changeImage = (
        <div className='upscale-change-image'>
            {loadingImage ? <LoadingOutlined /> : <Button type='primary'>Select another image</Button>}
        </div>
    );

    return (
        <Row className='image-upscale' gutter={[0, 30]}>
            <Col span={24}>
                <Typography.Title align='center'>Upscale Image Tool</Typography.Title>
            </Col>
            <Col span={24} align='center' justify='center'>
                <Spin spinning={upscaleLoading} tip='Image scaling...' size='large'>
                    <Upload
                        maxCount={1}
                        name='image-upscale'
                        listType='picture-card'
                        className='image-upscale__upload'
                        onChange={onChange}
                        customRequest={uploadImage}
                        showUploadList
                        beforeUpload={async (file) => {
                            try {
                                await beforeUpload(file, { maxFileSize: 3 });
                            } catch (err) {
                                notification.error({
                                    placement: 'top',
                                    message: err.message || 'Something went wrong'
                                });

                                return Upload.LIST_IGNORE;
                            }
                        }}
                        headers={{
                            'x-access-token': localStorage.getItem('BrainstormAuthToken')
                        }}
                    >
                        {fileList.length >= 1 ? changeImage : uploadButton}
                    </Upload>
                </Spin>
            </Col>
            <Col span={24} align='center' justify='center'>
                <Button loading={upscaleLoading} onClick={onUpscale} type='primary'>
                    Upscale image
                </Button>
            </Col>
            <Modal
                width='fit-content'
                title='Upscale Image'
                open={!!upscaleImage}
                onCancel={() => setUpscaleImage(() => '')}
                footer={[
                    <Space key='buttons' style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Button key='close' type='primary' onClick={() => setUpscaleImage(() => '')}>
                            Close
                        </Button>
                        <Button key='download' type='primary' onClick={() => onDownloadImage(upscaleImage)}>
                            Save
                        </Button>
                    </Space>
                ]}
            >
                <Row style={{ maxWidth: '600px' }} gutter={[0, 24]}>
                    <Col span={24}>
                        <Image style={{ maxWidth: '600px' }} width='100%' src={upscaleImage} />
                    </Col>
                    <Col span={24}>
                        <Typography.Text>
                            <Space>
                                <WarningOutlined style={{ display: 'flex', fontSize: '22px', color: '#ea1414' }} />
                                {/* eslint-disable-next-line react/no-unescaped-entities */}
                                Don't forget to save your file, once you close this window it will be deleted.
                            </Space>
                        </Typography.Text>
                    </Col>
                </Row>
            </Modal>
        </Row>
    );
}
