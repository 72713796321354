import React, { useState } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { Form, Progress, Steps } from 'antd';
import { useBreakpoint } from '../../../../hooks/media';
import { uuid } from '../../../../utils/crypto';
import BookSubTitle from './components/BookSubTitle/BookSubTitle';
import BookTitle from './components/BookTitle/BookTitle';
import BtnNextForm from './components/BtnSubmitForm/BtnNextForm';
import BtnSubmitForm from './components/BtnSubmitForm/BtnSubmitForm';
import BookDetailsPage from './pages/BookDetailsPage/BookDetailsPage';
import BookType from './pages/BookType/BookType';
import ChooseCharacterPage from './pages/ChooseCharacterPage/ChooseCharacterPage';
import ChooseVoicePage from './pages/ChooseVoicePage/ChooseVoicePage';
import YourStoryPage from './pages/YourStoryPage/YourStoryPage';
import { bookStep } from './constants';
import styles from './BookPage.module.scss';

export function BookPage({ history }) {
    const [current, setCurrent] = React.useState(0);

    const [progressPercent, setProgressPercent] = useState(0);

    const { isMobile, isTablet } = useBreakpoint();

    const { path, url } = useRouteMatch();

    const paths = {
        0: `${url}`,
        1: `${url}/details`,
        2: `${url}/story`,
        3: `${url}/voice`,
        4: `${url}/character`,
        5: ''
    };

    React.useEffect(() => {
        const historyStep = Object.entries(paths).filter(([key, value]) => {
            if (value === history.location.pathname) {
                return key;
            }

            return 0;
        });

        const currentStep = +historyStep[0][0];

        setCurrent(currentStep);

        const progressStep = Math.round((currentStep / 6) * 100);

        setProgressPercent(progressStep);
    }, [history.location.pathname]);

    const [stepForm] = Form.useForm();

    const nextStep = () => {
        const nextStep = current + 1;

        setProgressPercent(Math.round(((current + 1) / 6) * 100));
        setCurrent(nextStep);
        history.push(paths[current + 1]);
    };

    const items = [
        {
            content: <BookType key={uuid()} />
        },
        {
            content: <BookDetailsPage key={uuid()} />
        },
        {
            content: <YourStoryPage key={uuid()} />
        },
        {
            content: <ChooseVoicePage key={uuid()} />
        },
        {
            content: <ChooseCharacterPage key={uuid()} />
        },
        {
            content: ''
        }
    ];

    return (
        <div className={styles.book_page}>
            <Progress percent={progressPercent} showInfo={false} strokeLinecap='butt' />
            <div className={styles.book_page__wrapper}>
                <BookTitle title={bookStep[current].title} />
                <BookSubTitle
                    subTitle={
                        isMobile || isTablet ? bookStep[current].subTitleMobile : bookStep[current].subTitleDesktop
                    }
                />
                <Form className={styles.book_page__form} form={stepForm} onFinish={() => setCurrent(0)}>
                    {items[current].content}
                    {current === 4 ? (
                        <BtnSubmitForm type='primary' nextStep={nextStep} textBtn='Next' />
                    ) : (
                        <BtnNextForm type='primary' nextStep={nextStep} textBtn='Next' />
                    )}
                </Form>
            </div>
            <Steps
                direction='horizontal'
                className={styles.book_page__steps}
                current={current}
                onChange={(id) => {
                    setCurrent(id);
                    history.push(paths[id]);
                }}
                items={items}
            />
            <Switch>
                <Route exact path={`${path}/details`} />
                <Route exact path={`${path}/story`} />
                <Route exact path={`${path}/voice`} />
                <Route exact path={`${path}/character`} />
            </Switch>
        </div>
    );
}
