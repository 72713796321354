import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useLocation } from 'react-router-dom';
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import {
    $isListNode,
    INSERT_ORDERED_LIST_COMMAND,
    INSERT_UNORDERED_LIST_COMMAND,
    ListNode,
    REMOVE_LIST_COMMAND
} from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getSelectionStyleValueForProperty, $patchStyleText } from '@lexical/selection';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import { Input } from 'antd';
import {
    $getRoot,
    $getSelection,
    $insertNodes,
    $isRangeSelection,
    CAN_REDO_COMMAND,
    CAN_UNDO_COMMAND,
    FORMAT_ELEMENT_COMMAND,
    FORMAT_TEXT_COMMAND,
    REDO_COMMAND,
    SELECTION_CHANGE_COMMAND,
    UNDO_COMMAND
} from 'lexical';
import BoldIcon from '../../../../../../../components/Icons/BoldIcon';
import BulletListIcon from '../../../../../../../components/Icons/BulletList';
import CenterAlignIcon from '../../../../../../../components/Icons/CenterAlignIcon';
import ItalicIcon from '../../../../../../../components/Icons/ItalicIcon';
import LeftAlignIcon from '../../../../../../../components/Icons/LeftAlignIcon';
import LinkIcon from '../../../../../../../components/Icons/LinkIcon';
import NumberedListIcon from '../../../../../../../components/Icons/NumberedList';
import RedoIcon from '../../../../../../../components/Icons/RedoIcon';
import RightAlignIcon from '../../../../../../../components/Icons/RightAlignIcons';
import SaveIcon from '../../../../../../../components/Icons/SaveIcon';
import UnderlineIcon from '../../../../../../../components/Icons/UnderlineIcon';
import UndoIcon from '../../../../../../../components/Icons/UndoIcon';
import EditOptionDropDownList from './components/EditOptionDropDownList';
import FileOptionDropDownList from './components/FileOptionDropDownList';
import FloatingLinkEditor from './components/FloatingLinkEditor';
import FontDropDown from './components/FontDropdown';
import DropdownColorPicker from './ui/DropdownColorPicker';
import getSelectedNode from './utils/getSelectedNode';
import { saveContent } from './utils/saveContent';

const AUTO_SAVE_EVERY_5_MIN = 300000;

const LowPriority = 1;

const DEBOUNCE_TIME = 2500;

let debounceTimer = null;

const debounce = (func, delay) => {
    return function () {
        clearTimeout(debounceTimer);
        debounceTimer = setTimeout(func, delay);
    };
};

export default function ToolbarPlugin({ setDocToken, state, actions }) {
    const location = useLocation();

    const [editor] = useLexicalComposerContext();

    const inputRef = useRef(null);

    const toolbarRef = useRef(null);

    const save = useRef(null);

    const [isEditable, setIsEditable] = useState(() => editor.isEditable());

    const [canUndo, setCanUndo] = useState(false);

    const [title, setTitle] = useState(state.docData.title || location.state?.docTitle);

    const [canRedo, setCanRedo] = useState(false);

    const [blockType, setBlockType] = useState('paragraph');

    const [fileOptionDropDown, setFileOptionDropDown] = useState(false);

    const [editOptionDropDown, setEditOptionDropDown] = useState(false);

    const [isLink, setIsLink] = useState(false);

    const [activeEditor] = useState(editor);

    const [isBold, setIsBold] = useState(false);

    const [fontSize, setFontSize] = useState('15px');

    const [fontColor, setFontColor] = useState('#000');

    const [fontFamily, setFontFamily] = useState('Arial');

    const [isItalic, setIsItalic] = useState(false);

    const [isUnderline, setIsUnderline] = useState(false);

    const updateToolbar = useCallback(() => {
        const selection = $getSelection();

        debounce(() => {
            editor.update(async () => {
                await saveContent(editor, title, save, actions);
            });
        }, DEBOUNCE_TIME)();

        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();

            const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow();

            const elementKey = element.getKey();

            const elementDOM = editor.getElementByKey(elementKey);

            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);

                    const type = parentList ? parentList.getTag() : element.getTag();

                    setBlockType(type);
                } else {
                    const type = $isHeadingNode(element) ? element.getTag() : element.getType();

                    setBlockType(type);
                }
            }

            // Update text format
            setIsBold(selection.hasFormat('bold'));
            setIsItalic(selection.hasFormat('italic'));
            setIsUnderline(selection.hasFormat('underline'));

            // Update links
            const node = getSelectedNode(selection);

            const parent = node.getParent();

            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            } else {
                setIsLink(false);
            }

            // Handle buttons
            setFontSize($getSelectionStyleValueForProperty(selection, 'font-size', '15px'));
            setFontColor($getSelectionStyleValueForProperty(selection, 'color', '#000'));
            setFontFamily($getSelectionStyleValueForProperty(selection, 'font-family', 'Arial'));
        }
    }, [editor, title]);

    const applyStyleText = useCallback(
        (styles) => {
            activeEditor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, styles);
                }
            });
        },
        [activeEditor]
    );

    const onFontColorSelect = useCallback(
        (value) => {
            applyStyleText({ color: value });
        },
        [applyStyleText]
    );

    useEffect(() => {
        return mergeRegister(
            editor.registerEditableListener((editable) => {
                setIsEditable(editable);
            }),
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateToolbar();
                });
            }),
            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                (_payload) => {
                    updateToolbar();

                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_UNDO_COMMAND,
                (payload) => {
                    setCanUndo(payload);

                    return false;
                },
                LowPriority
            ),
            editor.registerCommand(
                CAN_REDO_COMMAND,
                (payload) => {
                    setCanRedo(payload);

                    return false;
                },
                LowPriority
            )
        );
    }, [editor, updateToolbar]);

    const insertLink = useCallback(() => {
        if (!isLink) {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
        } else {
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
        }
    }, [editor, isLink]);

    useEffect(() => {
        const interval = setInterval(() => {
            editor.update(async () => {
                const stringifiedEditorState = JSON.stringify(editor.getEditorState().toJSON());

                const parsedEditorState = editor.parseEditorState(stringifiedEditorState);

                const editorStateTextString = parsedEditorState.read(() => $getRoot().getTextContent());

                const htmlString = $generateHtmlFromNodes(editor, null);

                if (editorStateTextString || title) {
                    save.current = 'Saving';
                    await actions.update({ htmlString, title, id: localStorage.getItem('DocToken') });
                    save.current = 'Saved';
                }
            });
        }, AUTO_SAVE_EVERY_5_MIN);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        editor.update(() => {
            if (!state.docData.html && !location.state?.docText) {
                return;
            }

            setTitle(() => state.docData.title || location.state.docTitle);

            const parser = new DOMParser();

            const dom = parser.parseFromString(state.docData.html || location.state?.docText, 'text/html');

            const nodes = $generateNodesFromDOM(editor, dom);

            $getRoot().select();

            $insertNodes(nodes);
        });
    }, [state.docData.html, location.state?.docText]);

    const onRedo = () => {
        editor.dispatchCommand(REDO_COMMAND);
        onCloseDropdown();
    };

    const onUndo = () => {
        editor.dispatchCommand(UNDO_COMMAND);
        onCloseDropdown();
    };

    const onCloseDropdown = () => {
        setFileOptionDropDown(false);
        setEditOptionDropDown(false);
    };

    return (
        <div className='toolbar' ref={toolbarRef}>
            <Input
                ref={inputRef}
                className='toolbar-title'
                onChange={(e) => {
                    setTitle(() => e.target.value);
                }}
                value={title}
                placeholder='Untitled Document'
            />
            <div className='toolbar-options'>
                <React.Fragment>
                    <button
                        className='toolbar-option block-controls'
                        onClick={() => {
                            setFileOptionDropDown(!fileOptionDropDown);
                            setEditOptionDropDown(false);
                        }}
                        aria-label='File'
                    >
                        <span className='text'>File</span>
                    </button>
                    {fileOptionDropDown &&
                        createPortal(
                            <FileOptionDropDownList
                                actions={actions}
                                setTitle={setTitle}
                                documentTitle={title}
                                editor={editor}
                                blockType={blockType}
                                toolbarRef={toolbarRef}
                                onCloseDropdown={onCloseDropdown}
                                setDocToken={setDocToken}
                                inputRef={inputRef}
                            />,
                            document.body
                        )}
                </React.Fragment>
                <React.Fragment>
                    <button
                        className='toolbar-option block-controls'
                        onClick={() => {
                            setFileOptionDropDown(false);
                            setEditOptionDropDown(!editOptionDropDown);
                        }}
                        aria-label='Edit'
                    >
                        <span className='text'>Edit</span>
                    </button>
                    {editOptionDropDown &&
                        createPortal(
                            <EditOptionDropDownList
                                onRedo={onRedo}
                                onUndo={onUndo}
                                editor={editor}
                                toolbarRef={toolbarRef}
                                onCloseDropdown={onCloseDropdown}
                                inputRef={inputRef}
                            />,
                            document.body
                        )}
                </React.Fragment>
                {save.current ? (
                    <div className={`toolbar-save ${save.current === 'Saving' ? 'saving' : ''}`}>
                        <SaveIcon />
                        {save.current}
                    </div>
                ) : null}
            </div>
            <div className='toolbar-buttons'>
                <button
                    disabled={!canUndo}
                    onClick={() => {
                        editor.dispatchCommand(UNDO_COMMAND);
                    }}
                    className='toolbar-item'
                    aria-label='Undo'
                >
                    <UndoIcon />
                </button>
                <button
                    disabled={!canRedo}
                    onClick={() => {
                        editor.dispatchCommand(REDO_COMMAND);
                    }}
                    className='toolbar-item spaced'
                    aria-label='Redo'
                >
                    <RedoIcon />
                </button>
                <React.Fragment>
                    <FontDropDown disabled={!isEditable} style='font-family' value={fontFamily} editor={editor} />
                    <FontDropDown disabled={!isEditable} style='font-size' value={fontSize} editor={editor} />
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
                        }}
                        className={'toolbar-item ' + (isBold ? 'active' : '')}
                        aria-label='Format Bold'
                    >
                        <BoldIcon />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
                        }}
                        className={'toolbar-item ' + (isItalic ? 'active' : '')}
                        aria-label='Format Italics'
                    >
                        <ItalicIcon />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
                        }}
                        className={'toolbar-item spaced ' + (isUnderline ? 'active' : '')}
                        aria-label='Format Underline'
                    >
                        <UnderlineIcon />
                    </button>
                    <DropdownColorPicker
                        className='spaced'
                        disabled={!isEditable}
                        buttonClassName='toolbar-item color-picker'
                        buttonAriaLabel='Formatting text color'
                        buttonIconClassName='icon font-color'
                        color={fontColor}
                        onChange={onFontColorSelect}
                        title='text color'
                    />
                    <button
                        onClick={insertLink}
                        className={'toolbar-item spaced ' + (isLink ? 'active' : '')}
                        aria-label='Insert Link'
                    >
                        <LinkIcon />
                    </button>
                    {isLink && createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'left');
                        }}
                        className='toolbar-item'
                        aria-label='Left Align'
                    >
                        <LeftAlignIcon />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'center');
                        }}
                        className='toolbar-item'
                        aria-label='Center Align'
                    >
                        <CenterAlignIcon />
                    </button>
                    <button
                        onClick={() => {
                            editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, 'right');
                        }}
                        className='toolbar-item spaced'
                        aria-label='Right Align'
                    >
                        <RightAlignIcon />
                    </button>
                    <button
                        onClick={() => {
                            if (blockType !== 'ul') {
                                editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND);
                            } else {
                                editor.dispatchCommand(REMOVE_LIST_COMMAND);
                            }
                        }}
                        className='toolbar-item spaced'
                        aria-label='Bullet List'
                    >
                        <BulletListIcon />
                    </button>
                    <button
                        onClick={() => {
                            if (blockType !== 'ol') {
                                editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND);
                            } else {
                                editor.dispatchCommand(REMOVE_LIST_COMMAND);
                            }
                        }}
                        className='toolbar-item'
                        aria-label='Bullet List'
                    >
                        <NumberedListIcon />
                    </button>{' '}
                </React.Fragment>
            </div>
        </div>
    );
}
