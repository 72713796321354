import React from 'react';
import { Button, Form, Input } from 'antd';
import { uuid } from '../../../../../../utils/crypto';
import { getPromptsFromString } from '../../helpers';
import style from './index.module.scss';

export const PopoverPrompt = ({ text, setValue, actions }) => {
    const [form] = Form.useForm();

    const prompts = getPromptsFromString(text);

    const onFinish = async (values) => {
        let prompt = text;

        Object.entries(values).map(([key, value]) => {
            if (value) {
                prompt = prompt.replace(key, value);
            }

            return prompt;
        });

        setValue(() => prompt);

        await actions.promptPopover(false);
    };

    const onCancel = async () => {
        await actions.promptPopover(false);
    };

    return (
        <Form className={style.artPopoverPrompt} form={form} layout='vertical' onFinish={onFinish}>
            {prompts?.map((label) => {
                return (
                    <Form.Item name={`[${label}]`} label={label} key={uuid()}>
                        <Input />
                    </Form.Item>
                );
            })}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
                <Form.Item>
                    <Button onClick={onCancel}>Cancel</Button>
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Update Prompt
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};
