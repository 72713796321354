/* eslint-disable */

const config = {
    _id: 126,
    title: 'Home Improvement Proposal',
    alias: 'home-improvement-proposal',
    about: 'This tool helps real estate agents and homeowners to generate effective proposals for home improvement projects. By providing the details of the proposed improvement, budget, and expected benefits, the AI can draft a compelling proposal.',
    categories: ['Real Estate'],
    tips: [
        "Provide specific details about the improvement projects for which you're seeking proposals, including the expected benefits, budget, and timeline."
    ],
    prompts: [
        'Create a proposal for a [Specific Project] with a budget of [Amount].',
        'Generate a home improvement proposal highlighting the potential increase in property value.',
        'Design a proposal for a kitchen renovation including new appliances and a more efficient layout.',
        'Draft a proposal for a landscaping project that includes [Specific Plants/Design Elements].',
        'Create a proposal for a home extension, including a new bedroom and bathroom.'
    ]
};

export default config;
