import React from 'react';
import { Radio } from 'antd';
import { uuid } from '../../../../../../utils/crypto';
import styles from './RadioGroup.module.scss';

export default function RadioGroup({ arrayBtns, initialBtnActive }) {
    const [activeValue, setActiveValue] = React.useState(initialBtnActive);

    const onChangeRadioBtnValue = (e) => {
        setActiveValue(e.target.value);
    };

    return (
        <Radio.Group
            className={styles.radio_group}
            onChange={(e) => onChangeRadioBtnValue(e)}
            buttonStyle='solid'
            optionType='button'
            value={activeValue}
        >
            {arrayBtns.map((btn) => (
                <Radio.Button
                    key={uuid()}
                    value={btn.value}
                    name={btn.nameGroup}
                    className={styles.radio_group__radio_btn}
                >
                    {btn.name}
                </Radio.Button>
            ))}
        </Radio.Group>
    );
}
