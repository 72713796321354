/* eslint-disable */

const config = {
    _id: 84,
    title: 'Respond To Customer Emails',
    alias: 'respond-to-customer-emails',
    about: 'Craft personalized and on-brand responses to customer emails, so you can provide excellent customer service without spending hours in your inbox.',
    categories: ['Marketing'],
    tips: [
        'Provide context: Give Just Think as much information about the customer and their inquiry as possible. This can include details about their past interactions with your company, their needs and goals, and any relevant industry or market information.',
        "Be mindful of tone: Be aware of the tone that you want the response to have, and provide Just Think with examples of the tone you want it to emulate. This can include providing examples of previous responses you've written that have been well-received, or even a sample email with the tone you want to replicate.",
        'Use additional data: Give Just Think additional data such as customer\'s name, demographics, purchase history, etc.'
    ],
    prompts: [
        "What are some effective ways to respond to a customer's complaint in an email? The customer's complaint says: [customers complaint/message]",
        'I am looking for a way to streamline my customer service email responses. Can you help me create a template for addressing common questions and concerns about [product/service]?',
        'I am looking for a way to handle customer complaints and turn the situation around. Can you help me write an email that acknowledges the issue, apologizes and offers a solution to make it right? The issue is [issue].',
        'I want to make sure I am providing a professional and personalized response to customer emails. Can you help me create a script for introducing myself, thanking the customer for their email, and addressing their inquiry related to [product/service]?',
        'I need help crafting an email that addresses a common issue that customers have with [product/service]. Can you help me write a response that provides a solution and reassures the customer?'
    ]
};

export default config;
