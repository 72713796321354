import React from 'react';
import Slider from 'react-slick';
import { Form, Radio } from 'antd';
import { useBreakpoint } from '../../../../../../hooks/media';
import { uuid } from '../../../../../../utils/crypto';
import BookCover from '../../components/BookCover/BookCover';
import { coverBook } from '../../constants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './BookType.module.scss';

export default function BookType() {
    const { isDesktop, isDesktopMiddle, isDesktopLarge } = useBreakpoint();

    const settings = {
        rows: 1,
        arrows: false,
        variableWidth: true,
        swipeToSlide: !isDesktop || !isDesktopMiddle || !isDesktopLarge,
        speed: 500,
        infinite: false,
        focusOnSelect: true
    };

    return (
        <Form.Item
            span={24}
            className={styles.book_type}
            name='bookType'
            rules={[{ required: true, message: 'Please select a book type.' }]}
        >
            {isDesktop || isDesktopMiddle || isDesktopLarge ? (
                <Radio.Group wrap={false} className={styles.book_grid}>
                    {coverBook.map((item, index) => (
                        <BookCover key={uuid()} item={item} index={index} />
                    ))}
                </Radio.Group>
            ) : (
                <Radio.Group wrap={false} className={styles.book_grid}>
                    <Slider {...settings}>
                        {coverBook.map((item, index) => (
                            <BookCover key={uuid()} item={item} index={index} />
                        ))}
                    </Slider>
                </Radio.Group>
            )}
        </Form.Item>
    );
}
