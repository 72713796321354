import React from 'react';

export default function PhoneIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='14' height='24' fill='none' viewBox='0 0 14 24'>
            <path
                fill='#ABB1BB'
                d='M11.375.334A2.625 2.625 0 0114 2.959v18.083a2.625 2.625 0 01-2.625 2.625h-8.75A2.625 2.625 0 010 21.042V2.96A2.625 2.625 0 012.625.334h8.75zM8.458 19.001H5.542a.875.875 0 100 1.75h2.916a.875.875 0 000-1.75z'
            />
        </svg>
    );
}
