import React from 'react';
import { Table, Tag, Typography } from 'antd';
import moment from 'moment';
import { useBreakpoint } from '../../../../../../../../../../hooks/media';
import { uuid } from '../../../../../../../../../../utils/crypto';
import './index.scss';

const numFormatter = new Intl.NumberFormat('en-US');

export default function PlanSettings({ billing, auth }) {
    const {
        state: { plan }
    } = billing;

    const { isMobile } = useBreakpoint();

    const planSettingsDataSource = [
        {
            words: plan.words.value,
            images: plan.images.value,
            seats: plan.seats.value,
            audio: plan.audio.value,
            video: plan.video.value,
            period: plan.period,
            status: plan.planType,
            expiredAt: moment(plan.expiredAt).format('ll')
        }
    ];

    const planTableColumns = [
        {
            title: 'Words',
            dataIndex: 'words',
            key: 'words',
            width: isMobile ? 150 : 85,
            render: (words) => {
                let text = 'Unlimited';

                if (plan.words.limit > -1) {
                    text = `${numFormatter.format(words)}/${numFormatter.format(plan.words.limit)}`;
                }

                if (plan.words.limit > -1 && words >= plan.words.limit) {
                    return <Tag color='red'>{text}</Tag>;
                }

                return text;
            }
        },
        {
            title: 'Speech',
            dataIndex: 'audio',
            key: 'audio',
            width: isMobile ? 125 : 75,
            render: (audio) => {
                if (!plan.audio.limit) {
                    return '-';
                }

                return `${numFormatter.format(audio)}/${numFormatter.format(plan.audio.limit)}`;
            }
        },
        {
            title: 'Video',
            dataIndex: 'video',
            key: 'video',
            width: isMobile ? 125 : 75,
            render: (video) => {
                if (!plan.video.limit) {
                    return '-';
                }

                const limit = `${plan.video.limit / 60} min`;

                const value = video === 0 ? '0 min' : `${(video / 60).toFixed(2)} min`;

                return `${value}/${limit}`;
            }
        },
        {
            title: 'Images',
            dataIndex: 'images',
            key: 'images',
            width: isMobile ? 125 : 75,
            render: (images) => {
                if (!plan.images.limit) {
                    return '-';
                }

                return `${numFormatter.format(images)}/${numFormatter.format(plan.images.limit)}`;
            }
        },
        {
            title: 'Users',
            dataIndex: 'seats',
            key: 'seats',
            width: 75,
            render: (seats) => {
                return `${numFormatter.format(seats)}/${numFormatter.format(plan.seats.limit)}`;
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
            render: (status) => {
                return <div className='to-upper-case'>{status}</div>;
            }
        },
        {
            title: 'Expires',
            dataIndex: 'expiredAt',
            width: isMobile ? 250 : 100,
            key: 'expiredAt'
        }
    ];

    return (
        <div className='billing__selector__settings'>
            <Typography.Title level={2}>Plan Settings</Typography.Title>
            <Table
                className='ant-table--centered'
                loading={billing.state.loader.getPlan}
                rowKey={() => uuid()}
                columns={planTableColumns}
                dataSource={planSettingsDataSource}
                pagination={false}
                scroll={{ x: 500 }}
            />
        </div>
    );
}
