const config = {
    _id: 86,
    title: 'Crisis Management Guidance',
    alias: 'crisis-management-guidance',
    about: 'Provide valuable assistance and resources for effectively navigating and managing crises. It offers guidance, strategies, and support to help organizations mitigate risks, handle emergencies, and ensure swift and informed decision-making during challenging times.',
    categories: ['Marketing'],
    tips: [
        'Provide clear and concise information about the specific crisis situation to help Just Think generate more accurate and relevant responses.',
        'Give Just Think context about the severity of the crisis situation to ensure that the generated responses are appropriately sensitive and empathetic.',
        'Consider providing Just Think with details about the target audience, such as their age, location, or profession, to help tailor the guidance to their specific needs.'
    ],
    prompts: [
        'In the event of a [specific crisis], what are the most effective steps that [specific group] can take to ensure [specific outcome]?',
        'What [specific type of training/education/plan] should [specific organization] have in place to prepare for a [specific type of crisis] that could impact [specific group]?',
        'Considering the unique needs of [specific group of people], what are the best practices for [specific action] during a [specific type of crisis]?',
        'Can you provide guidance on how [specific organization] can effectively manage [specific type of crisis] while also addressing [specific concern/impact]?',
        'What are the most important factors to consider when [specific action] in the aftermath of a [specific type of crisis] that has impacted [specific group]?'
    ]
};

export default config;
