import React from 'react';
import { Col, Typography } from 'antd';
import styles from './index.module.scss';

export default function ProfileInfo(props) {
    const formValues = props.form.getFieldsValue();

    return (
        <Col justify='start' className={styles.profile__info} style={{ padding: '0 8px' }}>
            <Typography.Title level={4} className={styles.profile__info__name}>
                {formValues.fname} {formValues.lname}
            </Typography.Title>
            <Typography.Paragraph className={styles.profile__info__option}>{formValues.option}</Typography.Paragraph>
            <Typography.Paragraph className={styles.profile__info__email}>{formValues.email}</Typography.Paragraph>
        </Col>
    );
}
