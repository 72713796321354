import React, { useCallback } from 'react';
import { $patchStyleText } from '@lexical/selection';
import { $getSelection, $isRangeSelection } from 'lexical';
import DropDown, { DropDownItem } from '../ui/Dropdown';

const FONT_FAMILY_OPTIONS = [
    ['Arial', 'Arial'],
    ['Courier New', 'Courier New'],
    ['Georgia', 'Georgia'],
    ['Times New Roman', 'Times New Roman'],
    ['Trebuchet MS', 'Trebuchet MS'],
    ['Verdana', 'Verdana']
];

const FONT_SIZE_OPTIONS = [
    ['10px', '10px'],
    ['11px', '11px'],
    ['12px', '12px'],
    ['13px', '13px'],
    ['14px', '14px'],
    ['15px', '15px'],
    ['16px', '16px'],
    ['17px', '17px'],
    ['18px', '18px'],
    ['19px', '19px'],
    ['20px', '20px']
];

const dropDownActiveClass = (active) => {
    if (active) return 'active dropdown-item-active';
    else return '';
};

export default function FontDropDown({ editor, value, style, disabled = false }) {
    const handleClick = useCallback(
        (option) => {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $patchStyleText(selection, {
                        [style]: option
                    });
                }
            });
        },
        [editor, style]
    );

    const buttonAriaLabel =
        style === 'font-family' ? 'Formatting options for font family' : 'Formatting options for font size';

    return (
        <DropDown
            disabled={disabled}
            buttonClassName={'toolbar-item ' + style}
            buttonLabel={value}
            buttonAriaLabel={buttonAriaLabel}
        >
            {(style === 'font-family' ? FONT_FAMILY_OPTIONS : FONT_SIZE_OPTIONS).map(([option, text]) => (
                <DropDownItem
                    className={`item ${dropDownActiveClass(value === option)} ${
                        style === 'font-size' ? 'fontsize-item' : ''
                    }`}
                    onClick={() => handleClick(option)}
                    key={option}
                >
                    <span className='text'>{text}</span>
                </DropDownItem>
            ))}
        </DropDown>
    );
}
