// TODO: migrate to fetch API
import { Modal } from 'antd';
import { BACKEND_URL } from '../../config';
import { DEFAULT_REQUEST_CREDENTIALS, DEFAULT_REQUEST_HEADERS, METHODS, PAYMENT_MODAL_SETTINGS } from './constants';

function handleResponse(response) {
    if (!response.ok) {
        return Promise.reject(response);
    }

    if (response.headers.get('Content-Type') === 'application/json') {
        return response.json();
    }

    if (response.headers.get('Content-Type') === 'text/event-stream') {
        return response.body;
    }

    return Promise.reject(new Error('Unknown Content-Type'));
}

function handleError(response) {
    const error = new Error(response.statusText || 'Unknown error');

    if (response.status === 402) {
        const CONFIG = PAYMENT_MODAL_SETTINGS(response.url);

        Modal.warning(CONFIG);
    }

    return Promise.reject(error);
}

function createUrl(path) {
    return `${BACKEND_URL}${path}`;
}

function createRequestBody(data) {
    return JSON.stringify(data);
}

export function get(url, options) {
    return fetch(createUrl(url), {
        method: METHODS.GET,
        headers: {
            ...DEFAULT_REQUEST_HEADERS,
            ...options?.headers
        },
        credentials: DEFAULT_REQUEST_CREDENTIALS
    })
        .then(handleResponse)
        .catch(handleError);
}

export function post(url, body, options) {
    return fetch(createUrl(url), {
        method: METHODS.POST,
        headers: {
            'x-access-token': localStorage.getItem('BrainstormAuthToken')
        },
        credentials: DEFAULT_REQUEST_CREDENTIALS,
        body,
        signal: options?.signal
    })
        .then(handleResponse)
        .catch(handleError);
}

export function put(url, body, options) {
    return fetch(createUrl(url), {
        method: METHODS.PUT,
        headers: {
            ...DEFAULT_REQUEST_HEADERS,
            ...options?.headers
        },
        credentials: DEFAULT_REQUEST_CREDENTIALS,
        body: createRequestBody(body)
    })
        .then(handleResponse)
        .catch(handleError);
}

export function del(url) {
    return fetch(createUrl(url), {
        method: METHODS.DELETE,
        headers: DEFAULT_REQUEST_HEADERS,
        credentials: DEFAULT_REQUEST_CREDENTIALS
    })
        .then(handleResponse)
        .catch(handleError);
}
