export const downloadMp4 = async (title, url) => {
    const res = await fetch(url, { mode: 'cors' });

    const blob = await res.blob();

    let a = document.createElement('a');

    a.href = URL.createObjectURL(blob);
    a.download = `${title}_${Date.now()}.mp4`;

    a.click();
    a = null;
};
