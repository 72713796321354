import React from 'react';
import { Row, Spin } from 'antd';

export default function PageLoader({ spinning }) {
    return (
        <Row justify='center' align='middle' style={{ height: '100vh', width: '100%' }}>
            <Spin size='large' spinning={spinning} />
        </Row>
    );
}
