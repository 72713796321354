import React from 'react';
import Icon from '@ant-design/icons';

export default function DocumentIcon(props) {
    return (
        <Icon
            {...props}
            component={() => (
                <svg xmlns='http://www.w3.org/2000/svg' width='16' height='18' fill='none' viewBox='0 0 16 18'>
                    <path
                        fill='#fff'
                        d='M0 0v17.638h15.433V8.82H6.614V0H0zm8.82 0v6.614h6.613L8.82 0zM2.204 4.41H4.41v2.204H2.205V4.41zm0 4.41H4.41v2.204H2.205V8.819zm0 4.409h8.819v2.204h-8.82V13.23z'
                    />
                </svg>
            )}
        />
    );
}
