import React from 'react';
import { Button } from 'antd';
import styles from './BtnSubmitForm.module.scss';

export default function BtnSubmitForm({ textBtn, type }) {
    return (
        <Button className={styles.button_submit} type={type} htmlType='submit'>
            {textBtn}
        </Button>
    );
}
