import { useImmerReducer } from 'use-immer';
import { documentReducer, initState } from './reducer';
import service from './service';

export const useDocument = () => {
    const [state, dispatch] = useImmerReducer(documentReducer, initState);

    const update = async (payload) => {
        try {
            dispatch({
                type: 'DOCUMENT_UPDATE_START'
            });

            await service.document.update(payload);

            dispatch({
                type: 'DOCUMENT_UPDATE_END'
            });
        } catch (error) {
            dispatch({
                type: 'DOCUMENT_UPDATE_END'
            });
        }
    };

    const create = async (payload) => {
        try {
            dispatch({
                type: 'DOCUMENT_CREATE_START'
            });

            const response = await service.document.create();

            payload.setLocalStorage(() => response.data._id);

            dispatch({
                type: 'DOCUMENT_CREATE_END'
            });
        } catch (error) {
            dispatch({
                type: 'DOCUMENT_CREATE_END'
            });
        }
    };

    const get = async (payload) => {
        try {
            dispatch({
                type: 'DOCUMENT_GET_START'
            });

            const response = await service.document.get(payload);

            dispatch({
                type: 'DOCUMENT_GET_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'DOCUMENT_GET_END'
            });
        }
    };

    const list = async ({ page, limit, search }) => {
        try {
            dispatch({
                type: 'DOCUMENT_LIST_START'
            });

            const response = await service.document.list(page, limit, search);

            dispatch({
                type: 'DOCUMENT_LIST_END_SUCCESS',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'DOCUMENT_LIST_END_FAILED'
            });
        }
    };

    const remove = async (payload) => {
        try {
            dispatch({
                type: 'DOCUMENT_REMOVE'
            });

            const response = await service.document.remove(payload);

            dispatch({
                type: 'DOCUMENT_REMOVE_SUCCESS',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'DOCUMENT_REMOVE_FAILED'
            });
        }
    };

    const rename = async (payload) => {
        try {
            dispatch({
                type: 'DOCUMENT_RENAME'
            });

            const response = await service.document.remove(payload);

            dispatch({
                type: 'DOCUMENT_RENAME_SUCCESS',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'DOCUMENT_RENAME_FAILED'
            });
        }
    };

    const setDocTitle = async (payload) => {
        dispatch({
            type: 'SET_DOCUMENT_TITLE',
            payload
        });
    };

    const openModal = async () => {
        dispatch({
            type: 'OPEN_MODAL'
        });
    };

    const closeModal = async () => {
        dispatch({
            type: 'CLOSE_MODAL'
        });
    };

    const onHandleInputField = async (payload) => {
        dispatch({
            type: 'HANDLE_DOCUMENT_INPUT',
            payload
        });
    };

    const actions = {
        closeModal,
        rename,
        onHandleInputField,
        openModal,
        remove,
        setDocTitle,
        list,
        create,
        get,
        update
    };

    return {
        state,
        actions
    };
};
