import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { Button, Col, notification, Popover, Row, Typography } from 'antd';

export const PopoverRemove = ({ onRemove, setOpen }) => {
    return (
        <React.Fragment>
            <Typography.Text>Delete this prompt?</Typography.Text>
            <Row gutter={10} style={{ marginTop: '15px' }}>
                <Col>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                </Col>
                <Col>
                    <Button type='primary' onClick={onRemove}>
                        Delete
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default function SinglePrompt({ prompt, number, config, text, actions }) {
    const [open, setOpen] = useState(false);

    const history = useHistory();

    if (!prompt?.value && !text) return null;

    const onTryPrompt = () => {
        if (config.categories.includes('My tools')) {
            history.push('/chat', { text: prompt.value });
        } else {
            history.push('/chat', { text });
        }
    };

    const onRemove = async () => {
        try {
            await actions.removePrompt(prompt._id, config._id);

            setOpen(false);
        } catch (err) {
            notification.error({
                placement: 'top',
                message: 'Something went wrong! Please try again.'
            });
        }
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const promValue = config.categories.includes('My tools') ? prompt.value : text;

    return (
        <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }} xl={{ span: 8 }} xxl={{ span: 8 }}>
            <div className='single-tool__prompt'>
                <Typography.Title level={4} className='single-tool__prompt__title'>
                    {'Prompt #' + ++number}
                    {!config.categories.includes('My tools') ? null : (
                        <Popover
                            onOpenChange={handleOpenChange}
                            open={open}
                            trigger='click'
                            content={<PopoverRemove setOpen={setOpen} onRemove={onRemove} />}
                        >
                            <Button className='single-prompt__remove' icon={<DeleteOutlined />} />
                        </Popover>
                    )}
                </Typography.Title>
                <Typography.Paragraph className='single-tool__prompt__descr'>
                    <div
                        dangerouslySetInnerHTML={{
                            __html:
                                '"' +
                                promValue.replace(/\[(.*?)\]/g, function (text) {
                                    return '<span>' + text + '</span>';
                                }) +
                                '"'
                        }}
                    />
                </Typography.Paragraph>
                <Button onClick={onTryPrompt}>Try Prompt</Button>
            </div>
        </Col>
    );
}
