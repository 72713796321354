/* eslint-disable */

const config = {
    _id: 118,
    title: 'SEO Advisor',
    alias: 'seo-advisor',
    about: 'The AI SEO advisor can suggest ways to optimize your content for search engines, helping to increase the visibility of your educational content or blog.',
    categories: ['Website & SEO'],
    tips: [
        'Use SEO tips judiciously. The most important thing is that your content is valuable and readable for your human audience.',
        "Incorporate the AI's keyword suggestions naturally into your content.",
        'SEO is not a one-time task but an ongoing effort. Regularly review and update your content as needed.'
    ],
    prompts: [
        'Suggest relevant keywords for this blog post on [topic].',
        'How can I optimize my content for search engines? [insert content].',
        'Generate a search engine-friendly summary for this article. [insert article].',
        'Provide SEO tips for my [education]-focused YouTube channel.',
        'What are some commonly searched queries related to [specific topic]?'
    ]
};

export default config;
