export const download = async (url, prompt) => {
    const res = await fetch(url, { mode: 'cors' });

    const blob = await res.blob();

    let a = document.createElement('a');

    a.href = URL.createObjectURL(blob);
    a.download = `${prompt.replace(/\s+/g, '-').toLowerCase()}-just-think-ai.png`;
    a.click();
    a = null;
};
