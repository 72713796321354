import React from 'react';
import styles from './index.module.scss';

export default function ProfessionSurveyCard(props) {
    const { title, isSelected } = props;

    return (
        <div className={styles.card}>
            <span className={`${styles.card__title} ${isSelected ? styles.selected_text : ''}`}>{title}</span>
        </div>
    );
}
