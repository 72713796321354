import React, { useState } from 'react';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import { Drawer, Menu } from 'antd';
import JustThinkLogoFull from '../../../../../assets/images/just-think-logo-blue-full-logo-yellow.png';
import { useBreakpoint } from '../../../../../hooks/media';
import { ChatConversation } from '../../../pages/ChatPage/components/Conversation';
import { useConversationContext } from '../../../pages/ChatPage/components/Conversation/context';
import OrgSelect from '../../OrgSelect/OrgSelect';
import { MenuSettings } from '../components/menuSettings';

export default function MenuDrawer({ open, ctrlRef, showMenu, onClose, menuItems, selectedKeys, onMenuItemClick }) {
    const { isMobile } = useBreakpoint();

    const { conversation, message } = useConversationContext();

    const [openKeys, setOpenKeys] = useState([]);

    const menuKeys = menuItems.map((item) => item.key);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (menuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <Drawer
            width={!showMenu ? '100%' : isMobile ? '300' : '378'}
            getContainer={() => document.querySelector('#app-layout')}
            className={`menu-drawer-wrap ${!showMenu ? 'quick-access' : ''}`}
            title={!showMenu ? 'Quick Access' : <img src={JustThinkLogoFull} alt='logo' />}
            placement='left'
            closeIcon={!showMenu ? <CloseOutlined /> : <MenuOutlined />}
            onClose={() => {
                onClose();
            }}
            open={open}
        >
            {!showMenu ? (
                <ChatConversation
                    ctrlRef={ctrlRef}
                    menuClose={() => onClose()}
                    conversation={conversation}
                    message={message}
                />
            ) : null}
            {showMenu ? (
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    <OrgSelect />
                    <Menu
                        className='menu-side-wrap'
                        mode='inline'
                        openKeys={openKeys}
                        selectedKeys={selectedKeys}
                        onOpenChange={onOpenChange}
                        items={menuItems.map((item) => ({
                            ...item,
                            onClick: (e) => {
                                onMenuItemClick(e);
                                onClose();
                            }
                        }))}
                    />
                    <MenuSettings onClose={onClose} />
                </div>
            ) : null}
        </Drawer>
    );
}
