/* eslint-disable */

const config = {
    _id: 83,
    title: 'Create A YouTube Video Script',
    alias: 'create-a-youtube-video-script',
    about: "Craft scripts that will keep your audience hooked from start to finish. Plus, its ability to understand and analyze viewer data means you'll always know what type of content your audience wants to see.",
    categories: ['Social Media & Ads'],
    tips: [
        'Provide a brief summary or outline of the main points or takeaways you want the video to cover. This will give Just Think a better idea of what information to include in the script.',
        'Provide examples of similar videos or scripts that you like. This will give Just Think a sense of the tone, style and format you are looking for in the script.'
    ],
    prompts: [
        'I want to create a YouTube video about [topic]. Can you help me write a script that will engage my audience and provide valuable information?',
        'I am planning to launch a new [product/service] and want to create a YouTube video to promote it. Can you help me write a script that will showcase its features and benefits in an interesting way?',
        'I want to create a YouTube video that addresses common questions and concerns about [topic]. Can you help me write a script that will provide clear and concise answers?',
        'I am creating a tutorial video on [topic]. Can you help me write a script that will break down the steps in an easy-to-follow format for my audience?',
        'I am creating an animated explainer video about [topic]. Can you help me write a script that will be informative, clear and easy to understand?'
    ]
};

export default config;
