import React from 'react';

export default function MoonIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 256 256'>
            <path fill='none' d='M0 0h256v256H0z' />
            <path
                fill='#fff'
                stroke='#000'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth={8}
                d='M216.7 152.6A91.9 91.9 0 0 1 103.4 39.3h0a92 92 0 1 0 113.3 113.3Z'
            />
        </svg>
    );
}
