import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Drawer, Input, Tabs } from 'antd';
import { useBreakpoint } from '../../../../../../hooks/media';
import { useSearchParams } from '../../../../../../hooks/searchParams';
import { QuickLibrary } from './components/QuickLibrary';
import ToolsLibrary from './components/ToolsLibrary';
import promptsConfig from './configurations';
import './index.scss';

export default function PromptLibrary({ collapsed, conversation, message }) {
    const { isMobile } = useBreakpoint();

    const searchParams = useSearchParams();

    const history = useHistory();

    const { state, actions } = conversation;

    const [tabKey, setTabKey] = useState('1');

    const [prompts, setPrompts] = useState(promptsConfig.prompts);

    useEffect(() => {
        history.push({ pathname: '/chat' });
    }, []);

    const onPromptsChange = (e) => {
        const { value } = e.target;

        searchParams.set('search', value);

        history.push({ pathname: '/chat', search: searchParams.toString() });

        if (tabKey === '1') {
            const filteredPrompts = [...promptsConfig.prompts].filter(
                ({ title, list }) =>
                    title.toLowerCase().search(value.toLowerCase()) > -1 ||
                    list.find((element) => element.toLowerCase().search(value.toLowerCase()) > -1)
            );

            setPrompts(() => filteredPrompts);
        }
    };

    const onTabsChange = (value) => {
        setTabKey(() => value);

        if (value === '1' || value === '2') {
            searchParams.delete('category');

            history.push({ pathname: '/chat', search: searchParams.toString() });
        }

        if (value === '3') {
            searchParams.set('category', 'favorites');

            history.push({ pathname: '/chat', search: searchParams.toString() });
        }
    };

    const items = [
        {
            key: '1',
            label: 'Quick Library',
            children: <QuickLibrary actions={actions} prompts={prompts} collapsed={collapsed} message={message} />
        },
        {
            key: '2',
            label: 'Tools',
            children: <ToolsLibrary collapsed={collapsed} conversation={conversation} path='/chat' />
        },
        {
            key: '3',
            label: 'Favorites',
            children: <ToolsLibrary collapsed={collapsed} conversation={conversation} path='/tools' tabs={false} />
        }
    ];

    return (
        <React.Fragment>
            <Drawer
                className='prompts-library'
                title='Prompt Library'
                placement='right'
                closable
                onClose={() => actions.promptsOpen(false)}
                open={state.loader.promptsOpen}
                size='large'
                width={isMobile ? '100%' : '455px'}
                getContainer={() => document.querySelector('#app-layout')}
            >
                <Input.Search
                    className='prompts-search-input'
                    placeholder='Search Prompts'
                    allowClear
                    bordered={false}
                    onChange={onPromptsChange}
                    size='large'
                />
                <Tabs
                    onChange={(value) => onTabsChange(value)}
                    activeKey={tabKey}
                    className='prompts-library__tabs'
                    items={items}
                />
            </Drawer>
        </React.Fragment>
    );
}
