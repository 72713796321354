import React, { Fragment, useState } from 'react';
import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import atomDark from 'react-syntax-highlighter/src/styles/prism/atom-dark';
import { CheckOutlined, EditOutlined, LinkOutlined } from '@ant-design/icons';
import { Avatar, Button, Flex, Input, List, Modal, notification, Typography } from 'antd';
import copy from 'copy-to-clipboard';
import moment from 'moment/moment';
import remarkGfm from 'remark-gfm';
import thinkLogo from '../../../../../../assets/images/just-think-ai-icon.jpg';
import styles from './index.module.scss';

const ShareModal = ({ conversation, auth, message, open, onClose }) => {
    const [editTitle, setEditTitle] = useState(false);

    const [title, setTitle] = useState(conversation.state?.current?.title || '');

    const messages = message.state.data?.docs || [];
    const handleOk = () => {
        onClose();
    };

    const handleCancel = () => {
        onClose();
    };

    const onCopyLink = async () => {
        await conversation.actions.update(conversation.state?.current?._id, {
            title,
            public: true
        });

        copy(window.location.origin + '/share/' + conversation.state?.current?.shareId);
        onClose();
        notification.success({
            placement: 'top',
            message: 'Share link copied!'
        });
    };

    const onChangeTitle = async (e) => {
        const { value } = e.target;
        setTitle(value);
    };

    const onUpdateTitle = async (e) => {
        e.preventDefault();
        await conversation.actions.update(conversation.state?.current?._id, {
            title: e.target.value.trim()
        });
    };

    return (
        <Modal
            title='Share link to Chat'
            width={710}
            className='modal-component'
            centered
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            okText='Copy Link'
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Flex vertical>
                <Typography.Text className={styles['chat-share__descr']}>
                    Messages you send after creating your link won’t be shared. Anyone with URL will be able to view the
                    shared chat.
                </Typography.Text>
                <List
                    className={styles['chat-share__list']}
                    itemLayout='vertical'
                    size='large'
                    dataSource={messages}
                    split={false}
                    renderItem={(item) => {
                        return (
                            <div key={item._id} className='chat-items-wrapper'>
                                <List.Item>
                                    <div className='chat-item-wrapper'>
                                        <div className='chat-message'>
                                            <div className='chat-message-logo'>
                                                {auth.profile?.avatar ? (
                                                    <img alt='user-logo' src={auth.profile?.avatar} />
                                                ) : (
                                                    <Avatar
                                                        style={{
                                                            backgroundColor: auth.profile.uiSettings?.profileBg
                                                        }}
                                                    >
                                                        {auth.profile.fname?.charAt(0).toLocaleUpperCase()}
                                                    </Avatar>
                                                )}
                                            </div>
                                            <div className='chat-message-content'>
                                                <div className='chat-message-wrapper'>
                                                    <div className='chat-message-response question'>{item.content}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </List.Item>
                                {item.respond ? (
                                    <List.Item>
                                        <div className='chat-item-wrapper'>
                                            <div className='chat-message'>
                                                <div className='chat-message-logo'>
                                                    <img alt='just-think-logo' src={thinkLogo} />
                                                </div>
                                                <div className='chat-message-wrapper'>
                                                    <div className='chat-message-response'>
                                                        <Markdown
                                                            remarkPlugins={[remarkGfm]}
                                                            components={{
                                                                code(props) {
                                                                    const { children, className, node, ...rest } =
                                                                        props;

                                                                    const match = /language-(\w+)/.exec(
                                                                        className || ''
                                                                    );

                                                                    return match ? (
                                                                        <SyntaxHighlighter
                                                                            showLineNumbers
                                                                            {...rest}
                                                                            PreTag='div'
                                                                            language={match[1]}
                                                                            style={atomDark}
                                                                        >
                                                                            {String(children).replace(/\n$/, '')}
                                                                        </SyntaxHighlighter>
                                                                    ) : (
                                                                        <code {...rest} className={className}>
                                                                            {children}
                                                                        </code>
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {item.respond}
                                                        </Markdown>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </List.Item>
                                ) : null}
                            </div>
                        );
                    }}
                />
                <Flex gap={7} vertical className={styles['chat-share__info']}>
                    <Typography.Title level={2}>
                        <Flex gap={13} align='center' className={styles['chat-share__info__title']}>
                            {editTitle ? (
                                <Fragment>
                                    <Input
                                        onChange={onChangeTitle}
                                        onBlur={onUpdateTitle}
                                        onPressEnter={onUpdateTitle}
                                        value={title}
                                    />
                                    <CheckOutlined onClick={() => setEditTitle(false)} />
                                </Fragment>
                            ) : (
                                <Fragment>
                                    <Typography.Title level={3}>{title}</Typography.Title>
                                    <EditOutlined onClick={() => setEditTitle(true)} />
                                </Fragment>
                            )}
                        </Flex>
                    </Typography.Title>
                    <Typography.Text>
                        {moment(conversation.state?.current?.createdAt).format('MMMM DD, YYYY')}
                    </Typography.Text>
                </Flex>
                <Flex justify='flex-end' className={styles['chat-share__footer']}>
                    <Button onClick={onCopyLink}>
                        <LinkOutlined />
                        Copy Link
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
};

export default ShareModal;
