import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Col, Typography } from 'antd';

export default function SingleTip({ tip }) {
    return (
        <Col>
            <div className='single-tool__tip'>
                <Typography.Paragraph>
                    <InfoCircleOutlined />
                    {tip.value}
                </Typography.Paragraph>
            </div>
        </Col>
    );
}
