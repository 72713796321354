import React from 'react';
import { Select } from 'antd';
import styles from './SelectForm.module.scss';

export default function SelectForm({ setAgeGroup }) {
    return (
        <Select
            className={styles.select_form}
            onChange={setAgeGroup}
            placeholder='Please select a category'
            options={[
                { value: '3-5 years old', label: '3-5 years old' },
                { value: '6-8 years old', label: '6-8 years old' },
                { value: '9-12 years old', label: '9-12 years old' }
            ]}
        />
    );
}
