/* eslint-disable */

const config = {
    _id: 76,
    title: 'Write Email Subject Lines',
    alias: 'write-email-subject-lines',
    about: 'Generate tailored email subject lines by providing detailed information about the product or service, target audience, and desired action, allowing for versatile and effective campaigns.',
    categories: ['Marketing'],
    tips: [
        'Test different variations of the generated subject lines to see which ones perform the best.',
        'Use A/B testing to compare the performance of different subject lines.',
        'Try to be creative and use power words and phrases to make your subject lines stand out.',
        'Use different formats of the subject lines, ex: questions, statements, numbers, etc.',
        'Use emojis if they are appropriate for your audience and message.',
        'Don\'t be afraid to experiment and try new things.'
    ],
    prompts: [
        'I am looking for a subject line for an email promotion about [product/service]. The email will be sent to [target audience] and the goal is to [action desired from recipient]. Can you please generate a subject line that is [specific tone/style] and includes [keywords/phrases]?',
        'I am looking for a subject line for a newsletter about [topic]. The email will be sent to [target audience] and the goal is to [action desired from recipient]. Can you please generate a subject line that is [specific tone/style] and includes [keywords/phrases]?',
        'I am looking for a subject line for a reminder email about [event/deadline]. The email will be sent to [target audience] and the goal is to [action desired from recipient]. Can you please generate a subject line that is [specific tone/style] and includes [keywords/phrases]?',
        'I am crafting an email to promote our [product/service], and I need a compelling subject line that will entice recipients to open the email. Can you help me generate several options?',
        'I am sending an email to announce a new [feature/offer] and I need a catchy subject line that will grab the attention of my audience. Can you help me come up with a few options?'
    ]
};

export default config;
