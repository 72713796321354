import React from 'react';

export default function DayNightIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none' viewBox='0 0 22 22'>
            <path
                stroke='#1F1F1F'
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M11 5.407L6.306 16.339 5.36 13.4l-2.03 4.043M11 5.407l4.694 10.932.946-2.938 2 3.985M11 5.406v15.55M4.46 5.536l-.425-.425m4.088 4.088l-.426-.426M6.079 4.867v-.603m0 5.782v-.603m2.288-2.288h.603m-5.782 0h.603m3.906-1.618l.426-.426M4.035 9.199l.426-.426m3.282-1.618a1.664 1.664 0 11-3.329 0 1.664 1.664 0 013.329 0zm8.424-1.967l-.033.002a1.965 1.965 0 01-1.34 3.368 1.965 1.965 0 101.372-3.37zm-1.702.437a.155.155 0 11-.31 0 .155.155 0 01.31 0zm.413 1.802a.155.155 0 11-.31 0 .155.155 0 01.31 0zM21 11c0 5.523-4.477 10-10 10S1 16.523 1 11 5.477 1 11 1s10 4.477 10 10z'
            />
        </svg>
    );
}
