/* eslint-disable */

const config = {
    _id: 91,
    title: 'Personalize A Messages For Influencers',
    alias: 'personalize-a-messages-for-influencers',
    about: 'Tailor a message to specific influencers, and craft creative and impactful messages, increasing your chances of getting a response and making a lasting impression.',
    categories: ['Social Media & Ads'],
    tips: [
        'Provide Just Think with specific information about the influencers you are trying to reach out to. This can include their names, social media handles, and information about their interests and followers.',
        'Use Just Think to generate a list of ways to use influencers and their reach to increase brand awareness and drive more interactions.'
    ],
    prompts: [
        'I am looking to reach out to influencers in the [industry/niche] field to collaborate with my brand that [tell more about the brand]. Can you help me come up with personalized messages that will grab their attention and make them want to work with me?',
        'I am looking to collaborate with influencers who specialize in [specific content type, e.g. beauty, fashion, food]. Can you help me write personalized messages that showcase my brand\'s expertise with [expertise of the brand] in that area and how it aligns with the influencer\'s content?',
        'I am reaching out to influencers who have a large following in [specific audience]. Can you help me write personalized messages that will highlight [brand\'s relevance] to their audience and make it clear why it\'s worth their time to work with me?'
    ]
};

export default config;
