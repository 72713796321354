/* eslint-disable */

const config = {
    _id: 93,
    title: 'Create Effective Bullet Points',
    alias: 'create-effective-bullet-points',
    about: 'Generate impactful and concise bullet points, enabling you to communicate key information with precision and effectiveness.',
    categories: ['Blog Content'],
    tips: [
        'Be clear and concise: Bullet points should be short and to the point. Focus on conveying the most important information in as few words as possible.',
        "Use strong action verbs: Bullet points should use strong, active language that grabs the reader's attention and conveys a sense of urgency or importance.",
        'Tailor bullet points to your audience: Consider who you are writing for and adjust your language and tone accordingly. Use language and terminology that resonates with your target audience.'
    ],
    prompts: [
        "I'm writing bullet points for a [type of content] on [topic] and need [number] bullet points that will [goal], while also taking into account [factor] and [factor]. Can you help me with that?",
        "What are some effective bullet point structures for [type of content], considering the [goal] of the piece, the [type of audience] it's targeting, and the [topic] it's covering? Please provide at least [number] different examples.",
        'How can I make sure that my bullet points are engaging and memorable for [type of audience] when writing a [type of content] about [topic]? Specifically, how can I make sure that my bullet points highlight [unique aspect] while still being [tone]?',
        "I'm writing bullet points for [type of content] in the [industry] industry, and I need [number] persuasive bullet points that will [goal]. Can you provide examples of persuasive bullet points that focus on [specific aspect of industry] and that take into account the [type of audience] they're intended for?",
        'When writing bullet points for [type of content] that will be delivered via [medium], what are some common mistakes to avoid that may impact the [goal] of the piece? Specifically, how can I avoid [obstacle] and [obstacle] when crafting my bullet points for this [type of medium]?'
    ]
};

export default config;
