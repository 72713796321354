/* eslint-disable */

const config = {
    _id: 114,
    title: 'Critical Thinking Encourager',
    alias: 'critical-thinking-encourager',
    about: 'Designed to promote critical thinking skills, providing questions and prompts that encourage deep thought, analysis, and independent reasoning.',
    categories: ['Education'],
    tips: [
        'Encourage students to take their time with responses, prioritizing depth and quality over speed.',
        "Use the AI's questions as discussion starters to facilitate group conversation.",
        'Emphasize the importance of critical thinking in all areas of learning and life.'
    ],
    prompts: [
        "Provide a critical thinking prompt for today's lesson on [subject].",
        'What questions could I ask to encourage deeper thinking about [topic]?',
        'Suggest a debate topic related to [subject].',
        'Design a logic puzzle related to [subject].',
        'How can I encourage more critical thinking in [grade level] class discussions?'
    ]
};

export default config;
