import { dObjects } from './3dObjects';
import { animals } from './Animals';
import { architecture } from './Architecture';
import { bookCover } from './BookCover';
import { doodleArt } from './DoodleArt';
import { logos } from './Logos';
import { stickers } from './Stickers';
import { storybookIllustration } from './StorybookIllustration';
import { travelPhotography } from './TravelPhotography';
import { wallpaper } from './Wallpaper';

export const configurations = [
    stickers,
    doodleArt,
    logos,
    animals,
    storybookIllustration,
    travelPhotography,
    wallpaper,
    dObjects,
    bookCover,
    architecture
];
