const config = {
    _id: 88,
    title: 'Generate Instagram Ad targeting ideas',
    alias: 'generate-instagram-ad-targeting-ideas',
    about: 'Generate targeted Instagram ad ideas effortlessly.',
    categories: ['Social Media & Ads'],
    tips: [
        'Be specific with the product or service that is being promoted to get more accurate results.',
        'Use Just Think to brainstorm creative ideas and new approaches to ad targeting, such as exploring niche audiences or unconventional demographics.',
        'Consider using a combination of both broad and specific targeting options to maximize reach while also ensuring relevance to the target audience.'
    ],
    prompts: [
        'What are some [specific keyword] targeting ideas that can be used in Instagram ads for [product/service] to reach [specific demographic] with [specific interest]?',
        'Can you suggest some [creative ad  ideas/attention-grabbing headlines] that I can use to target [specific age group/gender] for [product/service] who are interested in [specific interest]?',
        'How can I [enhance/improve] the visual appeal of Instagram ads for [product/service] when targeting [specific audience] with [specific demographic] and [specific interest]?',
        'What are some [trending/emerging] [hashtags/keywords] that can be used in Instagram ads for [product/service] targeting [specific interest] and [specific demographic] in [specific location]?',
        'Can you provide some [unconventional/niche] targeting options that I can use in Instagram ads for [product/service] to reach a [specific group/audience] with [specific interest] and [specific demographic]?'
    ]
};

export default config;
