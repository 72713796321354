const config = {
    _id: 120,
    title: 'Property Inspection Checklist',
    alias: 'property-inspection-checklist',
    about: 'An AI that helps to generate a comprehensive checklist for property inspections, tailored to the property type and specific needs.',
    categories: ['Real Estate'],
    tips: [
        'Provide as much specific detail as possible about the property type, age, and special features to generate a more accurate and detailed checklist.'
    ],
    prompts: [
        'Generate a property inspection checklist for a [property type].',
        'How should I adjust my property inspection checklist for a property built in [year]?',
        'Create a inspection checklist for a [property type] with [specific features].',
        'Create a property inspection checklist for a property with [number of bedrooms and bathrooms].',
        'Create a property inspection checklist for an older property from [specific era/decade].',
        'How should the checklist change for a property with a [specific amenity - e.g., pool, garden]?'
    ]
};

export default config;
