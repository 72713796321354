import React, { useState } from 'react';
import { Flex, Layout, Menu, Typography } from 'antd';
import JustThinkLogoSmall from '../../../../../assets/images/bulb-icon-mini.png';
import JustThinkLogoFull from '../../../../../assets/images/just-think-logo-blue-full-logo-yellow.png';
import { uuid } from '../../../../../utils/crypto';
import OrgSelect from '../../OrgSelect/OrgSelect';
import { MenuSettings } from '../components/menuSettings';
import './index.scss';

const { Sider } = Layout;

export default function MenuSider({ collapsed, menuItems, selectedKeys, onMenuItemClick }) {
    const [openKeys, setOpenKeys] = useState([]);

    const menuKeys = menuItems.map((item) => item.key);

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

        if (menuKeys.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    return (
        <Sider width='245' theme='light' trigger={null} collapsible collapsed={collapsed} className='menu-side'>
            <div className='logo'>
                <img src={collapsed ? JustThinkLogoSmall : JustThinkLogoFull} alt='logo' />
            </div>
            <OrgSelect />
            <Menu
                className='menu-side-wrap desktop'
                selectedKeys={selectedKeys}
                onOpenChange={onOpenChange}
                mode='inline'
            >
                {menuItems.map((item) => {
                    return (
                        <Flex vertical key={uuid()}>
                            <Typography.Text>{item.label}</Typography.Text>
                            {item.children.map((child) => {
                                return (
                                    <Menu.Item
                                        className={selectedKeys.includes(child.key) ? 'active-item-custom' : null}
                                        onClick={() => onMenuItemClick({ key: child.key })}
                                        key={uuid()}
                                    >
                                        <Flex gap={5} align='center'>
                                            {child.icon}
                                            <span>{child.label}</span>
                                        </Flex>
                                    </Menu.Item>
                                );
                            })}
                        </Flex>
                    );
                })}
            </Menu>
            <MenuSettings />
        </Sider>
    );
}
