import React from 'react';
import { Flex, Typography } from 'antd';

export const METHODS = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    DELETE: 'DELETE'
};

export const DEFAULT_REQUEST_HEADERS = {
    'Content-Type': 'application/json'
};

export const DEFAULT_REQUEST_CREDENTIALS = 'include';

const contentModal = (url, code) => {
    if (code === 'CONVERSATION_LIMIT') {
        return {
            title: 'You have reached the limit\n',
            description: (
                <Typography.Text className='opacity'>
                    You can have up to <span style={{ fontWeight: '700' }}>500</span> Conversations
                </Typography.Text>
            )
        };
    }

    if (url.includes('image')) {
        return {
            title: 'Upgrade Image Generation Plan',
            description: (
                <Typography.Text className='opacity'>
                    Upgrade your <span style={{ fontWeight: '700' }}>Image Generation</span> plan to use this feature.
                </Typography.Text>
            )
        };
    }

    if (url.includes('video')) {
        return {
            title: 'Upgrade Video Plan',
            description: (
                <Typography.Text className='opacity'>
                    Upgrade your <span style={{ fontWeight: '700' }}>Video</span> plan to continue using this feature.
                </Typography.Text>
            )
        };
    }

    if (url.includes('speech')) {
        return {
            title: 'Upgrade Text to Speech Plan',
            description: (
                <Typography.Text className='opacity'>
                    Upgrade your <span style={{ fontWeight: '700' }}>Text to Speech </span>plan to continue using this
                    feature.
                </Typography.Text>
            )
        };
    }

    if (url.includes('chat')) {
        if (url.includes('/chat/conversations')) {
            return {
                title: 'Upgrade Billing Plan',
                description: (
                    <Typography.Text className='opacity'>
                        Upgrade your <span style={{ fontWeight: '700' }}>Billing</span> plan to increase conversation
                        limit.
                    </Typography.Text>
                )
            };
        }

        return {
            title: 'Upgrade Words Plan',
            description: (
                <Typography.Text className='opacity'>
                    Upgrade your <span style={{ fontWeight: '700' }}>Words</span>plan to continue using this feature.
                </Typography.Text>
            )
        };
    } else {
        return {
            title: 'Upgrade Your Plan',
            description: ''
        };
    }
};

export const PAYMENT_MODAL_SETTINGS = (url, code) => {
    const content = contentModal(url, code);

    const CONFIG = {
        title: 'Notification',
        content: (
            <Flex vertical>
                <Typography.Title level={4}>{content.title}</Typography.Title>
                {content.description}
            </Flex>
        ),
        className: 'modal-warning',
        okText: 'Upgrade',
        cancelText: 'Close',
        centered: true,
        closable: true,
        getContainer: () => document.querySelector('#app-layout'),
        onOk: () => {
            window.location.href = '/settings/billing';
        }
    };

    return CONFIG;
};

export const INFO_MODAL_SETTINGS = (url, code) => {
    const content = contentModal(url, code);

    const CONFIG = {
        title: 'Notification',
        content: (
            <Flex vertical>
                <Typography.Title level={4}>{content.title}</Typography.Title>
                {content.description}
            </Flex>
        ),
        className: 'modal-warning',
        okText: 'OK',
        cancelText: 'Close',
        centered: true,
        closable: true,
        getContainer: () => document.querySelector('#app-layout')
    };

    return CONFIG;
};
