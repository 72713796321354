import React, { useState } from 'react';
import { notification } from 'antd';
import { BACKEND_URL } from '../../config';
import { useLocalStorage } from '../../hooks/storage';
import { axios } from '../../services/http/axios';
import { onOauthPopupSuccess } from '../../utils/oauth';

export const useAuthHook = () => {
    const [isIdentifying, setIsIdentifying] = useState(true);

    const [resetPwdToken, setResetPwdToken] = useState('');

    const [authToken, setAuthToken] = useLocalStorage('BrainstormAuthToken');

    const [profile, setProfile] = useState(null);

    const whoami = async () => {
        try {
            const response = await axios.get('/auth/whoami');

            setProfile(() => response.data.profile);
        } catch (_) {
            setAuthToken('');
            setProfile(null);
        }

        setIsIdentifying(false);
    };

    const signin = async (values) => {
        const response = await axios.post('/auth/signin', values);

        setAuthToken(() => response.data.token);
        setProfile(() => response.data.profile);
    };

    const signup = async (values) => {
        const response = await axios.post('/auth/signup', values);

        setAuthToken(() => response.data.token);
        setProfile(() => response.data.profile);
    };

    const logout = async () => {
        setAuthToken('');
        setProfile(null);
    };

    const forgot = async (values) => {
        await axios.post('/auth/password/forgot', values);
    };

    const reset = async (values) => {
        await axios.post('/auth/password/reset?token=' + resetPwdToken, values);
    };

    const verify = async () => {
        const params = new URLSearchParams(window.location.search);

        const token = params.get('token');

        if (!token) {
            throw new Error('Invalid token');
        }

        await axios.get('/auth/password/verify?token=' + token);

        setResetPwdToken(() => token);
    };

    const oAuthSignup = async (
        provider,
        options = {
            isMobile: false
        }
    ) => {
        let popup;

        try {
            const w = options.isMobile ? screen.availWidth : screen.availWidth / 2;

            const h = screen.availHeight;

            const y = screen.availWidth;

            const x = options.isMobile ? w : w / 2;

            const params = new URLSearchParams(window.location.search);

            popup = window.open(
                BACKEND_URL + `/auth/${provider}/redirect?ref=` + params.get('ref') || '',
                'Google Auth',
                `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`
            );

            const response = await onOauthPopupSuccess();

            setAuthToken(() => response.token);
            setProfile(() => response.profile);

            popup?.close();
            popup = null;

            return true;
        } catch (error) {
            notification.error({
                placement: 'top',
                message: 'Authorization failed',
                description: 'Something went wrong, please try again later'
            });

            popup?.close();
            popup = null;

            return false;
        }
    };

    const addToolToFavorite = async (toolId) => {
        if (profile?.favoriteTools.includes(toolId)) {
            return;
        }

        await axios.post(`/tools/${toolId}/favorites`);

        setProfile((prev) => ({
            ...prev,
            favoriteTools: [...prev.favoriteTools, toolId]
        }));
    };

    const removeToolFromFavorite = async (toolId) => {
        if (!profile?.favoriteTools.includes(toolId)) {
            return;
        }

        await axios.delete(`/tools/${toolId}/favorites`);

        setProfile((prev) => ({
            ...prev,
            favoriteTools: prev.favoriteTools.filter((id) => id !== toolId)
        }));
    };

    const toggleFavorites = async (toolId) => {
        if (!profile) return;

        return profile.favoriteTools.includes(toolId) ? removeToolFromFavorite(toolId) : addToolToFavorite(toolId);
    };

    const addArtToFavorite = async (artId) => {
        if (profile?.favoriteArt.includes(artId)) {
            return;
        }

        await axios.post(`/stability/art/${artId}/favorites`);

        setProfile((prev) => ({
            ...prev,
            favoriteArt: [...prev.favoriteArt, artId]
        }));
    };

    const removeArtFromFavorite = async (artId) => {
        if (!profile?.favoriteArt.includes(artId)) {
            return;
        }

        await axios.delete(`/stability/art/${artId}/favorites`);

        setProfile((prev) => ({
            ...prev,
            favoriteArt: prev.favoriteArt.filter((id) => id !== artId)
        }));
    };

    const toggleFavoritesArt = async (artId) => {
        if (!profile) return;

        return profile.favoriteArt.includes(artId) ? removeArtFromFavorite(artId) : addArtToFavorite(artId);
    };

    const updateProfile = async (values) => {
        await axios.put('/user/profile', values);

        setProfile((prev) => ({
            ...prev,
            ...values
        }));
    };

    const resendVerification = async () => {
        const response = await axios.post('/user/profile/verify', {});

        setProfile((prev) => ({
            ...prev,
            emailVerificationSentAt: response.data.emailVerificationSentAt
        }));
    };

    const inviteToOrg = async (userOrgId, values) => {
        const response = await axios.post(`/org/${userOrgId}/invite`, values);

        return response.data;
    };

    const joinToOrg = async (userOrgId, userId) => {
        const response = await axios.get(`/org/${userOrgId}/user/${userId}/join`);

        return response.data;
    };

    const switchOrg = async (userOrgId) => {
        await axios.get(`/org/${userOrgId}/switch`);
    };

    const deleteFromOrg = async (userOrgId, userId) => {
        const response = await axios.delete(`/org/${userOrgId}/user/${userId}/delete`);

        return response.data;
    };

    return {
        oAuthSignup,
        signin,
        signup,
        logout,
        forgot,
        reset,
        verify: React.useCallback(verify, []),
        whoami: React.useCallback(whoami, [authToken, setAuthToken]),
        setResetPwdToken,
        setAuthToken,
        setProfile,
        resetPwdToken,
        authToken,
        profile,
        updateProfile,
        resendVerification,
        isIdentifying,
        tools: {
            favorite: {
                toggle: toggleFavorites
            }
        },
        art: {
            favorite: {
                toggle: toggleFavoritesArt
            }
        },
        org: {
            getCurrent: () => profile?.organizations?.find(({ org }) => org === profile?.org),
            invite: inviteToOrg,
            join: joinToOrg,
            switch: switchOrg,
            delete: deleteFromOrg
        }
    };
};
