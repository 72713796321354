export const stripeErrorsMap = {
    succeeded: {
        message: 'Paid',
        description: 'Your payment was successful. Thank you!'
    },
    refunded: {
        message: 'Refunded',
        description: 'Your payment was refunded. Please contact us for more information.'
    },
    partially_refunded: {
        message: 'Refunded (partially)',
        description: 'Your payment was partially refunded. Please contact us for more information.'
    },
    failed: {
        message: 'Failed',
        description: 'We are sorry, there was an error processing your payment. Please try again.'
    },
    pending: {
        message: 'Processing',
        description: 'We are processing your payment. Please wait a few seconds.'
    }
};
