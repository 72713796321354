export const logos = {
    _id: 3,
    title: 'Logos',
    alias: 'logos',
    about: 'Custom Logos',
    image: 'logos',
    categories: ['logos'],
    tips: [],
    prompts: [
        {
            text: 'Design a minimalist logo for [Company Name], combining [a simple shape or letter] with a restrained color palette to reflect the brand’s professional and sleek identity.',
            image: 'minimalist-logo'
        },
        {
            text: 'Generate a playful and creative logo for [Company Name]. Incorporate whimsical elements like a smiling sun or a winding path, highlighting the fun and unique personality of a children’s company. Use bright, vibrant colors like yellow, green, and blue, and imaginative typography to create a logo that is both engaging and memorable.',
            image: 'creative-logo'
        },
        {
            text: 'Design a luxury and elegant logo featuring the initials [Initials] for [Company Name]. The design should be sophisticated and high-end, using gold lettering on a black background. Incorporate subtle elements of velvet texture or sleek, stylized lines to enhance the opulent feel. The gold should be a rich, warm tone to stand out against the deep black, creating an air of exclusivity and luxury suitable for a high-end fashion boutique.',
            image: 'elegant-logo'
        }
    ]
};
