const config = {
    _id: 71,
    title: 'Write Product Launch Emails',
    alias: 'write-product-launch-emails',
    about: 'Generate email headlines that pop or body copy that sizzles, With its ability to understand and interpret your brand tone, Just Think can help you create emails that are not only informative, but also entertaining and memorable.',
    categories: ['Marketing'],
    tips: [
        'Define the target audience: Clearly define who you want to target with the product launch email. This will help you tailor your message to the right people and ensure that it is relevant and interesting to them.',
        'Highlight the benefits: Focus on the benefits that the product provides and how it can improve the lives of the target audience. This will help to build excitement and create a sense of urgency around the launch.'
    ],
    prompts: [
        'What is the best way to create excitement and generate interest for [product/service] in a product launch email?',
        'Write me a launch email that grabs the attention of potential customers for [product/service].',
        'Write me a launch email that highlights the key features and benefits of [product/benefits].',
        'Write me a launch email that creates excitement and generates interest for [product/service].',
        'Write me a launch email with all the key elements included to ensure success in generating sales for [product/service].'
    ]
};

export default config;
