import { useImmerReducer } from 'use-immer';
import { artTemplatesReducer, initState } from './reducer';
import api from './service';

export const useArtTemplates = () => {
    const [state, dispatch] = useImmerReducer(artTemplatesReducer, initState);
    const promptsView = () => {
        dispatch({
            type: 'PROMPTS_VIEW'
        });
    };
    const tipsView = () => {
        dispatch({
            type: 'TIPS_VIEW'
        });
    };

    const template = {
        create: async (payload) => {
            dispatch({
                type: 'MODAL_LOADING',
                payload: true
            });

            try {
                const data = await api.templateService.create(payload);

                dispatch({
                    type: 'TEMPLATE_CREATE_SUCCESS',
                    payload: data
                });
            } catch (err) {
                dispatch({
                    type: 'TEMPLATE_CREATE_FAILED',
                    payload: err
                });
                throw err;
            }
        },
        list: async () => {
            dispatch({
                type: 'TEMPLATE_LOADING',
                payload: true
            });

            try {
                const data = await api.templateService.list();

                dispatch({
                    type: 'TEMPLATE_LIST_SUCCESS',
                    payload: data
                });
            } catch (err) {
                dispatch({
                    type: 'TEMPLATE_LIST_FAILED',
                    payload: err
                });
                throw err;
            }
        },
        update: async (payload) => {
            dispatch({
                type: 'MODAL_LOADING',
                payload: true
            });

            try {
                const data = await api.templateService.update(payload);

                dispatch({
                    type: 'PROMPT_СREATE_SUCCESS',
                    payload: data
                });
            } catch (err) {
                dispatch({
                    type: 'PROMPT_СREATE_FAILED',
                    payload: err
                });
                throw err;
            }
        },
        remove: async (payload) => {
            dispatch({
                type: 'TEMPLATE_LOADING',
                payload: true
            });

            const queryParams = new URLSearchParams();
            if (payload.promptId) queryParams.set('promptId', payload.promptId);
            if (payload.tipId) queryParams.set('tipId', payload.tipId);

            try {
                await api.templateService.remove({ id: payload.id, query: queryParams });

                dispatch({
                    type: 'PROMPT_DELETE_SUCCESS'
                });
            } catch (err) {
                dispatch({
                    type: 'PROMPT_DELETE_FAILED',
                    payload: err
                });
                throw err;
            }
        }
    };

    const modals = {
        open: (payload) => {
            dispatch({
                type: 'MODAL_OPEN',
                payload
            });
        },
        close: (payload) => {
            dispatch({
                type: 'MODAL_CLOSE',
                payload
            });
        }
    };

    const actions = {
        modals,
        template,
        promptsView,
        tipsView
    };

    return {
        state,
        actions
    };
};
