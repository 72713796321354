import React from 'react';
import { Typography } from 'antd';
import styles from './BookSubTitle.module.scss';

export default function BookSubTitle({ subTitle }) {
    return (
        <Typography.Paragraph style={{ marginBottom: '0px' }} className={styles.book__subtitle}>
            {subTitle}
        </Typography.Paragraph>
    );
}
