import React from 'react';
import { Button, Modal, Space } from 'antd';

export const ReportModal = ({ isModalOpen, setIsModalOpen, setReportedImage }) => {
    return (
        <Modal
            title={<div style={{ fontSize: 24 }}>Report Image</div>}
            open={isModalOpen}
            centered
            onCancel={() => {
                setIsModalOpen(() => false);
            }}
            footer={[]}
        >
            <h3>If this image contains sensitive or incorrect content, please report it so our team can review.</h3>
            <br />
            <h3>For more detail, view our content policy.</h3>
            <br />
            <Space className='report-btns-wrap' direction='vertical' style={{ width: '100%' }} size='middle'>
                <Button
                    type='primary'
                    size='large'
                    block
                    onClick={() => {
                        // TODO: report mechanism
                        setIsModalOpen(false);
                        setReportedImage(null);
                    }}
                >
                    Contains sensitive/biased content
                </Button>
                <Button
                    type='primary'
                    size='large'
                    block
                    onClick={() => {
                        // TODO: report mechanism
                        setIsModalOpen(false);
                        setReportedImage(null);
                    }}
                >
                    Doesn&apos;t match my text description
                </Button>
            </Space>
            <Space direction='vertical' style={{ width: '100%', marginTop: '50px' }}>
                <Button
                    size='large'
                    block
                    onClick={() => {
                        setIsModalOpen(() => false);
                        setReportedImage(() => null);
                    }}
                >
                    Cancel
                </Button>
            </Space>
        </Modal>
    );
};
