const config = {
    _id: 85,
    title: 'Create FAQs',
    alias: 'create-faqs',
    about: 'Create a comprehensive FAQ section for your website or customer service portal. With its advanced language processing abilities, Just Think can help you anticipate customer questions and provide clear and concise answers.',
    categories: ['Website & SEO'],
    tips: [
        'Clearly define the purpose of the FAQs: Before starting to create the FAQs, it is important to have a clear understanding of the purpose of the FAQs. This will help you to focus on the most relevant questions that customers are likely to ask and to tailor the language and tone of the FAQs accordingly.',
        'Use simple and straightforward language: When writing the FAQs, use simple and straightforward language that is easy for customers to understand. Avoid using technical jargon or complex language that may be difficult for customers to interpret.'
    ],
    prompts: [
        'I want to create an FAQ section for my [business/website/product/service]. Can you help me come up with a list of frequently asked questions and answers that will provide helpful information for my customers?',
        'I want to improve the user experience on my website by creating an FAQ section for [business/website/product/service]. Can you help me come up with a list of frequently asked questions and answers that will be easily accessible and provide quick solutions for my customers?',
        'I want to create an FAQ brochure for [industry], Can you help me write answers that will help our customers better understand our product and services',
        'I want to create a FAQ section that addresses common misconceptions about [topic]. Can you help me write answers that provide accurate information and clear up any confusion based on this info [info]?'
    ]
};

export default config;
