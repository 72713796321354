import React from 'react';
import { Link } from 'react-router-dom';
import { App, Button, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import { EMAIL_VALIDATION_RULES } from '../../../../utils/validation';
import { useAuthContext } from '../../context';
import styles from './index.module.scss';

export default function Verify({ history }) {
    const [loading, setLoading] = React.useState(false);

    const [form] = Form.useForm();

    const auth = useAuthContext();

    const { notification } = App.useApp();

    const onFinish = async (values) => {
        setLoading(() => true);

        try {
            await auth.verify(values);
            setLoading(() => false);
            history.push('/');
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
            setLoading(() => false);
        }
    };

    return (
        <Form className={styles.verify} form={form} onFinish={onFinish} layout='vertical'>
            <Form.Item name='email' label='Email' rules={EMAIL_VALIDATION_RULES}>
                <Input placeholder='youremail@gmail.com' />
            </Form.Item>
            <Form.Item>
                <Button block type='primary' htmlType='submit' loading={loading}>
                    Reset password
                </Button>
            </Form.Item>
            <Form.Item className={styles.verify__links}>
                Already have an account? <Link to='/auth/login'>Login here.</Link>
            </Form.Item>
        </Form>
    );
}

Verify.propTypes = {
    history: PropTypes.object.isRequired
};
