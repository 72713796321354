import { useEffect, useState } from 'react';

function getStorageValue(key) {
    try {
        return localStorage.getItem(key);
    } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
    }

    return null;
}

export const useLocalStorage = (key) => {
    const [value, setValue] = useState(() => {
        return getStorageValue(key);
    });

    useEffect(() => {
        localStorage.setItem(key, value);
    }, [key, value]);

    return [value, setValue];
};
