/* eslint-disable */

const config = {
    _id: 116,
    title: 'Topic Generator',
    alias: 'topic-generator',
    about: 'This AI tool helps you come up with new and interesting topics to explore in your educational content or blog posts.',
    categories: ['Blog Content'],
    tips: [
        'Be specific about the area or subject you want topic suggestions for.',
        'Consider the interests and needs of your target audience when choosing topics.',
        'Use the AI\'s suggestions as a starting point and feel free to tweak them to better suit your content style and goals.'
    ],
    prompts: [
        'Suggest [number] blog post topics related to [specific subject].',
        'Generate a list of educational content topics for [grade level].',
        'What are some trending topics in the [specific field]?',
        'Propose [number] unique webinar themes based on current education trends.',
        'What are some engaging discussion topics for my next class?'
    ]
};

export default config;
