import React from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, List, Typography } from 'antd';
import SuccessCheckIcon from '../../../../../../components/Icons/SuccessCheckIcon/SuccessCheckIcon';
import { useBreakpoint } from '../../../../../../hooks/media';
import { checkList } from '../../constants';
import styles from './CheckList.module.scss';

export default function CheckList() {
    const { isMobile, isTable } = useBreakpoint();

    return (
        <div className={styles.checklist_page}>
            <Typography.Paragraph className={styles.checklist_description}>
                Upload a drawing of ONE character, where the arms and legs don’t overlap the body <br /> (See examples
                below).
            </Typography.Paragraph>
            <div className={styles.checklist_wrap}>
                <Typography.Paragraph className={styles.checklist_title}>Checklist</Typography.Paragraph>
                <List
                    className={styles.checklist}
                    itemLayout='horizontal'
                    dataSource={checkList}
                    renderItem={(item) => (
                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Button
                                        className={styles.checklist_btn}
                                        icon={isMobile || isTable ? <SuccessCheckIcon /> : <CheckCircleOutlined />}
                                    />
                                }
                                title={item.title}
                            />
                        </List.Item>
                    )}
                />
            </div>
        </div>
    );
}
