const config = {
    _id: 69,
    title: 'Create An Offer Letter',
    alias: 'create-an-offer-letter',
    about: 'Automate offer letter creation, using its language generation capabilities to create personalized, professional, and error-free letters in a fraction of the time',
    categories: ['Marketing'],
    tips: [
        'Be clear and specific: Clearly state what you are looking for, and provide as much relevant information as possible.'
    ],
    prompts: [
        'Please create an offer letter for a [position title] role with a salary of [salary amount] and start date of [start date]. The benefits include [list of benefits]. The job location is [location]. Also, include a brief company overview that does [company details].',
        'Can you draft an offer letter for a [position title] role with a salary of [salary amount] and start date of [start date]. The position is based in [location]. The letter should include details about [bonus/commission structure], plan, and [other benefits and perks] offered to employees.',
        'Draft an offer letter for a [position title] role with a [salary amount] salary and a start date of [start date]. The position is located in [location]. The letter should include information about the [training and development opportunities], [work schedule], and [other benefits and perks].',
        'Please create an offer letter for the [position title] role with a salary of [salary amount] and start date of [start date]. The benefits include [list of benefits]. The job location is [location]. Also, include a brief company overview.'
    ]
};

export default config;
