import React from 'react';
import { ArrowRightOutlined, CreditCardOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Popconfirm, Radio, Row, Space, Spin, Typography } from 'antd';
import AmericanExpress from '../../../../../../../../../../components/Icons/AmericanExpress/AmericanExpress';
import CardChip from '../../../../../../../../../../components/Icons/CardChip/CardChip';
import CardChipBg from '../../../../../../../../../../components/Icons/CardChip/CardChipBg';
import Jcb from '../../../../../../../../../../components/Icons/JCB/JCB';
import MasterCard from '../../../../../../../../../../components/Icons/MasterCard/MasterCard';
import VisaIcon from '../../../../../../../../../../components/Icons/VisaIcon/VisaIcon';
import { useBreakpoint } from '../../../../../../../../../../hooks/media';
import { uuid } from '../../../../../../../../../../utils/crypto';
import './CreditCards.scss';

const cardBrandIcons = {
    mastercard: <MasterCard />,
    visa: <VisaIcon />,
    amex: <AmericanExpress />,
    jcb: <Jcb />
};

export default function CreditCards({ form, billing, org }) {
    const { notification } = App.useApp();

    const { isMobile } = useBreakpoint();

    const onChangeCard = async (paymentMethod) => {
        await billing.actions.card.change(org, paymentMethod);
    };

    if (!billing.state.cards.length) {
        return (
            <div className='credit-cards'>
                <Row className='credit-cards__title'>
                    <Col>
                        <CreditCardOutlined />
                        <Typography.Title level={4}>Manage Cards</Typography.Title>
                        <ArrowRightOutlined />
                    </Col>
                </Row>
                <div className='credit-card__list'>
                    <div className='credit-card'>
                        <Row justify='center' align='middle' style={{ height: '100%' }}>
                            <Col style={{ textAlign: 'center' }}>
                                {billing.state.loader.setupIntent ? (
                                    <Spin spinning />
                                ) : (
                                    <PlusOutlined
                                        onClick={async () => {
                                            await billing.actions.intent.setup(org);
                                            billing.actions.modal.open('addCard');
                                        }}
                                        style={{
                                            fontSize: '30px',
                                            width: 'fit-content',
                                            color: '#eee',
                                            borderRadius: '4px',
                                            background:
                                                'radial-gradient(192.53% 172.33% at 0% 0%, rgb(0, 138, 101) 38.54%, rgb(29, 193, 152) 72.4%, rgb(29, 193, 152) 99.48%)',
                                            padding: '10px',
                                            cursor: 'pointer',
                                            margin: '0 auto 10px auto'
                                        }}
                                    />
                                )}
                                <div>
                                    <b>Add new card</b>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className='credit-cards'>
            <Row className='credit-cards__title'>
                <Col>
                    <CreditCardOutlined />
                    <Typography.Title level={4}>Manage Cards</Typography.Title>
                    <ArrowRightOutlined />
                </Col>
            </Row>
            <div className='credit-cards__list'>
                <Spin spinning={billing.state.loader.cards}>
                    <Form.Item name='card'>
                        <Radio.Group>
                            {billing.state.cards?.map((card) => {
                                return (
                                    <Radio
                                        onChange={() => onChangeCard(card.id)}
                                        key={uuid()}
                                        value={card.id}
                                        className='credit-card-radio'
                                    >
                                        <div className='credit-card added'>
                                            <div className='credit-card-header'>
                                                <CardChipBg className='credit-card-header__chip-bg' />
                                                <Space.Compact
                                                    className='credit-card-header__delete'
                                                    align='baseline'
                                                    block
                                                >
                                                    <Popconfirm
                                                        placement={isMobile ? 'top' : 'left'}
                                                        title='Are you sure you want to delete this card?'
                                                        okText='Yes'
                                                        cancelText='No'
                                                        onConfirm={async () => {
                                                            await billing.actions.card.detach(org, card.id, form);

                                                            notification.success({
                                                                placement: 'top',
                                                                message: 'Card removed successfully.'
                                                            });
                                                        }}
                                                    >
                                                        <DeleteOutlined />
                                                    </Popconfirm>
                                                </Space.Compact>
                                                <Row justify='space-between'>
                                                    <Col span={12}>
                                                        <CardChip className='credit-card-header__chip' />
                                                    </Col>
                                                    <Col span={12}>
                                                        <Space.Compact
                                                            align='baseline'
                                                            block
                                                            style={{ justifyContent: 'flex-end' }}
                                                        >
                                                            {cardBrandIcons[card.brand] || null}
                                                        </Space.Compact>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='credit-card-content'>
                                                <Row>
                                                    <Col span={24}>
                                                        <div className='credit-card-exp'>
                                                            <span>
                                                                Ex {card.expMonth}/
                                                                {card.expYear?.toString().substring(2, 4)}
                                                            </span>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <div className='credit-card-footer'>**** **** **** {card?.last4}</div>
                                        </div>
                                    </Radio>
                                );
                            })}
                        </Radio.Group>
                    </Form.Item>
                </Spin>
            </div>
            <Button
                onClick={async () => {
                    billing.actions.modal.open('addCard');
                    await billing.actions.intent.setup(org);
                }}
                className='credit-card-button'
            >
                Add payment method
            </Button>
        </div>
    );
}
