import React from 'react';

export default function UploadClip() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='25' height='29' fill='none' viewBox='0 0 25 29'>
            <path
                fill='#000'
                d='M17.888 0c-1.827 0-3.655.75-5.052 2.11l-9.96 9.722c-3.834 3.825-3.834 10.01 0 13.87 3.833 3.826 10.032 3.826 13.901 0l4.479-4.468-2.472-2.466-4.157 4.04-.322.464a6.346 6.346 0 01-8.957 0c-2.437-2.431-2.365-6.363 0-8.83l9.96-9.83c1.398-1.395 3.726-1.395 5.16 0 1.397 1.394 1.325 3.61 0 5.04l-8.958 8.83c-.358.357-.967.357-1.361 0-.359-.358-.359-.966 0-1.359l.215-.107 3.26-3.36-2.472-2.467-3.476 3.468c-1.72 1.715-1.72 4.54 0 6.256 1.72 1.715 4.55 1.751 6.27 0l8.958-8.723c2.795-2.788 2.795-7.293 0-10.045C21.507.75 19.715.035 17.852.035L17.888 0z'
            />
        </svg>
    );
}
