import React from 'react';
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';

export const PopoverContent = ({ onRename, onDownload, onShare, onRemove }) => {
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Button style={{ background: 0, fontWeight: 500, border: '0' }} onClick={onRename}>
                <Space align='center'>
                    <EditOutlined style={{ fontSize: '20px' }} />
                    Rename
                </Space>
            </Button>
            <br />
            <Button style={{ background: 0, fontWeight: 500, border: '0' }} onClick={onDownload}>
                <Space align='center'>
                    <DownloadOutlined style={{ fontSize: '20px' }} />
                    Download
                </Space>
            </Button>
            <br />
            <Button style={{ background: 0, fontWeight: 500, border: '0' }} onClick={onRemove}>
                <Space align='center'>
                    <DeleteOutlined style={{ fontSize: '20px' }} />
                    Delete
                </Space>
            </Button>
        </div>
    );
};
