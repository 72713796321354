import React from 'react';
import { Col, Modal, notification, Row, Spin, Typography } from 'antd';
import { planConfig } from '../../../../../../../../../hooks/planConfig';
import { useAuthContext } from '../../../../../../../../AuthPage/context';
import styles from './index.module.scss';

export default function BillingDowngrade({ org, form, billing }) {
    const auth = useAuthContext();
    const getPlanConfig = planConfig(auth?.profile?.userProf);

    const onModalClose = () => {
        billing.actions.modal.close('billingDowngrade');
    };

    const formValues = form.getFieldsValue();

    const onDowngrade = async () => {
        try {
            await billing.actions.plan.update(org, {
                ...getPlanConfig.initialValues[formValues?.type],
                period: formValues.period
            });

            notification.success({
                placement: 'top',
                message: 'Payment successful',
                description: 'Your payment has been processed successfully'
            });

            await billing.actions.fetch(org);
            await auth.whoami();
            await billing.actions.modal.close('billingDowngrade');
        } catch (error) {
            notification.error({
                placement: 'top',
                message: 'Payment failed',
                description:
                    error.message ||
                    error.response?.data?.message ||
                    'Something went wrong while processing your payment'
            });
        }
    };

    return (
        <Modal
            className={styles.billing__upgrade}
            centered
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            open={billing.state.activeModals.billingDowngrade}
            onCancel={onModalClose}
            footer={false}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Row>
                <Spin spinning={billing.state.loader.updatingPlan}>
                    <Col className={styles.billing__upgrade__right}>
                        <Row justify='flex-start' gutter={15} style={{ marginBottom: '20px' }}>
                            <Col>
                                <Typography.Title level={2}>
                                    Downgrade to <span style={{ textTransform: 'capitalize' }}>{formValues?.type}</span>
                                </Typography.Title>
                            </Col>
                        </Row>
                        <Typography.Text className={styles.billing__upgrade__right__list}>
                            Are you sure you want to Downgrade to {formValues?.type}?
                        </Typography.Text>
                        <Typography.Text style={{ marginBottom: '40px' }}>
                            Your features from previous plan will be lost.
                        </Typography.Text>
                        <React.Fragment>
                            <button
                                disabled={billing.state.loader.updatingPlan}
                                style={{ width: '100%' }}
                                className='stripe-submit-btn'
                                id='submit'
                                onClick={onDowngrade}
                            >
                                <span id='button-text'>Downgrade</span>
                            </button>
                            <div className='stripe-agreements'>
                                By continuing you agree to the{' '}
                                <a target='_blank' href='https://www.justthink.ai/terms-of-service' rel='noreferrer'>
                                    Terms of Use
                                </a>{' '}
                                applicable to Just Think AI and confirm you have read our{' '}
                                <a target='_blank' href='www.justthink.ai/privacy-policy'>
                                    Privacy policy
                                </a>
                                .
                            </div>
                        </React.Fragment>
                    </Col>
                </Spin>
            </Row>
        </Modal>
    );
}
