import React, { useEffect, useState } from 'react';
import { LinkAuthenticationElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useAuthContext } from '../../../page/AuthPage/context';

export default function StripeCheckoutForm(props) {
    const stripe = useStripe();

    const auth = useAuthContext();

    const elements = useElements();

    const [message, setMessage] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);

        if (!stripe || !elements) {
            return;
        }

        const response = await stripe.confirmSetup({
            elements,
            redirect: 'if_required',
            confirmParams: {
                return_url: window.location.origin + '/settings/billing'
            }
        });

        if (typeof props.setLoader === 'function') {
            await props.setLoader(true);
        }

        if (response?.error) {
            setMessage(response?.error.message);
        } else {
            if (response?.setupIntent) {
                if (typeof props.onSetupSuccess === 'function') {
                    await props.onSetupSuccess(response?.setupIntent);
                }
            }
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: 'tabs'
    };

    return (
        <form id='payment-form' onSubmit={handleSubmit}>
            <LinkAuthenticationElement
                id='link-authentication-element'
                options={{
                    defaultValues: {
                        email: auth.profile.email
                    }
                }}
            />
            <PaymentElement id='payment-element' options={paymentElementOptions} />
            {props.paymentValue}
            <button
                style={{ width: '100%' }}
                className='stripe-submit-btn'
                disabled={isLoading || !stripe || !elements}
                id='submit'
            >
                <span id='button-text'>{isLoading ? <div className='spinner' id='spinner' /> : 'Submit'}</span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id='payment-message'>{message}</div>}
            <div className='stripe-agreements'>
                By continuing you agree to the{' '}
                <a target='_blank' href='https://www.justthink.ai/terms-of-service' rel='noreferrer'>
                    Terms of Use
                </a>{' '}
                applicable to Just Think AI and confirm you have read our{' '}
                <a target='_blank' href='www.justthink.ai/privacy-policy'>
                    Privacy policy
                </a>
                .
            </div>
        </form>
    );
}
