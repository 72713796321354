import React from 'react';
import { Modal, Table, Tooltip } from 'antd';
import { uuid } from '../../../../../../../../../utils/crypto';
import { dateFormats, dateWithLocalTimezone } from '../../../../../../../../../utils/date';
import { stripeErrorsMap } from '../../../constants';

export default function PaymentHistoryModal({ billing }) {
    return (
        <Modal
            centered
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            title='Payment history'
            open={billing.state.activeModals.paymentHistory}
            onCancel={() => billing.actions.modal.close('paymentHistory')}
        >
            <Table
                className='ant-table--centered'
                rowKey={() => uuid()}
                columns={[
                    {
                        title: 'Date',
                        dataIndex: 'date',
                        key: 'date',
                        width: 150,
                        render: (date) => dateWithLocalTimezone(date, dateFormats.LLL)
                    },
                    {
                        title: 'Amount',
                        dataIndex: 'amount',
                        key: 'amount',
                        width: 100
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status',
                        width: 150,
                        render: (status) => {
                            return (
                                <Tooltip title={stripeErrorsMap[status]?.description || ''}>
                                    {stripeErrorsMap[status]?.message || 'Unknown'}
                                </Tooltip>
                            );
                        }
                    }
                ]}
                dataSource={billing.state.payments}
                pagination={{
                    pageSize: 5,
                    size: 'small',
                    simple: true,
                    position: ['topRight']
                }}
            />
        </Modal>
    );
}
