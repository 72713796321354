import React, { useEffect } from 'react';
import { Button, Form, Input, Popover } from 'antd';
import { uuid } from '../../../../../../../../utils/crypto';

export default function EasyInputForm({ openModal, setVisible, onCreate, arrayWords }) {
    const [form] = Form.useForm();

    useEffect(() => {
        if (openModal) {
            setVisible(true);
        }
    }, [openModal, setVisible]);

    const onFinish = async (values) => {
        await form.validateFields();
        const data = form.getFieldsValue();
        form.resetFields();
        onCreate(data);
        setVisible(false);
    };

    const content = (
        <Form form={form} layout='vertical' onFinish={onFinish}>
            {arrayWords.map((word) => (
                <Form.Item key={uuid()} label={word} name={`[${word}]`}>
                    <Input />
                </Form.Item>
            ))}
            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}>
                <Button onClick={() => setVisible(false)}>Cancel</Button>
                <Button type='primary' htmlType='submit'>
                    Update Prompt
                </Button>
            </div>
        </Form>
    );

    return (
        <Popover
            content={content}
            title='Input Prompt Details'
            trigger='click'
            open={openModal}
            overlayClassName='art-page__search__popover'
            placement='topLeft'
            align={{
                offset: [0, -75]
            }}
            getPopupContainer={() => document.querySelector('.chat-input-container')}
        />
    );
}
