import { useEffect, useState } from 'react';
import * as throttle from 'lodash/throttle';

const getDeviceConfig = (width) => {
    if (width < 320) {
        return 'xs';
    } else if (width >= 320 && width < 720) {
        return 'sm';
    } else if (width >= 720 && width < 1024) {
        return 'md';
    } else if (width >= 1024 && width < 1280) {
        return 'lg';
    } else if (width >= 1280 && width < 1440) {
        return 'xl';
    } else if (width >= 1440) {
        return 'xxl';
    }
};

export const useBreakpoint = () => {
    const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

    useEffect(() => {
        const calcInnerWidth = throttle(function () {
            setBrkPnt(getDeviceConfig(window.innerWidth));
        }, 200);

        window.addEventListener('resize', calcInnerWidth);

        return () => window.removeEventListener('resize', calcInnerWidth);
    }, []);

    return {
        brkPnt,
        isMobile: brkPnt === 'xs' || brkPnt === 'sm',
        isTable: brkPnt === 'md',
        isDesktop: brkPnt === 'lg',
        isDesktopMiddle: brkPnt === 'xl',
        isDesktopLarge: brkPnt === 'xxl'
    };
};
