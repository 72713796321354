import React, { useEffect } from 'react';
import { Flex, Form, Result, Spin } from 'antd';
import PageLoader from '../../../../../../components/PageLoader/PageLoader';
import { useAuthContext } from '../../../../../AuthPage/context';
import BillingHead from './components/BillingHead';
import AddCardModal from './components/Modals/AddCardModal';
import BillingDowngrade from './components/Modals/BillingDowngrade';
import BillingUpdate from './components/Modals/BillingUpdate';
import CancelPlanModal from './components/Modals/CancelPlanModal';
import DiscountsHistoryModal from './components/Modals/DiscountsHistoryModal';
import PaymentHistoryModal from './components/Modals/PaymentHistoryModal';
import UpdatePlanModal from './components/Modals/UpdatePlanModal';
import Payment from './components/Payment';
import PlanSelector from './components/PlanSelector';
import { useBilling } from './hooks';
import './index.scss';

export default function BillingPage() {
    const auth = useAuthContext();

    const org = auth.org.getCurrent();

    const billing = useBilling();

    const [form] = Form.useForm();

    useEffect(() => {
        (async () => {
            await billing.actions.fetch(org);
        })();
    }, []);

    if (!org?.isOwner) {
        return (
            <Result
                status='warning'
                title='You are not alowed to access this page'
                subTitle='Please contact your organization administrator'
            />
        );
    }

    if (!billing.state.loaded) {
        return <PageLoader spinning />;
    }

    return (
        <Form
            form={form}
            initialValues={{
                period: 'annual',
                card: billing.state.intent?.paymentMethod
            }}
        >
            <Flex className='billing' vertical='vertical'>
                <BillingHead billing={billing} />
                <PlanSelector form={form} billing={billing} org={org} auth={auth} />
                <Spin spinning={billing.state.loader.updatingPlan} style={{ maxHeight: 'initial' }}>
                    <Payment form={form} billing={billing} org={org} />
                </Spin>
                {billing.state.activeModals.updatePlan ? (
                    <UpdatePlanModal form={form} billing={billing} org={org} />
                ) : null}
                {billing.state.activeModals.cancelPlan ? <CancelPlanModal billing={billing} org={org} /> : null}
                {billing.state.activeModals.addCard ? (
                    <AddCardModal form={form} org={org} billing={billing} auth={auth} />
                ) : null}
                {billing.state.activeModals.paymentHistory ? <PaymentHistoryModal billing={billing} /> : null}
                {billing.state.activeModals.discountsHistory ? <DiscountsHistoryModal billing={billing} /> : null}
                {billing.state.activeModals.billingUpdate ? (
                    <BillingUpdate org={org} form={form} billing={billing} />
                ) : null}
                {billing.state.activeModals.billingDowngrade ? (
                    <BillingDowngrade org={org} form={form} billing={billing} />
                ) : null}
            </Flex>
        </Form>
    );
}
