import React from 'react';
import { Modal, Table } from 'antd';
import { uuid } from '../../../../../../../../../utils/crypto';
import { dateFormats, dateWithLocalTimezone } from '../../../../../../../../../utils/date';
import { dollarFormatterFunc } from '../../../../../../../../../utils/dollarFormater';
import styles from './index.module.scss';

export default function DiscountsHistoryModal({ billing }) {
    return (
        <Modal
            centered
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            title='Discounts history'
            open={billing.state.activeModals.discountsHistory}
            onCancel={() => billing.actions.modal.close('discountsHistory')}
        >
            <Table
                scroll={{ x: true }}
                rowClassName={(record) => styles[`row--${record.status}`]}
                rowKey={() => uuid()}
                columns={[
                    {
                        title: 'Applied at',
                        dataIndex: 'appliedAt',
                        key: 'appliedAt',
                        render: (date) => {
                            return (
                                <div style={{ width: 'max-content' }}>
                                    {date ? dateWithLocalTimezone(date, dateFormats.LL) : '-'}
                                </div>
                            );
                        }
                    },
                    {
                        title: 'Amount',
                        render: (record) => {
                            if (record.type === 'percent') {
                                return `${record.value}%`;
                            }

                            if (record.type === 'amount') {
                                return dollarFormatterFunc(record.value);
                            }

                            return '';
                        }
                    },
                    {
                        title: 'Status',
                        dataIndex: 'status',
                        key: 'status'
                    },
                    {
                        title: 'Expired at',
                        dataIndex: 'expiredAt',
                        key: 'expiredAt',
                        render: (date) => {
                            return (
                                <div style={{ width: 'max-content' }}>
                                    {date ? dateWithLocalTimezone(date, dateFormats.LL) : '-'}
                                </div>
                            );
                        }
                    }
                ]}
                dataSource={billing.state.discounts}
                pagination={{
                    pageSize: 5,
                    size: 'small',
                    simple: true,
                    position: ['topRight']
                }}
            />
        </Modal>
    );
}
