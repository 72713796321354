import React, { Fragment } from 'react';
import { Button, Col, Form, Radio, Row, Space } from 'antd';
import { uuid } from '../../../../../../utils/crypto';
import { dollarFormatterFunc } from '../../../../../../utils/dollarFormater';
import styles from './index.module.scss';

export const PlanSelect = ({ billingPlan, prices, form, onFinish }) => {
    return (
        <Fragment>
            <Form
                initialValues={{
                    type: 'annual'
                }}
                onFinish={onFinish}
                form={form}
                className={styles.billing__form__radio}
            >
                <Form.Item name='type'>
                    <Radio.Group>
                        <Space direction='vertical'>
                            {billingPlan.map((item) => {
                                const period = Object.keys(item)[0];

                                const value = Object.values(item)[0];

                                return (
                                    <Radio key={uuid()} value={period}>
                                        <Row style={{ width: '100%' }} justify='space-between'>
                                            <Col>
                                                <Row gutter={10} className={styles.billing__radio__price}>
                                                    <Col>{value}</Col>
                                                    {value === 'yearly' ? (
                                                        <Col>
                                                            <span>Best Value - Save $240</span>
                                                        </Col>
                                                    ) : null}
                                                </Row>
                                            </Col>
                                            <Col>
                                                {value === 'yearly'
                                                    ? `${dollarFormatterFunc(prices?.annual)}/mo`
                                                    : `${dollarFormatterFunc(prices?.month)}/mo`}
                                            </Col>
                                        </Row>
                                    </Radio>
                                );
                            })}
                        </Space>
                    </Radio.Group>
                </Form.Item>
                <Form.Item>
                    <Button className={styles.billing__form__btn} htmlType='submit'>
                        Next
                    </Button>
                </Form.Item>
            </Form>
        </Fragment>
    );
};
