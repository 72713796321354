/* eslint-disable */
const config = {
    _id: 77,
    title: 'Write A Benefit-Driven Sales Copy',
    alias: 'write-a-benefit-driven-sales-copy',
    about: 'Craft benefit-driven sales copy that will have people reaching for their wallets faster than you can say "buy now." Think of it as having a personal copywriting genie at your disposal.',
    categories: ['Marketing'],
    tips: [
        'Be specific with the product or service and the target audience. The more information you provide, the better the generated text will be.',
        "Highlight the product/service's track record of success.",
        'Try to use storytelling to illustrate how the product/service has made a difference in other customers\' lives.'
    ],
    prompts: [
        'Can you write a benefit-driven sales copy that highlights the unique features and benefits of [product/service]?',
        'How would you describe the benefits of [product/service] to a potential customer in a way that would convince them to purchase?',
        'Can you craft a benefit-driven sales copy that explains why [product/service] is the best solution for [customer\'s problem or need]?',
        'How would you sell the benefits of [product/service] to a customer who is on the fence about making a purchase?',
        'How would you sell the benefits of [product/service] to a customer who is skeptical about its effectiveness for [customer\'s problem or need]?'
    ]
};

export default config;
