import React, { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { App as AntApp, ConfigProvider } from 'antd';
import FullScreenSpin from './components/FullScreenSpin/FullScreenSpin';
import PrivateRoute from './components/Router/PrivaterRoute';
import AuthPage from './page/AuthPage';
import { useAuthContext } from './page/AuthPage/context';
import DashboardPage from './page/DashboardPage';
import { SingleVideoPage } from './page/DashboardPage/pages/VideoPage/pages/SingleVideoPage';
import { ShareChat } from './page/ShareChat';
import UserSurvey from './page/UserSurveyPage/index';
import ScrollToTop from './scrollToTop';
import { getTheme } from './theme';
import 'highlight.js/styles/atom-one-dark.css';

export default function App() {
    const { whoami, isIdentifying, profile } = useAuthContext();

    const auth = useAuthContext();

    useEffect(() => {
        whoami();
    }, [whoami]);

    if (isIdentifying) {
        return <FullScreenSpin />;
    }

    return (
        <ConfigProvider theme={getTheme(auth)}>
            <AntApp>
                <Router>
                    <ScrollToTop />
                    <Switch>
                        <Route path='/did-video/:id' component={SingleVideoPage} />
                        <Route path='/share/:id' component={ShareChat} />
                        <Route path='/auth' component={AuthPage} />
                        <Route
                            path='/signup-survey'
                            render={() => (profile?.userProf === null ? <UserSurvey /> : <Redirect to='/chat' />)}
                        />
                        <PrivateRoute path='/'>
                            <DashboardPage />
                        </PrivateRoute>
                    </Switch>
                </Router>
            </AntApp>
        </ConfigProvider>
    );
}
