import React, { useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import AnimatedText from '../Messages/AnimatedText';
import './SlowText.scss';

export const SlowText = ({ chunks }) => {
    const removeIdPattern = /\{"_id":"[a-fA-F0-9]{24}"\}/i;
    const ref = useRef(null);

    const t = useRef(null);

    useEffect(() => {
        t.current = new AnimatedText({
            el: ref.current
        });

        return () => t.current.el?.remove();
    }, []);

    useEffect(() => {
        if (!t.current || !chunks.length) return;

        const last = chunks[chunks.length - 1];

        const isDone = last?.includes('[DONE]');

        if (isDone) {
            t.current.pause(true);

            return () => t.current.el?.remove();
        }

        if (chunks.length === 1) {
            t.current.line(last);
        }

        if (chunks.length > 1 && !isDone) {
            t.current.continue(last);
        }
    }, [chunks]);

    return (
        <div className='chat-message-response'>
            <div className='chat-message-streaming' ref={ref} />
            <Markdown remarkPlugins={[remarkGfm]}>
                {ref.current?.innerHTML.replace(removeIdPattern, '').trim()}
            </Markdown>
        </div>
    );
};
