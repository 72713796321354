import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Icon, { ArrowRightOutlined, FileTextOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Input, List, notification, Popover, Space, Spin, Typography } from 'antd';
import moment from 'moment';
import CircleWithDots from '../../../../../../components/Icons/CircleWithDots';
import ThunderBolt from '../../../../../../components/Icons/ThunderBolt';
import { useBreakpoint } from '../../../../../../hooks/media';
import { uuid } from '../../../../../../utils/crypto';
import { formatDate } from '../../../../../../utils/date';
import { Content } from './components/Content';
import './index.scss';
import styles from '../../../TextToSpeechPage/pages/HistoryPage/components/Actions/index.module.scss';

export function ChatConversation({ menuClose, conversation, message, ctrlRef }) {
    const { state, actions } = conversation;

    const [open, setOpen] = useState(false);

    const { isMobile } = useBreakpoint();

    useEffect(() => {
        (async () => {
            await actions.knowledgeBase.list();
        })();
    }, []);

    const onNewChat = () => {
        if (ctrlRef.current) ctrlRef.current.abort();

        message.actions.setUploadFiles({ content: [] });

        actions.create();

        if (isMobile) menuClose();
    };

    const groupedConvos = state.data.list.reduce((acc, row) => {
        const conv = { ...row };

        conv.date = `${formatDate(moment(row.updatedAt))}`;

        acc.push(conv);

        return acc;
    }, []);

    const hide = () => {
        setOpen(false);
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const onNextPage = async () => {
        setTimeout(async () => {
            await conversation.actions.list();
        }, 1000);
    };

    return (
        <div className='chat-convo'>
            <div className='chat-convo-header'>
                <Spin spinning={state.knowledgeBase.loading}>
                    <Space.Compact className='chat-add-btns' direction='vertical' align='center' block>
                        <Button loading={state.loader.create} icon={<PlusOutlined />} onClick={onNewChat}>
                            New Chat
                            <ArrowRightOutlined />
                        </Button>
                        <Button icon={<Icon component={ThunderBolt} />} onClick={() => actions.promptsOpen(true)}>
                            Prompt Library
                            <ArrowRightOutlined />
                        </Button>
                        {state.knowledgeBase?.list?.video?.length || state.knowledgeBase?.list?.document?.length ? (
                            <Button icon={<FileTextOutlined />} onClick={() => actions.knowledgeBase.drawer(true)}>
                                Knowledge Base
                                <ArrowRightOutlined />
                            </Button>
                        ) : null}
                    </Space.Compact>
                </Spin>
            </div>
            <Typography.Title align='center' className='chat-history-title' level={2}>
                History
            </Typography.Title>
            <div id='scrollableDiv' className='chat-history-sidebar'>
                <InfiniteScroll
                    height='inherit'
                    next={onNextPage}
                    dataLength={groupedConvos.length}
                    hasMore={state.data.total > state.data.list?.length}
                    loader={<Spin style={{ display: 'block', height: '30px' }} />}
                >
                    <List
                        className='chat-history-list'
                        dataSource={groupedConvos}
                        renderItem={(row, key) => {
                            const isSelected = row._id === state.current?._id;

                            const onSelect = async (e) => {
                                e.preventDefault();

                                if (isMobile) menuClose();

                                if (isSelected) return;

                                // TODO: abort stream in this case
                                if (message.state.loader.create || message.state.loader.streaming) {
                                    notification.warning({
                                        placement: 'top',
                                        message: 'Please wait',
                                        description: 'You cannot switch conversations at this time.'
                                    });

                                    return;
                                }

                                await actions.update(row._id, {
                                    selected: true
                                });

                                actions.select(row._id);
                            };

                            const onUpdate = (e) => {
                                e.preventDefault();
                                actions.update(row._id, {
                                    title: e.target.value.trim()
                                });
                            };

                            const onEdit = (e) => {
                                e.preventDefault();
                                e.stopPropagation();

                                actions.edit(row._id);
                            };

                            const onRemove = () => {
                                actions.remove(row._id);
                            };

                            return (
                                <List.Item key={uuid()}>
                                    {row.date !== groupedConvos[key - 1]?.date ? (
                                        <div className='chat-history-date'>{row.date}</div>
                                    ) : null}
                                    <div
                                        onClick={onSelect}
                                        className={`chat-history-item ${isSelected ? 'chat-history-item--selected' : ''}`}
                                    >
                                        <div className='chat-history-item__inner'>
                                            {row.editMode ? (
                                                <Input
                                                    size='small'
                                                    autoFocus
                                                    defaultValue={row.title}
                                                    onPressEnter={onUpdate}
                                                    onBlur={onUpdate}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            ) : (
                                                <Typography.Paragraph
                                                    style={{
                                                        padding: 0,
                                                        margin: '0 16px 0 16px',
                                                        width: '100%'
                                                    }}
                                                    ellipsis={{
                                                        rows: 1
                                                    }}
                                                >
                                                    {row.title}
                                                </Typography.Paragraph>
                                            )}
                                        </div>
                                        {isSelected ? (
                                            <div className='chat-history-item__options'>
                                                <Popover
                                                    onOpenChange={handleOpenChange}
                                                    className={styles.actions__popover}
                                                    trigger='click'
                                                    content={
                                                        <Content
                                                            hide={hide}
                                                            onEdit={onEdit}
                                                            message={message}
                                                            state={state}
                                                            onRemove={onRemove}
                                                        />
                                                    }
                                                    open={open}
                                                >
                                                    <Button onClick={(e) => e.stopPropagation()}>
                                                        <CircleWithDots width={isMobile ? '20' : '26'} />
                                                    </Button>
                                                </Popover>
                                            </div>
                                        ) : null}
                                    </div>
                                </List.Item>
                            );
                        }}
                    />
                </InfiniteScroll>
            </div>
        </div>
    );
}
