export function onOauthPopupSuccess() {
    return new Promise((resolve, reject) => {
        function onPopupMessage(e) {
            try {
                if (!e.isTrusted || typeof e.data !== 'string' || e.origin.indexOf(location.hostname) === -1) return;

                const json = JSON.parse(e.data);

                if (!(json.token && json.profile && json.action === 'oauth.success')) {
                    throw new Error('Auth failed');
                }

                window.removeEventListener('message', onPopupMessage);

                return resolve(json);
            } catch (error) {
                window.removeEventListener('message', onPopupMessage);

                return reject(error);
            }
        }

        window.addEventListener('message', onPopupMessage);
    });
}
