import React from 'react';

export default function LockIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='59' height='64' fill='none' viewBox='0 0 59 64'>
            <g filter='url(#filter0_d_5191_2164)'>
                <path
                    fill='url(#paint0_diamond_5191_2164)'
                    d='M52.682 24.64h-4.926V8.96c0-4.949-4.151-8.96-9.273-8.96H20.517c-5.122 0-9.273 4.011-9.273 8.96v15.68H6.318C5.036 24.64 4 25.641 4 26.88v26.88C4 54.999 5.036 56 6.318 56h46.364C53.964 56 55 54.999 55 53.76V26.88c0-1.239-1.036-2.24-2.318-2.24zM16.46 8.96c0-2.163 1.819-3.92 4.057-3.92h17.966c2.239 0 4.057 1.757 4.057 3.92v15.68H16.46V8.96zm33.324 42H9.216V29.68h40.568v21.28z'
                />
            </g>
            <g filter='url(#filter1_d_5191_2164)'>
                <path
                    fill='url(#paint1_diamond_5191_2164)'
                    d='M27.25 41.467v3.938c0 .327.225.595.5.595h2.5c.275 0 .5-.268.5-.595v-3.938c.515-.441.9-1.065 1.1-1.782a4.213 4.213 0 00.006-2.21c-.195-.719-.576-1.346-1.09-1.79C30.255 35.24 29.636 35 29 35s-1.254.24-1.767.684c-.513.445-.894 1.072-1.089 1.791a4.213 4.213 0 00.007 2.21c.199.717.584 1.341 1.1 1.782z'
                />
            </g>
            <defs>
                <filter
                    id='filter0_d_5191_2164'
                    width='57.6'
                    height='63.3'
                    x='0.7'
                    y='0'
                    colorInterpolationFilters='sRGB'
                    filterUnits='userSpaceOnUse'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        result='hardAlpha'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    />
                    <feOffset dy='4' />
                    <feGaussianBlur stdDeviation='1.65' />
                    <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
                    <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5191_2164' />
                    <feBlend in='SourceGraphic' in2='effect1_dropShadow_5191_2164' result='shape' />
                </filter>
                <filter
                    id='filter1_d_5191_2164'
                    width='12.6'
                    height='18.3'
                    x='22.7'
                    y='35'
                    colorInterpolationFilters='sRGB'
                    filterUnits='userSpaceOnUse'
                >
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix
                        in='SourceAlpha'
                        result='hardAlpha'
                        values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
                    />
                    <feOffset dy='4' />
                    <feGaussianBlur stdDeviation='1.65' />
                    <feColorMatrix values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0' />
                    <feBlend in2='BackgroundImageFix' result='effect1_dropShadow_5191_2164' />
                    <feBlend in='SourceGraphic' in2='effect1_dropShadow_5191_2164' result='shape' />
                </filter>
                <radialGradient
                    id='paint0_diamond_5191_2164'
                    cx='0'
                    cy='0'
                    r='1'
                    gradientTransform='matrix(64.6321 96.50655 -108.97306 72.98113 4 0)'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0.385' stopColor='#FF916D' />
                    <stop offset='0.724' stopColor='#ED5E93' />
                    <stop offset='0.995' stopColor='#ED5E93' />
                </radialGradient>
                <radialGradient
                    id='paint1_diamond_5191_2164'
                    cx='0'
                    cy='0'
                    r='1'
                    gradientTransform='matrix(7.60378 18.95666 -15.9968 6.41653 26 35)'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0.385' stopColor='#FF916D' />
                    <stop offset='0.724' stopColor='#ED5E93' />
                    <stop offset='0.995' stopColor='#ED5E93' />
                </radialGradient>
            </defs>
        </svg>
    );
}
