import { Modal } from 'antd';
import Axios from 'axios';
import { BACKEND_URL } from '../../config';
import { INFO_MODAL_SETTINGS, PAYMENT_MODAL_SETTINGS } from './constants';

export const axios = Axios.create({
    baseURL: BACKEND_URL
});
// Request interceptor for API calls
axios.interceptors.request.use(
    (config) => {
        try {
            const token = localStorage.getItem('BrainstormAuthToken');

            if (token) {
                config.headers['x-access-token'] = token;
            }
        } catch (e) {
            // localStorage is not available
        }

        return config;
    },
    (error) => Promise.reject(error)
);
// Response interceptor for API calls
axios.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response.data.code === 'PAYMENT_REQUIRED' || error.response.status === 402) {
            const CONFIG = PAYMENT_MODAL_SETTINGS(error.config.url);

            Modal.warning(CONFIG);
        }

        if (error.response.data.code === 'CONVERSATION_LIMIT' || error.response.status === 403) {
            const CONFIG = INFO_MODAL_SETTINGS(error.config.url, error.response.data.code);

            Modal.warning(CONFIG);
        }

        return Promise.reject(error);
    }
);
