import { axios } from '../../services/http/axios';

export const messageService = {
    list: async (conversationId) => {
        const response = await axios.get(`/chat/conversations/${conversationId}/messages`);

        return response.data;
    }
};

export const conversationService = {
    share: async (shareId) => {
        const response = await axios.get(`/chat/share/${shareId}`);

        return response.data;
    }
};
