export const getResultUrl = async (service, newTalk) => {
    let createdVideo;

    let resultUrl = null;

    while (!resultUrl) {
        createdVideo = await service.talk.get(newTalk._id);
        resultUrl = createdVideo.resultUrl;

        if (!resultUrl) {
            await new Promise((resolve) => setTimeout(resolve, 2000));
        }
    }

    return resultUrl;
};
