import React from 'react';

export default function SaveIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='30' height='22' fill='none' viewBox='0 0 30 22'>
            <path
                fill='url(#paint0_diamond_3926_1947)'
                d='M16.396 0c-4.409 0-8.271 3.133-9.109 7.287C3.279 7.287 0 10.567 0 14.574c0 4.008 3.28 7.287 7.287 7.287h16.396a5.458 5.458 0 005.465-5.465c0-2.369-1.53-4.7-3.643-5.466V9.11C25.505 4.08 21.424 0 16.395 0z'
            />
            <defs>
                <radialGradient
                    id='paint0_diamond_3926_1947'
                    cx='0'
                    cy='0'
                    r='1'
                    gradientTransform='rotate(45.564) scale(52.7618 64.4172)'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0.385' stopColor='#008A65' />
                    <stop offset='0.724' stopColor='#1DC198' />
                    <stop offset='0.995' stopColor='#1DC198' />
                </radialGradient>
            </defs>
        </svg>
    );
}
