import astronaut from '../../../../assets/images/artIntro/astronaut.png';
import baloon from '../../../../assets/images/artIntro/baloon_col_1.png';
import car from '../../../../assets/images/artIntro/car_col_3.png';
import cityscape from '../../../../assets/images/artIntro/cityscape.png';
import land from '../../../../assets/images/artIntro/land.png';
import mice from '../../../../assets/images/artIntro/mice_col_3.png';
import space from '../../../../assets/images/artIntro/space_col_3.png';
import street from '../../../../assets/images/artIntro/street.png';
import sun from '../../../../assets/images/artIntro/sun.png';

/* eslint-disable */
export const surpriseStrings = [
    'Panda mad scientist mixing sparkling chemicals, digital art',
    'A group of happy dogs playing together in the park',
    'A beautiful sunset over the mountains with a lake in the foreground',
    'A futuristic cityscape with flying cars and towering skyscrapers',
    'A magical unicorn flying in the sky surrounded by rainbows and stars',
    'Cyberpunk cityscape with neon lights and flying cars',
    'Cartoon characters going on a wild adventure',
    'Gothic castle on top of a misty mountain',
    'Magical forest with enchanted creatures',
    'Futuristic robot colony on a distant planet',
    'Mythical sea creatures swimming in the deep ocean',
    'Ancient ruins with treasure and mysteries to be uncovered',
    'Steampunk airship soaring through the clouds',
    'Space battle between alien races',
    'Historical battle scene with warriors and horses',
    'Fantasy world filled with dragons and knights',
    'Underwater world with colorful coral reefs and exotic fish',
    'Galactic space station floating in the vast universe',
    'Superhero team saving the city from villains',
    'Enchanted garden filled with butterflies and fairies',
    'Pirate ship sailing on the high seas for treasure',
    'Dinosaurs roaming in a prehistoric jungle',
    'A bustling street market filled with exotic goods and spices',
    'A grand medieval tournament with knights in shining armor',
    'A futuristic space station with robots and astronauts',
    'A tropical island with clear blue waters and coconut palms',
    'A mysterious labyrinth filled with secrets and traps',
    'A wild west showdown between cowboys and outlaws',
    'A prehistoric landscape filled with giant dinosaurs',
    'A fantastic wonderland with whimsical creatures and mad hatters',
    'A post-apocalyptic wasteland with survivors fighting for survival',
    'A grand adventure through a mystical world filled with magic and wonder'
];

export const artIntroCols = [
    {
        column: 'first',
        slides: [
            {
                text: 'A bright and colorful digital artwork featuring a hot air balloon soaring through the clouds, with vibrant patterns and bold colors that capture the joy and freedom of flight.',
                image: baloon
            },
            {
                text: 'A digital art piece featuring a stylized sun with sunglasses in a vibrant and colorful style.',
                image: sun
            },
            {
                text: 'A futuristic cityscape with flying cars and towering skyscrapers',
                image: cityscape
            }
        ]
    },
    {
        column: 'second',
        slides: [
            {
                text: 'A digital artwork featuring a dark blue color palette that depicts a lone astronaut floating in the vastness of space, surrounded by stars and galaxies.',
                image: astronaut
            },
            {
                text: 'A dark street with neon lights and a neon sign, in the style of misty atmosphere, lorenz hideyoshi',
                image: street
            },
            {
                text: 'Poster design, cinematic scene design, casual game themes, 3D, low angle, view from the land',
                image: land
            }
        ]
    },
    {
        column: 'third',
        slides: [
            {
                text: "A macro 35mm photograph of two mice in Hawaii, they're each wearing tiny swimsuits are carrying tiny surf boards, digital art",
                image: mice
            },
            {
                text: 'A bright and colorful digital artwork featuring a sleek and sporty car, with a dynamic and aerodynamic design.',
                image: car
            },
            {
                text: 'A stunning digital artwork showcasing the vast and awe-inspiring expanse of space, with vibrant colors, swirling clouds of gas and dust, and sparkling stars that twinkle against the darkness.',
                image: space
            }
        ]
    }
];
