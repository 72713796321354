import React, { useEffect } from 'react';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Col, Form, Modal, Row, Spin, Typography } from 'antd';
import StripeElements from '../../../../shared/components/StripeElements';
import { dollarFormatterFunc } from '../../../../utils/dollarFormater';
import { useAuthContext } from '../../../AuthPage/context';
import { useBilling } from '../../pages/SettingsPage/pages/BillingPage/hooks';
import styles from './index.module.scss';
export const PaymentValue = ({ value }) => {
    return (
        <Row justify='space-between' className={styles.billing__payment__value}>
            <Col>Due today</Col>
            <Col>{value}</Col>
        </Row>
    );
};

export const BillingUpdateModal = ({ context }) => {
    const [form] = Form.useForm();

    // TODO: Set prices
    // const [prices, setPrices] = useState();

    const auth = useAuthContext();

    const org = auth.org.getCurrent();

    const billing = useBilling();

    useEffect(() => {
        (async () => {
            await billing.actions.fetch(org);
            await billing.actions.intent.setup(org);
        })();
    }, []);
    const onCancel = async () => {
        await context.actions.billingPage.modal(false);
    };

    // TODO: Create to dynamic period for price
    // const period = 'annual';

    return (
        <Modal
            open
            onCancel={onCancel}
            width={596}
            centered
            className={styles.billing__upgrade}
            footer={false}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Row>
                <Col className={styles.billing__upgrade__right} span={24}>
                    <Spin size='large' spinning={context.state.loaders.billing}>
                        <Row justify='flex-start' gutter={15} style={{ marginBottom: '20px' }}>
                            <Col>
                                <Typography.Title level={2}>Upgrade to Standard</Typography.Title>
                            </Col>
                        </Row>
                        <Typography.Text className={styles.billing__upgrade__right__descr}>
                            Create without limits by upgrading to an standard plan.
                        </Typography.Text>
                        <Typography>
                            <ul className={styles.billing__upgrade__right__list}>
                                <li>
                                    <CheckCircleOutlined /> 30 day money back gaurantee, cancel any time
                                </li>
                                <li>
                                    <CheckCircleOutlined /> Around-the-clock assistance 24/7 support
                                </li>
                            </ul>
                        </Typography>
                        <StripeElements
                            setLoader={context.actions.billing.loader}
                            onModalClose={onCancel}
                            context={context}
                            form={form}
                            billing={billing}
                            paymentValue={<PaymentValue value={dollarFormatterFunc(369)} />}
                        />
                    </Spin>
                </Col>
            </Row>
        </Modal>
    );
};
