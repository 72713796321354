import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { useAuthHook } from './hooks';

const AuthContext = createContext({});

const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
    const auth = useAuthHook();

    return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export { AuthProvider, useAuthContext };

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
};
