import React from 'react';
import Slider from 'react-slick';
import { PlayCircleOutlined } from '@ant-design/icons';
import { Radio, Row } from 'antd';
import PauseCircleOutlined from '../../../../../../components/Icons/PauseCircleOutlined/PauseCircleOutlined';
import { CDN_URL } from '../../../../../../config';
import { useBreakpoint } from '../../../../../../hooks/media';
import { useSpeech } from '../../../TextToSpeechPage/pages/JustSpeakPage/hooks';
import { voices } from '../../constants';
import styles from './VoicesGroup.module.scss';

export default function VoiceGroup({ setVoice, activeGender }) {
    const { isMobile } = useBreakpoint();

    const { state, actions } = useSpeech();

    const prevId = React.useRef(null);

    const audio = React.useMemo(() => new Audio(), []);

    const sliderSettings = {
        row: 1,
        arrows: false,
        slidesToShow: 2.1,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        infinite: false,
        focusOnSelect: true
    };

    const onClickCard = (option) => {
        setVoice(`${CDN_URL}/text-to-speech/samples/${option.voiceId}.mp3`);
    };

    const onSampleAudioStart = async (e, option) => {
        e.stopPropagation();

        if (prevId.current !== option.id) audio.src = `${CDN_URL}/text-to-speech/samples/${option.voiceId}.mp3`;

        prevId.current = option.id;
        await audio.play();
        actions.samplePlay(option);

        audio.addEventListener('ended', () => {
            actions.sampleStop(option);
        });
    };

    const onSampleAudioStop = async (e, option) => {
        e.stopPropagation();
        audio.pause();
        actions.sampleStop(option);
    };

    return (
        <Radio.Group className={styles.voice__radio_group} buttonStyle='outline'>
            {!isMobile ? (
                <Row wrap={false}>
                    {voices[activeGender].map((option, key) => (
                        <Radio.Button
                            className={styles.voice_card}
                            name='voice'
                            key={key}
                            value={option.voiceId}
                            onClick={() => onClickCard(option)}
                        >
                            <div className={styles.voice_card__content}>
                                <div className={styles.voice_card__photo}>
                                    <img
                                        src={`${CDN_URL}/text-to-speech/presenters/${option.imageId}.jpg`}
                                        alt='photo'
                                    />
                                </div>
                                <div className={styles.voice_card__info}>
                                    <p>{option.name}</p>
                                    {option.id === state.samples.id && state.samples.status ? (
                                        <PauseCircleOutlined onClick={(e) => onSampleAudioStop(e, option)} />
                                    ) : (
                                        <PlayCircleOutlined
                                            className={styles.voice_card__icon}
                                            onClick={(e) => onSampleAudioStart(e, option)}
                                        />
                                    )}
                                </div>
                            </div>
                        </Radio.Button>
                    ))}
                </Row>
            ) : (
                <Slider {...sliderSettings} className={styles.voice__slider}>
                    {voices[activeGender].map((option, key) => (
                        <Radio.Button
                            className={styles.voice_card}
                            name='voice'
                            key={key}
                            value={option.voiceId}
                            onClick={() => onClickCard(option)}
                        >
                            <div className={styles.voice_card__content}>
                                <div className={styles.voice_card__photo}>
                                    <img
                                        src={`${CDN_URL}/text-to-speech/presenters/${option.imageId}.jpg`}
                                        alt='photo'
                                    />
                                </div>
                                <div className={styles.voice_card__info}>
                                    <p>{option.name}</p>
                                    {option.id === state.samples.id && state.samples.status ? (
                                        <PauseCircleOutlined onClick={(e) => onSampleAudioStop(e, option)} />
                                    ) : (
                                        <PlayCircleOutlined
                                            className={styles.voice_card__icon}
                                            onClick={(e) => onSampleAudioStart(e, option)}
                                        />
                                    )}
                                </div>
                            </div>
                        </Radio.Button>
                    ))}
                </Slider>
            )}
        </Radio.Group>
    );
}
