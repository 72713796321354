import React, { useEffect } from 'react';
import { Form, Input, Modal, Spin, Typography } from 'antd';
import styles from './index.module.scss';

const { TextArea } = Input;

export const EnhanceModal = ({ engine, state, actions, setValue }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        const prompt = state.promptHelper.replace(/^"(.*)"$/, '$1');

        form.setFieldValue('prompt', prompt);
    }, [state.promptHelper]);

    const onUpdatePrompt = async () => {
        const prompt = form.getFieldValue('prompt');

        setValue(() => prompt);
        await actions.modals.helper(false);
    };

    return (
        <Modal
            title='Enhance Prompt'
            className='modal-component'
            centered
            confirmLoading={state.loader.helper}
            open={state.modal.helper}
            width={585}
            closable
            okText='Update Prompt'
            getContainer={() => document.querySelector('#app-layout')}
            onOk={onUpdatePrompt}
            onCancel={async () => await actions.modals.helper(false)}
        >
            <Spin tip='Your prompt is creating...' size='large' spinning={state.loader.helper}>
                <Form
                    initialValues={{
                        prompt: ''
                    }}
                    form={form}
                    className={styles.modal__enhance}
                >
                    <Typography.Text>Updated Prompt</Typography.Text>
                    <Form.Item>
                        <Form.Item name='prompt' rules={[{ required: true }]}>
                            <TextArea maxLength={engine === 'Basic' ? 1000 : 4000} rows={13} showCount />
                        </Form.Item>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};
