import React from 'react';
import { Button, Flex, Modal, Typography } from 'antd';
import styles from '../index.module.scss';

const TemplateRemove = ({ history, id, state, actions }) => {
    const onRemove = async () => {
        await actions.template.remove({ id });
        await actions.template.list();
        await actions.modals.close('templateRemove');
        history.push('/art/image-templates');
    };

    return (
        <Modal
            title='Remove Template'
            width={585}
            className='modal-component'
            centered
            open={state.modal.templateRemove}
            onCancel={() => actions.modals.close('templateRemove')}
            footer={null}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Flex vertical>
                <Typography.Title style={{ marginBottom: '35px' }} level={3}>
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    Are you sure you want to delete 'Image Template'?
                </Typography.Title>
                <Flex justify='space-between' className={styles['template-modals__footer']}>
                    <Button>Cancel</Button>
                    <Button onClick={onRemove}>Remove</Button>
                </Flex>
            </Flex>
        </Modal>
    );
};

export default TemplateRemove;
