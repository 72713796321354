import { axios } from '../../services/http/axios';

const tools = {
    list: () => axios.get('/tools')
};

const api = {
    tools
};

export default api;
