export const wallpaper = {
    _id: 7,
    title: 'Wallpaper',
    alias: 'wallpaper',
    about: 'Create Breathtaking Wallpapers',
    image: 'wallpaper',
    categories: ['wallpaper'],
    tips: [],
    prompts: [
        {
            text: 'Design a series of minimalist landscape wallpapers featuring [specific landscape types, (mountains, beaches, forests, deserts)]. Each wallpaper should depict a simplified version of [chosen landscape], using a restrained color palette of [preferred colors]. Focus on clean lines and geometric shapes to capture the serene beauty of [landscape]. These wallpapers should evoke [desired emotion,  (peace, tranquility)] and be suitable for [intended setting, (modern interiors, office spaces)].',
            image: 'minimalist-landscape'
        },
        {
            text: 'Create a space-themed wallpaper depicting [galaxies, planets, stars, etc.]. The design should be vibrant and captivating, using colors like [specify cosmic colors]. This wallpaper should evoke a feeling of [wonder, adventure, mystery, etc.] and be a great fit for [specific audience or room].',
            image: 'wallpaper-depicting'
        },
        {
            text: 'Design a wallpaper inspired by the beauty of nature. Include elements like [natural landscapes and features], with a color scheme reflecting [time of day or season]. The wallpaper should evoke a sense of [calm, energy, wonder, etc.] ',
            image: 'wallpaper-inspired'
        }
    ]
};
