import React from 'react';

export default function ThunderBolt() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <path
                fill='#fff'
                d='M21 7.908h-5.901l5.306-6.704a.215.215 0 00-.169-.349H9.964a.213.213 0 00-.185.108L2.84 12.949a.214.214 0 00.185.322h4.671l-2.394 9.578c-.051.21.2.357.356.206l15.49-14.78A.213.213 0 0021 7.908zM8.416 17.905l1.615-6.456H5.815l5.079-8.77h6.016l-5.58 7.053h5.652l-8.566 8.173z'
            />
        </svg>
    );
}
