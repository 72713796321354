import React, { useEffect, useState } from 'react';
import { DeleteOutlined, UserAddOutlined } from '@ant-design/icons';
import { App, Button, Col, Form, Input, Modal, Row, Table, Typography } from 'antd';
import { EMAIL_VALIDATION_RULES } from '../../../../../../utils/validation';
import { useAuthContext } from '../../../../../AuthPage/context';

export default function TeamPage() {
    const auth = useAuthContext();

    const currentOrg = auth.org.getCurrent();

    const [modalForm] = Form.useForm();

    const { notification } = App.useApp();

    useEffect(() => {
        auth.whoami();
    }, []);

    const data =
        currentOrg?.users.map((user) => ({
            key: user._id,
            email: user.email,
            status: user.status,
            role: user.role,
            org: user.org,
            delete: <DeleteOutlined onClick={() => onDeleteMembers(user.user, user.email)} />
        })) || [];

    const [, setLoading] = useState(false);

    const [dataSource, setDataSource] = useState(data);

    const [open, setOpen] = useState(false);

    const onInviteNewMember = async (record) => {
        setLoading(() => true);

        try {
            const userOrg = auth.org.getCurrent();

            const response = await auth.org.invite(userOrg._id, { email: record.email });

            setDataSource((prevState) =>
                prevState.concat({
                    ...record,
                    ...response,
                    delete: <DeleteOutlined onClick={() => onDeleteMembers(response.user, record.email)} />
                })
            );

            setLoading(() => false);

            notification.success({
                placement: 'top',
                message: `Invitation sent to ${record.email}`
            });
        } catch (e) {
            setLoading(() => false);

            notification.error({
                placement: 'top',
                message: e.response?.data?.message || 'Something went wrong'
            });
        }
    };

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            editable: true,
            rules: EMAIL_VALIDATION_RULES
        },
        {
            title: 'Role',
            dataIndex: 'role'
        },
        {
            title: 'Status',
            dataIndex: 'status'
        },
        {
            title: '',
            dataIndex: 'delete'
        }
    ];

    const onOk = async () => {
        await modalForm.validateFields();

        const values = modalForm.getFieldsValue();

        await onInviteNewMember(values);

        modalForm.resetFields();

        setOpen(false);
    };

    const onCancel = () => {
        modalForm.resetFields();

        setOpen(false);
    };

    const onDeleteMembers = async (userId, emailUser) => {
        setLoading(() => true);

        try {
            const userOrg = auth.org.getCurrent();

            await auth.org.delete(userOrg._id, userId);

            setDataSource((prevState) => prevState.filter((it) => it.email !== emailUser));

            setLoading(() => false);
            notification.success({
                placement: 'top',
                message: 'User deleted from organization.'
            });
        } catch (error) {
            setLoading(() => false);

            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };

    return (
        <div style={{ padding: '16px' }}>
            <Row justify='center'>
                <Typography.Title level={3}>Team</Typography.Title>
            </Row>
            <Row justify='center'>
                <Col xs={24} sm={24} md={20} lg={20}>
                    {!currentOrg?.billingPlan ? null : (
                        <Button
                            disabled={
                                currentOrg.billingPlan.status !== 'active' ||
                                currentOrg.billingPlan.seats.value >= currentOrg.billingPlan.seats.limit
                            }
                            onClick={() => setOpen(true)}
                            type='primary'
                            icon={<UserAddOutlined />}
                        >
                            Invite member
                        </Button>
                    )}
                    <Table
                        columns={columns}
                        dataSource={dataSource}
                        scroll={{ x: 550 }}
                        pagination={{
                            pageSize: 10,
                            size: 'small',
                            simple: true,
                            position: ['topRight']
                        }}
                    />
                </Col>
            </Row>
            <Modal title='Invite new member' centered closable maskClosable open={open} onOk={onOk} onCancel={onCancel}>
                <Form requiredMark={false} layout='vertical' form={modalForm}>
                    <Form.Item label='Email' name='email' rules={EMAIL_VALIDATION_RULES}>
                        <Input />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
}
