import React from 'react';

export default function RedoIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='15' height='20' fill='none' viewBox='0 0 15 20'>
            <path
                fill='#1F1F1F'
                fillOpacity='0.7'
                d='M0 11.25C0 16.075 3.925 20 8.75 20 5.3 20 2.5 17.2 2.5 13.75S5.3 7.5 8.75 7.5H10V10l5-5-5-5v2.5H8.75C3.925 2.5 0 6.425 0 11.25z'
            />
        </svg>
    );
}
