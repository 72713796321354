import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useScrollToBottom } from 'react-scroll-to-bottom';
import Icon, { ArrowRightOutlined, AudioOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Space, Upload } from 'antd';
import cn from 'classnames';
import recordImg from '../../../../../../assets/images/microphone_icon.png';
import PauseAudio from '../../../../../../components/Icons/PauseAudio/PauseAudio';
import UploadClip from '../../../../../../components/Icons/UploadClip';
import { useBreakpoint } from '../../../../../../hooks/media';
import { AttachedFile } from '../../../../../../shared/components/AttachedFile';
import { uploadImage } from '../../../ImageUpscalePage/hooks';
import AudioRecorder from '../../lib/AudioRecorder';
import EasyInputForm from './Components/Coundown/EasyInputForm';
import { PopoverContent } from './Components/Popover';
import { beforeUpload } from './Components/utils/upload';

export function ChatInput({ setEngine, isUploadDisabled, conversation, message, create, disabled }) {
    const scrollToBottom = useScrollToBottom();

    const { isMobile } = useBreakpoint();

    const history = useHistory();

    const location = useLocation();

    const { actions } = conversation;

    const [value, setValue] = useState(location?.state?.text || '');

    const [recording, setRecording] = useState(false);

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [open, setOpen] = useState(false);

    const inputChatRef = useRef(null);

    const [insertWords, setInsertWords] = useState([]);

    // search words in bracket in prompt
    const arrWordsInPrompt = (str) => {
        const regex = /\[([^\]]+)\]/g;

        return str.match(regex).map((match) => match.slice(1, -1));
    };

    const replaceWordsWithNumbers = (str) => {
        let count = 0;

        return str.replace(/\[[^\]]+\]/g, function (match) {
            return '[' + count++ + ']';
        });
    };

    useEffect(() => {
        const isTextFromPrompt = location?.state?.text;

        const isBracketsInPrompt = isTextFromPrompt?.includes('[');

        if (isTextFromPrompt && isBracketsInPrompt) {
            setOpen(true);

            const arrayWordsInBrackets = arrWordsInPrompt(isTextFromPrompt);

            setInsertWords([...arrayWordsInBrackets]);
        }

        if (location?.state?.text) {
            history.replace({ state: { text: '' } });
        } else {
            setValue(message.state.chatInput);
        }
    }, [message.state.chatInput]);

    useEffect(() => {
        inputChatRef.current.focus();
    }, [location.pathname]);

    const recorder = useMemo(
        () =>
            new AudioRecorder({
                onStop: (transcription) => {
                    setValue(transcription);

                    setRecording(() => false);
                },
                onError: () => {
                    setRecording(() => false);
                }
            }),
        []
    );

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onChange = (e) => {
        setValue(e.target.value);
    };

    const rows = value.split('\n').length;

    const onSubmit = (e) => {
        e.preventDefault();

        const formData = new FormData();

        for (let i = 0; i < message.state.uploadFileList?.length; i++) {
            formData.append('file[]', message.state.uploadFileList[i].originFileObj);
        }

        formData.append('content', value);

        create(formData, value);

        setValue('');
        scrollToBottom({ behavior: 'smooth' });
    };

    const onKeyPress = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            onSubmit(e);
        }
    };

    const onVoiceRecordStart = async () => {
        await recorder.start();

        if (!recorder.stream) {
            setIsModalOpen(true);

            return;
        }

        setRecording(() => true);

        if (AudioRecorder.isSpeechRecognitionSupported()) {
            recorder.startSpeechRecognition();
        }
    };

    const onVoiceRecordPause = () => {
        recorder.stop();
    };

    // create new string for prompt
    const newString = (newValues) => {
        const arr = Object.entries(newValues);

        const valueWithIndex = replaceWordsWithNumbers(value);

        return arr.reduce((acc, currentValue, index) => acc.replace(`[${index}]`, arr[index][1]), valueWithIndex);
    };

    const onCreate = (values) => {
        const newInput = newString(values);

        setValue(newInput);

        setOpen(false);
    };

    const isInsertWord = insertWords.length > 0;

    const onChangeFile = async ({ file, fileList }) => {
        const beforeUploadCheck = await beforeUpload(file);

        if (beforeUploadCheck) {
            message.actions.setUploadFiles({ content: fileList });
        }
    };

    return (
        <React.Fragment>
            <div
                className={cn({
                    'chat-input': true,
                    'files-attached': message.state.uploadFileList.length
                })}
            >
                {isInsertWord && open && (
                    <EasyInputForm openModal={open} setVisible={setOpen} onCreate={onCreate} arrayWords={insertWords} />
                )}
                <Modal
                    className='modal-record'
                    title='Voice Input'
                    open={isModalOpen}
                    onOk={handleOk}
                    closable={false}
                    centered
                    footer={[
                        <Button key='submit' type='primary' onClick={handleOk}>
                            Continue
                            <ArrowRightOutlined />
                        </Button>
                    ]}
                    onCancel={handleCancel}
                >
                    <img alt='record image' src={recordImg} />
                    <p>Microphone access is needed for input.</p>
                    <p>English only supported at the moment.</p>
                </Modal>
                <Upload
                    name='input-file-upload'
                    listType='picture'
                    className={cn({
                        'chat-upload': true,
                        active: !isUploadDisabled
                    })}
                    customRequest={uploadImage}
                    showUploadList
                    maxCount={3}
                    itemRender={(originNode, file, fileList, actions) => {
                        return <AttachedFile file={file} actions={actions} fileList={fileList} />;
                    }}
                    onChange={onChangeFile}
                    fileList={message.state.uploadFileList}
                    accept='.pdf, .csv, .txt, .jpeg, .png, .gif, .webp, .jpg'
                >
                    <Button
                        disabled={isUploadDisabled || disabled}
                        onClick={() => setEngine()}
                        type='primary'
                        icon={<Icon className='chat-upload-clip' component={UploadClip} />}
                    />
                    <div className='chat-upload__popover'>
                        <PopoverContent isUploadDisabled={isUploadDisabled} />
                    </div>
                </Upload>
                <Input.TextArea
                    className='chat-input-textarea'
                    autoSize={{ minRows: 1, maxRows: 6 }}
                    placeholder={isMobile ? 'Write anything important' : 'Write a paragraph about the meaning of life'}
                    value={value}
                    size='large'
                    onChange={onChange}
                    rows={rows}
                    onKeyPress={onKeyPress}
                    ref={inputChatRef}
                />
                <Space className='chat-action-icons'>
                    {(value || !AudioRecorder.isAudioSupported()) && (
                        <Button
                            disabled={disabled}
                            className='chat-input-button'
                            type='primary'
                            icon={<SendOutlined />}
                            onClick={onSubmit}
                        />
                    )}
                    {!value && AudioRecorder.isAudioSupported() ? (
                        recording ? (
                            <Button
                                disabled={disabled}
                                className='chat-input-button record-btn'
                                type='primary'
                                icon={<Icon component={PauseAudio} />}
                                onClick={onVoiceRecordPause}
                            />
                        ) : (
                            <Button
                                disabled={disabled}
                                className='chat-input-button record-btn'
                                type='primary'
                                icon={<AudioOutlined />}
                                onClick={onVoiceRecordStart}
                            />
                        )
                    ) : null}
                </Space>
            </div>
            <div className='chat-input-prompt'>
                Need help writing prompts? Try our <a onClick={() => actions.promptsOpen(true)}>Prompt Library</a>
            </div>
        </React.Fragment>
    );
}
