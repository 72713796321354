import React from 'react';
import { ExclamationCircleFilled, FileImageOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Input, Modal, notification, Spin, Typography, Upload } from 'antd';
import { beforeUpload } from '../../../../../../../utils/upload';
import { uploadImage } from '../../../../ImageUpscalePage/hooks';
import styles from '../index.module.scss';
const { Dragger } = Upload;

const PromptCreate = ({ id, state, actions }) => {
    const [form] = Form.useForm();
    const onCreatePrompt = async (values) => {
        try {
            const data = new FormData();
            data.append('image', values.image?.file?.originFileObj);
            data.append('values', JSON.stringify(values));
            await actions.template.update({ id, data });
            form.resetFields();
        } catch (err) {
            notification.error({
                placement: 'top',
                message: 'Something went wrong! Please try again.'
            });
        }

        await actions.template.list();
        actions.modals.close('prompt');
    };
    return (
        <Modal
            title='New Prompt'
            width={585}
            className='modal-component'
            centered
            open={state.modal.prompt}
            onCancel={() => actions.modals.close('prompt')}
            footer={null}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Spin spinning={state.loader.modal}>
                <Form className={styles['template-modals']} form={form} onFinish={onCreatePrompt}>
                    <Form.Item name='image'>
                        <Dragger
                            beforeUpload={async (file) => {
                                try {
                                    await beforeUpload(file, { maxFileSize: 2 });
                                } catch (err) {
                                    notification.error({
                                        placement: 'top',
                                        message: err.message || 'Something went wrong'
                                    });

                                    return Upload.LIST_IGNORE;
                                }
                            }}
                            maxCount={1}
                            customRequest={uploadImage}
                        >
                            <p className='ant-upload-drag-icon'>
                                <FileImageOutlined />
                            </p>
                            <p className='ant-upload-text'>Click or drag file to this area to upload</p>
                            <p className='ant-upload-hint'>Image Files, up to 2MB</p>
                        </Dragger>
                    </Form.Item>
                    <Alert
                        icon={<ExclamationCircleFilled />}
                        message='When crafting a prompt Use [brackets] to insert your unique inputs.
                    Customize prompts by replacing bracketed sections with your specific details or elements,
                    offering a way to tailor the prompt to your needs.'
                        type='success'
                        showIcon
                    />
                    <Form.Item>
                        <Typography.Text>Prompt</Typography.Text>
                        <Form.Item rules={[{ required: true }]} name='prompts'>
                            <Input.TextArea
                                maxLength={3000}
                                autoSize={{ minRows: 5, maxRows: 5 }}
                                placeholder='Design a minimalist logo for [Company Name], combining
                    [a simple shape or letter] with a restrained color palette to reflect the brand’s professional and sleek identity.'
                            />
                        </Form.Item>
                    </Form.Item>
                    <Form.Item className={styles['template-modals__footer']}>
                        <Button>Cancel</Button>
                        <Button htmlType='submit'>Add Prompt</Button>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
};

export default PromptCreate;
