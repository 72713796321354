const decoder = new TextDecoder('utf-8');

export async function chatStream(response, onChunk, done) {
    let chunk;

    const reader = response.getReader();

    while (!(chunk = await reader.read()).done) {
        onChunk(decoder.decode(chunk.value));
    }

    done();
}
