import React, { useState } from 'react';
import { Col, Form, Input, InputNumber, Modal, Radio, Row, Select } from 'antd';
import { NODE_ENV } from '../../../../../../../../../config';
import { dollarFormatterFunc } from '../../../../../../../../../utils/dollarFormater';

const layout = {
    labelAlign: 'left',
    labelCol: {
        span: 6
    },
    wrapperCol: {
        span: 18
    }
};

const justSpeakOptions = [
    { value: 0, label: '-' },
    { value: 25000, label: 'Starter - 25,000' },
    { value: 100000, label: 'Creator - 100,000' },
    { value: 350000, label: 'Publisher- 350,000' },
    { value: 1000000, label: 'Growing Business - 1,000,000' }
];

if (NODE_ENV === 'dev') {
    justSpeakOptions.splice(1, 0, { value: 1000, label: 'Test - 1,000' });
}

const justArtOptions = [
    { value: 0, label: '-' },
    { value: 100, label: '100' },
    { value: 250, label: '250' },
    { value: 500, label: '500' },
    { value: 750, label: '750' },
    { value: 1000, label: '1000' },
    { value: 5000, label: '5000' }
];

export default function UpdatePlanModal({ form, org, billing }) {
    const [price, setPrice] = useState(dollarFormatterFunc(billing.state.plan.price || '00.00'));

    const [priceDiff, setPriceDiff] = useState('0.00');

    const [action, setAction] = useState('');

    async function onParamsChange() {
        const data = await billing.actions.plan.calculate(org, form.getFieldsValue());

        setPrice(() => dollarFormatterFunc(data.price));
        setPriceDiff(() => dollarFormatterFunc(data.priceDiff));
        setAction(() => data.action);
    }

    return (
        <Modal
            title='Select new plan parameters'
            destroyOnClose
            forceRender
            centered
            open={billing.state.activeModals.updatePlan}
            confirmLoading={billing.state.loader.updatingPlan}
            onCancel={() => {
                billing.actions.modal.close('updatePlan');
            }}
            onOk={async () => {
                const values = await form.validateFields();

                await billing.actions.plan.update(org, values);

                billing.actions.modal.close('updatePlan');

                billing.actions.fetch(org);
            }}
            okButtonProps={{
                disabled: billing.state.loader.updatingPlan
            }}
            okText={action === 'downgrade' ? 'Downgrade' : 'Upgrade'}
            okType={action === 'downgrade' ? 'danger' : 'primary'}
        >
            <Form form={form} layout='horizontal' requiredMark={false} {...layout}>
                <Form.Item label='Words' name='words'>
                    <Input placeholder='unlimited' disabled />
                </Form.Item>
                <Form.Item extra='The number of characters for audio' label='JUST SPEAK' name='audio'>
                    <Select onChange={onParamsChange} options={justSpeakOptions} />
                </Form.Item>
                <Form.Item extra='The number of images ' label='JUST ART' name='images'>
                    <Select onChange={onParamsChange} options={justArtOptions} />
                </Form.Item>
                <Form.Item name='seats' label='USERS' extra='The number of seats'>
                    <InputNumber min={1} max={20} onChange={onParamsChange} />
                </Form.Item>
                <Form.Item name='period' label='Period'>
                    <Radio.Group onChange={onParamsChange}>
                        <Radio.Button value='month'>Monthly</Radio.Button>
                        <Radio.Button value='annual'>Annually</Radio.Button>
                    </Radio.Group>
                </Form.Item>
                <Form.Item noStyle>
                    <React.Fragment>
                        <Row justify='space-between'>
                            <Col span={12}>
                                <strong>Total:</strong>
                            </Col>
                            <Col span={12}>
                                {price} / {form.getFieldValue('period')}
                            </Col>
                        </Row>
                        <Row justify='space-between'>
                            <Col span={12}>
                                <small>Current payment price:</small>
                            </Col>
                            <Col span={12}>
                                <small>{priceDiff}</small>
                            </Col>
                        </Row>
                    </React.Fragment>
                </Form.Item>
            </Form>
        </Modal>
    );
}
