import React from 'react';
import { Card, Col, Form, Input, Row } from 'antd';
import { useAuthContext } from '../../../../../AuthPage/context';

export default function OrganizationPage() {
    const auth = useAuthContext();

    const [form] = Form.useForm();

    return (
        <Row>
            <Col
                xs={{
                    span: 24
                }}
                lg={{
                    span: 12
                }}
            >
                <Card title='Organization Information' bordered={false}>
                    <Form
                        form={form}
                        layout='vertical'
                        requiredMark={false}
                        initialValues={{
                            name: auth.org.getCurrent()?.label
                        }}
                    >
                        <Form.Item label='Name' name='name'>
                            <Input disabled />
                        </Form.Item>
                    </Form>
                </Card>
            </Col>
        </Row>
    );
}
