const config = {
    _id: 106,
    title: 'Article Summarizer',
    alias: 'article-summarizer',
    about: 'Condense lengthy articles into concise grade-level summaries across various subjects, saving time for students, teachers, and avid readers.',
    categories: ['Blog Content'],
    tips: [
        'Understand the target grade level and adjust the language and complexity of the summary accordingly.',
        'Focus on the main points, key arguments, and significant findings of the article.',
        'Use clear and concise language, avoiding jargon or complex terminology that may be difficult for the intended audience to understand.'
    ],
    prompts: [
        'Summarize this article for a high school audience [Insert Article].',
        'Can you provide a summary of this paragraph [insert paragraph].',
        'Please summarize this historical article for a college-level audience [insert article].'
    ]
};

export default config;
