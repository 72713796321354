import React from 'react';
import ReactPlayer from 'react-player/lazy';
import { useHistory } from 'react-router-dom';
import { DeleteOutlined, DownloadOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, Card, Empty, List, Spin, Typography } from 'antd';
import moment from 'moment/moment';
import { downloadMp4 } from '../../../../../../../../utils/mp4';
import './index.scss';

const { Meta } = Card;

export const SingleVideo = ({ item, actions, removing }) => {
    const history = useHistory();

    return (
        <List.Item className='img-to-video__history__single'>
            <Spin spinning={item.status === 'created'} size='large'>
                <Card
                    key={item._id}
                    cover={
                        <div className='img-to-video__wrapper'>
                            {item.status === 'done' ? (
                                <React.Fragment>
                                    <ReactPlayer
                                        className='img-to-video__react-player'
                                        playsinline
                                        width='100%'
                                        height='100%'
                                        url={item.resultUrl}
                                        controls
                                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                        light={<img src={item.sourceUrl} alt='Thumbnail' />}
                                        onClickPreview={(e) => {
                                            const wrapper = e.target.closest('.img-to-video__wrapper');

                                            wrapper.querySelector('span').style.display = 'none';
                                        }}
                                    />
                                    <span className='img-to-video-time'>
                                        {moment.utc(item.duration * 1000).format('mm:ss')}
                                    </span>
                                    <Button
                                        disabled={removing}
                                        loading={removing}
                                        className='img-to-video-delete'
                                        onClick={() => actions.talk.remove(item._id)}
                                        type='primary'
                                        icon={<DeleteOutlined />}
                                    />
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    <Empty description={item.errorReason || 'Sorry, something went wrong.'} />
                                    <Button
                                        disabled={removing}
                                        loading={removing}
                                        className='img-to-video-delete'
                                        onClick={() => actions.talk.remove(item._id)}
                                        type='primary'
                                        icon={<DeleteOutlined />}
                                    />
                                </React.Fragment>
                            )}
                        </div>
                    }
                    actions={[
                        <Typography.Paragraph
                            onClick={() =>
                                actions.modals.history({
                                    id: item._id,
                                    status: true
                                })
                            }
                            key='share'
                        >
                            Share
                            <ShareAltOutlined />
                        </Typography.Paragraph>,
                        <Typography.Paragraph
                            onClick={() => {
                                if (item.status === 'done') {
                                    return downloadMp4(item.title, item.resultUrl);
                                }
                            }}
                            key='download'
                        >
                            Download
                            <DownloadOutlined />
                        </Typography.Paragraph>
                    ]}
                >
                    <Meta
                        title={
                            <Typography.Title
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    if (item.status === 'done') {
                                        return history.push(`/did-video/${item._id}`);
                                    }
                                }}
                                level={2}
                            >
                                {item.title}
                            </Typography.Title>
                        }
                        description={moment(item.createdAt).format('MMMM DD, YYYY')}
                    />
                </Card>
            </Spin>
        </List.Item>
    );
};
