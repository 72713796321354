import React, { useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import { Button, Card, Col, Empty, Input, Row, Space, Spin, Tooltip, Typography } from 'antd';
import cn from 'classnames';
import PlusBoldIcon from '../../../../components/Icons/PlusBoldIcon';
import PageLoader from '../../../../components/PageLoader/PageLoader';
import { useBreakpoint } from '../../../../hooks/media';
import { useSearchParams } from '../../../../hooks/searchParams';
import { $colorPrimary } from '../../../../styles/global';
import { useAuthContext } from '../../../AuthPage/context';
import { ArtTemplate } from './components/ArtTemplate';
import TemplateCreate from './components/Modals/TemplateCreate';
import { TemplatePage } from './components/TemplatePage';
import { useArtTemplates } from './hooks';
import styles from './index.module.scss';

export const ImageTemplates = ({ history, match }) => {
    const { isMobile, isTable } = useBreakpoint();

    const auth = useAuthContext();

    const { actions, state } = useArtTemplates();

    const searchParams = useSearchParams();

    const historyUrl = useHistory();

    const search = searchParams.get('search') || '';

    const category = searchParams.get('category') || 'all-art';

    useEffect(() => {
        (async () => {
            await actions.template.list();
        })();
    }, []);

    const handleSearchChange = (e) => {
        searchParams.set('search', e.target.value);

        historyUrl.push({ pathname: '/art/image-templates', search: searchParams.toString() });
    };

    const handleCategoryClick = (key) => {
        searchParams.delete('search');

        key === 'all-art' ? searchParams.delete('category') : searchParams.set('category', key);

        historyUrl.push({ pathname: '/art/image-templates', search: searchParams.toString() });
    };

    if (!state.configs.length) {
        return <PageLoader />;
    }

    const categoryRouteMapper = state.tabs.reduce((acc, curr) => {
        acc[curr.key] = curr;

        return acc;
    }, {});

    const tabTitle = categoryRouteMapper[category].tab;

    if (match.params.alias) {
        const artConfig = state.configs.find((art) => match.params.alias === art.alias);

        if (!artConfig) {
            return <Redirect to='/art/image-templates' />;
        }

        const onBack = () => {
            history.goBack();
        };

        return <TemplatePage history={history} actions={actions} state={state} config={artConfig} onBack={onBack} />;
    }

    return (
        <Spin spinning={state.loader.main}>
            <Row className={styles['art-page']}>
                <Col span={24}>
                    <Card
                        className={cn(['no-shadow', styles['art-page__tabs']])}
                        bordered={false}
                        title={
                            <Space className={styles['art-page__head']} align='start'>
                                {isMobile || isTable ? null : (
                                    <Space direction='vertical'>
                                        <Typography.Title level={2} style={{ margin: 0 }}>
                                            Image Templates
                                        </Typography.Title>
                                        <Typography.Text>
                                            Need Help? Our Pre-made Art Templates Have You Covered!
                                        </Typography.Text>
                                    </Space>
                                )}
                                <Space size='middle' className={styles['art-page__head__right']} align='center'>
                                    <Tooltip title='Create image template'>
                                        <Button
                                            onClick={() => actions.modals.open('template')}
                                            className={styles['art-page__head__right__btn']}
                                        >
                                            <PlusBoldIcon />
                                            {isMobile || isTable ? null : 'New Template'}
                                        </Button>
                                    </Tooltip>
                                    <Input.Search
                                        className={styles['art-page__search']}
                                        placeholder='Search Specific template'
                                        bordered={false}
                                        value={search}
                                        onChange={handleSearchChange}
                                        size='large'
                                    />
                                </Space>
                            </Space>
                        }
                        tabList={state.tabs}
                        activeTabKey={category}
                        onTabChange={handleCategoryClick}
                    >
                        <Row
                            gutter={[
                                { xs: 16, sm: 16, md: 30 },
                                { xs: 16, sm: 16, md: 30 }
                            ]}
                        >
                            {category === 'favorites' && !auth.profile.favoriteArt.length && (
                                <Col span={24}>
                                    <Empty
                                        description={
                                            <React.Fragment>
                                                <p style={{ color: '#000' }}>
                                                    In order to add an art to your favorites, click on the star button
                                                    on the art card.
                                                </p>
                                                <br />
                                                <StarFilled
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        color: $colorPrimary,
                                                        fontSize: 20
                                                    }}
                                                />
                                            </React.Fragment>
                                        }
                                    />
                                </Col>
                            )}
                            {(category === 'all-art'
                                ? [...state.configs].filter((art) => {
                                      if (search) {
                                          return (
                                              art.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                              art.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                          );
                                      }

                                      return true;
                                  })
                                : [...state.configs].filter((art) => {
                                      const TabTitleToLowerCase = tabTitle.toLowerCase();

                                      if (category === 'favorites') {
                                          const isFavorite = auth.profile.favoriteArt.includes(art._id);

                                          if (search && isFavorite) {
                                              return (
                                                  art.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                                  art.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                              );
                                          }

                                          return isFavorite;
                                      }

                                      if (search && art.categories.includes(TabTitleToLowerCase)) {
                                          return (
                                              art.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                              art.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                          );
                                      }

                                      if (search && art.categories.includes(TabTitleToLowerCase)) {
                                          return (
                                              art.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                              art.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                          );
                                      }

                                      return art.categories.includes(TabTitleToLowerCase);
                                  })
                            ).map((art) => (
                                <ArtTemplate state={state} key={`art-card-${art._id}`} art={art} history={history} />
                            ))}
                        </Row>
                    </Card>
                </Col>
                <TemplateCreate history={history} state={state} actions={actions} />
            </Row>
        </Spin>
    );
};
