import React, { useEffect, useRef } from 'react';
import RedoIcon from '../../../../../../../../components/Icons/RedoIcon';
import UndoIcon from '../../../../../../../../components/Icons/UndoIcon';

export default function EditOptionDropDownList({ toolbarRef, onUndo, onRedo, onCloseDropdown }) {
    const dropDownRef = useRef(null);

    useEffect(() => {
        const toolbar = toolbarRef.current;

        const dropDown = dropDownRef.current;

        if (toolbar !== null && dropDown !== null) {
            const { top, left } = toolbar.getBoundingClientRect();

            dropDown.style.top = `${top + 85}px`;
            dropDown.style.left = `${left + 65}px`;
        }
    }, [dropDownRef, toolbarRef]);

    useEffect(() => {
        const dropDown = dropDownRef.current;

        const toolbar = toolbarRef.current;

        if (dropDown !== null && toolbar !== null) {
            const handle = (event) => {
                const target = event.target;

                if (!dropDown.contains(target) && !toolbar.contains(target)) {
                    onCloseDropdown();
                }
            };

            document.addEventListener('click', handle);

            return () => {
                document.removeEventListener('click', handle);
            };
        }
    }, [dropDownRef, toolbarRef]);

    return (
        <div className='dropdown' ref={dropDownRef}>
            <button className='item' onClick={onUndo}>
                <span className='text'>
                    <UndoIcon />
                    Undo
                </span>
            </button>
            <button className='item' onClick={onRedo}>
                <span className='text'>
                    <RedoIcon />
                    Redo
                </span>
            </button>
        </div>
    );
}
