/* eslint-disable */

const config = {
    _id: 111,
    title: 'Career Guidance Counselor',
    alias: 'career-guidance-counselor',
    about: "Suggest potential career paths and the steps to achieve them by analyzing a student's interests, skills, and goals.",
    categories: ['Education'],
    tips: [
        'Encourage students to be as honest and comprehensive as possible about their interests and goals.',
        'Use the AI tool as a starting point for a broader discussion about career planning.',
        'Regularly update the information to account for changing interests and goals.'
    ],
    prompts: [
        "Based on [student name]'s interests in [subjects], what careers might they be suited to?",
        'What steps should [student name] take to pursue a career in [desired field]?',
        'Suggest extracurricular activities that could help [student name] explore their interest in [field].',
        'What are the key skills needed for a career in [field]?',
        'Provide an overview of the career path in [field].'
    ]
};

export default config;
