import React from 'react';
import { Button, Card, Flex, Form, Radio, Typography } from 'antd';
import StarsIcon from '../../../../../../../../../../components/Icons/StarsIcon/StarsIcon';
import { planConfig } from '../../../../../../../../../../hooks/planConfig';
import { uuid } from '../../../../../../../../../../utils/crypto';
import { dollarFormatterFunc } from '../../../../../../../../../../utils/dollarFormater';
import { useAuthContext } from '../../../../../../../../../AuthPage/context';
import styles from './index.module.scss';

export default function PlanConfig({ form, billing, org }) {
    const selectedPlan = billing.state?.plan?.planType;
    const formatter = new Intl.NumberFormat('en-US');
    const auth = useAuthContext();

    const onPlanUpgrade = async (type, isPlanDowngrade) => {
        form.setFieldValue('type', type);

        if (isPlanDowngrade) {
            return billing.actions.modal.open('billingDowngrade');
        }

        await billing.actions.modal.open('billingUpdate');
        if (!billing.state.cards.length) {
            await billing.actions.intent.setup(org);
        }
    };

    const getPlanConfig = planConfig(auth?.profile?.userProf);

    const planTypes = Object.keys(getPlanConfig.initialValues);

    return (
        <div className={styles.billing__selector__config}>
            <Typography.Title style={{ marginBottom: 8 }} level={2} className={styles.billing__title}>
                Turn Ideas into Reality with Just Think
            </Typography.Title>
            <Typography.Paragraph style={{ marginBottom: 20 }} className={styles.billing__subtitle}>
                Choose the perfect plan for your needs
            </Typography.Paragraph>
            <Form.Item className={`${styles.billing__config__radio} ${styles.form__period}`}>
                <Form.Item name='period'>
                    <Radio.Group buttonStyle='solid' className={styles.form__period__radio}>
                        {getPlanConfig.period.map((item, key) => (
                            <Radio.Button key={key} value={item.value}>
                                {item.label}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>
                <Typography.Paragraph className={styles.form__period_info}>
                    <span>Save 20%</span> on yearly plan!
                </Typography.Paragraph>
            </Form.Item>
            <Flex className={styles.billing__selector__config__radio} gap='middle'>
                {Object.entries(getPlanConfig.initialValues).map(([key, plan], index) => {
                    const prevPlanValue = index ? getPlanConfig.plans[index - 1].value : 'free';
                    const isPlanDowngrade = planTypes.indexOf(plan.type) < planTypes.indexOf(selectedPlan);
                    const action = isPlanDowngrade ? 'Downgrade' : 'Upgrade';

                    return (
                        <Form.Item className={styles.billing__selector__item} key={uuid()} name='type'>
                            <Radio.Group>
                                <Radio value={plan.type}>
                                    <Card
                                        className={`${styles.billing__selector__card} ${plan.popular && styles.billing__selector__most_popular}`}
                                    >
                                        {plan.popular && (
                                            <span className={styles.billing__selector__most_popular__badge}>
                                                Most Popular
                                                <StarsIcon />
                                            </span>
                                        )}
                                        <Typography.Title level={3}>{plan.type}</Typography.Title>
                                        <Typography.Paragraph>
                                            {getPlanConfig.initialValues[plan.type]?.description}
                                        </Typography.Paragraph>
                                        <Form.Item
                                            shouldUpdate={(prevValues, curValues) =>
                                                prevValues.period !== curValues.period
                                            }
                                        >
                                            {() => {
                                                const values = form.getFieldsValue();
                                                const price =
                                                    values.period === 'month' ? plan.price.month : plan.price.annual;
                                                return (
                                                    <Typography.Paragraph className={styles.billing__selector__price}>
                                                        <span className={styles.billing__selector__price__value}>
                                                            {dollarFormatterFunc(price, true)}
                                                        </span>
                                                        /month
                                                    </Typography.Paragraph>
                                                );
                                            }}
                                        </Form.Item>
                                        <Button
                                            onClick={() => onPlanUpgrade(plan.type, isPlanDowngrade)}
                                            type='primary'
                                            block
                                            disabled={selectedPlan === plan.type}
                                        >
                                            {selectedPlan === plan.type ? 'Current Plan' : `${action} to ${plan.type}`}
                                        </Button>
                                        <div className={styles.billing__selector__info}>
                                            <Typography.Paragraph className={styles.billing__selector__features__title}>
                                                All {prevPlanValue} features, plus:
                                            </Typography.Paragraph>
                                            <ul className={styles.billing__selector__features__list}>
                                                <li>{plan.users} Seats</li>
                                                <li>Unlimited Words in Chat</li>
                                                <li>{formatter.format(plan.images)} AI Generated Images</li>
                                                <li>{plan.video / 60} min AI Video Generation</li>
                                                <li>{formatter.format(plan.speak)} Text to speech characters</li>
                                            </ul>
                                        </div>
                                    </Card>
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                    );
                })}
            </Flex>
        </div>
    );
}
