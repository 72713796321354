import React from 'react';

export default function NumberedListIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <g fill='#000' clipPath='url(#clip0_3454_1759)'>
                <path d='M21 5.5v1a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5v-1a.5.5 0 01.5-.5h11a.5.5 0 01.5.5zm-.5 5.5h-11a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zm0 6h-11a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-1a.5.5 0 00-.5-.5zM4.18 4.5v-.416h.832V7h-.46V4.5H4.18zM4.492 12.38c.256-.221.457-.403.604-.544.15-.144.273-.293.372-.448a.857.857 0 00.148-.464.561.561 0 00-.116-.376c-.075-.09-.193-.136-.356-.136-.157 0-.28.05-.368.152a.622.622 0 00-.136.4H4.2c.008-.304.099-.536.272-.696a.946.946 0 01.668-.244c.29 0 .517.08.68.24.165.16.248.373.248.64 0 .192-.05.377-.148.556a2.206 2.206 0 01-.348.476c-.133.139-.304.3-.512.484l-.18.16h1.268v.38H4.204v-.332l.288-.248zM4.232 16.804a.769.769 0 01.284-.58c.176-.139.4-.208.672-.208.187 0 .348.033.484.1a.717.717 0 01.308.272c.07.115.104.244.104.388a.686.686 0 01-.132.424.642.642 0 01-.316.236v.016c.157.048.28.136.368.264a.824.824 0 01.132.484.758.758 0 01-.428.712c-.141.07-.31.104-.508.104-.288 0-.525-.073-.712-.22-.184-.15-.283-.363-.296-.64h.44c.01.141.065.257.164.348a.579.579 0 00.4.132c.163 0 .288-.044.376-.132a.48.48 0 00.132-.348c0-.187-.06-.319-.18-.396-.117-.08-.299-.12-.544-.12h-.104v-.376h.108c.216-.003.38-.039.492-.108.115-.07.172-.179.172-.328a.39.39 0 00-.124-.304.496.496 0 00-.352-.116.49.49 0 00-.348.116.452.452 0 00-.148.28h-.444z' />
            </g>
            <defs>
                <clipPath id='clip0_3454_1759'>
                    <path fill='#fff' d='M0 0H24V24H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
