import React, { useEffect } from 'react';
import ReactPlayer from 'react-player/lazy';
import { NavLink, useParams } from 'react-router-dom';
import { Button, Col, Row, Typography } from 'antd';
import { useAuthContext } from '../../../../../AuthPage/context';
import { useImageToVideo } from '../../hooks';
import './index.scss';

export const SingleVideoPage = () => {
    const params = useParams();

    const { state, actions } = useImageToVideo();

    const { profile } = useAuthContext();

    useEffect(() => {
        (async () => {
            await actions.share.get(params.id);
        })();
    }, []);

    return (
        <Row>
            <Col span={4}>
                {profile && (
                    <React.Fragment>
                        <Typography.Title align='center' level={2}>
                            {state.singleVideo.title}
                        </Typography.Title>
                        <Button className='single-player-back'>
                            <NavLink to='/video/history'>Go back</NavLink>
                        </Button>
                    </React.Fragment>
                )}
                {!profile && (
                    <React.Fragment>
                        <Typography.Title align='center' level={2}>
                            {state.singleVideo.title}
                        </Typography.Title>
                        <Typography.Text style={{ display: 'block' }} align='center'>
                            Do you want to create your own video? Just click link bellow:
                        </Typography.Text>
                        <Button className='single-player-back'>
                            <NavLink to='/auth/signup'>Register</NavLink>
                        </Button>
                    </React.Fragment>
                )}
            </Col>
            <Col span={20}>
                <div className='single-player-wrapper'>
                    <ReactPlayer
                        className='single-react-player'
                        width='100%'
                        height='100%'
                        url={state.singleVideo.resultUrl}
                        controls
                    />
                </div>
            </Col>
        </Row>
    );
};
