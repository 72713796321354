import React from 'react';

export default function StarsIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none' viewBox='0 0 16 16'>
            <path
                fill='#fff'
                d='M6.5 16a.776.776 0 01-.73-.5l-1.244-3.238a.502.502 0 00-.287-.287L1 10.73a.78.78 0 010-1.458l3.238-1.245a.502.502 0 00.287-.288L5.77 4.5a.781.781 0 011.458 0l1.245 3.237a.502.502 0 00.288.288l3.218 1.238a.796.796 0 01.52.753.776.776 0 01-.5.713l-3.237 1.245a.502.502 0 00-.288.287L7.23 15.5a.776.776 0 01-.729.5zM2.75 5.5a.458.458 0 01-.428-.294l-.527-1.37a.228.228 0 00-.131-.131l-1.37-.527a.458.458 0 010-.856l1.37-.527a.229.229 0 00.131-.131L2.318.306a.469.469 0 01.377-.303.458.458 0 01.483.29l.527 1.37a.228.228 0 00.131.132l1.37.527a.458.458 0 010 .856l-1.37.527a.227.227 0 00-.131.131l-.527 1.37a.458.458 0 01-.428.294zM12.5 8a.5.5 0 01-.466-.32l-.714-1.856a.25.25 0 00-.144-.144l-1.855-.714a.5.5 0 010-.933l1.855-.713a.25.25 0 00.144-.144l.708-1.842a.514.514 0 01.412-.33.5.5 0 01.527.317l.713 1.855a.249.249 0 00.144.144l1.855.713a.5.5 0 010 .933l-1.855.714a.25.25 0 00-.144.144l-.713 1.855A.5.5 0 0112.5 8z'
            />
        </svg>
    );
}
