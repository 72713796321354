const config = {
    _id: 74,
    title: 'SMS Marketing',
    alias: 'sms-marketing',
    about: 'Generate clever, attention-grabbing messages that will make people want to take action. Whether you want to drive sales, build brand awareness, or simply communicate with your customers, Just Think has got you covered.',
    categories: ['Marketing'],
    tips: [
        'Provide positive and negative examples: Providing both positive and negative examples can help the model understand your preferences and generate a more personalized response.'
    ],
    prompts: [
        'Generate the SMS to introduce a new [product/service] to customers, involving saying how it is a perfect solution for [product benefit].',
        'Create an SMS to promote the launch of a new [product/service line], emphasizing the unique features and benefits it offers to customers.',
        'Generate an SMS to invite customers to a special [event/sale], mentioning the date, time, and location and how they can benefit from attending.',
        'Create an SMS to inform customers about a recent [update/improvement] in a product/service, highlighting the added value and increased efficiency it brings.',
        'Generate an SMS to offer a personalized discount on [products/services], emphasizing the value and savings available exclusively to customers.'
    ]
};

export default config;
