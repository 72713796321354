import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { App, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import PropTypes from 'prop-types';
import { BACKEND_URL } from '../../../../../../../../config';
import { beforeUpload } from '../../../../../../../../utils/upload';
import styles from './index.module.scss';

export default function AvatarUpload({ avatar, onUpdateCallback = () => {}, onRemoveCallback = () => {} }) {
    const [loading, setLoading] = useState(false);

    const { notification } = App.useApp();

    const [fileList, setFileList] = useState(
        avatar
            ? [
                  {
                      uid: '-1',
                      name: 'avatar',
                      status: 'done',
                      url: avatar
                  }
              ]
            : []
    );

    const onChange = (info) => {
        setFileList(() => [...info.fileList]);

        if (info.file.status === 'uploading') {
            setLoading(() => true);
        }

        if (info.file.status === 'done') {
            setFileList(() => [
                {
                    uid: '-1',
                    name: 'avatar',
                    status: 'done',
                    url: info.file.response.avatar
                }
            ]);

            onUpdateCallback(info.file.response.avatar);

            setLoading(() => false);
        }
    };

    const onRemove = (file) => {
        onRemoveCallback(file);
    };

    const uploadButton = <div>{loading ? <LoadingOutlined /> : <PlusOutlined />}</div>;

    return (
        <ImgCrop cropShape='round' className={styles.profile__avatar}>
            <Upload
                maxCount={1}
                name='avatar'
                listType='picture-circle'
                className={styles.profile__avatar__uploader}
                action={`${BACKEND_URL}/user/avatar`}
                showUploadList
                beforeUpload={async (file) => {
                    try {
                        return await beforeUpload(file, { maxFileSize: 5 });
                    } catch (error) {
                        notification.error({
                            placement: 'top',
                            message: error.message
                        });
                    }
                }}
                onChange={onChange}
                onRemove={onRemove}
                fileList={fileList}
                headers={{
                    'x-access-token': localStorage.getItem('BrainstormAuthToken')
                }}
            >
                {fileList.length >= 1 ? null : uploadButton}
            </Upload>
        </ImgCrop>
    );
}

AvatarUpload.propTypes = {
    avatar: PropTypes.string,
    onUpdateCallback: PropTypes.func.isRequired,
    onRemoveCallback: PropTypes.func.isRequired
};
