/* eslint-disable */

const config = {
    _id: 75,
    title: 'Generating Email Campaign Ideas',
    alias: 'generating-email-campaign-ideas',
    about: 'Generate fresh and creative ideas for your next campaign. Just Think can analyze your requirements and provide you with personalized and relevant ideas that can resonate with your target audience.',
    categories: ['Marketing'],
    tips: [
        'Use specific keywords related to your product or service in your prompts to get more personalized and relevant ideas from Just Think.',
        "Experiment with different prompts and tweak the generated ideas to fit your brand's voice and tone.",
        'Use Just Think as a starting point for your brainstorming sessions and combine its generated ideas with your own to come up with the perfect email campaign.'
    ],
    prompts: [
        'Can you give me [three] email campaign ideas for promoting [my new product] to [millennials] with a focus on [sustainability] and [social responsibility], while also incorporating [visual storytelling] and [gamification] elements to increase engagement?',
        'Can you suggest [five] unique email campaign ideas for boosting engagement for [my e-commerce store] during [the holiday season], with a focus on [personalization], [retargeting], [user-generated content], and [exclusivity] to incentivize purchases?',
        'I\'m planning to launch a [health and wellness] related email campaign for [women aged 30-50], can you help me come up with [ten] creative ideas that focus on [emotional well-being], [stress reduction], and [self-care], while also incorporating [video tutorials], [expert interviews], [podcasts], and [community building] to enhance the customer experience?',
        'I need [two] email campaign ideas for [re-engaging] my [inactive subscribers], who have not interacted with my brand in [the past six months], with a focus on [personalization], [incentives], [user-generated content], and [exclusivity] to encourage them to come back and engage with my brand.',
        'Can you suggest [three] email campaign ideas for [increasing brand awareness] for [my start-up] in [the B2B space] that focus on [thought-leadership], [content marketing], [partner promotions], [webinars], and [social media advertising] to generate leads and establish credibility in the market?'
    ]
};

export default config;
