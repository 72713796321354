export function cutStringByWordCount(string, wordCount) {
    const words = string.split(' ');

    if (words.length <= wordCount || !wordCount) {
        return string + ' ';
    }

    const slicedWords = words.slice(0, wordCount);

    return slicedWords.join(' ') + '... ';
}
