/* eslint-disable */
export const surveyConfig = {
    limitations: [
        'May occasionally generate incorrect information',
        'May occasionally produce harmful instructions',
        'Limited knowledge of world & events after 2021',
        'Difficulty in understanding sarcasm'
    ],
    choices: [
        {
            option: 'Business & Marketing',
            pricing: 'A',
            examples: [
                'Generate 10 business names for a home & garden store',
                'Generate ideas for a new business.',
                'Write a business plan for my new',
                'Give me some ideas for an ad about car cleaning products?',
                'Write a witty product description for a new dog leash'
            ]
        },
        {
            option: 'Designer',
            pricing: 'A',
            examples: [
                'Generate questions about "Your Topic"',
                'Explain global warming in simple terms',
                'Write me a love poem to my spouse.',
                'Please recommend a workout plan',
                'Can you suggest some things to do in Paris.'
            ]
        },
        {
            option: 'Educator',
            pricing: 'B',
            examples: [
                'Generate ideas for fun and engaging classroom activities.',
                'Create a lesson plan for a senior high school English class.',
                'Generate an outline for a lesson plan.',
                'Provide personalized advice or recommendations for individual students that [Enter Your Text].',
                'Generate a list of creative ideas for classroom projects.',
                'Create a tutorial or instruction guide for using educational technology.'
            ]
        },
        {
            option: 'Student',
            pricing: 'C',
            examples: [
                'Generate ideas for fun and engaging classroom activities.',
                'Create a quiz to help review material for an upcoming exam about [Enter your Text]',
                'Provide feedback on an essay draft and suggest ways to improve it [Paste essay]',
                'Generate a list of recommended books or resources for further reading on [Topic]'
            ]
        },
        {
            option: 'Writers',
            pricing: 'B',
            examples: [
                'Can you help me come up with a title for my children\'s story about elephants?',
                'Generate some Blog post ideas about Food.',
                'Create a Blog outline about global warming.',
                'Give me some ideas for a short story about a fairy tale land.'
            ]
        },
        {
            option: 'Researcher',
            pricing: 'B',
            examples: [
                'What are some good survey questions to ask about the public\'s opinions on climate change, and how can I analyze the data?',
                'Can you help me understand how to use regression analysis to test the relationship between education and income in my dataset?',
                'Can you help me write a research proposal and a grant application for my study on the use of virtual reality technology to enhance learning in classrooms?',
                'Explain global warming in simple terms'
            ]
        },
        {
            option: 'HR & Recruitment',
            pricing: 'B',
            examples: [
                'Generate questions about "Your Topic"',
                'Explain global warming in simple terms',
                'Write me a love poem to my spouse.',
                'Please recommend a workout plan',
                'Can you suggest some things to do in Paris.'
            ]
        },
        {
            option: 'Legal Professional',
            pricing: 'A',
            examples: [
                'Generate questions about "Your Topic"',
                'Explain global warming in simple terms',
                'Write me a love poem to my spouse.',
                'Please recommend a workout plan',
                'Can you suggest some things to do in Paris.'
            ]
        },
        {
            option: 'Content Creation',
            pricing: 'B',
            examples: [
                'Generate some questions to engage with my followers.',
                'Generate a list of creative ideas for fashion content campaigns.',
                'Generate an outline for a blog post.',
                'Generate viral YouTube video ideas',
                'Write 5 Instagram captions about love'
            ]
        },
        {
            option: 'Just Playing Around',
            pricing: 'C',
            examples: [
                'Generate questions about "Your Topic"',
                'Explain global warming in simple terms',
                'Write me a love poem to my spouse.',
                'Please recommend a workout plan',
                'Can you suggest some things to do in Paris.'
            ]
        }
    ]
};
