import imgCharacter1 from './images/imagesCharacters/1.png';
import imgCharacter2 from './images/imagesCharacters/2.png';
import imgCharacter3 from './images/imagesCharacters/3.png';
import imgCharacter4 from './images/imagesCharacters/4.png';
import imgCharacter5 from './images/imagesCharacters/5.png';
import imgCharacter6 from './images/imagesCharacters/6.png';

export const bookStep = {
    0: {
        title: 'Create a book',
        subTitleMobile: 'Create your own book in minutes',
        subTitleDesktop: 'Create your own book in minutes'
    },
    1: {
        title: 'Book Details',
        subTitleMobile: 'Enter some details so we can get started on your story',
        subTitleDesktop: 'Enter some details so we can get started on your story...'
    },
    2: {
        title: 'Your Story',
        subTitleMobile: 'We generated this story for you. Edit anytime.',
        subTitleDesktop: 'We generated this story for you. Edit your stories anytime.'
    },
    3: {
        title: 'Choose Your Voice',
        subTitleMobile: 'Select a voice to narrate your story',
        subTitleDesktop: 'Choose a voice to narrate your story'
    },
    4: {
        title: 'Choose Your Characters',
        subTitleMobile: 'Either you can choose or upload a drawing',
        subTitleDesktop: 'Either you can choose a character or you can upload a drawing'
    },
    5: {
        title: '',
        subTitleMobile: '',
        subTitleDesktop: ''
    }
};

export const coverBook = [
    {
        isAvailable: true,
        title: 'Kids Book'
    },
    {
        isAvailable: false,
        title: 'Cook Book'
    },
    {
        isAvailable: false,
        title: 'E-Book'
    },
    {
        isAvailable: false,
        title: 'Graphic Novel'
    }
];

export const voices = {
    male: [
        {
            id: 0,
            name: 'Lynda Ten',
            voiceId: 'tFuO9cetUs10ifuAAYFV',
            imageId: 'tFuO9cetUs10ifuAAYFV',
            language: 'English(US)'
        },
        {
            id: 1,
            name: 'Jeda Costa',
            voiceId: 'ErXwobaYiN019PkySvjV',
            imageId: 'ErXwobaYiN019PkySvjV',
            language: 'English(US)'
        },
        {
            id: 2,
            name: 'Ema Willium',
            voiceId: 'VR6AewLTigWG4xSOukaG',
            imageId: 'VR6AewLTigWG4xSOukaG',
            language: 'English(US)'
        }
    ],
    female: [
        {
            id: 12,
            name: 'Emma',
            voiceId: 'EXAVITQu4vr4xnSDxMaL',
            imageId: 'EXAVITQu4vr4xnSDxMaL',
            language: 'English(US)'
        },
        {
            id: 13,
            name: 'Sophia',
            voiceId: 'MF3mGyEYCl7XYWbV9V6O',
            imageId: 'MF3mGyEYCl7XYWbV9V6O',
            language: 'English(US)'
        },
        {
            id: 14,
            name: 'Ava',
            voiceId: '21m00Tcm4TlvDq8ikWAM',
            imageId: '21m00Tcm4TlvDq8ikWAM',
            language: 'English(US)'
        }
    ]
};

export const imagesCharacters = [
    {
        src: imgCharacter1,
        nameCharacter: '1'
    },
    {
        src: imgCharacter2,
        nameCharacter: '2'
    },
    {
        src: imgCharacter3,
        nameCharacter: '3'
    },
    {
        src: imgCharacter4,
        nameCharacter: '4'
    },
    {
        src: imgCharacter5,
        nameCharacter: '5'
    },
    {
        src: imgCharacter6,
        nameCharacter: '6'
    }
];

export const checkList = [
    {
        title: 'Make sure the character is drawn on white piece of paper without lines, wrinkles, or tears.'
    },
    {
        title: 'Make sure the drawing is well lit. To minimize shadows, hold the camera further away and zoom in on the drawing'
    },
    {
        title: `Don’t include any identifiable information, offensive content (see our terms of service), or drawings that infringe on the 
    copyrights of others.`
    }
];
