/* eslint-disable */

export const bookCover = {
    _id: 9,
    title: 'Book cover',
    alias: 'book-cover',
    about: 'Create a book cover',
    image: 'book-cover',
    categories: ['illustration'],
    tips: [],
    prompts: [
        {
            text: 'Create an artistic book cover for a novel classified under the [type of genre] genre. The cover must capture the mood and tone of the genre, with \'[title name]\' elegantly displayed. Think about using color schemes and illustrations that resonate with the genre\'s traditional aesthetics, adding a modern twist that will attract today\'s readers.',
            image: 'artistic-book-cover'
        },
        {
            text: 'Conceptualize a minimalist book cover for \'[title name],\' a novel in the [type of genre] genre. The design should be clean and impactful, using minimal elements to convey the novel\'s theme. Opt for a compelling typography for the title and a single, powerful image or abstract design that hints at the narrative\'s essence.',
            image: 'fantasy-book-cover'
        },
        {
            text: 'Design a book cover for a [genre] titled \'[title name]\' Feature the title prominently and use fantasy elements like dragons or magic symbols.',
            image: 'minimalist-book-cover'
        }
    ]
};
