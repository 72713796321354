export default class AnimatedText {
    constructor({ el, speed }) {
        this.el = el;
        this.lines = [];
        this.lIdx = 0;
        this.speed = speed ?? 10;

        this._paused = true;
    }

    pause(status) {
        this._paused = status;
    }

    line(text) {
        this.lines.push([text]);

        if (this._paused) {
            this._paused = false;
            this.animate();
        }
    }

    continue(text) {
        this.lines[this.lines.length - 1]?.push(text);

        if (this._paused) {
            this._paused = false;
            this.animate();
        }
    }

    animate() {
        if (this._paused) return;

        const typing = () => {
            if (this._paused) return;

            const line = this.lines[this.lIdx];

            const text = line?.join('') || '';

            this.el.innerHTML = text;

            if (text) setTimeout(typing, this.speed);
            else this._paused = true;
        };

        if (!this._paused) typing();
    }
}
