/* eslint-disable */

const config = {
    _id: 89,
    title: 'Generate Pinterest Ads targeting ideas',
    alias: 'generate-pinterest-ads-targeting-ideas',
    about: 'Generate targeted Pinterest ad ideas effortlessly.',
    categories: ['Social Media & Ads'],
    tips: [
        'Provide Just Think with as much information as possible about your target audience, including demographics, interests, and behaviors. This will help Just Think generate more specific and relevant targeting ideas.',
        'Be clear about your business goals and the purpose of your Pinterest ads. This will help Just Think suggest ad formats and targeting options that align with your objectives.',
        'Experiment with different combinations of targeting options and ad formats to find the ones that work best for your business. Pinterest provides a variety of ad formats, including Promoted Pins, Promoted Video Pins, and Promoted Carousel Pins, each with their own unique targeting options.'
    ],
    prompts: [
        'I am trying to generate some ideas for Pinterest ads targeting [product/service] for [specific audience]. Can you help me with some ad formats and targeting options that could appeal to [specific interest/behavior] and help me achieve [specific business goal]?',
        'I want to create Pinterest ads that reach a wider audience, specifically [demographic]. Could you provide me with some ideas for ad formats and targeting options that would be effective in reaching this group? Additionally, are there any other [interests/behaviors] that you would suggest targeting to improve ad performance?',
        'As a [business type], I\'m looking to increase brand awareness for [product/service] using Pinterest ads. Can you provide me with some ad formats and targeting options that have been proven to be successful in generating brand awareness? Additionally, are there any [interests/behaviors] that you recommend targeting for this type of campaign?',
        'I\'m looking to promote my [product/service] to a specific [demographic/interest/behavior] audience on Pinterest. Can you recommend some ad formats and targeting options that would be effective in reaching this audience? Also, are there any particular [seasons/events] that you suggest targeting for this type of campaign?',
        'I\'m struggling to come up with new ideas for Pinterest ads that will help me to drive [specific business goal]. Can you help me come up with some creative ad formats and targeting options that will be effective in achieving this goal? Additionally, are there any [interests/behaviors] that you recommend targeting for this type of campaign?'
    ]
};

export default config;
