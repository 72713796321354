/* eslint-disable */

const config = {
    _id: 113,
    title: 'Cognitive Load Manager',
    alias: 'cognitive-load-manager',
    about: 'Helps educators manage cognitive load in their students, suggesting strategies for presenting information in a way that optimizes understanding and retention.',
    categories: ['Education'],
    tips: [
        'Break complex topics down into smaller, more manageable chunks.',
        'Use a variety of teaching methods to cater to different learning styles.',
        'Regularly check in with students to ensure they\'re not feeling overwhelmed.'
    ],
    prompts: [
        'How can I present [complex topic] in a way that minimizes cognitive load?',
        'Suggest a lesson structure for teaching [complex topic].',
        'What strategies can I use to reduce cognitive load when teaching [subject]?',
        'Design a revision plan for [subject] that manages cognitive load effectively.',
        'Provide tips for students to manage their own cognitive load during self-study.'
    ]
};

export default config;
