import { dollarFormatterFunc } from '../../../../../../../utils/dollarFormater';

export const initState = {
    loaded: false,
    total: {
        action: '',
        subtotalPrice: dollarFormatterFunc('00.00'),
        discount: dollarFormatterFunc('00.00'),
        priceDiff: dollarFormatterFunc('00.00'),
        price: dollarFormatterFunc('00.00'),
        tax: dollarFormatterFunc('00.00')
    },
    plan: {
        words: {
            limit: null,
            value: null
        },
        images: {
            limit: null,
            value: null
        },
        audio: {
            limit: null,
            value: null
        },
        seats: {
            limit: null,
            value: null
        },
        period: null,
        price: null,
        status: null,
        expiredAt: null,
        planType: null
    },
    cards: [
        {
            id: null,
            brand: null,
            last4: null,
            expMonth: null,
            expYear: null
        }
    ],
    discount: {
        _id: '',
        code: '',
        error: '',
        loading: false
    },
    discounts: [],

    payments: [],

    loader: {
        cards: false,
        getPlan: true,
        updatingPlan: false,
        cancelingPlan: false,
        calculate: false,
        addCardModal: false
    },

    activeModals: {
        billingDowngrade: false,
        billingUpdate: false,
        discountsHistory: false,
        paymentHistory: false,
        addCard: false,
        updatePlan: false,
        cancelPlan: false,
        addUserValue: false
    },

    intent: {
        clientSecret: null,
        paymentMethod: null
    }
};

export function billingReducer(draft, action) {
    switch (action.type) {
        case 'CALCULATE_START': {
            draft.loader.calculate = true;
            break;
        }
        case 'CALCULATE_PRICE': {
            draft.total.price = dollarFormatterFunc(action.payload.price || '00.00');
            draft.total.subtotalPrice = dollarFormatterFunc(action.payload.subtotalPrice || '00.00');
            draft.total.priceDiff = dollarFormatterFunc(action.payload.priceDiff || '00.00');
            draft.total.discount = dollarFormatterFunc(-action.payload.discount || '00.00');
            draft.loader.calculate = false;
            break;
        }
        case 'CALCULATE_END': {
            draft.loader.calculate = false;
            break;
        }
        case 'GET_BILLING': {
            draft.loader.getPlan = true;
            break;
        }
        case 'GET_BILLING_SUCCESS': {
            draft.loaded = true;
            draft.loader.getPlan = false;
            draft.plan = action.payload.plan ?? initState.plan;
            draft.cards = action.payload.card ?? initState.cards;
            draft.payments = action.payload.payments ?? initState.payments;
            draft.intent.paymentMethod = action.payload.intent?.paymentMethod;
            draft.discounts = action.payload.plan.discounts ?? initState.discounts;

            const discount = action.payload.plan.discounts.find((discount) => {
                return discount.status === 'added';
            });

            draft.discount._id = discount?.discount || '';
            draft.discount.code = discount?.code || '';

            break;
        }
        case 'GET_BILLING_ERROR': {
            draft.loader.getPlan = false;
            break;
        }
        case 'BILLING_MODAL_LOADER': {
            draft.loader.addCardModal = action.payload;
            break;
        }
        case 'UPDATE_PLAN': {
            draft.loader.updatingPlan = true;
            break;
        }
        case 'UPDATE_PLAN_SUCCESS': {
            draft.total.price = dollarFormatterFunc('00.00');
            draft.total.priceDiff = dollarFormatterFunc('00.00');
            draft.total.subtotalPrice = dollarFormatterFunc('00.00');
            draft.total.discount = dollarFormatterFunc('00.00');
            draft.discount._id = '';
            draft.discount.code = '';
            draft.discount.error = '';
            break;
        }
        case 'UPDATE_PLAN_ERROR': {
            draft.loader.updatingPlan = false;
            break;
        }
        case 'CANCEL_PLAN': {
            draft.loader.cancelingPlan = true;
            break;
        }
        case 'CANCEL_PLAN_SUCCESS': {
            draft.loader.cancelingPlan = false;
            draft.plan.status = 'canceled';
            break;
        }
        case 'CANCEL_PLAN_ERROR': {
            draft.loader.cancelingPlan = false;
            break;
        }
        case 'SETUP_INTENT': {
            draft.loader.setupIntent = true;
            break;
        }
        case 'SETUP_INTENT_SUCCESS': {
            draft.loader.setupIntent = false;
            draft.intent.clientSecret = action.payload.clientSecret;
            draft.intent.paymentMethod = action.payload.paymentMethod;
            break;
        }
        case 'SETUP_INTENT_ERROR': {
            draft.loader.setupIntent = false;
            break;
        }
        case 'CONFIRM_INTENT': {
            draft.loader.confirmIntent = true;
            break;
        }
        case 'CONFIRM_INTENT_SUCCESS': {
            draft.loader.confirmIntent = false;
            draft.cards = action.payload;
            break;
        }
        case 'CONFIRM_INTENT_ERROR': {
            draft.loader.confirmIntent = false;
            break;
        }
        case 'DETACH_CARD': {
            draft.loader.cards = true;
            break;
        }
        case 'DETACH_CARD_SUCCESS': {
            draft.loader.cards = false;
            draft.cards = action.payload.cards;
            break;
        }
        case 'DETACH_CARD_ERROR': {
            draft.loader.cards = false;
            break;
        }
        case 'CHANGE_CARD': {
            draft.loader.cards = true;
            break;
        }
        case 'CHANGE_CARD_SUCCESS': {
            draft.loader.cards = false;
            draft.intent.paymentMethod = action.payload.paymentMethod;
            break;
        }
        case 'CHANGE_CARD_ERROR': {
            draft.loader.cards = false;
            break;
        }
        case 'OPEN_MODAL': {
            draft.activeModals[action.payload] = true;
            break;
        }
        case 'CLOSE_MODAL': {
            draft.activeModals[action.payload] = false;
            draft.loader.updatingPlan = false;
            break;
        }
        // DISCOUNT STATE
        case 'ADD_DISCOUNT': {
            draft.discount.loaded = true;
            draft.discount.code = action.payload;
            break;
        }
        case 'ADD_DISCOUNT_SUCCESS': {
            draft.discount.error = '';
            draft.discount.loaded = false;
            draft.discount._id = action.payload._id;
            draft.discount.code = action.payload.code;
            break;
        }
        case 'ADD_DISCOUNT_ERROR': {
            draft.discount.loaded = false;
            draft.discount._id = '';
            draft.discount.error = action.payload;
            break;
        }
        case 'REMOVE_DISCOUNT': {
            draft.discount.loaded = true;
            break;
        }
        case 'REMOVE_DISCOUNT_SUCCESS': {
            draft.discount.loaded = false;
            draft.discount._id = '';
            break;
        }
        case 'REMOVE_DISCOUNT_ERROR': {
            draft.discount.loaded = false;
            break;
        }
        case 'CLEAR_DISCOUNT_ERROR': {
            draft.discount.error = '';
            break;
        }
        case 'RESET_DISCOUNT': {
            draft.discount.loaded = false;
            draft.discount._id = '';
            draft.discount.code = '';
            draft.discount.error = '';
            break;
        }
        default:
            return draft;
    }
}
