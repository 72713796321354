const config = {
    _id: 102,
    title: 'Experiential Learning Planner',
    alias: 'experiential-learning-planner',
    about: 'Generate ideas for experiential learning activities in various subjects.',
    categories: ['Education'],
    tips: [
        'Be specific about the subject and grade level for age-appropriate activity suggestions.',
        'Take into account the resources and facilities available to your students when planning experiential learning activities.'
    ],
    prompts: [
        'Create an experiential learning activity for [grade level] students in [subject].',
        'Suggest a service-learning project related to [specific topic].',
        'Design an outdoor learning activity for [grade level] students on [specific topic].',
        'What experiential learning activities could help students understand [specific concept] in [subject]?',
        'Suggest a hands-on project that [grade level] students can do at home to learn about [specific topic].'
    ]
};

export default config;
