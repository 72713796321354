import React, { useEffect, useState } from 'react';
import Icon from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import _ from 'lodash';
import BulbIcon from '../../../../../../components/Icons/BulbIcon/BulbIcon';
import { surveyConfig } from '../../../../../../constants/surveyConfig';
import { useBreakpoint } from '../../../../../../hooks/media';
import { useAuthContext } from '../../../../../AuthPage/context';
import { useConversationContext } from '../Conversation/context';

export default function UserSurvey({ message }) {
    const { conversation } = useConversationContext();

    const auth = useAuthContext();

    const [options, setOptions] = useState([]);

    const { state, actions } = conversation;

    const { isMobile } = useBreakpoint();

    const examplesNumber = isMobile ? 2 : 4;

    useEffect(() => {
        let foundOption = surveyConfig.choices.find((element) => element.option === auth?.profile?.userProf);

        if (!foundOption) foundOption = surveyConfig.choices[0];

        setOptions(() =>
            state.loader.updateOptions
                ? _.sampleSize(foundOption?.examples, examplesNumber)
                : foundOption?.examples?.slice(0, examplesNumber)
        );
        actions.updateOptions(false);
    }, []);

    const onExampleClick = (value) => {
        message.actions.updateInput(value.target.innerText);
    };

    return (
        <div className='user-survey-wrap'>
            <Row gutter={[16, 16]} align='middle' className='user-survey-inner'>
                <Col span={24}>
                    <Icon component={BulbIcon} className='user-survey-icon' />
                    <Typography.Title level={2} className='user-survey-title'>
                        How can I help you?
                    </Typography.Title>
                    <Typography.Paragraph align='center' className='user-survey-text'>
                        Ask anything & get instant help
                    </Typography.Paragraph>
                </Col>
            </Row>

            <Row gutter={[16, 16]} align='bottom' className='user-survey-inner'>
                <Col span={24}>
                    <ul className='user-survey-examples'>
                        {options?.map((example, keyExample) => {
                            return (
                                <li onClick={onExampleClick} key={keyExample}>
                                    <span>{example}</span>
                                </li>
                            );
                        })}
                    </ul>
                </Col>
            </Row>
        </div>
    );
}
