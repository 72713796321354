import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Icon, {
    CreditCardOutlined,
    FileTextOutlined,
    GiftOutlined,
    InfoCircleOutlined,
    LoginOutlined,
    MoreOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import { App, Avatar, Button, Col, Form, Menu, Row, Switch as SwitchButton, Tooltip, Typography } from 'antd';
import ArrowTopIcon from '../../../../../components/Icons/ArrowTop/ArrowTop';
import DayNightIcon from '../../../../../components/Icons/DayNightIcon';
import MoonIcon from '../../../../../components/Icons/MoonIcon/MoonIcon';
import SunIcon from '../../../../../components/Icons/SunIcon/SunIcon';
import ZipIcon from '../../../../../components/Icons/ZipIcon';
import useModal from '../../../../../hooks/useModal';
import ModalForm from '../../../../../shared/components/ModalForm';
import { useAuthContext } from '../../../../AuthPage/context';
import { DashboardContext } from '../../../context';
import './index.scss';

export const MenuSettings = ({ onClose }) => {
    const context = useContext(DashboardContext);

    const [form] = Form.useForm();

    const auth = useAuthContext();

    const org = auth.org.getCurrent();

    const [isSwitchEnabled, setIsSwitchEnabled] = useState(auth.profile?.chatSettings?.customInstruction || false);

    const history = useHistory();

    const { notification } = App.useApp();
    const onSwitchChange = (checked) => {
        setIsSwitchEnabled(checked);
    };
    const onThemeModeChange = async (status) => {
        try {
            await auth.updateProfile(
                {
                    uiSettings: {
                        darkMode: !status,
                        profileBg: auth.profile.uiSettings?.profileBg
                    }
                },
                false
            );
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };
    const onCreateNewInstruction = async (values) => {
        try {
            await auth.updateProfile({
                systemMessages: { first: values.introduce, second: values.respond },
                chatSettings: { customInstruction: isSwitchEnabled }
            });
            setModalOpen(false);
        } catch (err) {
            notification.error({
                placement: 'top',
                message: 'Something went wrong! Please try again.'
            });
        }
    };

    const modalTitle = (
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
            <span>Custom Instructions</span>
            <Tooltip
                placement='topLeft'
                arrow={false}
                overlayClassName='modal-tooltip'
                title='Customize Just Think’s responses based on your preferences. Modify or remove custom instructions at any time for future conversations.'
            >
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    );

    const { setModalOpen, modal: ModalCustomInstructions } = useModal(ModalForm, {
        title: modalTitle,
        componentProps: {
            form,
            onCreateNewInstruction
        },
        modalProps: {
            onOk: () => form.submit(),
            onCancel: () => setModalOpen(false),
            confirmLoading: false,
            width: 585,
            centered: true,
            closable: true,
            okText: 'Save',
            className: 'custom-instruction-modal',
            getContainer: () => document.querySelector('#app-layout'),
            footer: (
                <React.Fragment>
                    <div className='switch-button'>
                        <span>Enable for new chats</span>
                        <SwitchButton checked={isSwitchEnabled} onChange={onSwitchChange} />
                    </div>
                    <Button key='back' onClick={() => setModalOpen(false)}>
                        Cancel
                    </Button>
                    <Button key='submit' type='primary' loading={false} onClick={() => form.submit()}>
                        Save
                    </Button>
                </React.Fragment>
            )
        }
    });

    const items = [
        {
            key: 'mob-menu',
            style: { width: '100%', display: 'flex', flexDirection: 'column-reverse' },
            label: (
                <Row gutter={[16, 16]} justify='middle' wrap={false}>
                    <Col className='menu-side__bottom__main' style={{ width: '49px' }}>
                        {auth.profile.avatar ? (
                            <Avatar src={auth.profile.avatar} />
                        ) : (
                            <Avatar
                                style={{
                                    backgroundColor: auth.profile.uiSettings?.profileBg
                                }}
                            >
                                {auth.profile.fname?.charAt(0).toLocaleUpperCase()}
                            </Avatar>
                        )}
                    </Col>
                    <Col style={{ width: 'calc(100% - 92px)' }}>
                        <Row>
                            <Col span={24}>
                                <Typography.Title
                                    level={4}
                                    style={{ marginBottom: 0, fontWeight: 500 }}
                                    ellipsis={{ rows: 1 }}
                                >
                                    {auth.profile.fname || ''} {auth.profile.lname || ''}
                                </Typography.Title>
                            </Col>
                            <Col span={24} style={{ fontSize: '12px' }}>
                                <span>{org?.billingPlan?.planType}</span>
                            </Col>
                        </Row>
                    </Col>
                    <Col style={{ marginRight: '8px', display: 'flex' }}>
                        <ArrowTopIcon />
                    </Col>
                </Row>
            ),
            children: [
                {
                    key: 'logout',
                    className: 'mob-menu__border',
                    label: (
                        <Row gutter={[16, 16]}>
                            <Col>
                                <LoginOutlined />
                            </Col>
                            <Col>Sign out</Col>
                        </Row>
                    ),
                    onClick: () => auth.logout()
                },
                {
                    key: 'billing',
                    label: (
                        <Row gutter={[16, 16]}>
                            <Col>
                                <CreditCardOutlined />
                            </Col>
                            <Col>My Plan</Col>
                        </Row>
                    ),
                    onClick: () => {
                        history.push('/settings/billing');

                        if (typeof onClose === 'function') onClose();
                    }
                },
                {
                    key: 'profile',
                    label: (
                        <Row gutter={[16, 16]}>
                            <Col>
                                <UserOutlined />
                            </Col>
                            <Col>Profile</Col>
                        </Row>
                    ),
                    onClick: () => {
                        history.push('/settings/profile');

                        if (typeof onClose === 'function') onClose();
                    }
                },
                {
                    key: 'team',
                    label: (
                        <Row gutter={[16, 16]}>
                            <Col>
                                <TeamOutlined />
                            </Col>
                            <Col>Team</Col>
                        </Row>
                    ),
                    onClick: () => {
                        history.push('/settings/team');

                        if (typeof onClose === 'function') onClose();
                    }
                },
                history.location.pathname === '/chat'
                    ? {
                          key: 'custom-instructions',
                          label: (
                              <Row gutter={[16, 16]}>
                                  <Col>
                                      <FileTextOutlined />
                                  </Col>
                                  <Col>Custom Instructions</Col>
                              </Row>
                          ),
                          onClick: async () => {
                              await context.actions.instruction.createInstruction(true);
                              setModalOpen(true);
                          }
                      }
                    : null,
                {
                    key: 'dark-mode',
                    className: 'app-layout-wrap__dark-mode',
                    label: (
                        <Row gutter={[16, 16]}>
                            <Col>
                                <DayNightIcon />
                            </Col>
                            <Col>
                                <SwitchButton
                                    onChange={(status) => onThemeModeChange(status)}
                                    checkedChildren={<Icon component={SunIcon} />}
                                    unCheckedChildren={<Icon component={MoonIcon} />}
                                    checked={!auth?.profile?.uiSettings?.darkMode}
                                />
                            </Col>
                        </Row>
                    )
                }
            ]
        }
    ];

    const onUpdateBilling = async () => {
        await context.actions.billing.modal(true);
    };

    return (
        <div className='menu-side__bottom'>
            <a
                href='https://justthink.tolt.io/login'
                target='_blank'
                className='menu-side__bottom__ref'
                rel='noreferrer'
            >
                <GiftOutlined />
                <Typography.Text>Refer and earn</Typography.Text>
            </a>
            {org?.billingPlan?.planType === 'free' || !org?.billingPlan?.planType ? (
                <Button className='menu-side__bottom__update' onClick={onUpdateBilling}>
                    <Icon component={ZipIcon} /> Upgrade
                </Button>
            ) : null}
            <Menu
                mode='horizontal'
                triggerSubMenuAction='click'
                expandIcon={<MoreOutlined style={{ color: '#000' }} />}
                className='menu-side__settings'
                items={items}
                getPopupContainer={() => document.querySelector('.menu-side__bottom')}
            />
            {ModalCustomInstructions}
        </div>
    );
};
