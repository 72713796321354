import { useImmerReducer } from 'use-immer';
import { initState, toolsReducer } from './reducer';
import service from './service';

export const useTools = () => {
    const [state, dispatch] = useImmerReducer(toolsReducer, initState);

    const createTools = async (payload) => {
        try {
            dispatch({
                type: 'TOOLS_CREATE_START'
            });

            const response = await service.toolsService.create(payload);

            dispatch({
                type: 'TOOLS_CREATE_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'TOOLS_CREATE_END'
            });
        }
    };

    const removeTools = async (payload) => {
        try {
            dispatch({
                type: 'TOOLS_DELETE_START'
            });

            const response = await service.toolsService.remove(payload);

            dispatch({
                type: 'TOOLS_DELETE_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'TOOLS_DELETE_END'
            });
        }
    };

    const createPrompt = async (payload, id) => {
        try {
            dispatch({
                type: 'PROMPT_CREATE_START'
            });

            const response = await service.promptsService.update(payload, id);

            dispatch({
                type: 'PROMPT_CREATE_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'PROMPT_CREATE_END'
            });
        }
    };
    const editToolTitle = (id) => {
        dispatch({
            type: 'EDIT_MODE',
            payload: id
        });
    };

    const updateToolTitle = async (id, payload) => {
        try {
            const response = await service.toolsService.update(id, payload);

            dispatch({
                type: 'UPDATE_TITLE',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'UPDATE_TITLE'
            });
        }
    };
    const createTip = async (payload, id) => {
        try {
            dispatch({
                type: 'TIP_CREATE_START'
            });

            const response = await service.tipsService.update(payload, id);

            dispatch({
                type: 'TIP_CREATE_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'TIP_CREATE_END'
            });
        }
    };

    const removePrompt = async (payload, id) => {
        try {
            dispatch({
                type: 'PROMPT_DELETE_START'
            });

            const response = await service.promptsService.remove(payload, id);

            dispatch({
                type: 'PROMPT_DELETE_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'PROMPT_DELETE_END'
            });
        }
    };

    const removeTip = async (payload, id) => {
        try {
            dispatch({
                type: 'TIP_DELETE_START'
            });

            const response = await service.tipsService.remove(payload, id);

            dispatch({
                type: 'TIP_DELETE_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'TIP_DELETE_END'
            });
        }
    };

    const promptsList = async (id) => {
        try {
            dispatch({
                type: 'PROMPTS_LIST_START'
            });

            const response = await service.promptsService.list(id);

            dispatch({
                type: 'PROMPTS_LIST_END',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'PROMPTS_LIST_END'
            });
        }
    };

    const aboutMore = () => {
        dispatch({
            type: 'ABOUT_MORE'
        });
    };

    const promptsView = () => {
        dispatch({
            type: 'PROMPTS_VIEW'
        });
    };

    const tipsView = () => {
        dispatch({
            type: 'TIPS_VIEW'
        });
    };

    const modals = {
        createTools: async (payload) => {
            dispatch({
                type: 'TOOLS_MODAL_ADD',
                payload
            });
        },
        removeTools: async (payload) => {
            dispatch({
                type: 'TOOLS_MODAL_REMOVE',
                payload
            });
        },
        createPrompt: async (payload) => {
            dispatch({
                type: 'PROMPTS_MODAL_STATUS',
                payload
            });
        },
        createTip: async (payload) => {
            dispatch({
                type: 'TIPS_MODAL_STATUS',
                payload
            });
        },
        removePrompt: async (payload) => {
            dispatch({
                type: 'PROMPTS_MODAL_REMOVE',
                payload
            });
        },
        removeTip: async (payload) => {
            dispatch({
                type: 'TIPS_MODAL_REMOVE',
                payload
            });
        }
    };

    const actions = {
        modals,
        removeTools,
        createTools,
        promptsList,
        removePrompt,
        editToolTitle,
        updateToolTitle,
        removeTip,
        createPrompt,
        createTip,
        promptsView,
        aboutMore,
        tipsView
    };

    return {
        state,
        actions
    };
};
