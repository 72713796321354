import React from 'react';

export default function DocsDownload() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='32' height='31' fill='none' viewBox='0 0 32 31'>
            <path
                stroke='#000'
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M11.3 22.75H20M15.65 6.8v10.15m0 0l5.075-5.074M15.65 16.95l-5.075-5.075M15.65 30c8.008 0 14.5-6.492 14.5-14.5S23.66 1 15.65 1C7.642 1 1.15 7.492 1.15 15.5S7.642 30 15.65 30z'
            />
        </svg>
    );
}
