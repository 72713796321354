import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Spin, Table, Typography } from 'antd';
import { useBreakpoint } from '../../../../../../hooks/media';
import { WhiteCover } from '../../../../../../shared/components/WhiteCover';
import { useDocument } from '../../../DocumentPage/hooks';
import { SingleRow } from './components/SingleRow';
import styles from './index.module.scss';

export const DocTab = ({ setDocToken }) => {
    const history = useHistory();

    const { actions, state } = useDocument();

    const { isMobile } = useBreakpoint();

    const columns = [
        {
            title: 'title',
            dataIndex: 'title',
            render: (text, record) => (
                <SingleRow item={record} setDocToken={setDocToken} history={history}>
                    {text}
                </SingleRow>
            )
        }
    ];

    const dataSorted =
        state.list.data?.map((item, key) => ({
            key: item._id,
            title: item.title || 'Untitled document',
            html: item.html
        })) || [];

    useEffect(() => {
        (async () => {
            await actions.list({ page: 1 });
        })();
    }, []);

    return (
        <Spin spinning={state.loader.list}>
            <WhiteCover padding='47px 93px'>
                <Row align='middle' justify='space-between' className={styles.tabs__docs}>
                    {!isMobile && (
                        <Col>
                            <Typography.Title className={styles.tabs__title} level={2}>
                                Docs
                            </Typography.Title>
                        </Col>
                    )}
                    <Table
                        className={styles.docs__table}
                        grid={{
                            gutter: 30
                        }}
                        pagination={{
                            showSizeChanger: false,
                            onChange: async (page) => await actions.list({ page }),
                            total: state.list.pagination.total,
                            current: state.list.pagination.currentPage,
                            pageSize: 5,
                            hideOnSinglePage: true
                        }}
                        columns={columns}
                        dataSource={dataSorted}
                    />
                </Row>
            </WhiteCover>
        </Spin>
    );
};
