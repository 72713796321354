const config = {
    _id: 67,
    title: 'Summarize Meeting Notes',
    alias: 'summarize-meeting-notes',
    about: 'Turn boring meeting minutes into engaging summaries. highlight key points, offering quick overviews or detailed summaries.',
    categories: ['Blog Content'],
    tips: [
        'Be clear and concise: Provide clear and concise notes that highlight the main points discussed during the meeting.'
    ],
    prompts: [
        'Can you summarize a meeting on [topic of meeting] by highlighting the key takeaways? The notes of the meeting: [add your notes].',
        'Can you summarize the objectives discussed in a meeting and the action items decided? The notes of the meeting: [add your notes].',
        'Can you summarize the decisions made during a meeting about [specific issue] and the next steps outlined? The notes of the meeting: [add your notes].',
        'Can you summarize the progress update given in a meeting on [project/task] and the future plans discussed? The notes of the meeting: [add your notes].',
        'Can you summarize the key points raised during a [team/department/etc.] meeting and the solutions proposed? The notes of the meeting: [add your notes].'
    ]
};

export default config;
