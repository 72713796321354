/* eslint-disable */

const config = {
    _id: 108,
    title: 'Concept Explainer',
    alias: 'concept-explainer',
    about: "Take complex concepts and explain them in a simple, understandable way. It's a useful tool for breaking down complex subject matter into digestible parts for students.",
    categories: ['Education'],
    tips: [
        'Be as specific as possible about the topic you want explained.',
        'If the first explanation is still too complex, ask the AI to simplify it further.',
        'Encourage students to ask follow-up questions to deepen their understanding.'
    ],
    prompts: [
        'Explain the concept of [specific topic] in simple terms.',
        'Create a metaphor to explain [specific topic].',
        'Give a real-world example of [specific topic].',
        'Suggest a practical activity to understand [specific topic].'
    ]
};

export default config;
