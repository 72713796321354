import React, { useContext } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import { App, Button, Card, Col, Empty, Form, Input, Row, Space, theme, Tooltip, Typography } from 'antd';
import FullScreenSpin from '../../../../components/FullScreenSpin/FullScreenSpin';
import PlusBoldIcon from '../../../../components/Icons/PlusBoldIcon';
import { useSearchParams } from '../../../../hooks/searchParams';
import useModal from '../../../../hooks/useModal';
import { $colorPrimary } from '../../../../styles/global';
import { useAuthContext } from '../../../AuthPage/context';
import { DashboardContext } from '../../context';
import Tool from './components/Tool/Tool';
import ToolCard from './components/ToolCard/ToolCard';
import ToolCreationForm from './components/ToolCreationForm';
import { categoryRouteMapper } from './constants';
import { useTools } from './hooks';
import './ToolsPage.scss';

export default function ToolsPage({ history, match }) {
    const searchParams = useSearchParams();

    const [form] = Form.useForm();

    const context = useContext(DashboardContext);

    const { actions, state } = useTools();

    const historyUrl = useHistory();

    const auth = useAuthContext();

    const { notification } = App.useApp();

    const {
        token: { colorBgLayout }
    } = theme.useToken();

    const category = searchParams.get('category') || 'all-tools';

    const tabTitle = categoryRouteMapper[category].tab;

    const search = searchParams.get('search') || '';
    const onCreateNewTool = async () => {
        form.submit();

        const validFields = await form.validateFields();

        try {
            await actions.createTools({
                ...validFields,
                ...{
                    categories: ['My tools'],
                    alias: validFields.title.replace(/\s+/g, '-').toLowerCase()
                }
            });

            await context.actions.toolsList();
            await actions.modals.createTools(false);
            setModalOpen(false);
            history.push({ pathname: '/tools', search: '?category=my-tools' });
        } catch (err) {
            notification.error({
                placement: 'top',
                message: 'Something went wrong! Please try again.'
            });
        }
    };

    const { setModalOpen, modal: ModalToolCreate } = useModal(ToolCreationForm, {
        title: 'Create a Tool',
        componentProps: {
            form,
            onCreateNewTool
        },
        modalProps: {
            getContainer: () => document.querySelector('#app-layout'),
            onOk: () => form.submit(),
            onCancel: () => setModalOpen(false),
            confirmLoading: false,
            width: 585,
            centered: true,
            closable: true,
            okText: 'Create Tool'
        }
    });
    const handleCategoryClick = (key) => {
        searchParams.delete('search');

        key === 'all-tools' ? searchParams.delete('category') : searchParams.set('category', key);

        historyUrl.push({ pathname: '/tools', search: searchParams.toString() });
    };

    const handleSearchChange = (e) => {
        searchParams.set('search', e.target.value);

        historyUrl.push({ pathname: '/tools', search: searchParams.toString() });
    };

    if (context.state.tool.loader) {
        return (
            <div className='tools-page-loading'>
                <FullScreenSpin />
            </div>
        );
    }

    // Return specific tool based on alias
    if (match.params.alias) {
        const toolConfig = context.state.tool.configs.find((tool) => match.params.alias === tool.alias);

        if (!toolConfig) {
            return <Redirect to='/tools' />;
        }

        const onBack = () => {
            history.goBack();
        };

        return <Tool config={toolConfig} onBack={onBack} />;
    }

    const onToolCreate = async () => {
        await actions.modals.createTools(true);
        setModalOpen(true);
    };

    return (
        <Row>
            <Col span={24} className='tools-page'>
                <Card
                    style={{ background: colorBgLayout }}
                    className='no-shadow tools-page__tabs'
                    bordered={false}
                    title={
                        <Space className='tools-page__head' align='center'>
                            <Typography.Title level={2} style={{ margin: 0 }}>
                                {tabTitle}
                            </Typography.Title>
                            <Space size='middle' className='tools-page__head__right' align='center'>
                                <Tooltip title='Create your tool'>
                                    <Button onClick={onToolCreate} className='tools-page__head__right__btn'>
                                        <PlusBoldIcon />
                                        New Tool
                                    </Button>
                                </Tooltip>
                                <Input.Search
                                    className='tools-page__search'
                                    placeholder='Search Specific tools'
                                    bordered={false}
                                    value={search}
                                    onChange={handleSearchChange}
                                    size='large'
                                />
                            </Space>
                        </Space>
                    }
                    tabList={context.state.tool.tabs}
                    activeTabKey={category}
                    onTabChange={handleCategoryClick}
                >
                    <Row
                        gutter={[
                            { xs: 16, sm: 16, md: 30 },
                            { xs: 16, sm: 16, md: 30 }
                        ]}
                    >
                        {category === 'favorites' && !auth.profile.favoriteTools.length && (
                            <Col span={24}>
                                <Empty
                                    description={
                                        <React.Fragment>
                                            <p style={{ color: '#000' }}>
                                                In order to add a tool to your favorites, click on the star button on
                                                the tool card.
                                            </p>
                                            <br />
                                            <StarFilled
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    color: $colorPrimary,
                                                    fontSize: 20
                                                }}
                                            />
                                        </React.Fragment>
                                    }
                                />
                            </Col>
                        )}
                        {(category === 'all-tools'
                            ? [...context.state.tool.configs].filter((tool) => {
                                  if (search) {
                                      return (
                                          tool.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                          tool.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                      );
                                  }

                                  return true;
                              })
                            : [...context.state.tool.configs].filter((tool) => {
                                  if (category === 'favorites') {
                                      const isFavorite = auth.profile.favoriteTools.includes(tool._id);

                                      if (search && isFavorite) {
                                          return (
                                              tool.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                              tool.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                          );
                                      }

                                      return isFavorite;
                                  }

                                  if (search && tool.categories.includes(tabTitle)) {
                                      return (
                                          tool.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                          tool.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                      );
                                  }

                                  if (search && tool.categories.includes(tabTitle)) {
                                      return (
                                          tool.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                          tool.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                      );
                                  }

                                  return tool.categories.includes(tabTitle);
                              })
                        ).map((tool) => (
                            <ToolCard state={state} key={`tool-card-${tool._id}`} tool={tool} history={history} />
                        ))}
                    </Row>
                </Card>
            </Col>
            {ModalToolCreate}
        </Row>
    );
}
