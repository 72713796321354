import React, { useState } from 'react';
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Col, notification, Popover, Row, Typography } from 'antd';

export const PopoverRemove = ({ onRemove, setOpen }) => {
    return (
        <React.Fragment>
            <Typography.Text>Delete this tip?</Typography.Text>
            <Row gutter={10} style={{ marginTop: '15px' }}>
                <Col>
                    <Button onClick={() => setOpen(false)}>Cancel</Button>
                </Col>
                <Col>
                    <Button type='primary' onClick={onRemove}>
                        Delete
                    </Button>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default function SingleTip({ config, tip, text, setTips, actions, state }) {
    const [open, setOpen] = useState(false);

    if (!tip?.value && !text) return null;

    const onRemove = async () => {
        try {
            await actions.removeTip(tip._id, config._id);

            setOpen(false);
        } catch (err) {
            notification.error({
                placement: 'top',
                message: 'Something went wrong! Please try again.'
            });
        }
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const tipValue = config.categories.includes('My tools') ? tip.value : text;

    return (
        <Col>
            <div className='single-tool__tip'>
                <Typography.Paragraph>
                    <InfoCircleOutlined />
                    {tipValue}
                </Typography.Paragraph>
                {!config.categories.includes('My tools') ? null : (
                    <Popover
                        onOpenChange={handleOpenChange}
                        open={open}
                        trigger='click'
                        content={<PopoverRemove setOpen={setOpen} onRemove={onRemove} />}
                    >
                        <Button className='single-tip__remove' icon={<DeleteOutlined />} />
                    </Popover>
                )}
            </div>
        </Col>
    );
}
