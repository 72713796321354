import { useImmerReducer } from 'use-immer';
import { artHistoryReducer, initState } from './reducer';
import apiArtHistory from './service';

export const useArtHistory = () => {
    const [state, dispatch] = useImmerReducer(artHistoryReducer, initState);

    const list = async (currentPage = 1, limit = 10, isFavorite = false) => {
        try {
            dispatch({
                type: 'ART_GET_ALL'
            });

            const response = await apiArtHistory.list(
                new URLSearchParams({
                    page: currentPage,
                    limit,
                    favorite: isFavorite
                })
            );

            dispatch({
                type: 'ART_GET_ALL_SUCCESS',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'ART_GET_ALL_ERROR'
            });
        }
    };

    const update = async (id, body) => {
        try {
            dispatch({
                type: 'ART_UPDATE'
            });

            const response = await apiArtHistory.update(id, body);

            dispatch({
                type: 'ART_UPDATE_SUCCESS',
                payload: response
            });
        } catch (error) {
            dispatch({
                type: 'ART_UPDATE_ERROR'
            });
        }
    };

    const actions = {
        list,
        update
    };

    return {
        state,
        actions
    };
};
