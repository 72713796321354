import { axios } from '../../../../services/http/axios';

const artService = {
    helper: (payload) => axios.post('/stability/text-to-image/prompt-helper', payload)
};

const api = {
    artService
};

export default api;
