import React from 'react';
import { Col, Row, Typography } from 'antd';

export const NewDocModal = (message, respondHtml, setDocToken, history) => {
    return {
        title: 'Create New Doc',
        content: (
            <Row>
                <Col>
                    <Typography.Title level={5}>Use this content to create a new document?</Typography.Title>
                </Col>
            </Row>
        ),
        open: message.state.modal.newDoc,
        className: 'modal-confirm',
        okText: 'Create',
        centered: true,
        closable: true,
        width: 585,
        getContainer: () => document.querySelector('#app-layout'),
        onOk: () => {
            setDocToken(() => null);
            history.push({
                pathname: '/document/editor',
                state: { docTitle: 'Untitled document', docText: respondHtml.innerHTML }
            });
        },
        onCancel: () => message.actions.newDocModal(false)
    };
};
