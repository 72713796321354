import React from 'react';
import Tabs from '../../components/Tabs/Tabs';

export default function ChooseCharacterPage() {
    return (
        <React.Fragment>
            <Tabs />
        </React.Fragment>
    );
}
