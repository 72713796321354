import { gsap } from 'gsap';
import * as isEmpty from 'lodash/isEmpty';

export const gsapInfinitySlider = (items, config) => {
    items = gsap.utils.toArray(items);

    if (isEmpty(items)) return;

    config = config || {};

    const tl = gsap.timeline({
        repeat: -1,
        paused: config.paused,
        defaults: { ease: 'none' },
        onReverseComplete: () => tl.totalTime(tl.rawTime() + tl.duration() * 100)
    });

    tl.restart();

    const length = items.length;

    const startY = items[0].offsetTop;

    const times = [];

    const heights = [];

    const yPercents = [];

    let curIndex = 0;

    const pixelsPerSecond = (config.speed || 1) * 25;

    const snap = config.snap === false ? (v) => v : gsap.utils.snap(config.snap || 1);

    let totalHeight;

    let curX;

    let distanceToStart;

    let distanceToLoop;

    let item;

    let i;

    gsap.set(items, {
        yPercent: (i, el) => {
            const h = (heights[i] = parseFloat(gsap.getProperty(el, 'height', 'px')));

            yPercents[i] = snap(
                (parseFloat(gsap.getProperty(el, 'y', 'px')) / h) * 100 + gsap.getProperty(el, 'yPercent')
            );

            return yPercents[i];
        }
    });
    gsap.set(items, { y: 0 });
    // eslint-disable-next-line prefer-const
    totalHeight =
        items[length - 1].offsetTop +
        (yPercents[length - 1] / 100) * heights[length - 1] -
        startY +
        items[length - 1].offsetHeight * gsap.getProperty(items[length - 1], 'scaleX');
    for (i = 0; i < length; i++) {
        item = items[i];
        curX = (yPercents[i] / 100) * heights[i];
        distanceToStart = item.offsetTop + curX - startY;
        distanceToLoop = distanceToStart + heights[i] * gsap.getProperty(item, 'scaleX');
        tl.to(
            item,
            {
                yPercent: snap(((curX - distanceToLoop) / heights[i]) * 100),
                duration: distanceToLoop / pixelsPerSecond
            },
            0
        )
            .fromTo(
                item,
                { yPercent: snap(((curX - distanceToLoop + totalHeight) / heights[i]) * 100) },
                {
                    yPercent: yPercents[i],
                    duration: (curX - distanceToLoop + totalHeight - curX) / pixelsPerSecond,
                    immediateRender: false
                },
                distanceToLoop / pixelsPerSecond
            )
            .add('label' + i, distanceToStart / pixelsPerSecond);
        times[i] = distanceToStart / pixelsPerSecond;
    }
    function toIndex(index, vars) {
        vars = vars || {};
        Math.abs(index - curIndex) > length / 2 && (index += index > curIndex ? -length : length);

        const newIndex = gsap.utils.wrap(0, length, index);

        let time = times[newIndex];

        // eslint-disable-next-line no-mixed-operators
        if (time > tl.time() !== index > curIndex) {
            vars.modifiers = { time: gsap.utils.wrap(0, tl.duration()) };
            time += tl.duration() * (index > curIndex ? 1 : -1);
        }

        curIndex = newIndex;
        vars.overwrite = true;

        return tl.tweenTo(time, vars);
    }
    tl.next = (vars) => toIndex(curIndex + 1, vars);
    tl.previous = (vars) => toIndex(curIndex - 1, vars);
    tl.current = () => curIndex;
    tl.toIndex = (index, vars) => toIndex(index, vars);
    tl.times = times;
    tl.progress(1, true).progress(0, true);

    if (!config.direction) {
        tl.vars.onReverseComplete();
        tl.reverse();
    }

    return tl;
};
