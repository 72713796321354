import React from 'react';

export default function PauseAudio() {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='512'
            height='512'
            enableBackground='new 0 0 512 512'
            version='1.1'
            viewBox='0 0 512 512'
            xmlSpace='preserve'
        >
            <path d='M224 435.8V76.1c0-6.7-5.4-12.1-12.2-12.1h-71.6c-6.8 0-12.2 5.4-12.2 12.1v359.7c0 6.7 5.4 12.2 12.2 12.2h71.6c6.8 0 12.2-5.4 12.2-12.2zM371.8 64h-71.6c-6.7 0-12.2 5.4-12.2 12.1v359.7c0 6.7 5.4 12.2 12.2 12.2h71.6c6.7 0 12.2-5.4 12.2-12.2V76.1c0-6.7-5.4-12.1-12.2-12.1z' />
        </svg>
    );
}
