export const voices = {
    male: [
        {
            id: 0,
            name: 'James',
            voiceId: '1WJJVX6e51bXZ8BTmyE2',
            imageId: '1WJJVX6e51bXZ8BTmyE2',
            language: 'English(US)'
        },
        {
            id: 1,
            name: 'Michael',
            voiceId: 'ErXwobaYiN019PkySvjV',
            imageId: 'ErXwobaYiN019PkySvjV',
            language: 'English(US)'
        },
        {
            id: 2,
            name: 'William',
            voiceId: 'VR6AewLTigWG4xSOukaG',
            imageId: 'VR6AewLTigWG4xSOukaG',
            language: 'English(US)'
        },
        {
            id: 3,
            name: 'Ethan',
            voiceId: 'TxGEqnHWrfWFTfGW9XjX',
            imageId: 'TxGEqnHWrfWFTfGW9XjX',
            language: 'English(US)'
        },
        {
            id: 4,
            name: 'Noah',
            voiceId: 'yoZ06aMxZJJ28mfd3POQ',
            imageId: 'yoZ06aMxZJJ28mfd3POQ',
            language: 'English(US)'
        },
        {
            id: 5,
            name: 'Jacob',
            voiceId: 'pNInz6obpgDQGcFmaJgB',
            imageId: 'pNInz6obpgDQGcFmaJgB',
            language: 'English(US)'
        },
        {
            id: 6,
            name: 'Josh',
            voiceId: '9F4C8ztpNUmXkdDDbz3J',
            imageId: '9F4C8ztpNUmXkdDDbz3J',
            language: 'English(US)'
        },
        {
            id: 7,
            name: 'Liam',
            voiceId: 'Sr2izmyqBUFGE7iKjC7F',
            imageId: 'Sr2izmyqBUFGE7iKjC7F',
            language: 'English(UK)'
        },
        {
            id: 8,
            name: 'Raj',
            voiceId: 'YGaQoh35XIrBrwzCHZUx',
            imageId: 'YGaQoh35XIrBrwzCHZUx',
            language: 'English(Indian)'
        },
        {
            id: 9,
            name: 'Arthur',
            voiceId: 'lchwh4CZCGN7Q8t9q2WZ',
            imageId: 'lchwh4CZCGN7Q8t9q2WZ',
            language: 'English(UK)'
        },
        {
            id: 10,
            name: 'Daniel',
            voiceId: 'qOBNIw51yonwY9Px0Ctr',
            imageId: 'qOBNIw51yonwY9Px0Ctr',
            language: 'English(South African)'
        },
        {
            id: 11,
            name: 'Moses',
            voiceId: 'KM3JshzDHFwTz8CMf3Ll',
            imageId: 'KM3JshzDHFwTz8CMf3Ll',
            language: 'English(South African)'
        }
    ],
    female: [
        {
            id: 12,
            name: 'Emma',
            voiceId: 'EXAVITQu4vr4xnSDxMaL',
            imageId: 'EXAVITQu4vr4xnSDxMaL',
            language: 'English(US)'
        },
        {
            id: 13,
            name: 'Sophia',
            voiceId: 'MF3mGyEYCl7XYWbV9V6O',
            imageId: 'MF3mGyEYCl7XYWbV9V6O',
            language: 'English(US)'
        },
        {
            id: 14,
            name: 'Ava',
            voiceId: '21m00Tcm4TlvDq8ikWAM',
            imageId: '21m00Tcm4TlvDq8ikWAM',
            language: 'English(US)'
        },
        {
            id: 15,
            name: 'Harper',
            voiceId: 'rM1eQGdaQ1KYnuH9zHRg',
            imageId: 'rM1eQGdaQ1KYnuH9zHRg',
            language: 'English(US)'
        },
        {
            id: 16,
            name: 'Grace',
            voiceId: 'x1Kpi7XSkjOIzsbXxyqS',
            imageId: 'x1Kpi7XSkjOIzsbXxyqS',
            language: 'English(US)'
        },
        {
            id: 17,
            name: 'Anaya',
            voiceId: '5QCnc0rmuflEAAHIcMTP',
            imageId: '5QCnc0rmuflEAAHIcMTP',
            language: 'English(Indian)'
        },
        {
            id: 18,
            name: 'Alice',
            voiceId: 'DGCvCp4790sFClOWKeEn',
            imageId: 'DGCvCp4790sFClOWKeEn',
            language: 'English(UK)'
        },
        {
            id: 19,
            name: 'Evelyn',
            voiceId: 'BjhwMpCyI4ouN8w3zIPj',
            imageId: 'BjhwMpCyI4ouN8w3zIPj',
            language: 'English(UK)'
        },
        {
            id: 20,
            name: 'Mia',
            voiceId: '6H9vwQTgfhoxTWJiM8O4',
            imageId: '6H9vwQTgfhoxTWJiM8O4',
            language: 'English(US)'
        },
        {
            id: 21,
            name: 'Nala',
            voiceId: 'KPAbkvwbbdHkKmjCkEnr',
            imageId: 'KPAbkvwbbdHkKmjCkEnr',
            language: 'English(South African)'
        },
        {
            id: 22,
            name: 'Ria',
            voiceId: 'rmBN0F4tpBlqLEBYuc1L',
            imageId: 'rmBN0F4tpBlqLEBYuc1L',
            language: 'English(Indian)'
        }
    ]
};
