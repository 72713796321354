import React from 'react';
import { Button, Form, Input, Modal } from 'antd';

export const CreateModal = ({ history, docToken, setDocToken, state, actions }) => {
    const [form] = Form.useForm();

    const onFinish = (values) => {
        if (docToken) {
            setDocToken(() => null);
        }

        history.push({ pathname: '/document/editor', state: { docTitle: values.title || 'Untitled document' } });
    };

    return (
        <Modal
            getContainer={() => document.querySelector('#app-layout')}
            className='new-doc-modal'
            title={<div style={{ fontSize: 24, textAlign: 'center' }}>New Document</div>}
            width='370px'
            centered
            onCancel={async () => {
                await actions.closeModal();
            }}
            footer={null}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ style: { display: 'none' } }}
            open={state.modal.create}
        >
            <p style={{ marginBottom: '10px', textAlign: 'center', color: 'rgba(31, 31, 31, 0.70)' }}>
                lets give your document a title
            </p>
            <Form form={form} onFinish={onFinish}>
                <Form.Item name='title'>
                    <Input
                        style={{
                            fontWeight: '600',
                            color: 'rgba(31, 31, 31, 0.50)',
                            height: '50px'
                        }}
                        placeholder='Document Title'
                    />
                </Form.Item>
                <Form.Item>
                    <Button style={{ marginTop: '20px', height: '38px' }} block htmlType='submit' type='primary'>
                        Create Document
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};
