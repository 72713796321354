import React from 'react';

export default function ClaudeIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none' viewBox='0 0 22 22'>
            <path
                fill='#141E30'
                d='M19.79 8.78l-5.68-.826-2.54-5.148a.719.719 0 00-1.284 0L7.748 7.954l-5.68.826A.715.715 0 001.671 10l4.11 4.007-.971 5.658a.715.715 0 001.038.754l5.08-2.671 5.081 2.671a.715.715 0 001.038-.754l-.97-5.658 4.11-4.007a.714.714 0 00.207-.41.713.713 0 00-.604-.811z'
            />
        </svg>
    );
}
