import React from 'react';

export default function ArrowRight() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <path
                fill='#1F1F1F'
                fillOpacity='0.7'
                d='M11.06 16.141a.501.501 0 01-.71 0l-.2-.2a.489.489 0 01-.15-.36v-7.16a.49.49 0 01.15-.36l.2-.2a.498.498 0 01.71 0l3.79 3.79a.481.481 0 010 .7l-3.79 3.79z'
            />
        </svg>
    );
}
