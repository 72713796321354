import React, { useState } from 'react';
import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { App, Button, Checkbox, Form, Input, List, Modal, Spin, Upload } from 'antd';
import Attention from '../../../../../../../components/Icons/Attention/Attention';
import Delete from '../../../../../../../components/Icons/Delete';
import { useBreakpoint } from '../../../../../../../hooks/media';
import styles from './AddVoiceModal.module.scss';

const MAX_TOTAL_FILES = 25;

const MAX_FILE_SIZE = 10 * 1024 * 1024;

const MAX_DESCRIPTION_LENGTH = 500;

const MAX_NAME_LENGTH = 100;

const { Dragger } = Upload;

export default function AddVoiceModal({ visible, setVisible, onCreate, loading }) {
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([]);

    const [audio, setAudio] = useState(null);

    const { notification } = App.useApp();

    const { isDesktop, isDesktopMiddle } = useBreakpoint();

    const handleCreate = async () => {
        try {
            const data = await form.validateFields(['name', 'description', 'agreement']);

            if (fileList.length === 0) {
                notification.error({
                    placement: 'top',
                    message: 'Upload minimum 1 audio file'
                });
            } else {
                onCreate({
                    ...data,
                    audio: fileList
                });
            }
        } catch (error) {
            notification.error({
                placement: 'top',
                message: 'Please fill in all fields!'
            });
        }
    };

    const removeCurrentFile = (deletedFile) => {
        if (audio?.playing) {
            audio.audio.pause();
            audio.playing = false;
        }

        if (fileList.length > 0) {
            const cutFileList = fileList.filter((file) => file.uid !== deletedFile.uid);

            setFileList(cutFileList);
        }
    };

    const handlePlayPause = (file) => {
        if (audio?.playing) {
            audio.audio.pause();
            audio.playing = false;
        }

        if (audio?.file === file) {
            setAudio(null);
        } else {
            const audioElement = new Audio(URL.createObjectURL(file));

            audioElement.play();
            audioElement.addEventListener('ended', () => {
                setAudio(null);
            });
            setAudio({ audio: audioElement, file, playing: true });
        }
    };

    const renderPlayButton = (file) => {
        if (audio?.file === file) {
            if (audio?.playing) {
                return <Button icon={<PauseCircleOutlined />} onClick={() => handlePlayPause(file)} />;
            } else {
                return <Button icon={<PlayCircleOutlined />} onClick={() => handlePlayPause(file)} />;
            }
        } else {
            return <Button icon={<PlayCircleOutlined />} onClick={() => handlePlayPause(file)} />;
        }
    };

    return (
        <Modal
            open={visible}
            className={styles.modal_add_voice}
            title='Add Voice'
            okText='Add Voice'
            onCancel={() => {
                setVisible(false);
            }}
            onOk={handleCreate}
            getContainer={() => {
                return document.getElementsByClassName('ant-layout')[0];
            }}
        >
            <Spin spinning={loading}>
                <Form form={form} layout='vertical' validateTrigger='onChange' encType='multipart/form-data'>
                    <Form.Item
                        label='Name'
                        name='name'
                        rules={[
                            { required: true, message: 'Please input the name of voice!' },
                            {
                                validator: (_, value) => {
                                    if (value && value && value.length < MAX_NAME_LENGTH) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error(`Name must not exceed ${MAX_NAME_LENGTH} characters`)
                                    );
                                }
                            }
                        ]}
                        validateTrigger={['onChange']}
                    >
                        <Input placeholder='Voice Name' maxLength={101} />
                    </Form.Item>
                    <Form.Item className={styles.upload_files} name='audio'>
                        <Dragger
                            name='audio'
                            maxCount={MAX_TOTAL_FILES}
                            multiple
                            accept='audio/*'
                            type='file'
                            listType='picture-card'
                            fileList={fileList}
                            showUploadList={false}
                            itemRender={(originNode, file, currFileList) => {
                                return (
                                    <React.Fragment>
                                        <span>
                                            Samples {currFileList.length} / {MAX_TOTAL_FILES}
                                        </span>
                                        <ul>
                                            {currFileList.map((f) => (
                                                <li key={f.uid}>{f.name}</li>
                                            ))}
                                        </ul>
                                    </React.Fragment>
                                );
                            }}
                            beforeUpload={(file) => {
                                if (fileList.length === MAX_TOTAL_FILES) {
                                    notification.destroy();
                                    notification.error({
                                        placement: 'top',
                                        message: 'File Limit Exceeded',
                                        description: `You can only upload up to ${MAX_TOTAL_FILES} files.`,
                                        maxCount: 1
                                    });

                                    return Upload.LIST_IGNORE;
                                }

                                if (file.size > MAX_FILE_SIZE) {
                                    notification.error({
                                        placement: 'top',
                                        message: `File ${file.name} size exceeded`
                                    });

                                    return Upload.LIST_IGNORE;
                                } else {
                                    setFileList([...fileList, file]);

                                    return false;
                                }
                            }}
                            onChange={({ fileList }) => {
                                setFileList(fileList);
                            }}
                        >
                            <p className='ant-upload-text'>Click to upload a file or drag and drop</p>
                            <p className='ant-upload-hint'>Audio files, up to 10MB each</p>
                        </Dragger>
                        <p className={styles.list_files__title}>
                            Samples {fileList.length} / {MAX_TOTAL_FILES}
                        </p>
                        <List
                            dataSource={fileList}
                            renderItem={(item) => (
                                <List.Item>
                                    <p>{item.name}</p>
                                    <div className={styles.list_files__btns}>
                                        {renderPlayButton(item.originFileObj)}
                                        <Button
                                            onClick={() => removeCurrentFile(item.originFileObj)}
                                            icon={<Delete />}
                                        />
                                    </div>
                                </List.Item>
                            )}
                        />
                    </Form.Item>
                    {!isDesktop && !isDesktopMiddle ? (
                        <div className={styles.notification_quality}>
                            <Attention />
                            <p>
                                Sample quality is more important than quantity. Noisy samples may give bad results.
                                Providing more than 5 minutes of audio in total brings little improvement.
                            </p>
                        </div>
                    ) : null}
                    <Form.Item
                        className={styles.description_item}
                        name='description'
                        label='Description'
                        rules={[
                            {
                                required: true,
                                message: 'Please type description of voice!'
                            },
                            {
                                validator: (_, value) => {
                                    if (value && value.length <= MAX_DESCRIPTION_LENGTH) {
                                        return Promise.resolve();
                                    }

                                    return Promise.reject(
                                        new Error(`Maximum character limit is ${MAX_DESCRIPTION_LENGTH}`)
                                    );
                                }
                            }
                        ]}
                        validateTrigger={['onChange', 'onBlur']}
                    >
                        <Input.TextArea
                            className={styles.add_voice__description}
                            maxLength={MAX_DESCRIPTION_LENGTH}
                            autoSize={{
                                minRows: 2,
                                maxRows: 2
                            }}
                            placeholder='Type description of voice here'
                            onChange={(e) => {
                                if (e.target.value.length === MAX_DESCRIPTION_LENGTH) {
                                    notification.destroy();
                                    notification.error({
                                        placement: 'top',
                                        message: `Character limit exceeded, max ${MAX_DESCRIPTION_LENGTH}.`
                                    });
                                }
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name='agreement'
                        className={styles.item_agreement}
                        valuePropName='checked'
                        rules={[
                            {
                                validator: (_, value) =>
                                    value ? Promise.resolve() : Promise.reject(new Error('Should accept agreement'))
                            }
                        ]}
                    >
                        <Checkbox>
                            I hereby confirm that I have all necessary rights or consents to upload and clone these
                            voice samples and that I will not use the platform-generated content for any illegal,
                            fraudulent, or harmful purpose. I reaffirm my obligation to abide by Just Think AI Terms of
                            Service and <a href='https://www.justthink.ai/privacy-policy'>Privacy Policy</a>.
                        </Checkbox>
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>
    );
}
