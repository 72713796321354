import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { App, Button, Checkbox, Form, Input } from 'antd';
import PropTypes from 'prop-types';
import {
    EMAIL_VALIDATION_RULES,
    FIRST_NAME_VALIDATION_RULES,
    PASSWORD_VALIDATION_RULES
} from '../../../../utils/validation';
import { useAuthContext } from '../../context';
import styles from './index.module.scss';

export default function Signup({ history }) {
    const [loading, setLoading] = React.useState(false);

    const [form] = Form.useForm();

    const auth = useAuthContext();

    const location = useLocation();

    const { notification } = App.useApp();

    const params = new URLSearchParams(location.search);

    const onFinish = async (values) => {
        setLoading(() => true);

        try {
            await auth.signup(values);

            setLoading(() => false);

            history.push('/signup-survey');
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
            setLoading(() => false);
        }
    };

    return (
        <Form
            className={styles.signup}
            form={form}
            layout='vertical'
            onFinish={onFinish}
            initialValues={{
                referral: params.get('ref')
            }}
        >
            <Form.Item name='email' label='Email' rules={EMAIL_VALIDATION_RULES}>
                <Input placeholder='youremail@gmail.com' />
            </Form.Item>
            <Form.Item hidden name='ref'>
                <Input type='text' disabled />
            </Form.Item>
            <Form.Item name='fullName' label='Full Name' rules={FIRST_NAME_VALIDATION_RULES}>
                <Input placeholder='Your Full Name Here' />
            </Form.Item>
            <Form.Item name='password' label='Password' rules={PASSWORD_VALIDATION_RULES}>
                <Input.Password visibilityToggle={false} placeholder='••••••••' />
            </Form.Item>
            <Form.Item name='updateMe'>
                <Checkbox value='updateNews'>Keep me updated with learning tips!</Checkbox>
            </Form.Item>
            <Form.Item>
                <Button block type='primary' htmlType='submit' loading={loading}>
                    Register
                </Button>
            </Form.Item>
            <Form.Item className={styles.signup__links}>
                Already have an account? <Link to='/auth/login'>Login Here.</Link>
            </Form.Item>
        </Form>
    );
}

Signup.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};
