export const initState = {
    homepage: {
        key: null,
        play: false
    },
    audio: {
        file: null,
        popup: false,
        loading: false,
        error: null
    },
    history: {
        list: [],
        pagination: {
            total: 0,
            limit: 10,
            totalPages: 1,
            currentPage: 1,
            nextPage: null,
            hasPrevPage: false,
            hasNextPage: false
        }
    },
    loadingOnDownload: false,
    loadingOnRemove: false,
    loadingList: true,
    voice: null,
    error: null
};

const DEFAULT_ERROR = 'Something went wrong, please try again';

export function speechHistoryReducer(draft, action) {
    switch (action.type) {
        case 'HOMEPAGE_AUDIO_START': {
            draft.homepage.key = action.payload;
            draft.homepage.play = true;
            break;
        }
        case 'HOMEPAGE_AUDIO_PAUSE': {
            draft.homepage.play = false;
            break;
        }
        case 'PLAY_AUDIO': {
            draft.voice = action.payload.voice;
            draft.audio.file = action.payload.url;
            draft.audio.popup = true;
            break;
        }
        case 'CLOSE_POPUP': {
            draft.audio.popup = false;
            break;
        }
        case 'VOICE': {
            draft.voice = action.payload;
            break;
        }
        case 'FETCH_DATA': {
            draft.loadingList = true;
            break;
        }
        case 'FETCH_DATA_SUCCESS': {
            draft.history.list = action.payload.list;
            draft.history.pagination = action.payload.pagination;
            draft.loadingList = false;
            break;
        }
        case 'FETCH_FAILED': {
            draft.error = DEFAULT_ERROR;
            draft.loadingList = false;
            break;
        }
        case 'REMOVE_AUDIO': {
            draft.loadingOnRemove = true;
            break;
        }
        case 'REMOVE_SUCCESS': {
            draft.loadingOnRemove = false;
            break;
        }
        case 'REMOVE_FAILED': {
            draft.loadingOnRemove = false;
            draft.error = DEFAULT_ERROR;
            break;
        }
        case 'DOWNLOAD_FILES': {
            draft.loadingOnDownload = true;
            break;
        }
        case 'DOWNLOAD_SUCCESS': {
            draft.loadingOnDownload = false;
            break;
        }
        case 'DOWNLOAD_FAILED': {
            draft.loadingOnDownload = false;
            draft.error = DEFAULT_ERROR;
            break;
        }
        default: {
            return draft;
        }
    }
}
