import React, { useState } from 'react';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, notification, Popover, Spin, Typography } from 'antd';
import CircleWithDots from '../../../../../../../../components/Icons/CircleWithDots';
import { useBreakpoint } from '../../../../../../../../hooks/media';
import { axios } from '../../../../../../../../services/http/axios';
import api from '../../../../service';
import { downloadFile } from '../../../../utils/downloadFile';
import { PopoverContent } from './components/PopoverContent';
import styles from './index.module.scss';

export const SingleRow = ({ state, item, setDocToken, history, actions }) => {
    const { isMobile } = useBreakpoint();

    const [form] = Form.useForm();

    const [open, setOpen] = useState(false);

    const [loading, setLoading] = useState(false);

    const [title, setTitle] = useState(item.title || 'Untitled Document');

    const hide = () => {
        setOpen(false);
    };

    const onRemove = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await actions.remove(item.key);
    };

    const onDocSelect = () => {
        if (!item.key) {
            notification.error({
                placement: 'top',
                message: 'This file doesn`t exist'
            });

            return;
        }

        setDocToken(() => item.key);

        history.push('/document/editor');
    };

    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };

    const onHandleInputField = async () => {
        await actions.onHandleInputField(item.key);
    };

    const onEdit = (e) => {
        setTitle(() => e.target.value);
    };

    const onCancelEdit = async () => {
        setTitle(() => item.title);
        await actions.onHandleInputField('');
    };

    const onFinish = async (values) => {
        setLoading(true);

        await axios.post('/document/update', { id: item.key, title: values.title, html: values.html });

        await actions.onHandleInputField('');
        setLoading(false);
    };

    const onDownload = async (e) => {
        e.preventDefault();
        e.stopPropagation();

        if (item.html) {
            const response = await api.document.download({ fileType: 'docx', id: item.key });

            downloadFile(response, item.title, 'docx');
        } else {
            notification.error({
                placement: 'top',
                message: 'File is empty'
            });
        }
    };

    return (
        <Col onClick={onDocSelect}>
            <div className={styles.history__single}>
                <div className={styles.history__single__inner}>
                    {state.renameField === item.key ? (
                        <Spin spinning={loading}>
                            <Form
                                initialValues={{
                                    title
                                }}
                                onFinish={onFinish}
                                form={form}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <Form.Item name='title'>
                                    <Input onChange={onEdit} value={title} />
                                </Form.Item>
                                <Form.Item>
                                    <Button icon={<CheckOutlined />} htmlType='submit' />
                                </Form.Item>
                                <Form.Item>
                                    <Button icon={<CloseOutlined />} onClick={onCancelEdit} />
                                </Form.Item>
                            </Form>
                        </Spin>
                    ) : (
                        <Typography.Title level={3}>{title}</Typography.Title>
                    )}
                </div>
                <Popover
                    onOpenChange={handleOpenChange}
                    className={styles.history__single__download}
                    trigger='click'
                    content={
                        <PopoverContent
                            onRename={onHandleInputField}
                            onRemove={onRemove}
                            hide={hide}
                            onDownload={onDownload}
                        />
                    }
                    open={open}
                >
                    <Button onClick={(e) => e.stopPropagation()}>
                        <CircleWithDots width={isMobile ? '20' : '26'} />
                    </Button>
                </Popover>
            </div>
        </Col>
    );
};
