import React, { useEffect } from 'react';
import { Col, Row, Table, Typography } from 'antd';
import moment from 'moment';
import { CDN_URL } from '../../../../../../config';
import { useBreakpoint } from '../../../../../../hooks/media';
import { WhiteCover } from '../../../../../../shared/components/WhiteCover';
import { AudioPlayerDrawer } from '../../../TextToSpeechPage/pages/components/AudioPlayer';
import { useHistorySpeech } from '../../../TextToSpeechPage/pages/HistoryPage/hooks';
import { Actions } from './components/Actions';
import styles from './index.module.scss';

export default function AudioTab() {
    const { isMobile } = useBreakpoint();

    const { state, actions } = useHistorySpeech();

    useEffect(() => {
        actions.fetch();
    }, []);

    const onPlay = async (record) => {
        await actions.play({ url: record.url, voice: record.voice });
    };

    const dataSorted =
        state.history.list?.slice(0, 4).map((item, key) => ({
            key: item._id,
            presenter: item.imageId ? `${CDN_URL}/text-to-speech/presenters/${item.imageId}.jpg` : null,
            voice: item.voiceName,
            date: new Date(item.createdAt).toDateString(),
            text: item.text,
            language: item.language,
            url: item.url
        })) || [];

    const columns = [
        {
            title: <Typography.Title level={2}>Voice</Typography.Title>,
            dataIndex: 'voice',
            render: (_, record) => (
                <React.Fragment>
                    <Typography.Title className={styles.tabs__audio__voice} level={2}>
                        {record.voice}
                    </Typography.Title>
                    <Typography.Text className={styles.tabs__audio__voice}>{record.language}</Typography.Text>
                </React.Fragment>
            ),
            width: isMobile ? '127px' : '20%'
        },
        {
            title: <Typography.Title level={2}>Date</Typography.Title>,
            dataIndex: 'date',
            render: (_, record) => (
                <React.Fragment>
                    <Typography.Title className={styles.tabs__audio__date} level={2}>
                        {moment(record.date).format('MMM D')}
                    </Typography.Title>
                    <Typography.Text className={styles.tabs__audio__date}>
                        {moment(record.date).format('YYYY')}
                    </Typography.Text>
                </React.Fragment>
            ),
            width: isMobile ? '127px' : '15%'
        },
        {
            title: <Typography.Title level={2}>Text</Typography.Title>,
            dataIndex: 'text',
            render: (_, record) => <Typography.Text className={styles.tabs__audio__text}>{record.text}</Typography.Text>
        },
        {
            title: <Typography.Title level={2}>Play</Typography.Title>,
            dataIndex: 'play',
            render: (_, record) => <Actions onPlay={onPlay} record={record} />,
            width: isMobile ? '60px' : '10%',
            align: 'center',
            fixed: 'right'
        }
    ];

    return (
        <WhiteCover padding='47px 93px'>
            <Row justify='center'>
                <Col className={styles.tabs__audio}>
                    <Table
                        loading={state.loadingList}
                        scroll={{ x: 1024 }}
                        className='text-to-speech__table'
                        pagination={false}
                        columns={columns}
                        dataSource={dataSorted}
                    />
                </Col>
            </Row>
            <AudioPlayerDrawer state={state} actions={actions} />
        </WhiteCover>
    );
}
