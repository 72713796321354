import React from 'react';
import styles from './ImageCharacter.module.scss';

export default function ImageCharacter({ src }) {
    return (
        <div className={styles.image_wrap}>
            <img className={styles.image} src={src} />
        </div>
    );
}
