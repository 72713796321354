import React from 'react';

export default function ItalicIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <path
                fill='#000'
                fillOpacity='0.85'
                d='M19.66 2.57H8.09a.215.215 0 00-.215.215v1.714c0 .118.097.214.214.214h4.854L8.764 19.285H4.42a.215.215 0 00-.214.214v1.714c0 .118.096.214.214.214H15.99a.215.215 0 00.215-.214V19.5a.215.215 0 00-.215-.214h-4.998l4.179-14.572h4.489a.215.215 0 00.214-.214V2.785a.215.215 0 00-.214-.215z'
            />
        </svg>
    );
}
