import configurations from './pages/ToolsPage/configurations';
import { categoryTabs } from './pages/ToolsPage/constants';

export const initState = {
    tool: {
        tabs: categoryTabs,
        configs: configurations,
        loader: true
    },
    billing: {
        step: 1
    },
    loaders: {
        billing: false
    },
    modals: {
        billing: false,
        billingUpgradeModal: false,
        createInstruction: false
    }
};

export function dashboardReducer(draft, action) {
    switch (action.type) {
        case 'TOOLS_LIST_START': {
            draft.tool.loader = true;
            break;
        }
        case 'BILLING_MODAL': {
            draft.modals.billing = action.payload;
            break;
        }
        case 'BILLING_UPGRADE_MODAL': {
            draft.modals.billingUpgradeModal = action.payload;
            break;
        }
        case 'BILLING_LOADING': {
            draft.loaders.billing = action.payload;
            break;
        }
        case 'BILLING_SET_STEP': {
            draft.billing.step = action.payload;
            break;
        }
        case 'INSTRUCTION_MODAL_ADD': {
            draft.modals.createInstruction = action.payload;
            break;
        }
        case 'TOOLS_LIST_END': {
            if (!action.payload.data.length) {
                draft.tool.tabs = categoryTabs.filter((item) => item.key !== 'my-tools');
            } else {
                draft.tool.tabs = categoryTabs;
            }

            draft.tool.configs = configurations.concat(action.payload.data);

            draft.tool.loader = false;
            break;
        }
        default: {
            return draft;
        }
    }
}
