import React, { useEffect, useState } from 'react';
import { App, Button, Col, Row, Table } from 'antd';
import { useBreakpoint } from '../../../../../../hooks/media';
import { AudioPlayerDrawer } from '../components/AudioPlayer';
import { Actions } from './components/Actions';
import { useHistorySpeech } from './hooks';
import './index.scss';

export default function SpeechHistoryPage() {
    const { isMobile } = useBreakpoint();

    const { state, actions } = useHistorySpeech();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const { notification } = App.useApp();

    useEffect(() => {
        actions.fetch();
    }, []);

    const onPlay = async (record) => {
        await actions.play({ url: record.url, voice: record.voice });
    };

    const onRemoveFiles = async () => {
        if (!selectedRowKeys.length) return;

        await actions.remove(selectedRowKeys);

        if (state.error) {
            notification.error({
                placement: 'top',
                message: state.audio.error
            });
        }

        if (!state.error) await actions.fetch();
    };

    const onDownloadFiles = async () => {
        if (!selectedRowKeys.length) return;

        await actions.download(selectedRowKeys);

        if (state.error) {
            notification.error({
                placement: 'top',
                message: state.audio.error
            });
        }
    };

    const onPageChange = async (pageNum) => {
        await actions.fetch(pageNum);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    };

    const dataSorted =
        state.history.list?.map((item, key) => ({
            key: item._id,
            voice: item.voiceName,
            date: new Date(item.createdAt).toDateString(),
            text: item.text,
            url: item.url
        })) || [];

    const columns = [
        {
            title: 'Voice',
            dataIndex: 'voice',
            width: isMobile ? '150px' : '15%'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            width: isMobile ? '150px' : '15%'
        },
        {
            title: 'Text',
            dataIndex: 'text'
        },
        {
            title: 'Actions',
            dataIndex: 'actions',
            render: (_, record) => <Actions onPlay={onPlay} record={record} />,
            width: isMobile ? '100px' : '15%',
            align: 'center',
            fixed: 'right'
        }
    ];

    return (
        <Row justify='center'>
            <Col sm={20}>
                <div className='text-to-speech__history'>
                    <div style={{ display: 'flex', gap: '20px', marginBottom: 16 }}>
                        <Button loading={state.loadingOnDownload} onClick={() => onDownloadFiles()} type='primary'>
                            Download selected
                        </Button>
                        <Button loading={state.loadingOnRemove} onClick={() => onRemoveFiles()} type='primary'>
                            Remove selected
                        </Button>
                    </div>
                    <Table
                        loading={state.loadingList}
                        scroll={{ x: 767 }}
                        className='text-to-speech__table'
                        pagination={{
                            position: [isMobile ? 'bottomRight' : 'topRight'],
                            showSizeChanger: false,
                            onChange: (pageNum) => onPageChange(pageNum),
                            total: state.history.pagination.total,
                            current: state.history.pagination.currentPage,
                            pageSize: 10
                        }}
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={dataSorted}
                    />
                    <AudioPlayerDrawer state={state} actions={actions} />
                </div>
            </Col>
        </Row>
    );
}
