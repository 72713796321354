import React, { useContext, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { StarFilled } from '@ant-design/icons';
import { Card, Col, Empty, Row, theme } from 'antd';
import { useSearchParams } from '../../../../../../../../hooks/searchParams';
import { $colorPrimary } from '../../../../../../../../styles/global';
import { useAuthContext } from '../../../../../../../AuthPage/context';
import { DashboardContext } from '../../../../../../context';
import Tool from '../../../../../ToolsPage/components/Tool/Tool';
import ToolCard from '../../../../../ToolsPage/components/ToolCard/ToolCard';
import { categoryRouteMapper } from '../../../../../ToolsPage/constants';
import './ToolsLibrary.scss';

export default function ToolsLibrary({ collapsed, history, match, path = '', tabs = true, conversation }) {
    const searchParams = useSearchParams();

    const { actions } = conversation;

    const context = useContext(DashboardContext);

    const historyUrl = useHistory();

    const auth = useAuthContext();

    const {
        token: { colorBgLayout }
    } = theme.useToken();

    const category = searchParams.get('category') || 'all-tools';

    const tabTitle = categoryRouteMapper[category].tab;

    const search = searchParams.get('search') || '';

    useEffect(() => {
        (async () => {
            await context.actions.toolsList();
        })();
    }, []);

    const handleCategoryClick = (key) => {
        if (search) {
            searchParams.set('search', search);
        }

        key === 'all-tools' ? searchParams.delete('category') : searchParams.set('category', key);

        historyUrl.push({ pathname: path, search: searchParams.toString() });
    };

    // Return specific tool based on alias
    if (match?.params?.alias) {
        const toolConfig = context.state.tool.configs.find((tool) => match.params.alias === tool.alias);

        if (!toolConfig) {
            return <Redirect to='/tools' />;
        }

        const onBack = () => {
            history.goBack();
        };

        return <Tool config={toolConfig} onBack={onBack} />;
    }

    return (
        <Row>
            <Col span={24} className='tools-page'>
                <Card
                    style={{ background: colorBgLayout }}
                    className={`no-shadow tools-page__tabs ${tabs ? '' : 'no-tabs'}`}
                    bordered={false}
                    tabList={context.state.tool.tabs}
                    activeTabKey={category}
                    onTabChange={handleCategoryClick}
                >
                    <Row
                        gutter={[
                            { xs: 16, sm: 16, md: 30 },
                            { xs: 16, sm: 16, md: 30 }
                        ]}
                    >
                        {category === 'favorites' && !auth.profile.favoriteTools.length && (
                            <Col span={24}>
                                <Empty
                                    description={
                                        <React.Fragment>
                                            <p style={{ color: '#000' }}>
                                                In order to add a tool to your favorites, click on the star button on
                                                the tool card.
                                            </p>
                                            <br />
                                            <StarFilled
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    color: $colorPrimary,
                                                    fontSize: 20
                                                }}
                                            />
                                        </React.Fragment>
                                    }
                                />
                            </Col>
                        )}
                        {(category === 'all-tools'
                            ? [...context.state.tool.configs].filter((tool) => {
                                  if (search) {
                                      return (
                                          tool.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                          tool.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                      );
                                  }

                                  return true;
                              })
                            : [...context.state.tool.configs].filter((tool) => {
                                  if (category === 'favorites') {
                                      const isFavorite = auth.profile.favoriteTools.includes(tool._id);

                                      if (search && isFavorite) {
                                          return (
                                              tool.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                              tool.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                          );
                                      }

                                      return isFavorite;
                                  }

                                  if (search && tool.categories.includes(tabTitle)) {
                                      return (
                                          tool.title.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
                                          tool.about.toLowerCase().indexOf(search.toLowerCase()) > -1
                                      );
                                  }

                                  return tool.categories.includes(tabTitle);
                              })
                        ).map((tool) => (
                            <ToolCard
                                collapsed={collapsed}
                                actions={actions}
                                key={`tool-card-${tool._id}`}
                                tool={tool}
                                history={historyUrl}
                            />
                        ))}
                    </Row>
                </Card>
            </Col>
        </Row>
    );
}
