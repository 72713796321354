import React, { Fragment } from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from './page/AuthPage/context';
import App from './App';
import './styles/global.scss';

const container = document.getElementById('root');

const root = createRoot(container);

root.render(
    <Fragment>
        <AuthProvider>
            <App />
        </AuthProvider>
    </Fragment>
);
