const validMimeTypes = ['image/jpeg', 'image/png', 'image/gif'];

export const beforeUpload = (file, options) => {
    if (!validMimeTypes.includes(file.type)) {
        throw new Error(`Valid image formats are: ${validMimeTypes.join(', ')}`);
    }

    const isLtMb = file.size / 1024 / 1024 < options.maxFileSize;

    if (!isLtMb) {
        throw new Error(`Image must smaller than ${options.maxFileSize}MB!`);
    }

    return true;
};
