const config = {
    _id: 63,
    title: 'Writing Blog Posts',
    alias: 'writing-blog-posts',
    about: 'Effortlessly craft compelling blog posts with Just Think AI. Save time, maintain your unique brand voice, and produce engaging, well-structured, and SEO-friendly content.',
    categories: ['Blog Content'],
    tips: [
        'Provide a clear and focused topic for the blog post to make it easier for Just Think AI to generate relevant content.',
        'If you like what is written in your blog but the text is too long, it is possible to ask to make it shorter instead of rewriting the prompt.'
    ],
    prompts: [
        'Write a blog post about the impact of [specific event/news] on [specific industry or community].',
        'Write a blog post of what are the key takeaways from [insert book/article] and how can they be applied to [insert industry/field]?',
        'Write a blog post about some common misconceptions about [insert topic] and how can they be corrected?',
        'Write an explanatory blog about the process of [specific task or project] and include tips on how to do it efficiently.',
        'Write a blog about an overview of the advantages of [specific activity or hobby] and see how to start with helpful tips and the equipment you need.'
    ]
};

export default config;
