import React, { useState } from 'react';
import { ArrowLeftOutlined, CheckOutlined, DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Input, Row, Space, Typography } from 'antd';
import { useBreakpoint } from '../../../../../../hooks/media';
import { uuid } from '../../../../../../utils/crypto';
import { useAuthContext } from '../../../../../AuthPage/context';
import { descriptions } from '../../constants';
import TemplateRemove from '../Modals/TemplateRemove';
import { SinglePrompt } from './components/SinglePrompt';
import { SinglePromptAdd } from './components/SinglePromptAdd';
import SingleTip from './components/SingleTip';
import { SingleTipAdd } from './components/SingleTipAdd';
import styles from './index.module.scss';

export const TemplatePage = ({ history, config, onBack, state, actions }) => {
    const [title, setTitle] = useState(config.title);
    const [edit, setEdit] = useState(false);
    const { isMobile } = useBreakpoint();

    const auth = useAuthContext();

    const onViewAllPrompts = () => {
        document.querySelector('.single-art__prompts').classList.toggle('show');
        actions.promptsView();
    };

    const onViewAllTips = () => {
        document.querySelector('.single-art__tips').classList.toggle('show');
        actions.tipsView();
    };

    const onRemove = async () => {
        await actions.modals.open('templateRemove');
    };

    const onTitleEdit = async () => {
        const data = new FormData();
        data.append('values', JSON.stringify({ title }));

        setEdit(false);
        await actions.template.update({ id: config._id, data });
        actions.template.list();
    };

    const org = auth.org.getCurrent();

    const isFreePlan = org?.billingPlan?.planType === 'free';

    return (
        <Row className={styles['single-art']}>
            <Col
                className={styles['single-art__left']}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 15 }}
            >
                <Space align='start' style={{ width: '100%', justifyContent: 'space-between' }}>
                    <Button onClick={onBack} className={styles['single-art__back']} icon={<ArrowLeftOutlined />}>
                        All Art Templates
                    </Button>
                    {!config.categories.includes('my templates') ? null : (
                        <Button onClick={onRemove} className='single-tool__remove' icon={<DeleteOutlined />} />
                    )}
                </Space>
                <Space className={styles['single-art__categories']}>
                    {config.categories?.map((category) => {
                        return (
                            <div key={uuid()} className='category'>
                                {category}
                            </div>
                        );
                    })}
                </Space>
                {config.categories.includes('my templates') ? (
                    <Flex align='center' justify='space-between' className={styles['single-art__title']}>
                        {edit ? (
                            <Input onChange={(e) => setTitle(e.target.value)} value={title} />
                        ) : (
                            <Typography.Title level={2}>{title}</Typography.Title>
                        )}
                        {edit ? (
                            <Button onClick={onTitleEdit}>
                                <CheckOutlined />
                            </Button>
                        ) : (
                            <Button onClick={() => setEdit(true)}>
                                <EditOutlined />
                            </Button>
                        )}
                    </Flex>
                ) : (
                    <Typography.Title level={2}>{title}</Typography.Title>
                )}
                <Space align='center' className={styles['single-art__view']}>
                    <Typography.Title level={4}>Prompts</Typography.Title>
                    {isMobile && config.prompts.length > 2 && <a onClick={onViewAllPrompts}>{state.promptsView}</a>}
                </Space>
                <Typography.Paragraph style={{ marginBottom: '30px' }}>{descriptions.prompts}</Typography.Paragraph>
                <Row
                    className='single-art__prompts'
                    gutter={[
                        { xs: 16, sm: 16, md: 32 },
                        { xs: 16, sm: 16, md: 32 }
                    ]}
                >
                    {config.prompts.map((prompt, index) => {
                        return (
                            <SinglePrompt
                                index={index}
                                key={uuid()}
                                art={config}
                                prompt={prompt}
                                actions={actions}
                                isFreePlan={isFreePlan}
                            />
                        );
                    })}
                    {config.prompts?.length < 5 && config.categories.includes('my templates') ? (
                        <SinglePromptAdd id={config._id} state={state} actions={actions} />
                    ) : null}
                </Row>
            </Col>
            <Col
                className={styles['single-art__right']}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 9 }}
            >
                <Space align='center' className='single-art__view'>
                    <Typography.Title level={4}>Tips</Typography.Title>
                    {isMobile && config.tips?.length > 2 && <a onClick={onViewAllTips}>{state.tipsView}</a>}
                </Space>
                <Typography.Paragraph>
                    <div dangerouslySetInnerHTML={{ __html: descriptions.tips }} />
                </Typography.Paragraph>
                <Row
                    className='single-art__tips'
                    gutter={[
                        { xs: 16, sm: 16, md: 16 },
                        { xs: 16, sm: 16, md: 16 }
                    ]}
                >
                    {config.tips?.map((tip) => {
                        return <SingleTip key={uuid()} tip={tip} />;
                    })}
                </Row>
                {config.tips?.length < 5 && config.categories.includes('my templates') ? (
                    <SingleTipAdd id={config._id} state={state} actions={actions} />
                ) : null}
            </Col>
            <TemplateRemove history={history} id={config._id} state={state} actions={actions} />
        </Row>
    );
};
