import articleSummarizer from './articleSummarizer';
import careerGuidanceCounselor from './careerGuidanceCounselor';
import cognitiveLoadManager from './cognitiveLoadManager';
import conceptExplainer from './conceptExplainer';
import contentEnhancer from './contentEnhancer';
import createAnOfferLetter from './createAnOfferLetter';
import createAYouTubeVideoScript from './createAYouTubeVideoScript';
import createBuyerPersona from './createBuyerPersona';
import createEffectiveBulletPoints from './createEffectiveBulletPoints';
import createFAQs from './createFAQs';
import createMetaDescriptions from './createMetaDescriptions';
import crisisManagementGuidance from './crisisManagementGuidance';
import criticalThinkingEncourager from './criticalThinkingEncourager';
import developAContentCalendar from './developAContentCalendar';
import discussionQuestionGenerator from './discussionQuestionGenerator';
import educationalGameCreator from './educationalGameCreator';
import evictionNoticeGenerator from './evictionNoticeGenerator';
import experientialLearningPlanner from './experientialLearningPlanner';
import generateContentIdeas from './generateContentIdeas';
import generateInstagramAdTargetingIdeas from './generateInstagramAdTargetingIdeas';
import generateLinkedInAdsTargetingIdeas from './generateLinkedInAdsTargetingIdeas';
import generatePinterestAdsTargetingIdeas from './generatePinterestAdsTargetingIdeas';
import generatingBlogPostOutlines from './generatingBlogPostOutlines';
import generatingEmailCampaignIdeas from './generatingEmailCampaignIdeas';
import generatingLongTailKeywords from './generatingLongTailKeywords';
import headlineOptimizer from './headlineOptimizer';
import homeImprovementProposal from './homeImprovementProposal';
import homeworkHelper from './homeworkHelper';
import keywordResearch from './keywordResearch';
import languageTranslator from './languageTranslator';
import leaseAgreementGenerator from './leaseAgreementGenerator';
import lessonPlannerPro from './lessonPlannerPro';
import motivationalCoach from './motivationalCoach';
import peerCollaborationFacilitator from './peerCollaborationFacilitator';
import peerReviewPro from './peerReviewPro';
import personalDevelopmentPlanner from './personalDevelopmentPlanner';
import personalizeAMessagesForInfluencers from './personalizeAMessagesForInfluencers';
import potentialClientProposal from './potentialClientProposal';
import projectIdeaGenerator from './projectIdeaGenerator';
import propertyInspectionChecklist from './propertyInspectionChecklist';
import prospectiveClientEmail from './prospectiveClientEmail';
import quizMaster from './quizMaster';
import rentIncreaseNotification from './rentIncreaseNotification';
import respondToCustomerEmails from './respondToCustomerEmails';
import securityDepositRefundLetter from './securityDepositRefundLetter';
import socialEmotionalLearningSupport from './sELSupport';
import seoAdvisor from './seoAdvisor';
import sMSMarketing from './sMSMarketing';
import summarizeMeetingNotes from './summarizeMeetingNotes';
import tenantScreeningQuestionnaire from './tenantScreeningQuestionnaire';
import topicGenerator from './topicGenerator';
import translateCopy from './translateCopy';
import vocabMaster from './vocabMaster';
import writeABenefitDrivenSalesCopy from './writeABenefitDrivenSalesCopy';
import writeAPitchForInfluencer from './WriteAPitchForInfluencer';
import writeEmailNewsletters from './writeEmailNewsletters';
import writeEmailSubjectLines from './writeEmailSubjectLines';
import writeProductLaunchEmails from './writeProductLaunchEmails';
import writeSalesCopyForColdCalling from './writeSalesCopyForColdCalling';
import writeSalesEmails from './writeSalesEmails';
import writingBlogPosts from './writingBlogPosts';

// Configurations in order of appearance by id
const configurations = [
    writingBlogPosts,
    generatingLongTailKeywords,
    createBuyerPersona,
    criticalThinkingEncourager,
    lessonPlannerPro,
    quizMaster,
    generateInstagramAdTargetingIdeas,
    securityDepositRefundLetter,
    personalDevelopmentPlanner,
    careerGuidanceCounselor,
    conceptExplainer,
    projectIdeaGenerator,
    socialEmotionalLearningSupport,
    educationalGameCreator,
    peerReviewPro,
    experientialLearningPlanner,
    discussionQuestionGenerator,
    cognitiveLoadManager,
    peerCollaborationFacilitator,
    motivationalCoach,
    vocabMaster,
    articleSummarizer,
    topicGenerator,
    potentialClientProposal,
    summarizeMeetingNotes,
    languageTranslator,
    createAnOfferLetter,
    writeProductLaunchEmails,
    writeEmailNewsletters,
    writeSalesEmails,
    sMSMarketing,
    generatingEmailCampaignIdeas,
    writeEmailSubjectLines,
    writeABenefitDrivenSalesCopy,
    createMetaDescriptions,
    generateContentIdeas,
    translateCopy,
    developAContentCalendar,
    generatingBlogPostOutlines,
    createAYouTubeVideoScript,
    respondToCustomerEmails,
    createFAQs,
    crisisManagementGuidance,
    generateLinkedInAdsTargetingIdeas,
    generatePinterestAdsTargetingIdeas,
    writeAPitchForInfluencer,
    personalizeAMessagesForInfluencers,
    keywordResearch,
    createEffectiveBulletPoints,
    seoAdvisor,
    contentEnhancer,
    headlineOptimizer,
    prospectiveClientEmail,
    homeworkHelper,
    writeSalesCopyForColdCalling,
    tenantScreeningQuestionnaire,
    homeImprovementProposal,
    evictionNoticeGenerator,
    rentIncreaseNotification,
    leaseAgreementGenerator,
    propertyInspectionChecklist
];

export default configurations;
