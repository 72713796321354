/* eslint-disable */

const config = {
    _id: 82,
    title: 'Generating Blog Post Outlines',
    alias: 'generating-blog-post-outlines',
    about: 'Generate outlines by providing relevant keywords, questions, and ideas that you want to cover in your blog post. Just Think can then use this information to generate an outline that covers all the essential topics and ideas you want to include in your blog post.',
    categories: ['Blog Content'],
    tips: [
        'Provide specific keywords, questions, or ideas that you want to cover in your blog post. The more specific information you provide, the better the outline generated by Just Think will be.',
        "If you're having trouble coming up with specific topics or ideas, try brainstorming related keywords or questions to provide to Just Think.",
        'After receiving the generated outline from Just Think, review it and make any necessary adjustments to ensure that it covers all the essential topics and ideas you want to include in your blog post.'
    ],
    prompts: [
        "I'm writing a blog post on [topic] and would like an outline that includes [idea 1], [idea 2], [idea 3], and [idea 4]. Can you also suggest some [related keyword] that I can cover in the post?",
        "Can you help me create a blog post outline that covers [subtopic 1], [subtopic 2], and [subtopic 3]? I'm looking for [adjective] ideas that will capture the reader's attention and keep them engaged throughout the post.",
        'I\'m struggling to come up with a clear structure for my blog post on [topic]. Can you generate an outline that covers [subtopic 1], [subtopic 2], [subtopic 3], [subtopic 4], and [subtopic 5]? Please ensure that the outline includes [specific detail] and [specific detail] to provide the reader with valuable insights.',
        'I\'m in the process of creating a blog post on [topic] and need help organizing my thoughts. Can you create an outline that covers [subtopic 1], [subtopic 2], and [subtopic 3], as well as [subtopic 4] and [subtopic 5], which should include [specific information] and [specific information], respectively?',
        'I\'m planning to write a blog post on [topic] but am struggling with coming up with an outline. Can you generate an outline that covers [subtopic 1], [subtopic 2], [subtopic 3], and [subtopic 4], and also provide some tips on [related topic] that I can include in the post?'
    ]
};

export default config;
