import React, { useEffect } from 'react';
import { App, Typography } from 'antd';
import { useBreakpoint } from '../../../../../../hooks/media';
import { useAuthContext } from '../../../../../AuthPage/context';
import { artIntroCols } from '../../constants';
import { gsapInfinitySlider } from '../../slider';
import './ArtIntro.scss';

export default function ArtIntro() {
    const auth = useAuthContext();

    const { isMobile } = useBreakpoint();

    const { notification } = App.useApp();

    useEffect(() => {
        auth.whoami();
    }, [auth.whoami]);

    useEffect(() => {
        gsapInfinitySlider('.art-intro__wrapper__col.first .art-slide', { speed: 1.4, direction: 1 });
        gsapInfinitySlider('.art-intro__wrapper__col.second .art-slide', { speed: 1.75, direction: 1 });
        gsapInfinitySlider('.art-intro__wrapper__col.third .art-slide', { speed: 1, direction: 1 });
    });

    const onAccept = async () => {
        try {
            await auth.updateProfile({
                modalActiveStatuses: { artIntro: false }
            });
        } catch (e) {
            notification.error({
                placement: 'top',
                message: 'Error',
                description: 'Something went wrong. Please try again later.'
            });
        }
    };

    if (!auth?.profile?.modalActiveStatuses?.artIntro) return null;

    return (
        <div className='art-intro'>
            <div className='art-intro__wrapper'>
                <div className='art-intro__wrapper__modal'>
                    <Typography.Title level={1}>JUST ART</Typography.Title>
                    <Typography.Paragraph>
                        At Just Think, our cutting-edge AI technology can turn your ideas into one-of-a-kind,
                        breathtaking atwork in a matter of seconds. Empower your creativity with Just Think.
                    </Typography.Paragraph>
                    <button onClick={onAccept}>Get Started</button>
                    <a>Use subject to our Terms & Conditions</a>
                </div>
                {!isMobile &&
                    artIntroCols.map((element, col) => {
                        return (
                            <div key={col} className={'art-intro__wrapper__col ' + element.column}>
                                {element.slides.map((slide, key) => {
                                    return (
                                        <div key={key} className='art-slide'>
                                            <img src={slide.image} alt={slide.text} />
                                            <p>{'"' + slide.text + '"'}</p>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                {isMobile && (
                    <div className='art-intro__wrapper__col first'>
                        {artIntroCols.map((element) => {
                            return element.slides.map((slide, key) => {
                                return (
                                    <div key={key} className='art-slide'>
                                        <img src={slide.image} alt={slide.text} />
                                        <p>{'"' + slide.text + '"'}</p>
                                    </div>
                                );
                            });
                        })}
                    </div>
                )}
            </div>
        </div>
    );
}
