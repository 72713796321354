import React, { useEffect } from 'react';
import { CloudDownloadOutlined, DeleteOutlined, PlayCircleOutlined } from '@ant-design/icons';
import { Button, Drawer, Image, List, Skeleton, Spin, Typography } from 'antd';
import moment from 'moment';
import { CDN_URL } from '../../../../../../../../config';
import { useBreakpoint } from '../../../../../../../../hooks/media';
import { uuid } from '../../../../../../../../utils/crypto';
import { formatDate } from '../../../../../../../../utils/date';
import { downloadMp3 } from '../../../../../../../../utils/mp3';
import './index.scss';

export const SpeechList = ({ isVisible, setVisible, actions, state }) => {
    const { isMobile } = useBreakpoint();
    const currentPage = state.history?.pagination?.currentPage || 1;
    const totalPages = state.history.pagination.total || 5;

    useEffect(() => {
        (async () => {
            await actions.speechList.get();
        })();
    }, []);

    const onloadMore = async () => {
        await actions.speechList.get(currentPage + 1);
    };

    const onPlay = async (item) => {
        await actions.speechList.play(item);
        if (isMobile) setVisible(false);
    };

    const onDownload = async (item) => {
        await downloadMp3(item.voiceName, item.url);
    };

    const onRemove = async (item) => {
        await actions.speechList.remove(item);
        await actions.speechList.get(1, true);
    };

    const loadMore =
        totalPages > currentPage * 5 && !state.loadingList ? (
            <Button className='audio-page__list__loadmore' onClick={onloadMore}>
                Load More
            </Button>
        ) : null;

    const list = () => {
        return (
            <div className='audio-page__list'>
                <Spin spinning={state.loader.speech.remove}>
                    <List
                        locale={{
                            emptyText: (
                                <div className='audio-page__list__empty'>
                                    <Image
                                        height='100%'
                                        width='100%'
                                        preview={false}
                                        src={`${CDN_URL}/other/just-think-lightbulb.png`}
                                    />
                                    <Typography.Title level={3}>Generate your first speech</Typography.Title>
                                    <Typography.Text level={3}>
                                        Your generated speech will appear here...
                                    </Typography.Text>
                                </div>
                            )
                        }}
                        loadMore={loadMore}
                        dataSource={state.history.list}
                        renderItem={(item) => {
                            return (
                                <List.Item key={uuid()} className='audio-page__list__item'>
                                    <Skeleton avatar title={false} loading={item.loading} active>
                                        <div className='audio-page__item__main'>
                                            <div className='audio-page__item__main__inner'>
                                                <span>{item.voiceName}</span>
                                                <span>{formatDate(moment(item.createdAt))}</span>
                                            </div>
                                            <div className='audio-page__item__main__text'>
                                                <Typography.Text>{item.fullText}</Typography.Text>
                                            </div>
                                        </div>
                                        <div className='audio-page__item__footer'>
                                            <Button onClick={() => onPlay(item)} icon={<PlayCircleOutlined />}>
                                                Play
                                            </Button>
                                            <Button onClick={() => onDownload(item)} icon={<CloudDownloadOutlined />}>
                                                Download
                                            </Button>
                                            <Button onClick={() => onRemove(item)} icon={<DeleteOutlined />}>
                                                Delete
                                            </Button>
                                        </div>
                                    </Skeleton>
                                </List.Item>
                            );
                        }}
                    />
                </Spin>
            </div>
        );
    };

    return isMobile ? (
        <Drawer
            getContainer={() => document.querySelector('#app-layout')}
            height='80%'
            placement='bottom'
            onClose={() => setVisible(false)}
            closable
            open={isVisible}
        >
            {list()}
        </Drawer>
    ) : (
        list()
    );
};
