import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { EmailShareButton, FacebookShareButton, ViberShareButton } from 'react-share';
import Icon, { FacebookOutlined, LinkOutlined, MailOutlined } from '@ant-design/icons';
import { Col, Modal, Popover, Row, Typography } from 'antd';
import ViberIcon from '../../../../../../../../components/Icons/Viber/ViberIcon';
import { BACKEND_URL } from '../../../../../../../../config';

export const Share = ({ state, actions }) => {
    const [activeValue, setActiveValue] = useState(0);

    const [open, setOpen] = useState(false);

    useEffect(() => {
        const clearPopover = setTimeout(() => {
            setOpen(() => false);
        }, 2000);

        return () => clearTimeout(clearPopover);
    });

    const videoUrl = window.location.origin + '/did-video/' + state.modals.share.id;

    const shareIcons = [
        {
            item: (
                <ViberShareButton url={window.location.origin + BACKEND_URL + '/share/html/' + state.modals.share.id}>
                    <Icon component={ViberIcon} />
                </ViberShareButton>
            )
        },
        {
            item: (
                <FacebookShareButton
                    url={window.location.origin + BACKEND_URL + '/share/html/' + state.modals.share.id}
                >
                    <FacebookOutlined />
                </FacebookShareButton>
            )
        },
        {
            item: (
                <EmailShareButton url={videoUrl}>
                    <MailOutlined />
                </EmailShareButton>
            )
        },
        {
            item: (
                <Popover content='Link copied' open={open}>
                    <CopyToClipboard text={videoUrl}>
                        <LinkOutlined onClick={() => setOpen(true)} />
                    </CopyToClipboard>
                </Popover>
            )
        }
    ];

    return (
        <Modal
            getContainer={() => document.querySelector('#app-layout')}
            width='auto'
            closable
            centered
            className='img-to-video__share'
            title={<Typography.Title level={3}>Share</Typography.Title>}
            open={state.modals.share.status}
            onCancel={() => actions.modals.history({ id: '', status: false })}
            footer={null}
        >
            <Row gutter={[32, 32]} className='img-to-video__share__list'>
                {shareIcons.map((element, key) => {
                    return (
                        <Col
                            key={key}
                            className={activeValue === key ? 'active' : ''}
                            onClick={() => setActiveValue(key)}
                        >
                            {element.item}
                        </Col>
                    );
                })}
            </Row>
        </Modal>
    );
};
