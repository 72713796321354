/* eslint-disable */

const config = {
    _id: 127,
    title: 'Tenant Screening Questionnaire',
    alias: 'tenant-screening-questionnaire',
    about: "The Tenant Screening Questionnaire Designer is a tool for landlords and property managers to create a comprehensive questionnaire for potential tenants. By providing relevant details, it helps in understanding the tenant's background, rental history, financial stability, and more.",
    categories: ['Real Estate'],
    tips: [
        'Be clear about the specific information you need from potential tenants. This could include financial stability, rental history, or lifestyle habits.'
    ],
    prompts: [
        'Generate a tenant screening questionnaire focusing on financial stability and rental history.',
        'Create a questionnaire for potential tenants of a pet-friendly apartment.',
        'Draft a questionnaire for potential tenants of a family-friendly property.',
        'Create a tenant questionnaire focusing on long-term leasing.',
        'Generate a tenant screening questionnaire for a short-term holiday rental property.'
    ]
};

export default config;
