const config = {
    _id: 104,
    title: 'Educational Game Creator',
    alias: 'educational-game-creator',
    about: 'Helps teachers create educational games tailored to their subject matter and student level.',
    categories: ['Education'],
    tips: [
        'Be specific about the learning objectives and student level for appropriate game design.',
        'Regularly review and revise the games based on student feedback and performance.'
    ],
    prompts: [
        'Create a trivia game for [subject] for [grade level].',
        'Create a set of game rules for a [subject] trivia quiz for [grade level] students.',
        'How can I integrate assessment into a game about [specific topic]?',
        'How can I turn [specific topic] into a fun group activity?',
        'Design an educational game for [grade level] students to reinforce [specific topic].'
    ]
};

export default config;
