import React, { useEffect } from 'react';
import { App } from 'antd';
import PropTypes from 'prop-types';
import { useAuthContext } from '../../context';

export default function Join({ history }) {
    const [loading, setLoading] = React.useState(false);

    const auth = useAuthContext();

    const { notification } = App.useApp();

    useEffect(() => {
        const onMount = async () => {
            setLoading(() => true);

            const searchParams = new URLSearchParams(window.location.search);

            const userOrgId = searchParams.get('o');

            const userId = searchParams.get('u');

            try {
                await auth.org.join(userOrgId, userId);

                notification.success({
                    placement: 'top',
                    message: 'Success',
                    description: 'You have successfully joined the organization, please login to continue'
                });
            } catch (error) {
                notification.error({
                    placement: 'top',
                    message: 'Something went wrong',
                    description: error.response?.data?.message || 'Server error'
                });
            }

            setLoading(() => false);

            history.push('/');
        };

        onMount();
    }, []);

    return <div>{loading ? 'loading' : 'done'}</div>;
}

Join.propTypes = {
    history: PropTypes.object.isRequired
};
