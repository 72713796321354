import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PUBLISH_KEY } from '../../../config';
import { useAuthContext } from '../../../page/AuthPage/context';
import StripeCheckoutForm from '../StripeCheckoutForm';
import './index.scss';

const stripePromise = loadStripe(STRIPE_PUBLISH_KEY);

export default function Stripe({ onSetupSuccess, setLoader, billing, paymentValue = null }) {
    const auth = useAuthContext();

    const clientSecret = billing.state.intent.clientSecret;

    const isDarkMode = auth?.profile?.uiSettings?.darkMode;

    const options = {
        clientSecret,
        appearance: {
            theme: isDarkMode ? 'night' : 'default'
        }
    };

    return (
        <div className='App'>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <StripeCheckoutForm
                        onSetupSuccess={onSetupSuccess}
                        setLoader={setLoader}
                        paymentValue={paymentValue}
                    />
                </Elements>
            )}
        </div>
    );
}
