const config = {
    _id: 65,
    title: 'Create a Buyer Persona',
    alias: 'create-a-buyer-persona',
    about: 'Creating a buyer persona is as easy as chatting with your grandma. Get ready to take your marketing game to the next level and truly connect with your customers.',
    categories: ['Marketing'],
    tips: [
        'Specify what type of information you are looking for (e.g. demographics, behaviors, motivations, etc.).',
        'Consider using follow-up questions to clarify or expand on previous answers.'
    ],
    prompts: [
        'What type of lifestyle would someone have if they were in the market for [product/service]?',
        'What are the common pain points that someone might have, who is looking to purchase [product/service]?',
        'What are the values, interests, and hobbies of the individuals who would buy [product/service]?',
        'What type of person would purchase [product/service] for their daily use?',
        'What type of person would purchase [product name] for their daily use?'
    ]
};

export default config;
