/* eslint-disable */

const config = {
    _id: 78,
    title: 'Create Meta Descriptions',
    alias: 'create-meta-descriptions',
    about: 'Create meta descriptions for web pages. Just Think can help you generate effective and relevant meta descriptions that accurately describe your content and entice users to click through to your website.',
    categories: ['Website & SEO'],
    tips: [
        'Use relevant and specific keywords in your meta descriptions to help search engines understand what your content is about and improve your ranking.',
        'Keep your meta descriptions concise and to the point, highlighting the most important information about your content within the character limit.'
    ],
    prompts: [
        'I need help creating a meta description for my web page on [topic]. It should be [length] characters long and include [keywords], which are related to [specific aspect of topic]. Additionally, I want to highlight [unique value proposition] in the meta description. Can you help me craft one that\'s both descriptive and persuasive?',
        'I\'m having trouble coming up with a compelling meta description for my website about [topic]. Can you provide me with [number] options that are [length] characters long and include [keywords], as well as [additional feature] that sets my content apart? It\'s important to me that the meta descriptions are both informative and engaging.',
        "I'm looking to write a meta description for my webpage about [topic], but I'm not sure how to structure it for maximum impact. Can you help me create one that incorporates [length] characters, the right [keywords] and a strong [call to action/unique selling point] that will attract users to click through to my website?",
        'Can you give me some guidance on best practices for writing effective meta descriptions for my website? Specifically, I\'m looking for [number] examples that are related to my [topic], and that follow the most recent [SEO trends/guidelines]. I\'d also like to know how to incorporate [unique aspect of content] in the meta description for maximum effect.',
        'I\'m interested in optimizing my website\'s meta descriptions for search engines. Can you walk me through the process for my [specific type of website/business], including how to identify the right [keywords], how to structure the meta description to be [length] characters long, and how to craft a [unique value proposition] that stands out to users?'
    ]
};

export default config;
