import React from 'react';
import { Input } from 'antd';
import styles from './TextAreaForm.module.scss';

const { TextArea } = Input;

export default function TextAreaForm() {
    return (
        <TextArea
            className={styles.text_area_form}
            maxLength={1500}
            autoSize={{ minRows: 11, maxRows: 20 }}
            placeholder='Start typing your story here'
        />
    );
}
