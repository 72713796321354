import React from 'react';

export default function ViberIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='36' height='36' fill='none' viewBox='0 0 36 36'>
            <g fill='#fff' clipPath='url(#clip0_335_1487)'>
                <path d='M26.096 21.522c-.438-.225-2.62-1.294-3.025-1.438-.406-.153-.703-.226-.997.224-.297.447-1.14 1.439-1.406 1.74-.257.298-.518.334-.956.113-2.604-1.302-4.311-2.323-6.027-5.268-.454-.783.454-.727 1.302-2.418.145-.298.072-.55-.04-.776-.113-.225-.997-2.402-1.366-3.29-.358-.864-.727-.744-.997-.76-.257-.016-.55-.016-.848-.016-.297 0-.775.113-1.18.55-.406.447-1.552 1.52-1.552 3.697 0 2.178 1.587 4.287 1.804 4.584.225.298 3.122 4.766 7.57 6.69 2.813 1.213 3.913 1.318 5.32 1.109.855-.129 2.62-1.069 2.985-2.11.366-1.036.366-1.924.257-2.109-.108-.197-.406-.31-.844-.522z' />
                <path d='M34.602 11.025a18.005 18.005 0 00-3.87-5.758 17.853 17.853 0 00-5.757-3.869A17.914 17.914 0 0018 0h-.08A17.959 17.959 0 005.203 5.32a17.837 17.837 0 00-3.825 5.737A18.007 18.007 0 000 18.084c.014 2.79.674 5.54 1.929 8.032v6.107a1.848 1.848 0 001.848 1.848h6.111A18.056 18.056 0 0017.92 36h.084c2.407 0 4.741-.466 6.94-1.378a17.86 17.86 0 005.737-3.825A17.957 17.957 0 0036 18.08a17.93 17.93 0 00-1.399-7.056zm-6.071 17.598a14.867 14.867 0 01-10.53 4.323h-.07a15 15 0 01-6.954-1.747l-.338-.181H4.982V25.36l-.18-.338a14.999 14.999 0 01-1.748-6.955c-.016-4.006 1.515-7.766 4.323-10.599 2.804-2.832 6.553-4.4 10.559-4.415h.068c2.01 0 3.958.39 5.794 1.16a14.85 14.85 0 014.781 3.215 14.87 14.87 0 013.215 4.781 14.885 14.885 0 011.16 5.858c-.023 4.002-1.594 7.75-4.423 10.555z' />
            </g>
            <defs>
                <clipPath id='clip0_335_1487'>
                    <path fill='#fff' d='M0 0H36V36H0z' />
                </clipPath>
            </defs>
        </svg>
    );
}
