import React from 'react';
import { App, Select } from 'antd';
import { useAuthContext } from '../../../AuthPage/context';

export default function OrgSelect() {
    const auth = useAuthContext();

    const currentOrg = auth.org.getCurrent();

    const [loading, setLoading] = React.useState(false);

    const { notification } = App.useApp();

    const handleChange = async (value) => {
        setLoading(() => true);

        try {
            await auth.org.switch(value);
            setLoading(() => false);
            window.location.reload();
        } catch (error) {
            notification.error({
                placement: 'top',
                message: error.response?.data?.message || 'Something went wrong'
            });
        }
    };

    if (auth.profile.organizations?.length < 2) return null;

    return (
        <Select
            className='org-select'
            disabled={loading}
            loading={loading}
            style={{ width: 'calc(100% - 10px)', margin: '10px 5px 0 5px' }}
            onChange={handleChange}
            value={currentOrg?._id}
            options={
                auth.profile.organizations?.map((userOrg) => ({
                    label: userOrg.label,
                    value: userOrg._id,
                    disabled: userOrg.status !== 'active'
                })) || []
            }
        />
    );
}
