import React, { useState } from 'react';
import { CaretUpOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Form, Slider as SliderAntd, Tooltip, Typography } from 'antd';
import './index.scss';

const marksStability = {
    0: (
        <div>
            Dynamic
            <Tooltip title='Odd performances that are overly random and cause the character to speak too quickly.'>
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    ),
    1: (
        <div>
            Stable
            <Tooltip title='Monotonous voice with limited emotion.'>
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    )
};

const marksClarity = {
    0: (
        <div>
            Low
            <Tooltip title='Min adhere to the original voice.'>
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    ),
    1: (
        <div>
            High
            <Tooltip title='Max adhere to the original voice.'>
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    )
};

const marksStyle = {
    0: 'None',
    1: (
        <div>
            Exaggerated
            <Tooltip title='It does consume additional computational resources and might increase latency.'>
                <InfoCircleOutlined />
            </Tooltip>
        </div>
    )
};

export const Accordion = ({ form }) => {
    const [activeKey, setActiveKey] = useState('');
    const onChange = () => {
        setActiveKey((prev) => (!prev ? 1 : ''));
    };
    const onChangeToDefault = () => {
        form.setFieldsValue({
            boost: false,
            style: [0.75],
            clarity: [0.75],
            stability: [0.75]
        });
    };

    return (
        <Collapse
            onChange={onChange}
            className='audio-page__accordion'
            expandIconPosition='end'
            activeKey={activeKey}
            expandIcon={({ isActive }) => <CaretUpOutlined rotate={isActive ? 0 : 180} />}
        >
            <Collapse.Panel header='Voice Settings' key={1}>
                <div className='audio-page__accordion__item'>
                    <Typography.Text>Stability</Typography.Text>
                    <Form.Item name='stability'>
                        <SliderAntd marks={marksStability} range min={0} max={1} step={0.01} />
                    </Form.Item>
                </div>
                <div className='audio-page__accordion__item'>
                    <Typography.Text>Clarity + Similarity</Typography.Text>
                    <Form.Item name='clarity'>
                        <SliderAntd marks={marksClarity} range min={0} max={1} step={0.01} />
                    </Form.Item>
                </div>
                <div className='audio-page__accordion__item'>
                    <Typography.Text>Style Exaggeration</Typography.Text>
                    <Form.Item name='style'>
                        <SliderAntd marks={marksStyle} range min={0} max={1} step={0.01} />
                    </Form.Item>
                </div>
                <div className='audio-page__accordion__item boost'>
                    <Form.Item name='boost' valuePropName='checked'>
                        <Checkbox>
                            Speaker Boost
                            <Tooltip title='Boost the similarity to the original speaker.'>
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Checkbox>
                    </Form.Item>
                    <Button onClick={onChangeToDefault}>Default</Button>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
};
