import React from 'react';
import { DeleteOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Popconfirm, Space } from 'antd';
import { download } from '../../../../../../../../utils/chat';

export const Content = ({ hide, onEdit, message, state, onRemove }) => {
    return (
        <div>
            <Button
                style={{ fontWeight: 500, border: '0', padding: '5px 35px 5px 5px' }}
                onClick={(e) => {
                    onEdit(e);
                    hide();
                }}
            >
                <Space>
                    <EditOutlined style={{ fontSize: '20px' }} />
                    Rename
                </Space>
            </Button>
            <br />
            <Button
                style={{ fontWeight: 500, border: '0', padding: '5px 35px 5px 5px' }}
                onClick={() => {
                    download(message.state.data.docs, state.current.title);
                    hide();
                }}
            >
                <Space>
                    <DownloadOutlined style={{ fontSize: '20px' }} />
                    Download
                </Space>
            </Button>
            <br />
            {state.data.list.length > 1 ? (
                <Popconfirm
                    title='Are you sure to delete this conversation?'
                    onConfirm={() => {
                        onRemove();
                        hide();
                    }}
                >
                    <Button
                        style={{ fontWeight: 500, border: '0', padding: '5px 35px 5px 5px' }}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                    >
                        <Space>
                            <DeleteOutlined style={{ fontSize: '20px' }} />
                            Delete
                        </Space>
                    </Button>
                </Popconfirm>
            ) : null}
        </div>
    );
};
