export const categories = [
    {
        key: 'logos',
        title: 'Logos',
        bgGradient: 'radial-gradient(192.53% 172.33% at 0% 0%, #FF916D 38.54%, #ED5E93 72.4%, #ED5E93 99.48%)'
    },
    {
        key: 'stickers',
        title: 'Stickers',
        bgGradient: 'radial-gradient(192.53% 172.33% at 0% 0%, #6A74D1 38.54%, #9B60A5 72.4%, #9B60A5 99.48%)'
    },
    {
        key: 'animals',
        title: 'Animals',
        bgGradient: 'radial-gradient(192.53% 172.33% at 0% 0%, #FFD700 38.54%, #FFA500 72.4%, #FFA500 99.48%)'
    },
    {
        key: 'illustration',
        title: 'Illustration',
        bgGradient: 'radial-gradient(192.53% 172.33% at 0% 0%, #0288D1 38.54%, #51AFE0 72.4%, #EAF9FE 99.48%)'
    },
    {
        key: 'wallpaper',
        title: 'Wallpaper',
        bgGradient: 'radial-gradient(192.53% 172.33% at 0% 0%, #008A65 38.54%, #1DC198 72.4%, #1DC198 99.48%)'
    },
    {
        key: 'travel',
        title: 'Travel',
        bgGradient: 'radial-gradient(192.53% 172.33% at 0% 0%, #141E30 19%, #243B55 36%, #243B55 50%)'
    },
    {
        key: 'architecture',
        title: 'Architecture',
        bgGradient: 'radial-gradient(192.53% 172.33% at 0% 0%, #141E30 19%, #243B55 36%, #243B55 50%)'
    }
];

export const descriptions = {
    tips: 'Follow these guidelines to maximize your experience and unlock the full potential of your conversations with <a href="/">Just Think AI.</a>',
    prompts: 'Click “Create” and replace placeholders with relevant text for an efficient and streamlined experience.'
};

export const categoryTabs = [
    { key: 'all-art', title: 'All Art' },
    { key: 'favorites', title: 'Favorites' }
]
    .concat(categories)
    .map(({ key, title, bgGradient }) => ({ key, tab: title, bgGradient }));
