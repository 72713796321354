import React from 'react';

export default function ForwardArrow() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <path
                fill='#212121'
                d='M14.296 16.294a1 1 0 101.415 1.414l4.997-5.004a1 1 0 000-1.413L15.71 6.293a1 1 0 00-1.415 1.414L17.59 11H11a8 8 0 00-7.996 7.75L3 19a1 1 0 102 0 6 6 0 015.775-5.996L11 13h6.586l-3.29 3.294z'
            />
        </svg>
    );
}
