/* eslint-disable */
const config = {
    _id: 72,
    title: 'Write Email Newsletters',
    alias: 'write-email-newsletters',
    about: 'Generate email newsletters by providing a prompt with the topic, audience, and tone, generating text, reviewing and personalizing it, formatting the newsletter, and sending it to your audience, with the option to fine-tune the model for improved relevance.',
    categories: ['Marketing'],
    tips: [
        'Be careful when adding or removing quote marks from prompts – they can change your output results. You can always re-adjust your outputs by asking more questions.'
    ],
    prompts: [
        'Write a newsletter for [audience] about [topic]. The tone should be [formal/casual/etc.] and include information about [specific information or key points to include].',
        'Write an email newsletter about the upcoming launch of our new product [product name].',
        'Can you help me write an email newsletter for my business, targeting [target audience] and promoting our new service [service name]?',
        'Generate a newsletter email for my company\'s upcoming event [event name].',
        'Write a newsletter email that promotes our new product [product name] and includes a call-to-action to visit our website for more information.'
    ]
};

export default config;
