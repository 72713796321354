const config = {
    _id: 107,
    title: 'Discussion Question Generator',
    alias: 'discussion-question-generator',
    about: 'Generate thought-provoking discussion questions from diverse texts, foster engaging conversations on various topics.',
    categories: ['Education'],
    tips: [
        'Insert the specific text you want to generate discussion questions from.',
        'Choose texts that present diverse perspectives, controversial topics, or thought-provoking ideas.',
        'Review the generated questions and select the most relevant and engaging ones for your discussion.'
    ],
    prompts: [
        'Can you provide five discussion questions based on this short story: [Insert the short story here]?',
        'Please generate two discussion questions based on artificial intelligence.',
        'Provide three discussion questions based on climate change.'
    ]
};

export default config;
