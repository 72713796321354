import React, { useState } from 'react';
import { Button, Checkbox, Collapse, Drawer, Typography } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import { useBreakpoint } from '../../../../../../hooks/media';
import { extractVideoId } from '../../../../../../utils/youtubeCode';
import './index.scss';

export const KnowledgeBaseDrawer = ({ conversation }) => {
    const { isMobile } = useBreakpoint();
    const { state, actions } = conversation;
    const [checked, setChecked] = useState(state.current?.embeddings);

    const onChange = async (e, id, type) => {
        const checkedByType = checked?.[type];
        if (checkedByType?.includes(id)) {
            setChecked((prev) => {
                return { ...prev, [type]: checkedByType.filter((el) => el !== id) };
            });
        } else {
            setChecked((prev) => {
                return { ...prev, [type]: [...checkedByType, id] };
            });
        }
        await actions.knowledgeBase.chat(state.current?._id, id, e.target.checked, type);
    };

    const generateItem = (element, type) => {
        const isChecked = checked?.[type]?.includes(element._id);

        return {
            key: element._id,
            label: (
                <div className={cn({ knowledge_wrapper__item__title: true, checked: isChecked })}>
                    {element.fileName}
                </div>
            ),
            children: (
                <div className={cn({ knowledge_wrapper__item: true })}>
                    <div className='knowledge_wrapper__item__checkbox'>
                        <Checkbox checked={isChecked} onChange={(e) => onChange(e, element._id, type)}>
                            Use In Current Chat
                        </Checkbox>
                    </div>
                    <div className='knowledge_wrapper__item__content'>
                        {element.url && type === 'video' ? (
                            <iframe
                                width='100%'
                                src={`https://www.youtube.com/embed/${extractVideoId(element.url)}`}
                                allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                allowFullScreen
                            />
                        ) : null}
                    </div>
                    <div className='knowledge_wrapper__item__bottom'>
                        <span>Trained</span>
                        <p>{moment(element.createdAt).format('MM-DD-YYYY')}</p>
                    </div>
                </div>
            )
        };
    };

    const videos = state.knowledgeBase.list?.video?.map((element) => generateItem(element, 'video'));
    const documents = state.knowledgeBase.list?.document?.map((element) => generateItem(element, 'document'));

    return (
        <Drawer
            className='knowledge_wrapper'
            title='Knowledge Base'
            placement='right'
            closable
            open={state.knowledgeBase.drawer}
            onClose={() => actions.knowledgeBase.drawer(false)}
            size='large'
            width={isMobile ? '100%' : '455px'}
            getContainer={() => document.querySelector('#app-layout')}
        >
            <div className='knowledge__inner'>
                <Button className='knowledge__inner__btn'>Add Knowledge</Button>
                <Typography.Title level={3}>Video</Typography.Title>
                <Collapse items={videos} />
                <Typography.Title level={3}>Document</Typography.Title>
                <Collapse items={documents} />
            </div>
        </Drawer>
    );
};
