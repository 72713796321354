import React from 'react';
import { Button } from 'antd';
import styles from './BtnSubmitForm.module.scss';

export default function BtnNextForm({ textBtn, type, nextStep }) {
    return (
        <Button className={styles.button_submit} type={type} htmlType='button' onClick={nextStep}>
            {textBtn}
        </Button>
    );
}
