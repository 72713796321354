import React, { useEffect, useState } from 'react';
import Markdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import atomDark from 'react-syntax-highlighter/src/styles/prism/atom-dark';
import { Avatar, Flex, List, Typography } from 'antd';
import cn from 'classnames';
import moment from 'moment';
import remarkGfm from 'remark-gfm';
import thinkLogo from '../../assets/images/just-think-ai-icon.jpg';
import PageLoader from '../../components/PageLoader/PageLoader';
import { uuid } from '../../utils/crypto';
import { useAuthContext } from '../AuthPage/context';
import NotFound from './components/NotFound';
import { conversationService } from './service';
import styles from './index.module.scss';

export const ShareChat = () => {
    const { profile } = useAuthContext();
    const currentUrl = window.location.href;
    const [data, setData] = useState({});
    const [error, setError] = useState(false);
    const shareId = currentUrl.split('/').pop();

    useEffect(() => {
        (async () => {
            try {
                const response = await conversationService.share(shareId);

                setData(() => response);
            } catch (err) {
                setError(true);
            }
        })();
    }, []);

    if (error) {
        return <NotFound profile={profile} />;
    }

    if (!data.conversation) {
        return <PageLoader />;
    }

    return (
        <Flex vertical className={styles.share_chat}>
            <Flex className={styles.share_chat__heading} vertical>
                <Typography.Title level={1}>{data.conversation.title}</Typography.Title>
                <Typography.Text>{moment(data.conversation.createdAt).format('MMMM DD, YYYY')}</Typography.Text>
            </Flex>
            <Flex className={styles.share_chat__info}>
                <Typography.Text>
                    This chat may be customized based on specific information from the person who started it,
                    {/* eslint-disable-next-line react/no-unescaped-entities */}
                    influencing the AI's responses. This information is private and enhances the conversation's
                    relevance.
                </Typography.Text>
            </Flex>
            <Flex vertical className={styles.share_chat__list}>
                <List itemLayout='vertical' size='large' split={false}>
                    {data.message?.docs.length &&
                        data.message?.docs.reverse()?.map((item) => {
                            return (
                                <Flex vertical key={uuid()}>
                                    <List.Item
                                        className={cn(
                                            styles.share_chat__item,
                                            { user: true },
                                            { align: item.content.length < 100 }
                                        )}
                                    >
                                        <div className='chat-message-logo'>
                                            {data.conversation.user?.avatar ? (
                                                <img alt='user-logo' src={data.conversation.user?.avatar} />
                                            ) : (
                                                <Avatar
                                                    style={{
                                                        backgroundColor: data.conversation.user?.uiSettings?.profileBg
                                                    }}
                                                >
                                                    {data.conversation?.user?.fname?.charAt(0).toLocaleUpperCase()}
                                                </Avatar>
                                            )}
                                        </div>
                                        {item.content}
                                    </List.Item>
                                    <List.Item
                                        className={cn(
                                            styles.share_chat__item,
                                            { respond: true },
                                            { align: item.respond.length < 100 }
                                        )}
                                    >
                                        <div>
                                            <img alt='just-think-logo' src={thinkLogo} />
                                        </div>
                                        <div className='chat-message-response'>
                                            <Markdown
                                                remarkPlugins={[remarkGfm]}
                                                components={{
                                                    code(props) {
                                                        const { children, className, node, ...rest } = props;

                                                        const match = /language-(\w+)/.exec(className || '');

                                                        return match ? (
                                                            <SyntaxHighlighter
                                                                showLineNumbers
                                                                {...rest}
                                                                PreTag='div'
                                                                language={match[1]}
                                                                style={atomDark}
                                                            >
                                                                {String(children).replace(/\n$/, '')}
                                                            </SyntaxHighlighter>
                                                        ) : (
                                                            <code {...rest} className={className}>
                                                                {children}
                                                            </code>
                                                        );
                                                    }
                                                }}
                                            >
                                                {item.respond}
                                            </Markdown>
                                        </div>
                                    </List.Item>
                                </Flex>
                            );
                        })}
                </List>
            </Flex>
            <Flex align='center' justify='center' className={styles.share_chat__signup}>
                <Link to={profile ? '/' : '/auth/signup'}>Get Started With JustThink</Link>
            </Flex>
        </Flex>
    );
};
