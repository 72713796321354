/* eslint-disable */

const config = {
    _id: 123,
    title: 'Rent Increase Notification',
    alias: 'rent-increase-notification ',
    about: 'An AI tool that generates compliant and considerate rent increase notifications, including all necessary details and justifications.',
    categories: ['Real Estate'],
    tips: [
        'When composing rent increase notifications, be clear about the reasons for the increase, and give adequate notice as per your local regulations.'
    ],
    prompts: [
        "Create a rent increase notification for tenant [tenant's name] in [property type].",
        'What justifications should I include in a rent increase notification?',
        'How much notice should I provide with a rent increase notification?',
        'Generate a rent increase notification based on [specific percentage or amount] for tenant [tenant\'s name].',
        'Draft a rent increase notification for multiple properties that I manage.',
        'Create a rent increase notification letter in light of recent property improvements.',
        "Draft a rent increase notification to tenant [tenant's name] providing alternative options if they cannot afford the increase.",
        'Generate a rent increase notice highlighting the current market trends supporting the increase.'
    ]
};

export default config;
