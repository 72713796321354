const config = {
    _id: 64,
    title: 'Generating long-tail keywords',
    alias: 'generating-long-tail-keywords',
    about: 'Boost your SEO game effortlessly with Just Think AI. Generate unique, targeted long-tail keywords to make your content stand out and reach the right audience.',
    categories: ['Marketing'],
    tips: [
        'Define the topic clearly: To get better results from Just Think AI, provide a clear and specific definition of what you are looking for in regard to generating long-tail keywords.',
        'Clearly define your target audience: Understand who your target audience is and what their needs and interests are, to help generate long-tail keywords that are relevant and specific to them.'
    ],
    prompts: [
        'Can you define [keyword] in a few words?',
        'What is a short description for [keyword]?',
        'What is the central idea of [keyword]?',
        'What is the core of [keyword]?',
        'What are the key elements of [keyword]?'
    ]
};

export default config;
