const config = {
    _id: 96,
    title: 'Lesson Planner Pro',
    alias: 'lesson-planner-pro',
    about: 'Generate comprehensive lesson plans based on curriculum requirements and student needs.',
    categories: ['Education'],
    tips: [
        'Incorporate student feedback into your requests to better tailor lesson plans.',
        'Use specific curriculum goals in your prompts.'
    ],
    prompts: [
        'Generate a week-long lesson plan for teaching [specific topic] to [grade level].',
        'Suggest a set of engaging activities to help [grade level] students understand [specific topic].',
        'Provide a plan for a [subject] project on [topic] suitable for [grade level] students.',
        'Suggest some hands-on activities for a lesson plan on [specific topic] for [grade level].',
        'Devise a plan for teaching [specific topic] to [grade level] with a focus on inclusivity and differentiated instruction.'
    ]
};

export default config;
