import { axios } from '../../../../../../services/http/axios';

export const speechService = {
    convert: async ({ values, presenter }) => {
        const response = await axios.post('/speech/convert', { values, presenter });

        return response.data;
    },
    list: async (page = 1, limit = 5) => {
        const response = await axios.post('/speech/list', { page, limit });

        return response.data;
    },
    remove: async (ids) => {
        const response = await axios.post('/speech/remove', ids);

        return response.data;
    }
};

export const voiceService = {
    create: async (formData) => {
        const response = await axios.post('/speech/voices', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response.data;
    },
    list: async () => {
        const response = await axios.get('/speech/voices');

        return response.data;
    },
    get: async (id) => {
        const response = await axios.get(`/speech/voices/${id}`);

        return response.data;
    },
    delete: async (id) => {
        const response = await axios.delete(`/speech/voices/${id}`);

        return response;
    }
};
