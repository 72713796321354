import React from 'react';

export default function BoldIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <path
                fill='url(#paint0_diamond_3453_1684)'
                d='M16.977 11.18a5.178 5.178 0 001.452-3.596V7.31c0-2.882-2.36-5.22-5.27-5.22H5.235a.735.735 0 00-.734.734v18.19c0 .437.354.79.79.79h8.537c3.134 0 5.673-2.523 5.673-5.638v-.295a5.626 5.626 0 00-2.523-4.69zM7.07 4.662h6.02c1.529 0 2.766 1.19 2.766 2.66v.255c0 1.467-1.24 2.66-2.767 2.66H7.071V4.66zm9.82 11.502c0 1.685-1.385 3.051-3.094 3.051H7.071v-6.391h6.726c1.71 0 3.094 1.366 3.094 3.05v.29z'
            />
            <defs>
                <radialGradient
                    id='paint0_diamond_3453_1684'
                    cx='0'
                    cy='0'
                    r='1'
                    gradientTransform='rotate(60.772 .468 4.882) scale(38.9308 40.5156)'
                    gradientUnits='userSpaceOnUse'
                >
                    <stop offset='0.385' stopColor='#0288D1' />
                    <stop offset='0.724' stopColor='#51AFE0' />
                    <stop offset='0.995' stopColor='#EAF9FE' />
                </radialGradient>
            </defs>
        </svg>
    );
}
