import React from 'react';
import Icon from '@ant-design/icons';
import { Collapse, Typography } from 'antd';
import ArrowBottom from '../../../../../../../../components/Icons/ArrowBottom/ArrowBottom';
import ForwardArrow from '../../../../../../../../components/Icons/ForwardArrow/ForwardArrow';

const { Panel } = Collapse;

export const QuickLibrary = ({ actions, prompts, collapsed, message }) => {
    const onPromptClick = (e) => {
        message.actions.updateInput(e.target.innerText);
        actions.promptsOpen(false);
        collapsed(false);
    };

    return (
        <React.Fragment>
            {prompts.length ? (
                <Collapse expandIcon={ArrowBottom} className='prompts-accordion' defaultActiveKey={['0']}>
                    {prompts.map((element, key) => {
                        return (
                            <Panel header={element.title} key={key}>
                                {element.list.map((question, index) => {
                                    return (
                                        <div className='prompt-link' key={index}>
                                            <Icon component={ForwardArrow} />
                                            <a onClick={onPromptClick} key={index}>
                                                {question}
                                            </a>
                                        </div>
                                    );
                                })}
                            </Panel>
                        );
                    })}
                </Collapse>
            ) : (
                <Typography.Title level={3} style={{ padding: '8px' }}>
                    Nothing Found
                </Typography.Title>
            )}
        </React.Fragment>
    );
};
