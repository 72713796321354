/* eslint-disable */

const config = {
    _id: 92,
    title: 'Keyword Research',
    alias: 'keyword-research',
    about: 'Enhances your keyword research process by providing valuable insights and suggestions.',
    categories: ['Website & SEO'],
    tips: [
        'Be specific about your target audience and industry when requesting keyword suggestions from Just Think, as this will help ensure that the generated keywords are relevant and effective for lead generation purposes.',
        'Experiment with different variations and combinations of keywords to identify the ones that perform best in generating leads for your business.',
        'Use Just Think’s suggestions as a starting point for further research and analysis, and be prepared to refine your keyword strategy over time based on your results and feedback.'
    ],
    prompts: [
        'Based on [specific criteria or parameters], can you suggest [number] high-traffic keywords related to [topic or industry] that are most likely to generate leads for my business?',
        'What are the most effective [type of keyword] keywords for lead generation in [location] that [describe a unique feature or attribute]?',
        'Can you analyze [number] of my competitor\'s websites and suggest [adjective] keywords that they are targeting for lead generation purposes in [industry or niche]?',
        'How can I use [specific keyword research tool or feature] to identify [adjective] long-tail keywords that have [certain level of search volume or competition] in [industry or location] for lead generation?',
        'Can you provide [number] alternative keywords or phrases that are similar to [specific keyword or phrase] and may generate more leads for my business in [industry or niche]?'
    ]
};

export default config;
