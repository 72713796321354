import React from 'react';
import { Button } from 'antd';
import cn from 'classnames';
import { useBreakpoint } from '../../../../../../../../hooks/media';
import style from './index.module.scss';

const AnimatedShadow = ({ delay }) => {
    const startingWidth = (delay / 30) * 100;

    const variables = {
        '--delay-duration': `${delay}s`,
        '--starting-width': `${startingWidth}%`
    };

    return <div className={style.animated} style={variables} />;
};

const ResendVerificationButton = ({ disabled, delay, onClick, children }) => {
    const { isMobile } = useBreakpoint();

    return (
        <Button
            block={isMobile}
            className={cn({
                [style.disabled]: disabled,
                [style.profile__resend__button]: true
            })}
            type='dashed'
            onClick={onClick}
            disabled={disabled}
        >
            {disabled ? <AnimatedShadow delay={delay} /> : null}
            {children}
        </Button>
    );
};

export default ResendVerificationButton;
