import React from 'react';

export default function LinkIcon() {
    return (
        <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none' viewBox='0 0 24 24'>
            <path
                fill='#000'
                fillOpacity='0.85'
                d='M13.66 16.107a.215.215 0 00-.303 0l-3.112 3.112c-1.441 1.441-3.873 1.594-5.464 0-1.594-1.593-1.441-4.023 0-5.464l3.112-3.112a.215.215 0 000-.303L6.827 9.274a.215.215 0 00-.303 0l-3.112 3.112a5.79 5.79 0 000 8.197 5.793 5.793 0 008.196 0l3.113-3.113a.215.215 0 000-.302l-1.06-1.061zM20.587 3.41a5.791 5.791 0 00-8.197 0L9.275 6.523a.215.215 0 000 .303l1.064 1.063c.083.083.22.083.302 0l3.113-3.112c1.441-1.442 3.873-1.594 5.464 0 1.594 1.593 1.441 4.023 0 5.464l-3.112 3.112a.215.215 0 000 .303l1.066 1.066c.083.083.22.083.302 0l3.113-3.112a5.8 5.8 0 000-8.2zm-5.96 4.846a.215.215 0 00-.303 0L8.258 14.32a.215.215 0 000 .303l1.061 1.06c.083.084.22.084.303 0l6.064-6.064a.215.215 0 000-.302l-1.058-1.061z'
            />
        </svg>
    );
}
