import React, { useEffect } from 'react';
import { Button, Col, Form, Row, Spin, Typography } from 'antd';
import PlusCircleBorder from '../../../../../../../../components/Icons/PlusCircleBorder/PlusCircleBorder';
import { uuid } from '../../../../../../../../utils/crypto';
import { ModalPromptCreate } from '../ModalPromptCreate';
import SinglePrompt from '../Prompt/Prompt';

export const PromptsList = ({ config, actions, state }) => {
    useEffect(() => {
        (async () => {
            await actions.promptsList(config._id);
        })();
    }, []);

    const onPromptCreate = async () => {
        await actions.modals.createPrompt(true);
    };

    if (!config.categories.includes('My tools')) {
        return (
            <Row
                className='single-tool__prompts'
                gutter={[
                    { xs: 16, sm: 16, md: 32 },
                    { xs: 16, sm: 16, md: 32 }
                ]}
            >
                {config.prompts.map((prompt, key) => {
                    return (
                        <SinglePrompt
                            key={uuid()}
                            text={prompt}
                            number={key}
                            config={config}
                            actions={actions}
                            state={state}
                        />
                    );
                })}
            </Row>
        );
    }

    return (
        <Spin spinning={state.loader.prompts}>
            <Form.Provider>
                <Form name='promptCreateForm'>
                    <Form.Item name='prompts' hidden />
                    <Row
                        className='single-tool__prompts'
                        gutter={[
                            { xs: 16, sm: 16, md: 32 },
                            { xs: 16, sm: 16, md: 32 }
                        ]}
                    >
                        <Form.Item noStyle shouldUpdate>
                            {() => {
                                return (
                                    <React.Fragment>
                                        {state.promptsList.map((prompt, index) => (
                                            <SinglePrompt
                                                key={`prompt-${index}`}
                                                prompt={prompt}
                                                number={index}
                                                config={config}
                                                actions={actions}
                                                state={state}
                                            />
                                        ))}
                                    </React.Fragment>
                                );
                            }}
                        </Form.Item>
                        {config.categories.includes('My tools') && state.promptsList.length < 5 ? (
                            <Col
                                className='prompt-add'
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}
                                xl={{ span: 8 }}
                                xxl={{ span: 8 }}
                            >
                                <Form.Item className='single-tool__prompt single-tool__prompt--new'>
                                    <Typography.Title level={4}>Add prompt</Typography.Title>
                                    <div className='single-tool__prompt__body'>
                                        <Button onClick={onPromptCreate}>
                                            <PlusCircleBorder />
                                        </Button>
                                    </div>
                                </Form.Item>
                            </Col>
                        ) : null}
                    </Row>
                </Form>
                {state.modal.createPrompt ? (
                    <ModalPromptCreate state={state} actions={actions} id={config._id} />
                ) : null}
            </Form.Provider>
        </Spin>
    );
};
