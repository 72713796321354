/* eslint-disable */
export const doodleArt = {
    _id: 2,
    title: 'Doodle Art',
    alias: 'doodle-art',
    about: 'Create doodle art',
    image: 'doodle-art',
    categories: ['illustration'],
    tips: [],
    prompts: [
        {
            text: 'generate a whimsical doodles image of a [subject] [details], kawaii, cartoon drawing, add the text "[text]" in a handwrite style, only the subject on a flat white background without decorations',
            image: 'doodles-image'
        },
        {
            text: 'A doodle-style illustration of a vibrant [everyday object] with a splatter effect, and the inspiring phrase [short positive phrase] at its center. The background is a white, complementing the playful and whimsical nature of the doodle art. The colors are bright and cheerful, with a hand-drawn, sketch-like quality. The phrase [\'short positive phrase] is written in a simple, yet bold and creative font typical of doodle art, adding to the overall charm and lightheartedness of the image.',
            image: 'doodle-style'
        },
        {
            text: 'Create a doodle style illustration that is playful and spontaneous of a [simple whimsical elements]. Use simple, free-flowing lines and minimal colors, or even just black and white. The doodle should reflect a hand-drawn, casual feel.',
            image: 'doodle-illustration'
        }
    ]
};
