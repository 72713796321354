/* eslint-disable */

const config = {
    _id: 73,
    title: 'Write Sales Emails',
    alias: 'write-sales-emails',
    about: 'Boost your sales email writing with these techniques: provide specific information to generate introduction emails, utilize completion function to finish drafts, or engage in conversation for suggestions and examples.',
    categories: ['Marketing'],
    tips: [
        'Be specific and clear about the product or service you are offering in the sales email. Provide detailed information about its features and benefits, and how it can solve the recipient\'s problem or meet their needs.',
        'Keep the email concise and easy to read. Break up the text with headings, bullet points and images to make it more visually appealing.'
    ],
    prompts: [
        'Write an email introducing our new product [product name] to a potential customer, highlighting its key features and benefits.',
        'Write a follow-up email to a potential customer who has shown interest in our product [product name], addressing any objections they may have and closing the sale.',
        'Write a thank-you email to a customer who has just made a purchase, encouraging them to leave a review and promoting related products.',
        'Write a re-engagement email to a customer who has not made a purchase in the last [X] months, offering them a special deal to come back.',
        'Write an email with a subject line that would entice the customer to open it and a body that will make them want to buy our product [product name].'
    ]
};

export default config;
