const config = {
    _id: 115,
    title: 'Peer Collaboration Facilitator',
    alias: 'peer-collaboration-facilitator',
    about: 'Facilitate peer collaboration by suggesting collaborative activities, grouping students based on their strengths, and providing communication tools.',
    categories: ['Education'],
    tips: [
        'Regularly change group compositions to allow students to work with a variety of peers.',
        'Encourage students to communicate openly and constructively.',
        'Use the AI tool to provide feedback and improvement suggestions to the group.'
    ],
    prompts: [
        'Suggest a collaborative activity for [subject].',
        'Group the students for a project on [topic] based on their strengths.',
        'Provide communication tools for group work.',
        'What roles should each student play in a group project on [topic]?'
    ]
};

export default config;
