import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, List, Spin, Typography } from 'antd';
import PlusBoldIcon from '../../../../../../components/Icons/PlusBoldIcon';
import { useDocument } from '../../hooks';
import { CreateModal } from './components/CreateModal';
import { SingleRow } from './components/SingleRow';
import styles from './index.module.scss';

export default function DocumentHistory({ docToken, setDocToken }) {
    const history = useHistory();

    const { actions, state } = useDocument();

    const [search, setSearch] = useState('');

    const onCreateDocument = async () => {
        await actions.openModal();
    };

    useEffect(() => {
        (async () => {
            await actions.list({ page: 1 });
        })();
    }, []);

    const onSearchChange = async (e) => {
        setSearch(e.target.value);
        await actions.list({ search: e.target.value });
    };

    const dataSorted =
        state.list.data?.map((item, key) => ({
            key: item._id,
            title: item.title || 'Untitled document',
            html: item.html
        })) || [];

    return (
        <div className={styles.history}>
            <div className={styles.history__heading}>
                <Typography.Title level={2}>My Docs</Typography.Title>
                <Typography.Text>Streamline Document Editing with My Docs - The Ultimate Editor</Typography.Text>
            </div>
            <div className={styles.history__buttons}>
                <Button type='primary' onClick={onCreateDocument}>
                    <PlusBoldIcon />
                    New Doc
                </Button>
                <Input
                    value={search}
                    prefix={<SearchOutlined />}
                    style={{
                        color: '1F1F1F'
                    }}
                    onChange={(e) => onSearchChange(e)}
                    placeholder='Search your documents'
                />
            </div>
            <Spin spinning={state.loader.list}>
                <List
                    className={styles.history__table}
                    grid={{
                        gutter: 34,
                        xs: 1,
                        sm: 1,
                        md: 2,
                        lg: 2,
                        xl: 2,
                        xxl: 2
                    }}
                    pagination={{
                        showSizeChanger: false,
                        onChange: async (page) => await actions.list({ page }),
                        total: state.list.pagination.total,
                        current: state.list.pagination.currentPage,
                        pageSize: 10,
                        hideOnSinglePage: true
                    }}
                    renderItem={(item) => {
                        return (
                            <SingleRow
                                state={state}
                                actions={actions}
                                item={item}
                                setDocToken={setDocToken}
                                history={history}
                            />
                        );
                    }}
                    dataSource={dataSorted}
                />
            </Spin>
            {!state.modal.create ? null : (
                <CreateModal
                    history={history}
                    setDocToken={setDocToken}
                    docToken={docToken}
                    state={state}
                    actions={actions}
                />
            )}
        </div>
    );
}
