import React, { createRef } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import { DownloadOutlined } from '@ant-design/icons';
import { Drawer, Spin } from 'antd';
import { downloadMp3 } from '../../../../../../../utils/mp3';

export function AudioPlayerDrawer({ state, actions }) {
    const player = createRef();

    const closePopUp = async () => {
        player.current.audio.current.pause();
        await actions.close();
    };

    return (
        <Drawer
            placement='bottom'
            closable={false}
            height='bottom'
            open={state.audio.popup}
            onClose={closePopUp}
            rootClassName='audio-player'
            getContainer={() => document.querySelector('#app-layout')}
        >
            <Spin spinning={state.audio.loading} tip='Creating audio...'>
                <AudioPlayer
                    header={'Audio from ' + state.voice}
                    src={state.audio.file}
                    ref={player}
                    preload='auto'
                    showJumpControls={false}
                    layout='horizontal-reverse'
                    onLoadedData={() => player.current.audio.current.play()}
                    customAdditionalControls={[
                        // eslint-disable-next-line react/jsx-key
                        <a onClick={() => downloadMp3(state.voice, state.audio.file)}>
                            <DownloadOutlined />
                        </a>
                    ]}
                />
            </Spin>
        </Drawer>
    );
}

export function AudioPlayerSpin({ state }) {
    const player = createRef();

    return (
        <Spin spinning={state.audio.loading} tip='Creating audio...'>
            <AudioPlayer
                header={state.voice ? 'Audio from ' + state.voice : 'No audio file'}
                src={state.audio.file}
                ref={player}
                preload='auto'
                showJumpControls={false}
                layout='horizontal-reverse'
                onLoadedData={() => player.current.audio.current.play()}
                customAdditionalControls={[
                    // eslint-disable-next-line react/jsx-key
                    state.voice ? (
                        <a onClick={() => downloadMp3(state.voice, state.audio.file)}>
                            <DownloadOutlined />
                        </a>
                    ) : null
                ]}
            />
        </Spin>
    );
}
